import { defaultFetch } from "./baseApi";
type GroupType = {
    id: number
    name: string
    partnership: boolean
    pivot: {
        companyId: number
        groupId: number
    },
    createdAt: string
    updatedAt: string
    createdBy: number
    updatedBy: number
}

type ConfigType = {
    id: number
    name: string
    label: string
    description: string | null
    enabled: boolean
    entityType: string
    createdAt: string | null
    updatedAt: string | null
    createdBy: number
    updatedBy: number | null
}

export type CompanyType = {
    address: string | null
    advancedConfig:
    ConfigType[]
    aiEnabled: boolean
    attorney: string | null
    // TODO: add correct types
    businessAreas: []
    businessLines: []
    code: string
    createdAt: string
    createdBy: number
    groupids: GroupType[]
    hasModulePartnership: boolean
    id: number
    legalRepresentative: string | null
    name: string
    oecompanysize: { code: string, name: string, description: string }
    payrollMgmtModule: boolean
    pec: string | null
    powerOfAttorney: number
    sizeCode: string | null
    updatedAt: string
    updatedBy: number
    vat: string
}

export const getCompanies = async () => {
    return defaultFetch<CompanyType[]>('/v0/companies');
}

export const getCompanyById = async (companyId: string) => {
    return defaultFetch<CompanyType>(`/v0/companies/${companyId}`);
}

export type CompanyUserType = {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
};

export const getUsersByCompanyId = async (companyId: string) => {
    return defaultFetch<CompanyUserType[]>(`/v0/companies/${companyId}/users`);
}