import { ColDef, ITooltipParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import CustomTooltip from "components/ag-grid/CustomTooltip";
import { DialogConfirmation } from "components/atomic/molecules";
import { OETrans } from 'components/translation/OETrans';
import { translate } from "components/translation/translation";
import { IcoCanc, IcoEdit, IcoPlus, Lens } from "icone";
import { deleteProjectPartner } from "lib/api_ts/parternshipApi";
import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
} from "react";
import {
  Button,
  ButtonToolbar,
  Col,
  FormControl,
  InputGroup,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { ProjectPartnershipDataType, ShowAlertType } from "./PartnershipTab";
import { Alert } from "components/atomic/atoms";

interface PartnershipTableProps {
  projectPartnershipData: ProjectPartnershipDataType[];
  toggleIsOpenModal: () => void;
  selectedPartnerId: number;
  setSelectedPartnerId?: Dispatch<SetStateAction<number>>;
  projectId: number;
  setIsManagingPartner?: Dispatch<SetStateAction<boolean>>;
  availableRolesData: { code: string; name: string }[];
  // showAlert: ShowAlertType;
  // setShowAlert: Dispatch<SetStateAction<ShowAlertType>>;
}

const PartnershipTable: React.FC<PartnershipTableProps> = ({
  projectPartnershipData,
  toggleIsOpenModal,
  selectedPartnerId,
  setSelectedPartnerId,
  projectId,
  setIsManagingPartner,
  availableRolesData,
  // showAlert,
  // setShowAlert,
}) => {
  const DELAY = 3000;
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const gridRef = React.useRef<AgGridReact>(null);
  const onFilterTextBoxChanged = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      gridRef.current.api.setQuickFilter(e.target.value);
    },
    []
  );

  const [showAlert, setShowAlert] = React.useState<ShowAlertType>({
    show: false,
    message: "",
    type: "",
  });

  const resetDeleteAlert = () => {
    setShowAlert({ show: false, message: "", type: "" });
  };

  const handleDelete = async () => {
    setIsManagingPartner(true);
    try {
      // const result = await deleteProjectPartner(
      await deleteProjectPartner(
        projectId.toString(),
        selectedPartnerId.toString()
      );

      // setShowAlert({
      //   show: true,
      //   message: "Partnership eliminata con successo",
      //   type: "success",
      // });

      // console.log("result", result);
      setShowAlert({
        show: true,
        message: "Partnership eliminata con successo",
        type: "success",
      });
      // setShowDeleteModal(false);
      setTimeout(() => {
        resetDeleteAlert();
        // setShowAlert({ show: false, message: "", type: "" });
      }, DELAY);
    } catch (error) {
      console.error(error);
      setShowAlert({
        show: true,
        message: "Errore durante l'eliminazione della partnership",
        type: "danger",
      });
      setTimeout(() => {
        resetDeleteAlert();
        // setShowAlert({ show: false, message: "", type: "" });
      }, DELAY);
    } finally {
      setShowDeleteModal(false);
      setSelectedPartnerId(undefined);
      setIsManagingPartner(false);
    }
    // console.log("delete");
  };

  const defaultColDef: ColDef = {
    resizable: true,
    flex: 1,
    wrapText: true,
    autoHeight: true,
    suppressMovable: true,
    sortable: true,
    cellStyle: {
      paddingLeft: "1rem",
      paddingRight: "1rem",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
    tooltipComponent: CustomTooltip,
    tooltipValueGetter: (params: ITooltipParams) => {
      return params.value;
    },
    filter: "agTextColumnFilter",
  };

  //     filter: "agTextColumnFilter",
  //     resizable: true,
  //     flex: 2,
  //   };
  const columnDefs = [
    {
      headerName: "Nome",
      headerTooltip: "Nome",
      field: "name",
    },
    {
      headerName: "Acronimo",
      headerTooltip: "Acronimo",
      field: "acronym",
    },
    {
      headerName: "PIC",
      headerTooltip: "PIC",
      field: "pic",
    },
    {
      headerName: "Nazione",
      headerTooltip: "Nazione",
      field: "country",
    },
    {
      headerName: "Ruolo",
      headerTooltip: "Ruolo",
      field: "roleCode",
      valueGetter: (params: { data: { roleCode: string } }) => {
        const role = availableRolesData.find(
          (role) => role.code === params.data.roleCode
        );
        return role ? role.name : params.data.roleCode;
      },
    },
    {
      headerName: "Contatto principale",
      headerTooltip: "Contatto principale",
      field: "contactUserId",
    },
    {
      headerName: "",
      minWidth: 110,
      maxWidth: 110,
      sortable: false,
      cellRenderer: (params: { data: { id: number } }) => {
        const buttons = [
          {
            action: "edit",
            icon: <IcoEdit className="second-color ico-small edit" />,
            tooltip: (
              <OETrans
                type={undefined}
                code="common.tooltip.edit"
                fallback={"Modifica"}
              />
            ),
          },
          {
            action: "delete",
            icon: <IcoCanc className="second-color ico-small cancel" />,
            tooltip: (
              <OETrans
                type={undefined}
                code="common.tooltip.delete"
                fallback={"Elimina"}
              />
            ),
          },
        ];

        return (
          <div className="d-flex justify-content-center align-items-center mt-2">
            {buttons.map((button, index) => (
              <OverlayTrigger
                key={button.action}
                placement="top"
                overlay={<Tooltip>{button.tooltip}</Tooltip>}
              >
                <Button
                  variant="link"
                  className={`me-${index === 0 ? 3 : 0}`}
                  onClick={() => {
                    setSelectedPartnerId(params.data.id);
                    switch (button.action) {
                      case "edit":
                        toggleIsOpenModal();
                        break;
                      case "delete":
                        setShowDeleteModal(true);
                        break;
                      default:
                        break;
                    }
                    // convert params.data.sent and params.data.approved to string
                    // if (typeof params.data.sent !== "string") {
                    //   params.data.sent = params.data.sent === true ? "1" : "0";
                    // }

                    // if (typeof params.data.approved !== "string") {
                    //   params.data.approved =
                    //     params.data.approved === true ? "1" : "0";
                    // }

                    // // Remove createdAt, createdBy, updatedAt, updatedBy
                    // delete params.data.createdAt;
                    // delete params.data.createdBy;
                    // delete params.data.updatedAt;
                    // delete params.data.updatedBy;
                    // setDeliverableData(params.data);
                    // if (button.action === "edit") {
                    //   setShowFormModal(true);
                    // } else if (button.action === "delete") {
                    //   setShowDeleteModal(true);
                    // } else {
                    //   setShowFormModal(true);
                    // }
                  }}
                >
                  {button.icon}
                </Button>
              </OverlayTrigger>
            ))}
          </div>
        );
      },
    },
  ];
  return (
    <>
      <div className="row d-flex align-items-center mb-3">
        <div className="col-12 text-right py-1">
          <ButtonToolbar className="justify-content-end">
            <Button
              variant="success"
              // {...(isLoading && { disabled: true })}
              onClick={toggleIsOpenModal}
            >
              <IcoPlus className="light-color ico-small plus new-button" />
              <span className="d-md-inline">
                  <OETrans type={undefined} code="common.addpartner" fallback={"Aggiungi nuovo partner"}/></span>
            </Button>

            <InputGroup className="search-table align-items-center mt-3 mt-md-0">
              <FormControl
                type="text"
                className="filter-text-box"
                onInput={onFilterTextBoxChanged}
              />
              <Lens />
            </InputGroup>
          </ButtonToolbar>
        </div>
      </div>
      <div className="ag-theme-alpine">
        <AgGridReact
          ref={gridRef}
          columnDefs={columnDefs}
          rowData={projectPartnershipData}
          // rowData={deliverables}
          defaultColDef={defaultColDef}
          domLayout="autoHeight"
          tooltipShowDelay={0}
          tooltipHideDelay={2000}
          tooltipMouseTrack={true}
          onGridReady={null}
          headerHeight={50}
          rowHeight={50}
          // localeText={localeText}
          // ariaDescription="ag-grid-table"
          suppressCellFocus={true}
          // autoSizeStrategy={{ type: "fitCellContents" }}
        />
      </div>
      <DialogConfirmation
        title="Elimina"
        content={translate(
          "common.warning.delete",
          "Sei sicuro di voler eliminare questo elemento?"
        )}
        confirmLabel={translate("common.delete", "Elimina")}
        cancelLabel={translate("common.cancel", "Annulla")}
        onConfirm={handleDelete}
        onCancel={() => setShowDeleteModal(false)}
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
      />
      {showAlert.show && (
        <Row className="pt-3">
          <Col className="col-12 p-0">
            <Alert
              show={showAlert.show}
              message={showAlert.message}
              variant={showAlert.type}
              delay={DELAY}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default PartnershipTable;
