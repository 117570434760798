import { getCompanyById } from "lib/api_ts/companiesApi";
import { getPartnersByCompanyId } from "lib/api_ts/parternshipApi";
import { SelectOptionType } from "lib/api_ts/types";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";

type CompanyType = {
  id: string | number;
  name: string;
};

interface PartnerSectionProps {
  // checkboxOnChange: (e: ChangeEvent<HTMLInputElement>) => void;
  // checkboxChecked?: boolean;
  selectOnChange: (e: SelectOptionType[]) => void;
  selectValue: SelectOptionType[];
  companies: CompanyType[];
  companyId?: string;
}

const PartnerSection: React.FC<PartnerSectionProps> = ({
  // checkboxChecked = true,
  // checkboxOnChange,
  selectValue,
  selectOnChange,
  companies,
  companyId,
}) => {
  const companyOptions = companies
    .map((company) => ({
      value: company.id,
      label: company.name,
    }));
  const [partners, setPartners] = useState<SelectOptionType[]>(
    selectValue || []
  );
  const [hasPartnership, setHasPartnership] = useState(false);
  useEffect(() => {
    if (!companyId) return;

    (async () => {
      try {
        const apiCompany = await getCompanyById(companyId);
        const isPartnershipEnabled = apiCompany.advancedConfig.some(
          (config) => config.name === "has_partnership" && config.enabled
        );
        // console.log("has partnership", isPartnershipEnabled);
        setHasPartnership(isPartnershipEnabled);
      } catch (error) {
        console.error(error);
      }
    })();

    (async () => {
      try {
        const apiPartners = await getPartnersByCompanyId(companyId);
        const formattedApiPartners = apiPartners.map((partner) => ({
          value: partner.id,
          label: partner.name,
        }));
        setPartners(formattedApiPartners);
        // setPartners((prevPartners) => {
        //   const existingPartnerIds = new Set(
        //     prevPartners.map((partner) => partner.value)
        //   );
        //   const mergedPartners = [
        //     ...prevPartners.filter(
        //       (partner) =>
        //         !formattedApiPartners.some(
        //           (apiPartner) => apiPartner.value.toString() === partner.value
        //         )
        //     ),
        //     ...formattedApiPartners.filter(
        //       (apiPartner) =>
        //         !existingPartnerIds.has(apiPartner.value.toString())
        //     ),
        //   ];
        //   return mergedPartners;
        // });
      } catch (error) {
        console.error(error);
      }
    })();
    // getPartnersByCompanyId(companyId).then((apiPartners) => {
    //   const formattedApiPartners = apiPartners.map((partner) => ({
    //     value: partner.id.toString(),
    //     label: partner.name,
    //   }));

    //   setPartners((prevPartners) => {
    //     const existingPartnerIds = new Set(
    //       prevPartners.map((partner) => partner.value)
    //     );

    //     const mergedPartners = [
    //       ...prevPartners.filter(
    //         (partner) =>
    //           !formattedApiPartners.some(
    //             (apiPartner) => apiPartner.value === partner.value
    //           )
    //       ),
    //       ...formattedApiPartners.filter(
    //         (apiPartner) => !existingPartnerIds.has(apiPartner.value)
    //       ),
    //     ];

    //     return mergedPartners;
    //   });
    // });
  }, [companyId]);
  // console.log("partners", partners);
  return hasPartnership ? (
    <>
      <Form.Label htmlFor="hasPartnership" className="mb-3">
        Partenariato <span className="required-field">*</span>
        {/* <OETrans code='companies.aiEnabled' fallback={'Intelligenza artificiale abilitata'}/> */}
        {/* <Form.Check
          inline
          style={{ marginTop: "0.2rem" }}
          className="ms-3"
          type="checkbox"
          id="hasPartnership"
          name="hasPartnership"
          checked={hasPartnership}
          onChange={(e) => {
            setHasPartnership(e.target.checked);
            // checkboxOnChange(e);
          }}
        /> */}
      </Form.Label>
      <Select
        // isDisabled={!hasPartnership}
        components={{
          IndicatorSeparator: () => null,
        }}
        isMulti
        // placeholder={!hasPartnership ? "" : "Seleziona..."}
        placeholder="Seleziona..."
        className="react-select-container"
        classNamePrefix="react-select"
        name="partners"
        isClearable={false}
        options={companyOptions || []}
        value={partners || null}
        onChange={(e: SelectOptionType[]) => {
          setPartners(e);
          selectOnChange(e);
        }}
      />
    </>
  ) : null;
};

export default PartnerSection;
