import React, { useState, useEffect, useContext } from 'react';
import { Button, OverlayTrigger, Tooltip, Modal, Spinner } from 'react-bootstrap';
import { LevelContext } from 'components/contexts/LevelContext';
import { OETrans } from 'components/translation/OETrans';

const OEModal = ({
   children,
   title,
   enabled,
   enabledSave = true,
   fullscreen,
   size,
   btnShowIcon,
   btnShowLabel,
   btnShowTooltip,
   btnShowClassName,
   btnCloseLabel,
   btnSaveLabel,
   handleSubmit,
   handleShow,
   handleClose,
   isSaving,
   disableFooter = false,
   hideFooter = false,
   open = false,
   checkIsOe = false,
  status = null
}) => {
   const { isOe, userRole } = useContext(LevelContext);
   const [showModalEdit, setShowModalEdit] = useState(false);

   useEffect(() => {
      setShowModalEdit(open);
   }, [open]);

   const _handleCloseModal = (setShowModal) => {
      setShowModal(false);
      if (handleClose) {
         handleClose();
      }
   };

   const _handleShowModal = (setShowModal) => {
      setShowModal(true);
      if (handleShow) {
         handleShow();
      }
   };

   const editTooltip = (props) => <Tooltip {...props}>{btnShowTooltip}</Tooltip>;

   return (
      <div>
         {(!checkIsOe || isOe || userRole === 5) && (
            <OverlayTrigger placement='top' overlay={editTooltip}>
               <Button
                  disabled={!enabled}
                  onClick={() => _handleShowModal(setShowModalEdit)}
                  variant='none'
                  className={btnShowClassName}>
                 {status && (isSaving || !enabledSave) ?
                 (<><Spinner as="span" animation="border" size="sm" role="status" />&nbsp; Creazione...  {status.progress}%</>) :
                   (<>{btnShowIcon ? btnShowIcon : null}{btnShowLabel ? btnShowLabel : null}</>)
                 }
               </Button>
            </OverlayTrigger>
         )}

         {showModalEdit && (
            <Modal
               fullscreen={fullscreen}
               size={size}
               className='modal-with-nav'
               show={showModalEdit}
               onHide={() => _handleCloseModal(setShowModalEdit)}
               centered>
               <Modal.Header closeButton>
                  <Modal.Title>{title}</Modal.Title>
               </Modal.Header>
               <Modal.Body>{children}</Modal.Body>

               {!hideFooter && (
                  <Modal.Footer>
                     <Button
                        disabled={disableFooter}
                        variant='secondary'
                        onClick={() => _handleCloseModal(setShowModalEdit)}>
                        {btnCloseLabel ? btnCloseLabel : 'Chiudi'}
                     </Button>

                     <Button
                        disabled={disableFooter || !enabledSave || isSaving}
                        type='submit'
                        onClick={(e) => {
                           handleSubmit(e);
                        }}
                        variant='primary'>
                        {isSaving || !enabledSave ?
                           (<><Spinner as="span" animation="border" size="sm" role="status" />&nbsp; Creazione...</>) :
                           (btnSaveLabel ? btnSaveLabel : <OETrans code='common.save' fallback={'Salva'}/>)
                        }
                     </Button>
                  </Modal.Footer>
               )}
            </Modal>
         )}
      </div>
   );
};

export default OEModal;
