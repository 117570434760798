import React, { useState, useEffect, useMemo } from "react";
import {
  Button,
  OverlayTrigger,
  Tooltip,
  Modal,
  Form,
  Row,
  Col,
  // Tab,
  // Tabs,
  Spinner,
  Tabs,
  Tab,
} from "react-bootstrap";
import { IcoEdit, IcoCanc, Lens } from "@icons";
import { editMilestone, deleteMilestone } from "@lib/api";
import Select from "react-select";
// import UploadDocument from "components/form/upload/uploadDocuments";
import StatusBar from "@components/StatusBar";
import { dateFormatter, dateFormatterInput } from "@lib/helpers/formatting";
import { translate } from "components/translation/translation";
import { OETrans } from "components/translation/OETrans";
import ForecastDateInput from "components/advancement/ForecastDateInput";
import UploadDocument from "components/form/upload/uploadDocuments";
// import { useLocation } from "react-router-dom";
// import { getUsersForResponsible } from "components/projects/getUsersForResponsible";

const ActionBtnRendererMilestones = ({
  data,
  setRefresh,
  optionsCompleted,
  canEdit,
  streams,
  isConfig,
}) => {
  const [milestone, setMilestone] = useState({});
  const [selectedCompleted, setSelectedCompleted] = useState([]);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [sbShow, setSbShow] = useState(<></>);
  const [activeKey, setActiveKey] = useState("registry");
  const [showSpinner, setShowSpinner] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const optionsUsers = useMemo(() => {
     if (milestone.wpStreamId && streams) {
        const tempStream = streams.filter(stream => stream.id === milestone.wpStreamId).pop();
        if (!tempStream || !tempStream.responsibles) return [];

        return tempStream.responsibles.reduce((acc, obj) => {
              const objUsers = obj.users ?? [];
              const arrayWithProperties = objUsers.map((user) => ({
                 value: user.id,
                 label: `${user.firstName} ${user.lastName} (${user.email})`,
              }))
  
              return acc.concat(arrayWithProperties);
           }, []);
     } else {
        return [];
     }
  }, [milestone.wpStreamId, streams]);

  useEffect(() => {
    setMilestone(data);
  }, [data]);

  useEffect(() => {
    if (milestone.id) {
      setSelectedCompleted(
        optionsCompleted
          .filter((option) => option.value === milestone.completed)
          .map((filteredOpt) => ({
            value: filteredOpt.value,
            label: filteredOpt.label,
          }))
      );
    }
  }, [milestone, optionsCompleted]);

  const handleCloseModal = (setShowModal) => {
    setSbShow(<></>);
    setMilestone(data);
    setActiveKey("registry");
    setShowModal(false);
  };
  const handleShowModal = (setShowModal) => setShowModal(true);

  const handleStatusBar = (status, message, timeout) => {
    setSbShow(
      <StatusBar status={status} message={message} timeout={timeout} />
    );
  };

  const onChange = (e, fieldName, setSelect) => {
    //console.log('e', e);

    let tempMilestone = { ...milestone };
    let value;

    // console.log("tempMilestone", tempMilestone);
    switch (fieldName) {
      case "number":
      case "title":
      case "description":
      case "responsible":
      /* case 'wp': */ case "wpStreamId":
      case "probativeDocumentation":
      case "note":
      case "forecastDate":
      case "effectiveDate":
        value = e.target.value;
        tempMilestone[fieldName] = value;
        break;
      case "completed":
        value = e.value;
        tempMilestone[fieldName] = value;

        setSelect(e);
        break;
      default:
        break;
    }

    setMilestone(tempMilestone);
  };

  const handleDelete = async () => {
    const deleteResult = await deleteMilestone(
      milestone.projectId,
      milestone.id
    );

    if (deleteResult.success) {
      handleStatusBar(
        "success",
        translate("common.message.deleted", "Elemento eliminato correttamente"),
        true
      );
      setTimeout(() => {
        setShowModalDelete(false);
        setRefresh(true);
      }, 2000);
    } else {
      handleStatusBar(
        "error",
        deleteResult.message ??
          translate(
            "common.error.delete",
            "Si è verificato un errore durante l’eliminazione delle informazioni"
          ),
        false
      );
    }
  };

  const handleEdit = async (e) => {
    e?.preventDefault();

    setSbShow(<></>);

    const { createdAt, createdBy, updatedAt, updatedBy, ...restMilestone } =
      milestone;

    if (!milestone.number || !milestone.title || !milestone.wpStreamId || !milestone.forecastDate) {
      handleStatusBar(
        "error",
        translate("common.error.requiredFields", "Compila i campi obbligatori"),
        false
      );
      return;
    }

    if (restMilestone.forecastDate) {
      restMilestone.forecastDate =
        restMilestone.forecastDate.length === 10
          ? restMilestone.forecastDate
          : restMilestone.forecastDate + "-01";
    }

    setIsLoading(true);
    setShowSpinner(true);

    let result = await editMilestone(
      milestone.projectId,
      milestone.id,
      restMilestone
    );

    if (result.success) {
      handleStatusBar(
        "success",
        translate("common.message.edited", "Elemento modificato con successo"),
        true
      );
      setTimeout(() => {
        setMilestone(result.data);
        setRefresh(true);
      }, 2000);
    } else {
      handleStatusBar(
        "error",
        result.success === false
          ? Object.values(result.data).join()
          : translate(
              "common.error.save",
              "Si è verificato un errore durante il salvataggio delle informazioni"
            ),
        false
      );
    }

    setIsLoading(false);
    setShowSpinner(false);
  };

  const editTooltip = (props) => (
    <Tooltip {...props}>
      {canEdit ? (
        <OETrans code="common.tooltip.edit" fallback={"Modifica"} />
      ) : (
        "Visualizza"
      )}
    </Tooltip>
  );

  const deleteTooltip = (props) => (
    <Tooltip {...props}>
      <OETrans code="common.tooltip.delete" fallback={"Elimina"} />
    </Tooltip>
  );

  return (
    <div>
      <OverlayTrigger placement="top" overlay={editTooltip}>
        <Button
          variant="link"
          className="me-3"
          onClick={() => handleShowModal(setShowModalEdit)}
        >
          {canEdit ? (
            <IcoEdit className="second-color ico-small edit" />
          ) : (
            <Lens className="second-color ico-small edit path" />
          )}
        </Button>
      </OverlayTrigger>

      {canEdit && (
        <OverlayTrigger placement="top" overlay={deleteTooltip}>
          <Button
            variant="link"
            className="me-0"
            onClick={() => handleShowModal(setShowModalDelete)}
          >
            <IcoCanc className="second-color ico-small cancel" />
          </Button>
        </OverlayTrigger>
      )}

      {showModalEdit && (
        <Modal
          size="lg"
          className="modal-with-nav"
          show={showModalEdit}
          onHide={() => handleCloseModal(setShowModalEdit)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Modifica</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {!isConfig ? 
            <Tabs
              className="mb-4"
              activeKey={activeKey}
              onSelect={(k) => setActiveKey(k)}
              fill
            >
              <Tab eventKey="registry" title={"Dettagli"}>
                <Form id="form-new-milestone">
                  <Row className="mb-3" style={{ marginTop: "2rem" }}>
                    {/* <Col className="col-4"> */}
                    <Col className="col-6">
                      <Form.Group as={Col} className="pe-2">
                        <Form.Label
                          className="mb-3"
                          // {...((!canEdit || (canEdit && !isConfig)) && {
                          //   style: { fontWeight: "bold" },
                          // })}
                        >
                          N. Milestone
                          {canEdit && isConfig && (
                            <span className="required-field">*</span>
                          )}
                        </Form.Label>
                        {canEdit && isConfig ? (
                          <Form.Control
                            type="text"
                            name="number"
                            value={milestone.number ?? ""}
                            onChange={(e) => onChange(e, "number")}
                          />
                        ) : (
                          <Form.Text as="p">{milestone.number}</Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                    {/* <Col className="col-8"> */}
                    <Col className="col-6">
                      <Form.Group as={Col} className="pe-2">
                        <Form.Label
                          className="mb-3"
                          // {...((!canEdit || (canEdit && !isConfig)) && {
                          //   style: { fontWeight: "bold" },
                          // })}
                        >
                          Titolo
                          {canEdit && isConfig && (
                            <span className="required-field">*</span>
                          )}
                        </Form.Label>
                        {canEdit && isConfig ? (
                          <Form.Control
                            type="text"
                            name="title"
                            value={milestone.title ?? ""}
                            onChange={(e) => onChange(e, "title")}
                          />
                        ) : (
                          <Form.Text as="p">{milestone.title ?? ""}</Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-3" style={{ marginTop: "2rem" }}>
                    {/* <Col className="col-8"> */}
                    <Col className="col-6">
                      <Form.Group as={Col} className="pe-2">
                        <Form.Label
                          className="mb-3"
                          // {...((!canEdit || (canEdit && !isConfig)) && {
                          //   style: { fontWeight: "bold" },
                          // })}
                        >
                          Descrizione
                        </Form.Label>
                        {canEdit && isConfig ? (
                          <Form.Control
                            type="text"
                            name="description"
                            value={milestone.description ?? ""}
                            onChange={(e) => onChange(e, "description")}
                          />
                        ) : (
                          <Form.Text as="p">
                            {milestone.description ?? "N.D."}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                    {/* <Col className="col-4"> */}
                    <Col className="col-6">
                      <Form.Group as={Col} className="pe-2">
                        <Form.Label
                          className="mb-3"
                          // {...((!canEdit || (canEdit && !isConfig)) && {
                          //   style: { fontWeight: "bold" },
                          // })}
                        >
                          WP di riferimento
                          {canEdit && isConfig && (
                            <span className="required-field">*</span>
                          )}
                        </Form.Label>
                        {canEdit && isConfig ? (
                          <Select
                            components={{
                              IndicatorSeparator: () => null,
                            }}
                            className="react-select-container"
                            classNamePrefix="react-select"
                            name="wpStreamId"
                            placeholder="Seleziona..."
                            defaultValue={
                              milestone.wpStreamId
                                ? {
                                    value:
                                      streams.find((stream) => {
                                        return (
                                          stream.id === milestone.wpStreamId
                                        );
                                      })?.id || "",
                                    label: (() => {
                                      const selectedStream = streams.find(
                                        (stream) => {
                                          return (
                                            stream.id ===
                                            parseInt(milestone.wpStreamId)
                                          );
                                        }
                                      );
                                      if (!selectedStream) return null;
                                      return `${selectedStream.streamId} - ${selectedStream.name}`;
                                    })(),
                                  }
                                : null
                            }
                            options={
                              streams && streams.length !== 0
                                ? streams.sort((a, b) => a.streamId - b.streamId).map((stream) => ({
                                    value: stream.id,
                                    label: `${stream.streamId} - ${stream.name}`,
                                  }))
                                : []
                            }
                            onChange={(selectedOption) =>
                              onChange(
                                {
                                  target: {
                                    value: selectedOption?.value || "",
                                  },
                                },
                                "wpStreamId"
                              )
                            }
                          />
                        ) : (
                          <Form.Text as="p">
                            {(() => {
                              const selectedStream = streams.find((stream) => {
                                return stream.id === milestone.wpStreamId;
                              });
                              if (!selectedStream) return null;
                              return `${selectedStream.streamId} - ${selectedStream.name}`;
                            })() ?? "N.D."}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row
                    className="mb-3"
                    style={{ marginTop: "2rem", rowGap: "2rem" }}
                  >
                    {/* <Col className="col-4"> */}
                    <Col className="col-6">
                      <Form.Group as={Col} className="pe-2">
                        <Form.Label
                          className="mb-3"
                          // {...((!canEdit || (canEdit && !isConfig)) && {
                          //   style: { fontWeight: "bold" },
                          // })}
                        >
                          Responsabile
                        </Form.Label>
                        {canEdit && isConfig ? (
                          <Select
                            components={{
                              IndicatorSeparator: () => null,
                            }}
                            className="react-select-container"
                            classNamePrefix="react-select"
                            name="responsible"
                            placeholder="Seleziona..."
                            defaultValue={
                              milestone.responsible
                                ? {
                                    value: milestone.responsible,
                                    label: (() => {
                                      const selectedResponsible = optionsUsers.find(
                                        (user) =>
                                          user.value === milestone.responsible
                                      );
                                      if (!selectedResponsible) return null;
                                      return selectedResponsible.label;
                                    })(),
                                  }
                                : null
                            }
                            options={optionsUsers}
                            onChange={(selectedOption) =>
                              onChange(
                                {
                                  target: {
                                    value: selectedOption?.value || "",
                                  },
                                },
                                "responsible"
                              )
                            }
                            isDisabled={!milestone.wpStreamId}
                          />
                        ) : (
                          <Form.Text as="p">
                            {(() => {
                              const selectedResponsible = optionsUsers.find(
                                (user) => user.value === milestone.responsible
                              );
                              if (!selectedResponsible) return null;
                              return selectedResponsible.label;
                            })()}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>

                    {/* {isConfig && canEdit ? (
                    <ForecastDateInput 
                      onChange={(e) => onChange(e, "forecastDate")}
                      value={milestone?.forecastDate || ""}
                      />) : null} */}
                    {!isConfig ? (
                      // <Col className="col-8">
                      <Col className="col-6">
                        <Form.Group as={Col} className="pe-2">
                          <Form.Label
                            className="mb-3"
                            {...(!canEdit && { style: { fontWeight: "bold" } })}
                          >
                            Doc. probatoria
                          </Form.Label>
                          {canEdit ? (
                            <Form.Control
                              type="text"
                              name="probativeDocumentation"
                              value={milestone.probativeDocumentation ?? ""}
                              onChange={(e) =>
                                onChange(e, "probativeDocumentation")
                              }
                            />
                          ) : (
                            <Form.Text as="p">
                              {milestone.probativeDocumentation ?? "N.D."}
                            </Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                    ) : null}
                    {/* </Row> */}
                    {/* {isConfig && canEdit ? (
                    <ForecastDateInput 
                      onChange={(e) => onChange(e, "forecastDate")}
                      value={milestone?.forecastDate || ""}
                      />) : null} */}
                  {/* {(!isConfig) ? */}
                  {/* <Row className="mb-3" style={{ marginTop: "2rem" }}> */}
                    {!isConfig ? <Col className="col-4">
                      <Form.Group
                        as={Col}
                        className="pe-2"
                        {...(!canEdit && { style: { fontWeight: "bold" } })}
                      >
                        <Form.Label>Completata</Form.Label>
                        {canEdit ? (
                          <Select
                            components={{
                              IndicatorSeparator: () => null,
                            }}
                            className="react-select-container"
                            classNamePrefix="react-select"
                            name="completed"
                            placeholder={translate(
                              "common.placeholder.select",
                              "Seleziona..."
                            )}
                            value={selectedCompleted}
                            options={optionsCompleted}
                            onChange={(e) =>
                              onChange(e, "completed", setSelectedCompleted)
                            }
                          />
                        ) : (
                          <Form.Text as="p">
                            {milestone.completed === null
                              ? "N.D."
                              : optionsCompleted
                                  .filter(
                                    (opt) => opt.value === milestone.completed
                                  )
                                  .map((el) => el.label)}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col> : null}
                    {isConfig && canEdit ? (
                      <ForecastDateInput
                        onChange={(e) => onChange(e, "forecastDate")}
                        value={milestone?.forecastDate || ""}
                        className="col-6"
                      />
                    ) : null}
                    {!isConfig ? (
                      <Col className="col-4">
                        <Form.Group as={Col} className="pe-2">
                          <Form.Label
                            className="mb-3"
                            // style={{ fontWeight: "bold" }}
                            // {...((!canEdit || (canEdit && !isConfig)) && {
                            //   style: { fontWeight: "bold" },
                            // })}
                          >
                            Data prevista
                            {/* {canEdit && isConfig && (
                            <span className="required-field">*</span>
                          )} */}
                          </Form.Label>
                          {/* {canEdit && isConfig ? (
                          <Form.Control
                            // type="month"
                            type="date"
                            name="forecastDate"
                            value={
                              milestone?.forecastDate || ""
                              // dateFormatterInput(
                              //   milestone?.forecastDate,
                              //   "yyyy-MM"
                              // ) || ""
                            }
                            onChange={(e) => onChange(e, "forecastDate")}
                          />
                        ) : ( */}
                          <Form.Text as="p">
                            {milestone.forecastDate
                              ? dateFormatter(
                                  milestone.forecastDate /*, "MM-YYYY" */
                                )
                              : "N.D."}
                          </Form.Text>
                        {/* )} */}
                      </Form.Group>
                    </Col>) : null}
                    {!isConfig ? <Col className="col-4">
                      <Form.Group as={Col} className="pe-2">
                        <Form.Label
                          className="mb-3"
                          {...(!canEdit && { style: { fontWeight: "bold" } })}
                        >
                          Data effettiva
                        </Form.Label>
                        {canEdit ? (
                          <Form.Control
                            type="date"
                            name="effectiveDate"
                            value={
                              dateFormatterInput(milestone?.effectiveDate) || ""
                            }
                            onChange={(e) => onChange(e, "effectiveDate")}
                          />
                        ) : (
                          <Form.Text as="p">
                            {milestone.forecastDate
                              ? dateFormatter(milestone.forecastDate)
                              : "N.D."}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col> : null}
                  </Row> 
                  {/* : null} */}

                  <Row className="mb-3" style={{ marginTop: "2rem" }}>
                    <Col>
                      <Form.Group as={Col} className="pe-2">
                        <Form.Label
                          {...(!canEdit && { style: { fontWeight: "bold" } })}
                        >
                          Note
                        </Form.Label>
                        {canEdit ? (
                          <Form.Control
                            as="textarea"
                            rows={2}
                            name="note"
                            value={milestone.note ?? ""}
                            onChange={(e) => onChange(e, "note")}
                          />
                        ) : (
                          <Form.Text as="p">
                            {milestone.note ?? "N.D."}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
                {sbShow}

                <Row>
                  <Col className="col-12">
                    <span className="label">
                      <span className="required-field">*</span>&nbsp;
                      <OETrans
                        code="common.requiredFields"
                        fallback={"Campi obbligatori"}
                      />
                    </span>
                  </Col>
                </Row>
                </Tab>
              <Tab
                  eventKey="docs"
                  title={
                    <OETrans code="common.documents" fallback={"Documenti"} />
                  }
                  disabled={!milestone.id || isConfig}
                >
                  <UploadDocument
                    entity="projectmilestones"
                    entityId={milestone.id}
                  />
                  {sbShow}
                </Tab>
            </Tabs>:
                          <Form id="form-new-milestone">
                          <Row className="mb-3" style={{ marginTop: "2rem" }}>
                            {/* <Col className="col-4"> */}
                            <Col className="col-6">
                              <Form.Group as={Col} className="pe-2">
                                <Form.Label
                                  className="mb-3"
                                  // {...((!canEdit || (canEdit && !isConfig)) && {
                                  //   style: { fontWeight: "bold" },
                                  // })}
                                >
                                  N. Milestone
                                  {canEdit && isConfig && (
                                    <span className="required-field">*</span>
                                  )}
                                </Form.Label>
                                {canEdit && isConfig ? (
                                  <Form.Control
                                    type="text"
                                    name="number"
                                    value={milestone.number ?? ""}
                                    onChange={(e) => onChange(e, "number")}
                                  />
                                ) : (
                                  <Form.Text as="p">{milestone.number}</Form.Text>
                                )}
                              </Form.Group>
                            </Col>
                            {/* <Col className="col-8"> */}
                            <Col className="col-6">
                              <Form.Group as={Col} className="pe-2">
                                <Form.Label
                                  className="mb-3"
                                  // {...((!canEdit || (canEdit && !isConfig)) && {
                                  //   style: { fontWeight: "bold" },
                                  // })}
                                >
                                  Titolo
                                  {canEdit && isConfig && (
                                    <span className="required-field">*</span>
                                  )}
                                </Form.Label>
                                {canEdit && isConfig ? (
                                  <Form.Control
                                    type="text"
                                    name="title"
                                    value={milestone.title ?? ""}
                                    onChange={(e) => onChange(e, "title")}
                                  />
                                ) : (
                                  <Form.Text as="p">{milestone.title ?? ""}</Form.Text>
                                )}
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="mb-3" style={{ marginTop: "2rem" }}>
                            {/* <Col className="col-8"> */}
                            <Col className="col-6">
                              <Form.Group as={Col} className="pe-2">
                                <Form.Label
                                  className="mb-3"
                                  // {...((!canEdit || (canEdit && !isConfig)) && {
                                  //   style: { fontWeight: "bold" },
                                  // })}
                                >
                                  Descrizione
                                </Form.Label>
                                {canEdit && isConfig ? (
                                  <Form.Control
                                    type="text"
                                    name="description"
                                    value={milestone.description ?? ""}
                                    onChange={(e) => onChange(e, "description")}
                                  />
                                ) : (
                                  <Form.Text as="p">
                                    {milestone.description ?? "N.D."}
                                  </Form.Text>
                                )}
                              </Form.Group>
                            </Col>
                            {/* <Col className="col-4"> */}
                            <Col className="col-6">
                              <Form.Group as={Col} className="pe-2">
                                <Form.Label
                                  className="mb-3"
                                  // {...((!canEdit || (canEdit && !isConfig)) && {
                                  //   style: { fontWeight: "bold" },
                                  // })}
                                >
                                  WP di riferimento
                                  {canEdit && isConfig && (
                                    <span className="required-field">*</span>
                                  )}
                                </Form.Label>
                                {canEdit && isConfig ? (
                                  <Select
                                    components={{
                                      IndicatorSeparator: () => null,
                                    }}
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    name="wpStreamId"
                                    placeholder="Seleziona..."
                                    defaultValue={
                                      milestone.wpStreamId
                                        ? {
                                            value:
                                              streams.find((stream) => {
                                                return (
                                                  stream.id === milestone.wpStreamId
                                                );
                                              })?.id || "",
                                            label: (() => {
                                              const selectedStream = streams.find(
                                                (stream) => {
                                                  return (
                                                    stream.id ===
                                                    parseInt(milestone.wpStreamId)
                                                  );
                                                }
                                              );
                                              if (!selectedStream) return null;
                                              return `${selectedStream.streamId} - ${selectedStream.name}`;
                                            })(),
                                          }
                                        : null
                                    }
                                    options={
                                      streams && streams.length !== 0
                                        ? streams.sort((a, b) => a.streamId - b.streamId).map((stream) => ({
                                            value: stream.id,
                                            label: `${stream.streamId} - ${stream.name}`,
                                          }))
                                        : []
                                    }
                                    onChange={(selectedOption) =>
                                      onChange(
                                        {
                                          target: {
                                            value: selectedOption?.value || "",
                                          },
                                        },
                                        "wpStreamId"
                                      )
                                    }
                                  />
                                ) : (
                                  <Form.Text as="p">
                                    {(() => {
                                      const selectedStream = streams.find((stream) => {
                                        return stream.id === milestone.wpStreamId;
                                      });
                                      if (!selectedStream) return null;
                                      return `${selectedStream.streamId} - ${selectedStream.name}`;
                                    })() ?? "N.D."}
                                  </Form.Text>
                                )}
                              </Form.Group>
                            </Col>
                          </Row>

                          <Row
                            className="mb-3"
                            style={{ marginTop: "2rem", rowGap: "2rem" }}
                          >
                            {/* <Col className="col-4"> */}
                            <Col className="col-6">
                              <Form.Group as={Col} className="pe-2">
                                <Form.Label
                                  className="mb-3"
                                  // {...((!canEdit || (canEdit && !isConfig)) && {
                                  //   style: { fontWeight: "bold" },
                                  // })}
                                >
                                  Responsabile
                                </Form.Label>
                                {canEdit && isConfig ? (
                                  <Select
                                    components={{
                                      IndicatorSeparator: () => null,
                                    }}
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    name="responsible"
                                    placeholder="Seleziona..."
                                    defaultValue={
                                      milestone.responsible
                                        ? {
                                            value: milestone.responsible,
                                            label: (() => {
                                              const selectedResponsible = optionsUsers.find(
                                                (user) =>
                                                  user.value === milestone.responsible
                                              );
                                              if (!selectedResponsible) return null;
                                              return selectedResponsible.label;
                                            })(),
                                          }
                                        : null
                                    }
                                    options={optionsUsers}
                                    onChange={(selectedOption) =>
                                      onChange(
                                        {
                                          target: {
                                            value: selectedOption?.value || "",
                                          },
                                        },
                                        "responsible"
                                      )
                                    }
                                    isDisabled={!milestone.wpStreamId}
                                  />
                                ) : (
                                  <Form.Text as="p">
                                    {(() => {
                                      const selectedResponsible = optionsUsers.find(
                                        (user) => user.value === milestone.responsible
                                      );
                                      if (!selectedResponsible) return null;
                                      return selectedResponsible.label;
                                    })()}
                                  </Form.Text>
                                )}
                              </Form.Group>
                            </Col>
        
                            {/* {isConfig && canEdit ? (
                            <ForecastDateInput 
                              onChange={(e) => onChange(e, "forecastDate")}
                              value={milestone?.forecastDate || ""}
                              />) : null} */}
                            {!isConfig ? (
                              // <Col className="col-8">
                              <Col className="col-6">
                                <Form.Group as={Col} className="pe-2">
                                  <Form.Label
                                    className="mb-3"
                                    {...(!canEdit && { style: { fontWeight: "bold" } })}
                                  >
                                    Doc. probatoria
                                  </Form.Label>
                                  {canEdit ? (
                                    <Form.Control
                                      type="text"
                                      name="probativeDocumentation"
                                      value={milestone.probativeDocumentation ?? ""}
                                      onChange={(e) =>
                                        onChange(e, "probativeDocumentation")
                                      }
                                    />
                                  ) : (
                                    <Form.Text as="p">
                                      {milestone.probativeDocumentation ?? "N.D."}
                                    </Form.Text>
                                  )}
                                </Form.Group>
                              </Col>
                            ) : null}
                            {/* </Row> */}
                            {/* {isConfig && canEdit ? (
                            <ForecastDateInput 
                              onChange={(e) => onChange(e, "forecastDate")}
                              value={milestone?.forecastDate || ""}
                              />) : null} */}
                          {/* {(!isConfig) ? */}
                          {/* <Row className="mb-3" style={{ marginTop: "2rem" }}> */}
                            {!isConfig ? <Col className="col-4">
                              <Form.Group
                                as={Col}
                                className="pe-2"
                                {...(!canEdit && { style: { fontWeight: "bold" } })}
                              >
                                <Form.Label>Completata</Form.Label>
                                {canEdit ? (
                                  <Select
                                    components={{
                                      IndicatorSeparator: () => null,
                                    }}
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    name="completed"
                                    placeholder={translate(
                                      "common.placeholder.select",
                                      "Seleziona..."
                                    )}
                                    value={selectedCompleted}
                                    options={optionsCompleted}
                                    onChange={(e) =>
                                      onChange(e, "completed", setSelectedCompleted)
                                    }
                                  />
                                ) : (
                                  <Form.Text as="p">
                                    {milestone.completed === null
                                      ? "N.D."
                                      : optionsCompleted
                                          .filter(
                                            (opt) => opt.value === milestone.completed
                                          )
                                          .map((el) => el.label)}
                                  </Form.Text>
                                )}
                              </Form.Group>
                            </Col> : null}
                            {isConfig && canEdit ? (
                              <ForecastDateInput
                                onChange={(e) => onChange(e, "forecastDate")}
                                value={milestone?.forecastDate || ""}
                                className="col-6"
                              />
                            ) : null}
                            {!isConfig ? (
                              <Col className="col-4">
                                <Form.Group as={Col} className="pe-2">
                                  <Form.Label
                                    className="mb-3"
                                    // style={{ fontWeight: "bold" }}
                                    // {...((!canEdit || (canEdit && !isConfig)) && {
                                    //   style: { fontWeight: "bold" },
                                    // })}
                                  >
                                    Data prevista
                                    {/* {canEdit && isConfig && (
                                    <span className="required-field">*</span>
                                  )} */}
                                  </Form.Label>
                                  {/* {canEdit && isConfig ? (
                                  <Form.Control
                                    // type="month"
                                    type="date"
                                    name="forecastDate"
                                    value={
                                      milestone?.forecastDate || ""
                                      // dateFormatterInput(
                                      //   milestone?.forecastDate,
                                      //   "yyyy-MM"
                                      // ) || ""
                                    }
                                    onChange={(e) => onChange(e, "forecastDate")}
                                  />
                                ) : ( */}
                                  <Form.Text as="p">
                                    {milestone.forecastDate
                                      ? dateFormatter(
                                          milestone.forecastDate /*, "MM-YYYY" */
                                        )
                                      : "N.D."}
                                  </Form.Text>
                                {/* )} */}
                              </Form.Group>
                            </Col>) : null}
                            {!isConfig ? <Col className="col-4">
                              <Form.Group as={Col} className="pe-2">
                                <Form.Label
                                  className="mb-3"
                                  {...(!canEdit && { style: { fontWeight: "bold" } })}
                                >
                                  Data effettiva
                                </Form.Label>
                                {canEdit ? (
                                  <Form.Control
                                    type="date"
                                    name="effectiveDate"
                                    value={
                                      dateFormatterInput(milestone?.effectiveDate) || ""
                                    }
                                    onChange={(e) => onChange(e, "effectiveDate")}
                                  />
                                ) : (
                                  <Form.Text as="p">
                                    {milestone.forecastDate
                                      ? dateFormatter(milestone.forecastDate)
                                      : "N.D."}
                                  </Form.Text>
                                )}
                              </Form.Group>
                            </Col> : null}
                          </Row> 
                          {/* : null} */}
        
                          <Row className="mb-3" style={{ marginTop: "2rem" }}>
                            <Col>
                              <Form.Group as={Col} className="pe-2">
                                <Form.Label
                                  {...(!canEdit && { style: { fontWeight: "bold" } })}
                                >
                                  Note
                                </Form.Label>
                                {canEdit ? (
                                  <Form.Control
                                    as="textarea"
                                    rows={2}
                                    name="note"
                                    value={milestone.note ?? ""}
                                    onChange={(e) => onChange(e, "note")}
                                  />
                                ) : (
                                  <Form.Text as="p">
                                    {milestone.note ?? "N.D."}
                                  </Form.Text>
                                )}
                              </Form.Group>
                            </Col>
                          </Row>
                        </Form>
          }
          </Modal.Body>

          <Modal.Footer>
            <Button
              disabled={isLoading}
              variant="secondary"
              onClick={() => handleCloseModal(setShowModalEdit)}
            >
              {canEdit ? (
                <OETrans code="common.cancel" fallback={"Annulla"} />
              ) : (
                "Chiudi"
              )}
            </Button>
            {canEdit && (
              <Button
                variant="primary"
                onClick={(e) => handleEdit(e)}
                disabled={activeKey === "docs" || isLoading}
              >
                <OETrans code="common.edit" fallback={"Modifica"} />
                {showSpinner && (
                  <Spinner
                    as="span"
                    className="ms-2"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      )}

      <Modal
        show={showModalDelete}
        onHide={() => handleCloseModal(setShowModalDelete)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <OETrans code="common.warning" fallback={"Attenzione"} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="my-3">
            <span>
              <OETrans
                code="common.warning.delete"
                fallback={"Sei sicuro di voler eliminare questo elemento?"}
              />
            </span>
          </div>
          {sbShow}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => handleCloseModal(setShowModalDelete)}
          >
            <OETrans code="common.cancel" fallback={"Annulla"} />
          </Button>
          <Button variant="danger" onClick={() => handleDelete()}>
            <OETrans code="common.delete" fallback={"Elimina"} />
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ActionBtnRendererMilestones;
