import React, { useState, useContext } from "react";

import { Row, Col } from "react-bootstrap";
import { DialogConfirmation } from "components/atomic/molecules";
import { Alert } from "components/atomic/atoms";
import DeliverableTable from "./DeliverableTable";
import DeliverableForm from "./DeliverableForm";
import { deleteDeliverable } from "@lib/api";
import { LevelContext } from "@components/contexts/LevelContext";
import { translate } from "components/translation/translation";
// import { useLocation } from "react-router-dom";

const Deliverable = ({ projectId, streams, isConfig, users }) => {
  const { isOe, userRole } = useContext(LevelContext);
  const [deliverableData, setDeliverableData] = useState({});
  const [showFormModal, setShowFormModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState({
    show: false,
    message: "",
    type: "",
  });

  // const { pathname } = useLocation()
  // const isConfig = !pathname.includes('/advancement')
  
  const handleSubmitDelete = async () => {
    setShowAlert({ show: false, message: "", type: "" });
    setIsLoading(true);
    setShowDeleteModal(false);

    await deleteDeliverable(projectId, deliverableData.id)
      .then(() => {
        setDeliverableData({});
        setShowAlert({
          show: true,
          message: translate('common.message.deleted', 'Elemento eliminato correttamente'),
          type: "success",
        });
      })
      .catch((error) => {
        setShowAlert({
          show: true,
          message: error.message,
          type: "danger",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      {showAlert.show && (
        <Row className="pt-3">
          <Col className="col-12 p-0">
            <Alert
              show={showAlert.show}
              message={showAlert.message}
              variant={showAlert.type}
              delay={5000}
            />
          </Col>
        </Row>
      )}

      <DeliverableTable
        projectId={projectId}
        setShowAlert={setShowAlert}
        setDeliverableData={setDeliverableData}
        setShowFormModal={setShowFormModal}
        setShowDeleteModal={setShowDeleteModal}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        canEdit={userRole < 50 || isOe}
        isConfig={isConfig}
        streams={streams}
        users={users}
      />

      {(userRole < 50 || isOe) && (
        <DialogConfirmation
          title="Elimina"
          content={translate('common.warning.delete', 'Sei sicuro di voler eliminare questo elemento?')}
          confirmLabel={translate('common.delete', 'Elimina')}
          cancelLabel={translate('common.cancel', 'Annulla')}
          onConfirm={() => handleSubmitDelete()}
          onCancel={() => setShowDeleteModal(false)}
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
        />
      )}
      <DeliverableForm
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        showFormModal={showFormModal}
        setShowFormModal={setShowFormModal}
        deliverableData={deliverableData}
        setDeliverableData={setDeliverableData}
        projectId={projectId}
        setShowAlert={setShowAlert}
        canEdit={userRole < 50 || isOe}
        isConfig={isConfig}
        streams={streams}
        users={users}
      />
    </>
  );
};

export default Deliverable;
