import React from "react";
import { Col, Form } from "react-bootstrap";

interface ForecastDateInputProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
}

const ForecastDateInput: React.FC<ForecastDateInputProps> = ({
  value,
  onChange,
  className = "col-4",
}) => {
  return (
    <Col className={className}>
      <Form.Group as={Col} className="pe-2">
        <Form.Label className="mb-3">
          Data prevista<span className="required-field">*</span>
        </Form.Label>
        <Form.Control
          // type="month"
          type="date"
          name="forecastDate"
          value={
            //   newMilestone?.forecastDate || ""
            value
            // dateFormatterInput(
            //   newMilestone?.forecastDate,
            //   "yyyy-MM"
            // ) || ""
          }
          // onChange={(e) => onChange(e, "forecastDate")}
          onChange={onChange}
        />
      </Form.Group>
    </Col>
  );
};
export default ForecastDateInput;
