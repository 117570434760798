import React, { useContext, useEffect, useState } from "react";
import { Row, Tab, Tabs } from "react-bootstrap";
import Procedural from "@components/advancement/Procedural";
import Financial from "@components/advancement/Financial";
import Physical from "@components/advancement/Physical";
import Milestones from "components/advancement/Milestones";
import UploadDocument from "@components/form/upload/uploadDocuments";
import Risk from "@components/atomic/organisms/Risk/Risk";
import Deliverable from "@components/atomic/organisms/Deliverable/Deliverable";
import { LevelContext } from "components/contexts/LevelContext";
import { getUsersForResponsible } from "lib/api_ts/responsibleApi";
import { getAllStreams } from "@lib/api/index";

const Advancement = ({ project }) => {
  const { isOe, userRole } = useContext(LevelContext);
  const canEdit = isOe || userRole < 50;

  const [users, setUsers] = useState([]);
  
  useEffect(() => {
   if (!project?.company?.id) return;
   (async () => {
     try {
       const usersFromAPI = await getUsersForResponsible(project.company.id);
       setUsers(usersFromAPI);
     } catch (error) {
       console.error("Error getting users", error);
     }
   })();
  }, [project?.company?.id]);

  const [streams, setStreams] = useState([]);
  useEffect(() => {
    (async () => {
      if (!project.id) return;
      try {
        const streamsFromAPI = await getAllStreams(project.id);
        setStreams(streamsFromAPI.data);
      } catch (error) {
        console.error("Error getting streams", error);
      }
    })();
  }, [project.id]);

  return (
    <>
      <div className="candidacy simple-box scenaritab">
        <Row>
          <Tabs
            variant="pills"
            defaultActiveKey="procedurale"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="procedurale" title="Procedurale">
              {project?.id && <Procedural projectId={project.id} />}
            </Tab>
            <Tab eventKey="finanziario" title="Finanziario">
              {project?.id && <Financial projectId={project.id} />}
            </Tab>
            <Tab eventKey="fisico" title="Fisico">
              {project?.id && <Physical projectId={project.id} />}
            </Tab>
            {userRole < 50 && project?.streamConfigurationType === 'WP' && (
              <Tab eventKey="deliverable" title="Deliverables">
                {project?.id && streams && (
                  <Deliverable
                    projectId={project.id}
                    streams={streams}
                    users={users}
                  />
                )}
              </Tab>
            )}
            {userRole < 50 && project?.streamConfigurationType === 'WP' && (
              <Tab eventKey="milestones" title="Milestones">
                {project?.id && streams && (
                  <Milestones
                    projectId={project.id}
                    canEdit={canEdit}
                    streams={streams}
                    users={users}
                  />
                )}
              </Tab>
            )}
            {userRole < 50 && (
              <Tab eventKey="risk" title="Rischi">
                {project?.id && <Risk projectId={project.id} />}
              </Tab>
            )}
            <Tab eventKey="monitoraggio" title="Monitoraggio">
              {project?.id && (
                <UploadDocument
                  entity="projects"
                  entityId={project.id}
                  filterBySection={["monitoring"]}
                />
              )}
            </Tab>
          </Tabs>
        </Row>
      </div>
    </>
  );
};
export default Advancement;
