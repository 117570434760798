import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
   ButtonToolbar,
   Spinner,
   Button,
   Modal,
   Row,
   Col,
   Form,
} from 'react-bootstrap';
import { IcoPlus, /* IcoCheck */ } from '@icons';
import { getGroups, createGroup } from '@lib/api';
//import { columnCentered } from '@lib/helpers/agGridHelpers';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
import AG_GRID_LOCALE_IT from '@components/ag-grid/locale-it';
import ActionBtnRendererGroups from '@components/actionBtnRenderer/ActionBtnRendererGroups';
import StatusBar from '@components/StatusBar';
import CustomPaginationMui from 'components/materialComponents/CustomPaginationMui';
import CustomQuickFilterBox from 'components/ag-grid/CustomQuickFilterBox';
import { translate } from 'components/translation/translation';
import { OETrans } from 'components/translation/OETrans';

const initialState = {
   name: '',
   //partnership: false,
   //companyids: [], //in caso di aggiunta delle società qui
};

const GroupsList = ({ setSetupGroups }) => {
   const [groups, setGroups] = useState(null);
   const [groupsMeta, setGroupsMeta] = useState({});
   const [newGroup, setNewGroup] = useState(initialState);
   const [columnDefs, setColumnDefs] = useState([]);
   const [showModalNew, setShowModalNew] = useState(false);
   const [sbShow, setSbShow] = useState(<></>);
   const [refresh, setRefresh] = useState(false);
   const [isFirstResearch, setIsFirstResearch] = useState(true);
   const gridRef = useRef();
   const localeText = AG_GRID_LOCALE_IT;
   //console.log('groups', groups);
   //console.log('newGroup', newGroup);

   const handleCloseModal = (setShowModal) => {
      setSbShow(<></>);
      setShowModal(false);
   };

   const handleShowModal = (setShowModal) => setShowModal(true);

   const handleStatusBar = (status, message, timeout) => {
      setSbShow(<StatusBar status={status} message={message} timeout={timeout} />);
   };

   const fetchData = useCallback(async (isRefresh) => {
      getGroups(undefined, 'groups').then((retrievedGroups) => {
         if (retrievedGroups.success) {
            setGroups(retrievedGroups.data);
            if (isRefresh) setSetupGroups(retrievedGroups.data);

            const { data, success, ...restResp } = retrievedGroups;
            setGroupsMeta(restResp);
         }
      });
   }, [setSetupGroups]);

   useEffect(() => {
      fetchData();
   }, [fetchData]);

   useEffect(() => {
      if (refresh) {
         fetchData(true);
         setRefresh(false);
      }
   }, [fetchData, refresh]);

   const onGridReady = useCallback(() => {
      fetchData();
   }, [fetchData]);

   /* const CheckBoxCellRenderer = useCallback((params) => {
      //console.log('params', params.data.partnership);
      //params.data.partnership = true;

      return <>{params.data.partnership ?
         <Row>
            <IcoCheck as={Col} style={{ fill: '#689689' }} />
         </Row> : null
      }
      </>;
   }, []); */

   const defaultColDef = {
      resizable: false,
      wrapText: true,
      autoHeight: true,
      flex: 1,
      suppressMovable: true,
      cellStyle: { padding: '0 1rem' },
   };

   useEffect(() => {
      setColumnDefs([
         {
            headerName: translate('groups.name', 'Nome'),
            field: 'name',
            filter: 'agTextColumnFilter',
         },
         {
            headerName: translate('groups.companies', 'Società associate'),
            field: 'companies',
            filter: 'agTextColumnFilter',
            valueGetter: (params) => {
               return params.data.companies.map(company => company.name);
            },
            flex: 2,
         },
         /* {
            headerName: translate('groups.partnership', 'Partenariato'),
            field: 'partnership',
            cellRenderer: CheckBoxCellRenderer,
            cellRendererParams: {
               field: 'partnership',
            },
            maxWidth: 190,
            autoHeight: true,
            ...columnCentered('', {
               height: '100%',
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
               padding: '17px',
            }),
         }, */
         {
            headerName: '',
            minWidth: 130,
            maxWidth: 150,
            cellStyle: { textAlign: 'center', padding: 0 },
            cellRenderer: ActionBtnRendererGroups,
            cellRendererParams: {
               setRefresh: setRefresh,
            },
         },
      ]);
   }, [/* CheckBoxCellRenderer */]);

   const onChange = (e, fieldName) => {
      let tempNewGroup = { ...newGroup };
      let value;

      switch (fieldName) {
         case 'name':
            value = e.target.value;

            tempNewGroup[fieldName] = value;
            break;
         /* case 'partnership':
            value = e.target.checked;

            tempNewGroup[fieldName] = value;
            break; */
         default:
            break;
      }

      setNewGroup(tempNewGroup);
   }

   const handleNewGroup = async (e) => {
      e?.preventDefault();

      setSbShow(<></>);

      if (!newGroup.name) {
         handleStatusBar('error', translate('common.error.requiredFields', 'Compila i campi obbligatori'), false);
         return;
      }

      const createResult = await createGroup(newGroup);
      console.log('createResult', createResult);

      if (createResult.success) {
         handleStatusBar('success', translate('common.message.created', 'Elemento creato con successo'), true);

         setTimeout(() => {
            setNewGroup(initialState);
            setShowModalNew(false);
            setRefresh(true);
         }, 2000);
      } else {
         handleStatusBar('error', createResult.message ?? translate('common.error.save', 'Si è verificato un errore durante il salvataggio delle informazioni'), false);
      }
   };

   return (
      <>
         <div className='simple-box scenaritab'>
            <div className='row d-flex align-items-center mb-3'>
               <div className='col-4'>
                  <h5 className='title-simple-box'><OETrans code='common.groups.grouplist' fallback={'Lista gruppi'}/></h5>
               </div>
               <div className='col-8 text-right px-0 py-1'>
                  <ButtonToolbar className='justify-content-end'>
                     <Button variant='success' onClick={() => handleShowModal(setShowModalNew)}>
                        <IcoPlus className='light-color ico-small plus new-button' />
                        <span className='d-md-inline'><OETrans code='common.groups.addgroup' fallback={'Nuovo gruppo'}/></span>
                     </Button>
                     <CustomQuickFilterBox
                        tableRef={gridRef}
                        dataMeta={groupsMeta}
                        setData={setGroups}
                        dataLength={groups?.length}
                        isFirstResearch={isFirstResearch} 
                        setIsFirstResearch={setIsFirstResearch}
                        refTable='groups'
                     />
                  </ButtonToolbar>
               </div>
            </div>
               <div className='row'>
                  {Array.isArray(groups) ? (
                     <div className='col-12' id='grid-wrapper'>
                           <div className='ag-theme-alpine list-table'>
                              <AgGridReact
                                 ref={gridRef}
                                 columnDefs={columnDefs}
                                 rowData={groups}
                                 defaultColDef={defaultColDef}
                                 domLayout='autoHeight'
                                 tooltipShowDelay={0}
                                 tooltipHideDelay={2000}
                                 tooltipMouseTrack={true}
                                 onGridReady={onGridReady}
                                 //onFirstDataRendered={onFirstDataRendered}
                                 //onModelUpdated={onModelUpdated}
                                 headerHeight={50}
                                 rowHeight={50}
                                 localeText={localeText}
                                 ariaDescription="ag-grid-table"
                                 suppressCellFocus={true}
                              />
                              {Object.keys(groupsMeta).length > 0 && (
                                 <CustomPaginationMui
                                    dataMeta={groupsMeta} 
                                    setData={setGroups} 
                                    setDataMeta={setGroupsMeta}
                                    refTable='groups'
                                    setIsFirstResearch={setIsFirstResearch}
                                 />
                              )}
                           </div>
                     </div>
                  ) : (
                     <div className='text-center my-4'>
                        <Spinner animation='border' role='status'>
                           <span style={{ visibility: 'hidden' }}><OETrans code='common.loading' fallback={'Caricamento'}/>...</span>
                        </Spinner>
                     </div>
                  )}
               </div>
         </div>

         <Modal show={showModalNew} onHide={() => handleCloseModal(setShowModalNew)} centered>
            <Modal.Header closeButton>
               <Modal.Title>
                  <OETrans code='common.groups.creategroup' fallback={'Crea gruppo'}/>
               </Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <Form onSubmit={handleNewGroup} id='form-new-group'>
                  <Row className='mb-3 px-2' style={{ marginTop: '2rem' }}>
                     <Col>
                        <Form.Group as={Col} className='pe-2'>
                           <Form.Label className='mb-3'>
                              <OETrans code='groups.name' fallback={'Nome'}/>
                              &nbsp;<span className='required-field'>*</span>
                           </Form.Label>
                           <Form.Control
                              type='text'
                              name='name'
                              value={newGroup.name}
                              onChange={(e) => onChange(e, 'name')}
                           />
                        </Form.Group>
                     </Col>
                  </Row>
                  {/* <Row className='mb-3 px-2' style={{ marginTop: '2rem' }}>
                     <Col>
                        <Form.Group as={Col}>
                           <Form.Check
                              inline
                              type='checkbox'
                              name='partnership'
                              defaultChecked={newGroup.partnership}
                              onClick={(e) => onChange(e, 'partnership')}
                           />
                           <Form.Label>
                              <OETrans code='groups.partnership' fallback={'Partenariato'}/>
                           </Form.Label>
                        </Form.Group>
                     </Col>
                  </Row> */}
               </Form>
               {sbShow}

               <Row className='mt-5 mb-2'>
                  <span className='mx-2'>
                     <span className='required-field'>*</span>&nbsp;<OETrans code='common.requiredFields' fallback={'Campi obbligatori'}/>
                  </span>
               </Row>
            </Modal.Body>

            <Modal.Footer>
               <Button variant='secondary' onClick={() => handleCloseModal(setShowModalNew)}>
                  <OETrans code='common.cancel' fallback={'Annulla'}/>
               </Button>
               <Button variant='primary' onClick={(e) => handleNewGroup(e)}>
                  <OETrans code='common.create' fallback={'Crea'}/>
               </Button>
            </Modal.Footer>
         </Modal>
      </>
   );
};
export default GroupsList;