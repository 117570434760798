import React, {useState, useEffect, useRef} from 'react';
import {Row, Col, Spinner} from 'react-bootstrap';
import {
  getDocuments,
  getProjectFrameworks,
  downloadDocument,
  getProjectDossierStatus,
  //getSectionsStatementsDossier, //WIP Sezione 3
  makeProjectDossier,
  downloadProjectDossier,
  documentPreview,
} from '@lib/api';
import {CloudArrowDown, ChevronRight, IcoChevronDown, Eye, EyeSlash} from '@icons';
import {TreeView} from '@mui/x-tree-view/TreeView';
import {TreeItem} from '@mui/x-tree-view/TreeItem';
import {dateFormatter} from '@lib/helpers/formatting';
import StatusBar from '@components/StatusBar';
import OEModal from '@components/oemodal/OEModal';
import OEIframePreview from "components/oeiframepreview/OEIframePreview";
import {determineMimeType} from 'utils/determineMimeType';

//WIP Sezione 3
/* const testSection = [
   {
      section: "Anticipo/Anticipo1",
      codes: [
          "A_yyyy"
      ]
   },
   {
      section: "Anticipo/Anticipo2",
      codes: [
          "A_xxxxx"
      ]
   },
   {
       section: "SAL-1/Richiesta di anticipazione",
       codes: [
           "R_ANTICIPAZIONE"
       ]
   },
   {
       section: "SAL-1/Allegati",
       codes: [
           "R_ANTICIPOALLEGATI"
       ]
   },
   {
       section: "SAL-1/Attestazione delle verifiche effettuale",
       codes: [
           "R_ATTESTAZIONE"
       ]
   },
   {
       section: "SAL-1/Checklist autocontrollo",
       codes: [
           "R_AUTOCONTROLLO"
       ]
   },
   {
       section: "SAL-1/Documento Bando",
       codes: [
           "R_BANDO"
       ]
   },
   {
       section: "SAL-1/DSAN pagamento cedolini paga",
       codes: [
           "R_CEDOLINI"
       ]
   },
   {
       section: "SAL-1/Estratto libro cespiti",
       codes: [
           "R_CESPITI"
       ]
   },
   {
       section: "SAL-1/Checklist",
       codes: [
           "R_CHECKLIST"
       ]
   },
   {
       section: "SAL-1/Checklist DNSH",
       codes: [
           "R_CHECKLISTDNSH"
       ]
   },
   {
       section: "SAL-1/Tagging Ambientale",
       codes: [
           "R_COND_AMBIENTALE"
       ]
   },
   {
       section: "SAL-1/Tagging Digitale",
       codes: [
           "R_COND_DIGITALE"
       ]
   },
   {
       section: "SAL-1/DNSH",
       codes: [
           "R_COND_DNSH"
       ]
   },
   {
       section: "SAL-1/ParitÃ di Genere",
       codes: [
           "R_COND_PARITA"
       ]
   },
   {
       section: "SAL-1/DSAN 1",
       codes: [
           "R_DSAN1"
       ]
   },
   {
       section: "SAL-1/Richiesta di erogazione",
       codes: [
           "R_EROGAZIONE"
       ]
   },
   {
       section: "SAL-1/Registro beni inventariali ",
       codes: [
           "R_INVENTARIO"
       ]
   },
   {
       section: "SAL-1/Liquidazioni IVA mensili",
       codes: [
           "R_LIQUIDAZIONI"
       ]
   },
   {
       section: "SAL-1/DSAN pagamento oneri previdenziali e assicurativi",
       codes: [
           "R_PREVIDENZIALI"
       ]
   },
   {
       section: "SAL-1/Richiesta",
       codes: [
           "R_RICHIESTA"
       ]
   },
   {
       section: "SAL-1/Domanda di rimborso",
       codes: [
           "R_RIMBORSO"
       ]
   },
   {
       section: "SAL-1/Risposta",
       codes: [
           "R_RISPOSTA"
       ]
   },
   {
       section: "SAL-1/DSAN split payment",
       codes: [
           "R_SPLIT"
       ]
   },
   {
       section: "SAL-1/Relazione sullo stato di attuazione del progetto",
       codes: [
           "R_STATO_PROGETTO"
       ]
   },
   {
       section: "SAL-1/Archivio fotografico targhe",
       codes: [
           "R_TARGHE"
       ]
   },
   {
       section: "SAL-1/Documento di riconoscimento in corso di validitÃ  del dichiarante",
       codes: [
           "R_TITOLARE"
       ]
   },
   {
      section: "Saldo/Saldo1",
      codes: [
          "S_yyyy"
      ]
   },
   {
      section: "Saldo/Saldo2",
      codes: [
          "S_xxxxx"
      ]
   },
]  */

const TreeItemComponent = ({
                             doc, handleStatusBar, setSbShow, setPreviewModeParent, setPreviewBlob, setPreviewMimeType,
                             activePreviewDocId, setActivePreviewDocId
                           }) => {
  const [isPendingDownload, setIsPendingDownload] = useState(false);
  const [isPendingPreview, setIsPendingPreview] = useState(false);
  const [previewMode, setPreviewMode] = useState(false);

  const handleDownload = async (event, document) => {
    setSbShow(<></>);
    setIsPendingDownload(true);

    try {
      const result = await downloadDocument(document);
      //console.log('result', result);

      if (result.success) {
        setIsPendingDownload(false);
      } else {
        const message = result.message ?? result.data.file.join(',');
        handleStatusBar('error', message, false);
        setIsPendingDownload(false);
      }
    } catch (error) {
      handleStatusBar('error', 'Si è verificato un errore durante il download.', false);
      setIsPendingDownload(false);
    }
  };

  const handlePreview = async (event, upDocument) => {
    setSbShow(<></>);
    setActivePreviewDocId(upDocument.id);
    setIsPendingPreview(true);
    setPreviewMode(true);
    setPreviewModeParent(true);
    const response = await documentPreview(upDocument);
    const blob = await response;
    setPreviewBlob(blob);
    setPreviewMimeType(determineMimeType(upDocument));
    setIsPendingPreview(false);
  };

  return (
    <>
      <TreeItem
        nodeId={`${doc.id}`}
        style={{pointerEvents: 'none', marginLeft: '-23px'}}
        label={
          <div className='align-tree-center-vert'>
            <div className='float-start' style={{width: '80px'}}>
              {previewMode && activePreviewDocId === doc.id ?
                <EyeSlash
                  style={{pointerEvents: 'initial', cursor: "pointer", fill: "#09A8B0"}}
                  className={`ms-2 ico-small download-upload ${isPendingPreview ? "ico-disabled" : "second-color"
                  }`}
                  onClick={(e) => {
                    setPreviewMode(false);
                    setPreviewModeParent(false);
                    setActivePreviewDocId(null)
                  }}
                /> :
                (isPendingPreview ?
                  <Spinner style={{color: '#09A8B0', marginRight: '5px', marginLeft: '14px'}} animation="border"
                           size="sm" role="status"/> :
                  <Eye
                    style={{pointerEvents: 'initial'}}
                    className={`ms-2 ico-small download-upload ${doc.fileName.endsWith('zip') || doc.fileName.endsWith('7z') ? "ico-disabled" : "ico-enabled"}`}
                    {...(doc.fileName.endsWith('zip') || doc.fileName.endsWith('7z') ? {} : {onClick: (e) => handlePreview(e, doc)})}
                  />)
              }
              {isPendingDownload ?
                <Spinner style={{color: '#09A8B0', marginRight: '5px', marginLeft: '14px'}} animation="border" size="sm"
                         role="status"/> :
                <CloudArrowDown
                  style={{pointerEvents: 'initial', fill: '#09A8B0'}}
                  onClick={(e) => handleDownload(e, doc.document)}
                  className={`ms-2 ico-small download-upload ${isPendingDownload ? 'disabled' : 'second-color'}`}
                />
              }
            </div>
            {doc.fileName}
          </div>
        }
      />
    </>
  );
};

const ProjectDossier = ({entityObj}) => {
  const [project, setProject] = useState({});
  const [treeData, setTreeData] = useState([]);
  const [economicFrameworksIds, setEconomicFrameworksIds] = useState([]);
  const [documents, setDocuments] = useState([]);
  //const [sectionsStatementsDossier, setSectionsStatementsDossier] = useState([]); //WIP Sezione 3
  const [sbShow, setSbShow] = useState(<></>);
  const [btnDownloadEnabled, setbtnDownloadEnabled] = useState(true);
  const [status, setStatus] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [previewModeParent, setPreviewModeParent] = useState(false);
  const [previewBlob, setPreviewBlob] = useState(null);
  const [previewMimeType, setPreviewMimeType] = useState(null);
  const [activePreviewDocId, setActivePreviewDocId] = useState(null);

  const fetchInterval = useRef(0)

  useEffect(() => {
    // contructor
    return () => {
      if (fetchInterval.current) {
        clearInterval(fetchInterval.current)
      }
      fetchInterval.current = 0
    }
  }, [fetchInterval])


  const fetchData = () => {
    if (entityObj?.id) {
      getProjectDossierStatus(entityObj.id).then((res) => {
        setStatus(res);
        if (res && (res.status === 'DONE' || res.status === 'DONE WITH ERRORS')) {
          if (fetchInterval.current !== 0) {
            clearInterval(fetchInterval.current)
          }
          fetchInterval.current = 0
        } else {
          if (fetchInterval.current === 0) {
            fetchInterval.current = setInterval(() => fetchData(), 3000)
          }
        }

      }).catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    if (Object.values(entityObj).length > 0) {
      setProject(entityObj);
      fetchData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityObj]);

  useEffect(() => {
    if (Object.values(project).length > 0) {
      getProjectFrameworks(project.id).then(frameworks => {
        if (frameworks.success) {
          setEconomicFrameworksIds(frameworks.data.map(framework => framework.id));

          const entityTypes = [
            {entityType: 'notices', entityId: [project.noticeId]},
            {entityType: 'projects', entityId: [project.id]},
            {entityType: 'projectEconomicFrameworks', entityId: frameworks.data.map(framework => framework.id)},
            //{ entityType: 'frameworkContracts', entityId: ??? }
            //{ entityType: 'statements', entityId: [project.id] }, WIP Sezione 3
          ];

          let tempDocuments = [];

          entityTypes.forEach(el => {
            el.entityId.forEach(entityId => {
              getDocuments(el.entityType, entityId).then(retrievedDocuments => {
                if (retrievedDocuments.success && retrievedDocuments.data.length > 0) {
                  retrievedDocuments.data.forEach(doc => {
                    if (!tempDocuments.some(tempDoc => tempDoc.id === doc.id)) {
                      tempDocuments.push(doc);
                    }
                  });

                  setDocuments(tempDocuments.map(doc => ({
                    id: doc.id,
                    code: doc.documentCode,
                    fileName: doc.fileName,
                    entityType: doc.entityType,
                    document: {id: doc.id, fileName: doc.fileName},
                    entityId: doc.entityId,
                  })));
                }
              });
            })
          });
        }
      })

      //WIP Sezione 3
      /* getSectionsStatementsDossier(project.id).then(retrievedSections => {
         if (retrievedSections.success) {
            setSectionsStatementsDossier(retrievedSections.data);
         }
      }); */
      //setSectionsStatementsDossier(testSection);
    }
  }, [project]);

  useEffect(() => {
    if (Object.values(project).length > 0) {
      const docsInCandidatura = documents.filter(doc =>
        doc.code === 'P_CANDIDATO' ||
        doc.code === 'P_ALLEGATI' ||
        doc.code === 'B_AVVISO' ||
        doc.code === 'B_AVVISOALL'
      );
      const docsInAvvio = documents.filter(doc => doc.code === 'P_AVVIO');
      const docInNormativaLineeGuida = documents.filter(doc => doc.code === 'B_NORMATIVA');
      const docsInAvvisoAllegati = documents.filter(doc => doc.code === 'B_AVVISOALL' || doc.code === 'B_AVVISO');
      const docsInProgettoAllegati = documents.filter(doc => doc.code === 'P_ALLEGATI' || doc.code === 'P_CANDIDATO');
      const docsInAmmissione = documents.filter(doc => doc.code === 'P_CUP' || doc.code === 'P_DECRETO');
      const docsInAttoConcessione = documents.filter(doc => doc.code === 'P_DECRETO');
      const docsInAltriAtti = documents.filter(doc => doc.code === 'B_ALTRO');
      const docsInSchedaCUP = documents.filter(doc => doc.code === 'P_CUP');
      const docsInLineeGuida = documents.filter(doc => doc.code === 'B_LINEEGUIDA' || doc.code === 'B_RENDICONTAZIONE' || doc.code === 'B_NORMATIVA');
      const docsInLineeGuida2 = documents.filter(doc => doc.code === 'B_LINEEGUIDA');
      const docsInTemplate = documents.filter(doc => doc.code === 'B_RENDICONTAZIONE');
      const docsInAttuazione = documents.filter(doc => doc.code === 'P_PROGETTAZIONE' || doc.code === 'PEF_VARIANTE' || doc.code === 'PEF_APPROVAZIONE' || doc.code === 'P_PFTE' || doc.code === 'P_AVANZAMENTO');
      const docsInRelazioniAvanzamento = documents.filter(doc => doc.code === 'P_AVANZAMENTO');
      const docsInPFTE = documents.filter(doc => doc.code === 'P_PFTE');
      const docsInProgettazioneEsecutiva = documents.filter(doc => doc.code === 'P_PROGETTAZIONE');
      const docsInVariazioni = documents.filter(doc => doc.code === 'PEF_VARIANTE' || doc.code === 'PEF_APPROVAZIONE');
      const docsInTracciabilita = documents.filter(doc => ['P_CKLINERENZA', 'P_FOTOETICHETTA', 'P_REGISTRO'].includes(doc.code));
      const docsInChecklistInerenza = documents.filter(doc => ['P_CKLINERENZA'].includes(doc.code));
      const docsInFotoEtichetta = documents.filter(doc => ['P_FOTOETICHETTA'].includes(doc.code));
      const docsInRegistro = documents.filter(doc => ['P_REGISTRO'].includes(doc.code));
      const docsInInformazione = documents.filter(doc => ['P_CKLPUB', 'P_FOTOTARGA', 'P_ARTICOLO'].includes(doc.code));
      const docsInChlpub = documents.filter(doc => ['P_CKLPUB'].includes(doc.code));
      const docsInFotoTarga = documents.filter(doc => ['P_FOTOTARGA'].includes(doc.code));
      const docsInArticolo = documents.filter(doc => ['P_ARTICOLO'].includes(doc.code));
      const docsInDNSH = documents.filter(doc => ['P_NDNSH', 'P_DNSHSTART', 'P_DNSHONGOING', 'P_DNSHEND'].includes(doc.code));
      const docsInNormativa = documents.filter(doc => ['P_NDNSH'].includes(doc.code));
      const docsInCorrispondenza = documents.filter(doc => doc.code === 'P_PEC');
      const docsInAltro = documents.filter(doc => doc.code === 'P_ALTRO');

      let tempTreeData = [
        {
          id: 'root-candidatura',
          name: <span>Candidatura <span className='small'>({docsInCandidatura.length})</span></span>,
          children: [
            {
              id: 'B_AVVISO',
              name: <span>Avviso e allegati <span className='small'>({docsInAvvisoAllegati.length})</span></span>,
              documents: docsInAvvisoAllegati,
            },
            {
              id: 'P_ALLEGATI',
              name: <span>Progetto e allegati <span className='small'>({docsInProgettoAllegati.length})</span></span>,
              documents: docsInProgettoAllegati,
            },
          ],
        },
        {
          id: 'root-approvazione',
          name: <span>Ammissione e finanziamento <span className='small'>({docsInAmmissione.length})</span></span>,
          children: [
            {
              id: 'P_CUP',
              name: <span>Scheda CUP <span className='small'>({docsInSchedaCUP.length})</span></span>,
              documents: docsInSchedaCUP,
            },
            {
              id: 'P_DECRETO',
              name: <span>Atto di concessione <span className='small'>({docsInAttoConcessione.length})</span></span>,
              documents: docsInAttoConcessione,
            },
            {
              id: 'B_ALTRO',
              name: <span>Altri atti e provvedimenti <span className='small'>({docsInAltriAtti.length})</span></span>,
              documents: docsInAltriAtti,
            },
          ],
        },
        {
          id: 'root-lineeguida',
          name: <span>Riferimenti normativi <span className='small'>({docsInLineeGuida.length})</span></span>,
          children: [
            {
              id: 'B_NORMATIVA',
              name: <span>Normativa <span className='small'>({docInNormativaLineeGuida.length})</span></span>,
              documents: docInNormativaLineeGuida,
            },
            {
              id: 'B_LINEEGUIDA',
              name: <span>Linee Guida <span className='small'>({docsInLineeGuida2.length})</span></span>,
              documents: docsInLineeGuida2,
            },
            {
              id: 'B_RENDICONTAZIONE',
              name: <span>Template <span className='small'>({docsInTemplate.length})</span></span>,
              documents: docsInTemplate,
            },
          ],
        },
        {
          id: 'root-avvio',
          name: <span>Avvio <span className='small'>({docsInAvvio.length})</span></span>,
          documents: docsInAvvio,
        },
        {
          id: 'root-attuazione',
          name: <span>Attuazione <span className='small'>({docsInAttuazione.length})</span></span>,
          children: [
            {
              id: 'P_PFTE',
              name: <span>PFTE <span className='small'>({docsInPFTE.length})</span></span>,
              documents: docsInPFTE,
            },
            {
              id: 'P_PROGETTAZIONE',
              name: <span>Progettazione Esecutiva <span className='small'>({docsInProgettazioneEsecutiva.length})</span></span>,
              documents: docsInProgettazioneEsecutiva,
            },
            {
              id: 'PEFSENZACODE',
              name: <span>Variazioni progettuali <span className='small'>({docsInVariazioni.length})</span></span>,
              documents: docsInVariazioni,
            },
            {
              id: 'P_AVANZAMENTO',
              name: <span>Relazioni di avanzamento <span className='small'>({docsInRelazioniAvanzamento.length})</span></span>,
              documents: docsInRelazioniAvanzamento,
            },
          ],
        },
        {
          id: 'root-tracciabilita',
          name: <span>Tracciabilità dei beni <span className='small'>({docsInTracciabilita.length})</span></span>,
          children: [
            {
              id: 'P_CHECKLIST',
              name: <span>Template Checklist inerenza <span className='small'>({docsInChecklistInerenza.length})</span></span>,
              documents: docsInChecklistInerenza,
            },
            {
              id: 'P_FOTOETICHETTA',
              name: <span>Archivio fotografico etichette <span
                className='small'>({docsInFotoEtichetta.length})</span></span>,
              documents: docsInFotoEtichetta,
            },
            {
              id: 'P_REGISTRO',
              name: <span>Registro beni inventariabili <span className='small'>({docsInRegistro.length})</span></span>,
              documents: docsInRegistro,
            },
          ],
        },
        {
          id: 'root-informazione',
          name: <span>Informazione e pubblicità <span className='small'>({docsInInformazione.length})</span></span>,
          children: [
            {
              id: 'P_CKLPUB',
              name: <span>Template Checklist pubblicità <span className='small'>({docsInChlpub.length})</span></span>,
              documents: docsInChlpub,
            },
            {
              id: 'P_FOTOTARGA',
              name: <span>Archivio fotografico targhe <span className='small'>({docsInFotoTarga.length})</span></span>,
              documents: docsInFotoTarga,
            },
            {
              id: 'P_ARTICOLO',
              name: <span>Azioni di informazione e comunicazione <span
                className='small'>({docsInArticolo.length})</span></span>,
              documents: docsInArticolo,
            },
          ],
        },
        {
          id: 'P_DNSH',
          name: <span>DNSH <span className='small'>({docsInDNSH.length})</span></span>,
          children: [
            {
              id: 'P_NDNSH',
              name: <span>Normativa <span className='small'>({docsInNormativa.length})</span></span>,
              documents: docsInNormativa,
            },
          ],
        },
        {
          id: 'P_PEC',
          name: <span>Corrispondenza <span className='small'>({docsInCorrispondenza.length})</span></span>,
          documents: docsInCorrispondenza,
        },
        {
          id: 'P_ALTRO',
          name: <span>Altro <span className='small'>({docsInAltro.length})</span></span>,
          documents: docsInAltro,
        },
      ];

      //WIP Sezione 3
      /* if (sectionsStatementsDossier.length > 0) {
         const alldocCodesInSectionsStatements = sectionsStatementsDossier.map(sect => sect.codes).flat();
         console.log('alldocCodesInSectionsStatements', alldocCodesInSectionsStatements);

         const docsInSezione3 = documents.filter(doc => alldocCodesInSectionsStatements.includes(doc.code));
         console.log('docsInSezione3', docsInSezione3);

         tempTreeData.push({
            id: 'root-richiesteerogazione',
            name: <span>Richieste di erogazione <span className='small'>({docsInSezione3.length})</span></span>,
            children: [],
         });

         const indexOfRichieste = tempTreeData.findIndex(el => el.id === 'root-richiesteerogazione');
         const arrayOfRichieste = tempTreeData[indexOfRichieste].children;

         let previousSectionName = sectionsStatementsDossier[0].section.substring(0, sectionsStatementsDossier[0].section.indexOf('/')); //la prima sezione, es. SAL1

         sectionsStatementsDossier.forEach((sect, i) => {
            const currentSectionName = sect.section.substring(0, sect.section.indexOf('/'));
            const docInThisSection = documents.filter(doc => sect.codes.includes(doc.code));
            console.log('docInThisSection', docInThisSection);

            const capitalizeName = ([first, ...rest], lowerRest = false) =>
               first.toUpperCase() + (lowerRest ? rest.join('').toLowerCase() : rest.join(''));

            if (i === 0 || currentSectionName !== previousSectionName) {
               arrayOfRichieste.push({
                  id: currentSectionName,
                  name: <span>{currentSectionName.includes('SAL') ? currentSectionName : capitalizeName(currentSectionName, true)} <span className='small'>({docInThisSection.length})</span></span>,
                  documents: [], //docInThisSection,
               });

               previousSectionName = currentSectionName;
            } else {
               const indexOfLastChange = arrayOfRichieste.findIndex(el => el.id === currentSectionName) < 0 ? i : arrayOfRichieste.findIndex(el => el.id === currentSectionName);

               arrayOfRichieste[indexOfLastChange]['children'] = [];

               arrayOfRichieste[indexOfLastChange].children.push({
                  id: currentSectionName,
                  name: <span>{currentSectionName.includes('SAL') ? currentSectionName : capitalizeName(currentSectionName, true)} <span className='small'>({docInThisSection.length})</span></span>,
                  documents: [], //docInThisSection,
               });
            }
         });
      } */

      setTreeData(tempTreeData);
    }
  }, [documents, economicFrameworksIds, project]);

  const handleStatusBar = (status, message, timeout) => {
    setSbShow(<StatusBar status={status} message={message} timeout={timeout}/>);
  };

  const renderTree = (data) => {
    let tree = [];

    if (Array.isArray(data) && data.length > 0) {
      tree = data.map(node => {

        /* const docFilter = (doc) => {
           if (node.frameworkId) {
              return `${doc.code}-${node.frameworkId}` === node.id && doc.entityId === node.frameworkId;
           } else {
              return doc.code === node.id;
           }
        }; */

        return (
          <TreeItem key={node.id} nodeId={`${node.id}`} label={node.name}>
            {Array.isArray(node.children) ? renderTree(node.children) :
              <>
                {node.documents.length > 0 ?
                  node.documents.map(doc => {
                    return <TreeItemComponent
                      key={`${doc.id}`}
                      doc={doc}
                      handleStatusBar={handleStatusBar}
                      setSbShow={setSbShow}
                      setPreviewModeParent={setPreviewModeParent}
                      setPreviewBlob={setPreviewBlob}
                      setPreviewMimeType={setPreviewMimeType}
                      activePreviewDocId={activePreviewDocId}
                      setActivePreviewDocId={setActivePreviewDocId}
                    />
                  }) : null}
              </>
            }
          </TreeItem>
        );
      });
    }

    return tree;
  };

  const handleCloseModal = () => {
    setDownloading(false);
    setbtnDownloadEnabled(true);
    //setModalIsOpen(false);
  };

  const handleSubmit = (e) => {
    //e.preventDefault();
    setIsSaving(true);
    makeProjectDossier(project.id).then(res => {
        setIsSaving(false);
        if (res.success) {
          setStatus(res);
          fetchData()
        } else {
          alert(res.message)
        }
      }
    ).catch(err => console.log(err));
    return false
    //handleSave('ciao');
    //setShowModalEdit(false);
  };

  /* const startWorker = (projectID, projectName) => {
     console.log('startWorker')
     if (typeof (Worker) !== "undefined") {
        console.log('startWorker2')
        if (typeof (w) == "undefined") {
           console.log('startWorker3')
           w = new Worker("/worker_download.js");
        }
        console.log('startWorker4')
        w.onmessage = function (event) {
           setbtnDownloadEnabled(true);
           console.log('event', event)
           const blob = event.data;
           alert("Worker says: " + blob)
           var a = document.createElement('a');
           a.href = window.URL.createObjectURL(blob);
           a.download = projectName;
           document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
           a.click();
           a.remove(); //afterwards we remove the element again
           //return { success: true };
        };

        w.postMessage({ url: `http://openrep.localhost/api/v0/projects/${projectID}/dossier` })
     } else {
        alert("Sorry, your browser does not support Web Workers...")
     }
  } */

  const onDownload = (e) => {
    e.preventDefault();
    //startWorker(project.id, project.name);
    //return false

    setbtnDownloadEnabled(false);
    setDownloading(true);
    downloadProjectDossier(project.id, project.name).then(res => {
        if (res.success) {
          setbtnDownloadEnabled(true)
        } else {
          alert(res.message)
        }
      }
    ).catch(err => console.log(err))
      .finally(() => {
        setTimeout(() => {
          setDownloading(false)
        }, 5000);
      });
    return false
  };

  return (
    <>
      {sbShow}
      <Row className='mt-2 mb-3'>
        <Col className='col-12 text-center'>
          <OEModal
            title='Richiedi Fascicolo di Progetto'
            size={'xl'}
            btnShowIcon={null}
            btnShowLabel={'RICHIEDI FASCICOLO DI PROGETTO'}
            btnShowClassName={'btn btn-primary px-2 w-25'}
            btnShowTooltip='Effettua domanda di creazione di un fascicolo'
            btnSaveLabel={'RICHIEDI'}
            handleSubmit={() => handleSubmit()}
            handleClose={() => handleCloseModal()}
            isSaving={isSaving}
            disableFooter={downloading}
            enabled={true}
            enabledSave={status && status.status === 'DOING' ? false : true}
            status={status}
          >
            {
              !downloading ? (
                  <div>
                    {status && status.dossierAvailable && (
                      <>
                        <button disabled={!btnDownloadEnabled || status.status === 'DOING'} className="btn btn-primary"
                                onClick={(e) => onDownload(e)}>SCARICA
                        </button>
                        &nbsp;l'ultimo fascicolo di progetto disponibile (generato in
                        data: {(status && dateFormatter(status.endedAt)) || <b>In corso...</b>})
                        <p className='mt-3'>oppure</p>
                      </>
                    )}
                    Richiedi la creazione di un nuovo fascicolo aggiornato.<br/>
                    Cliccando su "Richiedi" verrà inviata una richiesta di creazione del fascicolo di progetto.<br/>
                    La richiesta verrà inoltrata al sistema che la metterà in coda.<br/>
                    Una volta processata sarà possibile scaricare il fascicolo tornando su questa schermata.<br/>
                    <br/>
                    {status && status.status !== 'DONE' && (
                      <>
                        <h2>Dettagli richiesta in corso</h2>
                        {status.message}
                        <br/><br/>
                        {status.startedAt && (
                          <>
                            <strong>Eseguita il:</strong>
                            <br/>
                            {dateFormatter(status.startedAt) ?? 'N.D.'}
                            <br/><br/>
                            <strong>Stato:</strong>
                            <br/>
                            {status.status}
                            <br/><br/>
                            <strong>Progresso:</strong>
                            <br/>
                            {status.progress ?? 0} %
                            <br/><br/>
                          </>
                        )}
                        <br/>
                      </>
                    )}
                  </div>)
                : (
                  <div className="px-4">
                    Download del fascicolo di progetto in corso...
                    <br/>
                    L'operazione potrebbe richiedere diversi minuti, si prega di attendere senza chiudere la pagina.
                  </div>
                )
            }
          </OEModal>
        </Col>
      </Row>

      <Row className='mt-2 mb-3'>
        <Col className={`py-3 ${previewModeParent ? 'col-6' : 'col-12'}`}>
          <TreeView className="" /*defaultExpanded={['root']}*/ defaultCollapseIcon={<IcoChevronDown/>}
                    defaultExpandIcon={<ChevronRight/>}>
            {treeData.length > 0 && renderTree(treeData)}
          </TreeView>
        </Col>

        {previewModeParent &&
          <Col className='col-6 py-3'>
            <OEIframePreview blob={previewBlob} mimeType={previewMimeType}/>
          </Col>
        }
      </Row>
    </>
  );
};

export default ProjectDossier;
