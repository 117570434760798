import {
  getStatementInvoicesOrTimesheets,
  pickUnpickAllProjectStatement,
} from "lib/api";
import { useEffect, useState, useRef, useMemo /*, useCallback*/ } from "react";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-community/core/dist/styles/ag-theme-alpine.css";
import AG_GRID_LOCALE_IT from "@components/ag-grid/locale-it";
import { customCellStyle } from "@lib/helpers/agGridHelpers";
import { months } from "@lib/helpers/calendar";
import { formatToDecimals } from "@lib/helpers/formatting";
import {
  InputGroup,
  FormControl,
  Spinner,
  ButtonToolbar,
  // Row,
  // Col,
} from "react-bootstrap";
import { Lens } from "@icons";
import { translate } from "components/translation/translation";
import DataHeader from "components/DataHeader";
// import { TablePagination } from "@mui/material";
import CustomPagination from "./CustomPagination";
import { debounce } from "@mui/material";

const ExpenseReportInternalCosts = ({
  projectId,
  detail,
  callback,
  isValidated = false,
  pickLoading,
  setPickLoading,
  isLoadingIndirectCosts,
  indirectCosts,
}) => {
  const [timesheets, setTimesheets] = useState(null);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  // const [pickLoading, setPickLoading] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  // const [urlParams, setUrlParams] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const DEFAULT_PER_PAGE = 100;
  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE);
  const [defaultPerPage, setDefaultPerPage] = useState(DEFAULT_PER_PAGE);

  // State for table
  const gridRef = useRef();
  const localeText = AG_GRID_LOCALE_IT;

  const [filterInputValue, setFilterInputValue] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  // console.log("filterInputValue", filterInputValue);
  // console.log("searchTerm", searchTerm);

  const defaultColDef = {
    resizable: false,
    flex: false,
    suppressMovable: true,
    sortable: false,
    filterParams: {
      suppressAndOrCondition: true,
      filterOptions: ["contains"],
    },
    cellStyle: {
      padding: "3px 1rem",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
  };

  const columnDefs = useMemo(
    () => [
      {
        hide: isValidated,
        maxWidth: 50,
        resizable: false,
        checkboxSelection: !isValidated,
        headerCheckboxSelection: true,
        cellStyle: () =>
          loading || pickLoading
            ? { pointerEvents: "none", opacity: "0.4" }
            : null,
      },
      {
        headerName: "Matricola",
        field: "serialNumber",
        filter: "agTextColumnFilter",
        minWidth: 130,
        width: 130,
      },
      {
        headerName: "Categoria",
        field: "category",
        filter: "agTextColumnFilter",
        cellStyle: { ...customCellStyle },
        minWidth: 150,
        width: 150,
        cellRendererParams: { queryParam: "category" },
      },
      {
        headerName: "Record",
        field: "number",
        filter: "agTextColumnFilter",
        minWidth: 130,
      },
      {
        headerName: "Giorno",
        field: "day",
        headerClass: "text-center",
        minWidth: 100,
        width: 100,
        cellRendererParams: { queryParam: "day" },
        cellStyle: { ...customCellStyle, justifyContent: "center" },
      },
      {
        headerName: "Mese",
        field: "month",
        minWidth: 110,
        width: 110,
        headerClass: "text-center",
        cellStyle: { ...customCellStyle, justifyContent: "center" },
        valueGetter: (params) => months()[params.data.month - 1],
        cellRendererParams: { queryParam: "month" },
      },
      {
        headerName: "Anno",
        field: "year",
        headerClass: "text-center",
        width: 100,
        cellRendererParams: { queryParam: "year" },
        cellStyle: { ...customCellStyle, justifyContent: "center" },
      },
      {
        headerName: "Ordine",
        field: "orderNumber",
        filter: "agTextColumnFilter",
        cellStyle: { ...customCellStyle },
        flex: 1,
        minWidth: 150,
        cellRendererParams: { queryParam: "orderNumber" },
      },
      {
        headerName: "WBE",
        field: "wbe",
        filter: "agTextColumnFilter",
        cellStyle: { ...customCellStyle },
        flex: 1,
        minWidth: 150,
        cellRendererParams: { queryParam: "wbe" },
      },
      {
        headerName: "WP/Stream",
        field: "streamId",
        filter: "agTextColumnFilter",
        width: 150,
        cellStyle: { ...customCellStyle, justifyContent: "center" },
      },
      {
        headerName: "Costo totale €",
        field: "totalCost",
        filter: "agTextColumnFilter",
        type: "rightAligned",
        cellStyle: { ...customCellStyle, justifyContent: "right" },
        valueGetter: (params) =>
          params.data.totalCost ? formatToDecimals(params.data.totalCost) : 0,
      },
    ],
    [isValidated, loading, pickLoading]
  );

  const handlePickAll = (action, rows) => {
    if (rows.length === 0) return;
    setPickLoading(true);
    pickUnpickAllProjectStatement(projectId, detail.id, "timesheets", action, {
      timesheetDetailIds: rows
        ? rows
        : timesheets.map((t) => t.timesheetDetailId),
    }).finally(() => {
      callback && callback();
      setPickLoading(false);
    });
  };

  const handleData = (params) => {
    // console.log("handleData", params);
    params.api.forEachNode((node) => {
      // console.log("node", node);
      // console.log("node.data.checked", node.data.checked);
      node.setSelected(node.data.checked === 1, false, true);
      // console.log("node.isSelected()", node.isSelected());
    });
  };

  // const handleRenderData = useCallback(() => {
  //   // const key = k ?? 'invoices';

  //   console.count("rendered data");

  //   // if (detail.statementTypeCode === "ADV") return;

  //   setLoading(true);

  //   // if (key === "invoices") {
  //   getStatementInvoicesOrTimesheets(
  //     projectId,
  //     detail.id,
  //     "timesheets",
  //     "timesheetsStatements",
  //     searchTerm === ""
  //       ? `?page=${currentPage + 1}&perPage=${perPage}`
  //       : `?page=${currentPage + 1}&perPage=${perPage}&term=${searchTerm}`
  //     // `?page=${currentPage + 1}&perPage=${perPage}`
  //   )
  //     .then((retrievedElements) => {
  //       //Confronta lo stato attuale con il nuovo stato prima di continuare
  //       if (
  //         retrievedElements.success &&
  //         JSON.stringify(retrievedElements.data) !== JSON.stringify(timesheets)
  //       ) {
  //         handleData(gridRef.current);
  //         setTimesheets(retrievedElements.data);
  //         const { data, success, ...restResp } = retrievedElements;

  //         //  setInvoicesMeta(restResp);
  //         // console.log("restResp", restResp);
  //         // setInvoicesMeta(restResp);
  //         setCount(restResp.meta.total);
  //         setPerPage(restResp.meta.perPage);
  //         setDefaultPerPage(restResp.meta.perPage);
  //         // if (searchTerm !== "") {
  //         //   setCurrentPage(0);
  //         //   gridRef.current.api.paginationGoToPage(0);
  //         // }
  //         //    setOriginCheckedInvoices(retrievedElements.data.filter((el) => el.checked));
  //       }
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });

  //   // }

  //   // if (key === "timesheets") {
  //   //    getStatementInvoicesOrTimesheets(
  //   //       projectId,
  //   //       detail.id,
  //   //       "timesheets",
  //   //       "timesheetsStatements"
  //   //    ).then((retrievedElements) => {
  //   //       //Confronta lo stato attuale con il nuovo stato prima di continuare
  //   //       if (retrievedElements.success && JSON.stringify(retrievedElements.data) !== JSON.stringify(timesheets)) {
  //   //          setTimesheets(retrievedElements.data);
  //   //          const { data, success, ...restResp } = retrievedElements;

  //   //          setTimesheetsMeta(restResp);
  //   //          setOriginCheckedTimesheets(retrievedElements.data.filter((el) => el.checked));
  //   //       }
  //   //    });
  //   // }

  //   // if (key === 'general-expenses') {
  //   //    getProjectStatementGeneralExpenses(projectId, detail.id).then(
  //   //       (retrievedGeneralExpenses) => {
  //   //          if (retrievedGeneralExpenses.success) {
  //   //             setGeneralExpenses(retrievedGeneralExpenses.data.statementGe);
  //   //          }
  //   //       }
  //   //    );
  //   // }
  // }, [projectId, detail.id, timesheets, currentPage, perPage, searchTerm]);

  // useEffect(() => {
  //   getStatementInvoicesOrTimesheets(
  //     projectId,
  //     detail.id,
  //     "timesheets",
  //     "timesheetsStatements",
  //     `?page=${currentPage + 1}&perPage=${perPage}`
  //   )
  //     .then((response) => {
  //       if (response.data && response.success) {
  //         const { data, success, ...restResp } = response;

  //         //  setInvoicesMeta(restResp);
  //         // console.log("restResp", restResp);
  //         // setTimesheetsMeta(restResp);
  //         setCount(restResp.meta.total);
  //         setPerPage(restResp.meta.perPage);
  //         setTimesheets(response.data);
  //         setSelectedIds(
  //           response.data
  //             .filter((t) => t.checked === 1)
  //             .map((t) => t.timesheetDetailId)
  //         );
  //       }
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //       // console.log("gridRef.current", gridRef.current);
  //       // handleData(gridRef.current);
  //     });
  // }, [projectId, detail.id, currentPage, perPage]);

  // console.log("after useEffect");
  const debouncedSetSearchTermRef = useRef(
    debounce((value) => {
      setCurrentPage(0);
      setPerPage(defaultPerPage);
      if (gridRef.current) {
        gridRef.current.api.paginationGoToPage(0);
        gridRef.current.api.paginationSetPageSize(defaultPerPage);
      }
      setSearchTerm(value); // Solo l'aggiornamento finale dopo debounce
    }, 500)
  );

  const onFilterChange = (e) => {
    const value = e.target.value;
    setFilterInputValue(value);
    // if (gridRef.current) {
    //   gridRef.current.api.setQuickFilter(value);
    // }
  };

  useEffect(() => {
    debouncedSetSearchTermRef.current(filterInputValue);
  }, [filterInputValue]);

  const isSearchRef = useRef(false);

  useEffect(() => {
    setLoading(true);

    // if (key === "invoices") {
    getStatementInvoicesOrTimesheets(
      projectId,
      detail.id,
      "timesheets",
      "timesheetsStatements",
      searchTerm === ""
        ? `?page=${currentPage + 1}&perPage=${perPage}`
        : `?page=${currentPage + 1}&perPage=${perPage}&term=${searchTerm}`
      // `?page=${currentPage + 1}&perPage=${perPage}`
    )
      .then((retrievedElements) => {
        //Confronta lo stato attuale con il nuovo stato prima di continuare
        if (
          retrievedElements.success &&
          JSON.stringify(retrievedElements.data) !==
            JSON.stringify(timesheets) &&
          !isSearchRef.current
        ) {
          isSearchRef.current = true;
          handleData(gridRef.current);
          setTimesheets(retrievedElements.data);
          const { data, success, ...restResp } = retrievedElements;

          //  setInvoicesMeta(restResp);
          // console.log("restResp", restResp);
          // setInvoicesMeta(restResp);
          setCount(restResp.meta.total);
          setPerPage(restResp.meta.perPage);
          setDefaultPerPage(restResp.meta.perPage);
          // if (searchTerm !== "") {
          //   setCurrentPage(0);
          //   gridRef.current.api.paginationGoToPage(0);
          // }
          //    setOriginCheckedInvoices(retrievedElements.data.filter((el) => el.checked));
        } else {
          isSearchRef.current = false;
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });

    // handleRenderData();
    // }, [searchTerm, handleRenderData]);
  }, [searchTerm, currentPage, perPage, detail.id, projectId, timesheets]);

  // const gridApi = gridRef.current?.api;
  // useEffect(() => {
  //   if (!gridApi) return;

  //   console.log("gridApi", gridApi);

  //   // Registriamo l'evento manualmente
  //   gridApi.addEventListener("paginationChanged", () => {
  //     const currentPage = gridApi.paginationGetCurrentPage();
  //     const pageSize = gridApi.paginationGetPageSize();
  //     setUrlParams(`?page=${currentPage}&perPage=${pageSize}`);
  //     // handleData(gridApi);
  //     console.log(
  //       `Pagina corrente: ${currentPage + 1}, Righe per pagina: ${pageSize}`
  //     );
  //   });

  //   // Pulizia quando il componente viene smontato
  //   return () => {
  //     if (gridApi) {
  //       gridApi.removeEventListener("paginationChanged", () => {});
  //     }
  //   };
  // }, [gridApi]);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <DataHeader
          customStyle={{
            margin: 0,
            paddingTop: "5px",
            paddingBottom: 0,
            marginLeft: "15px",
          }}
          data={[
            {
              name: isLoadingIndirectCosts ? (
                <Spinner animation="border" role="status" />
              ) : indirectCosts > 0 ? (
                <span className="text-nowrap">Costi indiretti</span>
              ) : (
                <div style={{ height: "24px" }} />
                // ""
              ),
              value:
                indirectCosts > 0 ? (
                  formatToDecimals(indirectCosts) + " €"
                ) : (
                  <div style={{ height: "17px" }} />
                  // "",
                ),
            },
          ]}
        />
        <div
          style={{
            // marginBottom: "20px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <ButtonToolbar className="justify-content-end">
            <InputGroup
              className="search-table align-items-center mt-md-0 ms-auto"
              // className="search-table align-items-center mt-md-0 ms-1"
              style={{ width: "300px" }}
            >
              <FormControl
                type="text"
                className="filter-text-box"
                placeholder={translate("common.search", "Cerca") + "..."}
                onChange={onFilterChange}
              />
              <div style={{ width: "40px", textAlign: "center" }}>
                <Lens className="lens" />
              </div>
            </InputGroup>
          </ButtonToolbar>
        </div>
      </div>
      <div className="ag-theme-alpine list-table">
        {/* <div
          style={{
            marginBottom: "20px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {isLoadingIndirectCosts ? (
            <Spinner animation="border" role="status" />
          ) : indirectCosts > 0 ? (
            <div>
              <h5 className="m-0">{formatToDecimals(indirectCosts) + " €"}</h5>
              <p className="m-0 dataHeaderName">Costi indiretti</p>
            </div>
          ) : null} */}

        {/* {(!isLoadingIndirectCosts && indirectCosts > 0) ? (
            <div>
              <h5 className="m-0">{formatToDecimals(indirectCosts) + " €"}</h5>
              <p className="m-0 dataHeaderName">Costi indiretti</p>
            </div>
          ) : <Spinner animation="border" role="status" />} */}
        {/* <InputGroup
            className="search-table align-items-center mt-md-0 ms-auto"
            // className="search-table align-items-center mt-md-0 ms-1"
            style={{ width: "300px" }}
          >
            <FormControl
              type="text"
              className="filter-text-box"
              placeholder={translate("common.search", "Cerca") + "..."}
              onChange={onFilterChange}
            />
            <div style={{ width: "40px", textAlign: "center" }}>
              <Lens className="lens" />
            </div>
          </InputGroup>
        </div> */}
        <AgGridReact
          ref={gridRef}
          columnDefs={columnDefs}
          rowData={timesheets || []}
          loading={loading || pickLoading}
          defaultColDef={defaultColDef}
          domLayout="autoHeight"
          tooltipShowDelay={0}
          tooltipHideDelay={2000}
          tooltipMouseTrack={true}
          colResizeDefault={"shift"}
          headerHeight={50}
          rowHeight={50}
          ariaDescription="ag-grid-table"
          suppressCellFocus={true}
          rowSelection={"multiple"}
          onFirstDataRendered={handleData}
          onGridReady={handleData}
          onSelectionChanged={(e) => {
            const selectedRows = [];
            const unselectedRows = [];

            // Check if user has filtered the table
            if (e.api.getDisplayedRowCount() !== timesheets.length) {
              // If the table is filtered, we need to loop through all the nodes
              e.api.forEachNodeAfterFilter((node) => {
                // If the node is selected, push it to the selectedRows array
                if (node.isSelected()) {
                  selectedRows.push(node.data);
                } else {
                  // If the node is not selected, push it to the unselectedRows array
                  unselectedRows.push(node.data);
                }
              });
            } else {
              // If user has not filtered the table, we can use the selectedRows array
              selectedRows.push(...e.api.getSelectedRows());

              // We can also use the unselectedRows array
              unselectedRows.push(
                ...timesheets.filter(
                  (t) =>
                    !selectedRows.some(
                      (s) => s.timesheetDetailId === t.timesheetDetailId
                    )
                )
              );
            }

            handlePickAll(
              "unpickall",
              unselectedRows.map((s) => s.timesheetDetailId)
            );
            handlePickAll(
              "pickall",
              selectedRows.map((s) => s.timesheetDetailId)
            );

            // Update the state with the selected IDs
            setSelectedIds((prevSelectedIds) => {
              // Remove IDs that are in unselectedRows
              const remainingIds = prevSelectedIds.filter(
                (id) =>
                  !unselectedRows.some((row) => row.timesheetDetailId === id)
              );

              // Add IDs from selectedRows that aren't already in the state
              const newIds = selectedRows
                .map((row) => row.timesheetDetailId)
                .filter((id) => !remainingIds.includes(id));

              return [...remainingIds, ...newIds];
            });
          }}
          onFilterChanged={(params) => {
            // When the filter changes, we need to update the selected rows
            params.api.forEachNode((node) => {
              node.setSelected(
                selectedIds.includes(node.data.timesheetDetailId),
                false,
                true
              );
            });
          }}
          suppressRowClickSelection={true}
          suppressPaginationPanel={true}
          rowMultiSelectWithClick={true}
          localeText={localeText}
          pagination={true}
          paginationPageSize={100}
          paginateChildRows={true}
          onPaginationChanged={(e, newPage) => {
            if (e.newData === undefined) return;
            // console.log("onPaginationChanged", e, newPage);
            // setCurrentPage(newPage);
            handleData(e);
            // handleRenderData();
          }}
        />
        <CustomPagination
          // count={timesheetsMeta?.meta?.total || 0}
          count={count}
          loading={loading}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          perPage={perPage}
          setPerPage={setPerPage}
          gridRef={gridRef}
        />
      </div>
    </>
  );
};

export default ExpenseReportInternalCosts;
