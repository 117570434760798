import React, { useState, useEffect, useCallback, useRef, Fragment } from 'react';
import moment from 'moment';
import {
   ButtonToolbar,
   Spinner,
   Button,
   Modal,
   Row,
   Col,
   Form,
   Tabs,
   Tab,
   FloatingLabel,
   OverlayTrigger,
   Tooltip,
} from 'react-bootstrap';
import Select from 'react-select';
import { InputNumber } from 'primereact';
import { IcoPlus, IcoMinusCircled, TooltipInfoIcon } from '@icons';
import {
   getNotices,
   getNotice,
   getFunds,
   getFundTaxonomies,
   getInstruments,
   getInstitutions,
   createNotice,
   editNotice,
   getNoticesTargets,
   getNoticesExpenseItems,
   getNoticesEngagementRules,
   getNoticesContactPerson,
   createNoticeContactPerson,
   editNoticeContactPerson,
   deleteNoticeContactPerson,
   createNoticeTarget,
   editNoticeTarget,
   deleteNoticeTarget,
   getNoticesDocumentsOfAccountability,
   createNoticesDocumentsOfAccountability,
   editNoticesDocumentsOfAccountability,
   deleteNoticesDocumentsOfAccountability,
   createNoticeExpenseItems,
   editNoticeExpenseItems,
   deleteNoticeExpenseItems,
   createNoticeSubExpenseItems,
   editNoticeSubExpenseItems,
   deleteNoticeSubExpenseItems,
   createNoticeEngagementRules,
   editNoticeEngagementRules,
   deleteNoticeEngagementRules,
   getOePnrrTaxonomyValues,
   getProjects,
   getQuestionSets,
   editQuestionSetByEntity,
   getOEStatementTypes,
   editAdvancedEnabledConfig,
   getAdvancedConfig,
} from '@lib/api';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
import AG_GRID_LOCALE_IT from '@components/ag-grid/locale-it';
import ActionBtnRendererNotices from 'components/actionBtnRenderer/ActionBtnRendererNotices';
import UploadDocument from 'components/form/upload/uploadDocuments';
import CustomTooltip from '@components/ag-grid/CustomTooltip';
import OeApiResponseRenderer from 'components/OeApiResponseRenderer';
import CustomPaginationMui from 'components/materialComponents/CustomPaginationMui';
import CustomQuickFilterBox from 'components/ag-grid/CustomQuickFilterBox';
import { translate } from 'components/translation/translation';
import { OETrans } from 'components/translation/OETrans';

const initialState = {
   //Anagrafica
   name: '', //obbl
   fundId: '', //obbl
   instrumentId: '', //obbl
   institutionId: '', //obbl
   institutionCode: '', //obbl   //
   questionsetId: null,
   publishedAt: '',
   expiresAt: '',
   call: '', //obbl
   //Regole d'ingaggio
   objectives: '',
   timeframe: '',
   managingSystem: '',
   compounding: '',
   changeManagement: '',
   disbursement: '',
   admissibleCosts: '',
   advancePaymentPercentage: 0,
   //Condizionalità
   environmentalTagging: false,
   digitalTagging: false,
   genderEquality: false,
   dnsh: false,
};

const NoticesList = ({ setupInstruments, setupInstitutions }) => {
   const [notices, setNotices] = useState(null);
   const [noticesMeta, setNoticesMeta] = useState({});
   const [newNotice, setNewNotice] = useState(initialState);
   const [projects, setProjects] = useState([]);
   const [funds, setFunds] = useState([]);
   const [instruments, setInstruments] = useState([]);
   const [institutions, setInstitutions] = useState([]);
   const [fundTaxonomies, setFundTaxonomies] = useState([]);
   const [taxonomyInstances, setTaxonomyInstances] = useState([]);
   const [pnrrTaxonomies, setPnrrTaxonomies] = useState({});
   const [currentPnrrTaxonomies, setCurrentPnrrTaxonomies] = useState({});
   const [contactPersons, setContactPersons] = useState([]);
   const [questionsNoticesSets, setQuestionsNoticesSets] = useState([]);
   const [optionsFunds, setOptionsFunds] = useState([]);
   const [optionsInstruments, setOptionsInstruments] = useState([]);
   const [optionsInstitutions, setOptionsInstitutions] = useState([]);
   const [optionsQuestionSet, setOptionsQuestionSet] = useState([]);
   const [selectedQuestionSet, setSelectedQuestionSet] = useState([]);
   const [selectedFund, setSelectedFund] = useState([]);
   const [selectedInstrument, setSelectedInstrument] = useState([]);
   const [selectedInstitution, setSelectedInstitution] = useState([]);
   const [targets, setTargets] = useState([]);
   const [docsOfAccountability, setDocsOfAccountability] = useState([]);
   const [originDocsOfAccountability, setOriginDocsOfAccountability] = useState([]);
   const [optionsStatementTypes, setOptionsStatementTypes] = useState([]);
   const [allExpenseItems, setAllExpenseItems] = useState([]);
   const [engagementRules, setEngagementRules] = useState([]);
   const [originContactPersons, setOriginContactPersons] = useState([]);
   const [originTargets, setOriginTargets] = useState([]);
   const [originAllExpenseItems, setOriginAllExpenseItems] = useState([]);
   const [deletedSubExpenseItems, setDeletedSubExpenseItems] = useState([]);
   const [originEngagementRules, setOriginEngagementRules] = useState([]);
   const [activeKey, setActiveKey] = useState('registry');
   const [columnDefs, setColumnDefs] = useState([]);
   const [showModalNew, setShowModalNew] = useState(false);
   const [showModalWarning, setShowModalWarning] = useState(false);
   const [isConfirmed, setIsConfirmed] = useState(false);
   const [sbShow, setSbShow] = useState(null);
   const [showSpinner, setShowSpinner] = useState(false);
   const [isUsed, setIsUsed] = useState(false);
   const [refresh, setRefresh] = useState(false);
   const [isFirstResearch, setIsFirstResearch] = useState(true);
   const [availableAdvancedConfig, setAvailableAdvancedConfig] = useState([]);
   const [selectedAdvancedConfig, setSelectedAdvancedConfig] = useState([]);
   const gridRef = useRef();
   const localeText = AG_GRID_LOCALE_IT;
   const [emailInvalid, setEmailInvalid] = useState(false);
   const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/; // eslint-disable-line
   const localLang = `${localStorage.getItem('userLanguage').toLowerCase()}-${localStorage.getItem(
      'userLanguage'
   )}`;
   //console.log('notices', notices);
   //console.log('newNotice', newNotice);

   const handleCloseModal = (setShowModal) => {
      setSbShow(null);
      setActiveKey('registry');
      setRefresh(true);
      setShowModal(false);
      setNewNotice(initialState);
   };

   const handleShowModal = (setShowModal) => setShowModal(true);

   const handleStatusBar = (dataToHandle) => {
      setSbShow(<OeApiResponseRenderer dataToHandle={dataToHandle} />);
   };

   useEffect(() => {
      getFunds().then((retrievedFunds) => {
         setFunds(retrievedFunds.data);
      });

      getInstruments().then((retrievedInstruments) => {
         setInstruments(setupInstruments ?? retrievedInstruments.data);
      });

      getInstitutions().then((retrievedInstitutions) => {
         setInstitutions(setupInstitutions ?? retrievedInstitutions.data);
      });

      getFundTaxonomies().then((retrievedFundTaxonomy) => {
         setFundTaxonomies(retrievedFundTaxonomy.data);
      });

      getProjects().then((retrievedProjects) => {
         setProjects(retrievedProjects.data);
      });

      getQuestionSets(undefined, false).then((retrievedQuestionsSets) => {
         if (retrievedQuestionsSets.success) {
            setQuestionsNoticesSets(
               retrievedQuestionsSets.data.filter((el) => el.legacyType === 'notices')
            );
         }
      });
   }, [setupInstitutions, setupInstruments]);

   useEffect(() => {
      getNotices('notices').then((retrievedNotices) => {
         if (retrievedNotices.success) {
            setNotices(retrievedNotices.data);
            const { data, success, ...restResp } = retrievedNotices;
            setNoticesMeta(restResp);
         }
      });
   }, []);

   useEffect(() => {
      if (newNotice.id) {
         getOePnrrTaxonomyValues().then((retrievedOepnrr) => {
            setPnrrTaxonomies(retrievedOepnrr.data);
            setCurrentPnrrTaxonomies(retrievedOepnrr.data);
         });

         setIsUsed(projects.some((proj) => proj.noticeId === newNotice.id));
      }
   }, [newNotice.id, projects]);

   useEffect(() => {
      if (isConfirmed) {
         handleSave();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [isConfirmed]);

   useEffect(() => {
      setOptionsFunds(
         funds.map((fund) => ({
            value: fund.id,
            label: fund.name,
         }))
      );

      setOptionsInstruments(
         instruments.map((instrument) => ({
            value: instrument.id,
            label: instrument.name,
            code: instrument.code,
         }))
      );

      setOptionsInstitutions(
         institutions.map((institution) => ({
            value: institution.id,
            label: `${institution.code} - ${institution.name}`,
            code: institution.code,
         }))
      );

      setOptionsQuestionSet(
         questionsNoticesSets.map((set) => ({
            value: set.id,
            label: set.description ?? set.name,
         }))
      );

      getOEStatementTypes().then((retrievedStatementTypes) => {
         setOptionsStatementTypes(
            retrievedStatementTypes.data.map((type) => ({
               value: type.code,
               label: type.name,
            }))
         );
      });
   }, [funds, institutions, instruments, questionsNoticesSets]);

   useEffect(() => {
      setSelectedFund(
         optionsFunds
            .filter((option) => option.value === newNotice.fundId)
            .map((filteredOpt) => ({
               value: filteredOpt.value,
               label: filteredOpt.label,
            }))
      );

      setSelectedInstrument(
         optionsInstruments
            .filter((option) => option.value === newNotice.instrumentId)
            .map((filteredOpt) => ({
               value: filteredOpt.value,
               label: filteredOpt.label,
               code: filteredOpt.code,
            }))
      );

      setSelectedInstitution(
         optionsInstitutions
            .filter((option) => option.value === newNotice.institutionId)
            .map((filteredOpt) => ({
               value: filteredOpt.value,
               label: filteredOpt.label,
               code: filteredOpt.code,
            }))
      );

      setSelectedQuestionSet(
         optionsQuestionSet.filter((option) => option.value === newNotice.questionsetId)
      );
   }, [newNotice, optionsFunds, optionsInstitutions, optionsInstruments, optionsQuestionSet]);

   useEffect(() => {
      if (refresh) {
         getNotices('notices').then((retrievedNotices) => {
            if (retrievedNotices.success) {
               setNotices(retrievedNotices.data);
               const { data, success, ...restResp } = retrievedNotices;
               setNoticesMeta(restResp);
            }
         });

         setRefresh(false);
      }
   }, [refresh]);

   const onGridReady = useCallback(() => {
      getNotices('notices').then((retrievedNotices) => {
         if (retrievedNotices.success) {
            setNotices(retrievedNotices.data);
            const { data, success, ...restResp } = retrievedNotices;
            setNoticesMeta(restResp);
         }
      });
   }, []);

   useEffect(() => {
      getAdvancedConfig('notices').then((retrievedAdvancedConfig) => {
         setAvailableAdvancedConfig(retrievedAdvancedConfig.data.advancedConfig);
      });
   }, []);

   const defaultColDef = {
      resizable: true,
      wrapText: true,
      autoHeight: true,
      flex: 1,
      suppressMovable: true,
      cellStyle: {
         padding: '0 1rem',
         textOverflow: 'ellipsis',
         whiteSpace: 'nowrap',
         overflow: 'hidden',
      },
      tooltipComponent: CustomTooltip,
   };

   useEffect(() => {
      setColumnDefs([
         {
            headerName: translate('notices.call', 'Avviso/Call'),
            field: 'call',
            filter: 'agTextColumnFilter',
            minWidth: 200,
            tooltipField: 'call',
         },
         {
            headerName: translate('notices.instrumentId', 'Strumento'),
            field: 'instrumentId',
            filter: 'agTextColumnFilter',
            valueGetter: (params) => {
               return instruments
                  .filter((instrument) => instrument.id === params.data.instrumentId)
                  .map((instrument) => instrument.name);
            },
            minWidth: 170,
         },
         {
            headerName: translate('notices.fundId', 'Fondo'),
            field: 'fundId',
            filter: 'agTextColumnFilter',
            maxWidth: 200,
            valueGetter: (params) => {
               return funds.filter((fund) => fund.id === params.data.fundId).map((fund) => fund.name);
            },
         },
         {
            headerName: translate('notices.name', 'Bando'),
            field: 'name',
            minWidth: 550,
            filter: 'agTextColumnFilter',
            tooltipField: 'name',
         },
         {
            headerName: '',
            minWidth: 250,
            maxWidth: 250,
            cellStyle: { textAlign: 'center', padding: 0 },
            cellRenderer: ActionBtnRendererNotices,
            cellRendererParams: {
               setRefresh: setRefresh,
               fundTaxonomies: fundTaxonomies,
               optionsFunds: optionsFunds,
               optionsInstruments: optionsInstruments,
               optionsInstitutions: optionsInstitutions,
               optionsQuestionSet: optionsQuestionSet,
               optionsStatementTypes: optionsStatementTypes,
               availableAdvancedConfig: availableAdvancedConfig,
               projects: projects,
               localLang: localLang,
            },
         },
      ]);
   }, [
      fundTaxonomies,
      optionsFunds,
      optionsInstruments,
      optionsInstitutions,
      optionsQuestionSet,
      optionsStatementTypes,
      instruments,
      funds,
      projects,
      localLang,
      availableAdvancedConfig,
   ]);

   const refreshTaxonomiesOptions = (elCode, val) => {
      let tempCurrentPnrrTaxonomies = { ...currentPnrrTaxonomies };

      let code = '';
      switch (elCode) {
         case 'PNRRMISSION':
            code = 'missione';

            tempCurrentPnrrTaxonomies['componente'] = pnrrTaxonomies['componente'].filter(
               (tax) => tax.code.indexOf(val) === 0
            );

            tempCurrentPnrrTaxonomies['misura'] = pnrrTaxonomies['misura'].filter(
               (tax) => tax.code.indexOf(val) === 0
            );

            tempCurrentPnrrTaxonomies['submisura'] = pnrrTaxonomies['submisura'].filter(
               (tax) => tax.code.indexOf(val) === 0
            );

            tempCurrentPnrrTaxonomies['investimento'] = pnrrTaxonomies['investimento'].filter(
               (tax) => tax.code.indexOf(val) === 0
            );
            break;
         case 'PNRRCOM':
            code = 'componente';

            tempCurrentPnrrTaxonomies['misura'] = pnrrTaxonomies['misura'].filter(
               (tax) => tax.code.indexOf(val) === 0
            );

            tempCurrentPnrrTaxonomies['submisura'] = pnrrTaxonomies['submisura'].filter(
               (tax) => tax.code.indexOf(val) === 0
            );

            tempCurrentPnrrTaxonomies['investimento'] = pnrrTaxonomies['investimento'].filter(
               (tax) => tax.code.indexOf(val) === 0
            );
            break;
         case 'PNRRMIS':
            code = 'misura';

            tempCurrentPnrrTaxonomies['submisura'] = pnrrTaxonomies['submisura'].filter(
               (tax) => tax.code.indexOf(val) === 0
            );

            break;
         case 'PNRRINV':
            code = 'investimento';
            break;

         case 'PNRRSUBMIS':
            code = 'submisura';
            break;
         case 'PNRRAMM':
            code = 'amministrazioneTitolare';
            break;
         default:
            break;
      }

      if (code === 'missione') {
         tempCurrentPnrrTaxonomies['componente'] = pnrrTaxonomies['componente'].filter(
            (tax) => tax.code.indexOf(val) === 0
         );

         tempCurrentPnrrTaxonomies['misura'] = pnrrTaxonomies['misura'].filter(
            (tax) => tax.code.indexOf(val) === 0
         );

         tempCurrentPnrrTaxonomies['submisura'] = pnrrTaxonomies['submisura'].filter(
            (tax) => tax.code.indexOf(val) === 0
         );

         tempCurrentPnrrTaxonomies['investimento'] = pnrrTaxonomies['investimento'].filter(
            (tax) => tax.code.indexOf(val) === 0
         );
      }

      setCurrentPnrrTaxonomies(tempCurrentPnrrTaxonomies);
   };

   const onChange = (e, fieldName, setSelect, elCode, elIndex, list, parentIndex) => {
      //console.log('e', e);

      let tempNewNotice = { ...newNotice };
      let value;

      switch (fieldName) {
         case 'name':
         case 'publishedAt':
         case 'expiresAt':
         case 'objectives':
         case 'timeframe':
         case 'managingSystem':
         case 'compounding':
         case 'changeManagement':
         case 'disbursement':
         case 'admissibleCosts':
         case 'call':
            value = e.target.value;

            tempNewNotice[fieldName] = value;
            break;
         case 'fundId':
         case 'instrumentId':
         case 'institutionId':
            value = e.value ?? [];

            tempNewNotice[fieldName] = value;

            if (fieldName === 'institutionId') tempNewNotice.institutionCode = e.code;

            if (fieldName === 'fundId' && tempNewNotice.id && tempNewNotice.fundId !== 1) {
               tempNewNotice.taxonomyinstances = tempNewNotice.taxonomyinstances.map((obj) => {
                  return { ...obj, value: null };
               });
            }

            if (
               fieldName === 'institutionId' &&
               tempNewNotice.id &&
               tempNewNotice.fundId === 1 &&
               tempNewNotice.taxonomyinstances.length > 0
            ) {
               let indexOfPNRRAMM = tempNewNotice.taxonomyinstances.findIndex(
                  (el) => el.fundTaxonomyCode === 'PNRRAMM'
               );
               tempNewNotice.taxonomyinstances[indexOfPNRRAMM].value = tempNewNotice.institutionCode;
            }

            setSelect(e);

            tempNewNotice[fieldName] = value;
            break;
         case 'taxonomyinstances':
            let index = tempNewNotice.taxonomyinstances.findIndex(
               (tax) => tax.fundTaxonomyCode === elCode
            );

            tempNewNotice.taxonomyinstances[index].value = e.target ? e.target.value : e.value;

            setSelect(tempNewNotice.taxonomyinstances);

            if (tempNewNotice.fundId === 1)
               refreshTaxonomiesOptions(elCode, tempNewNotice.taxonomyinstances[index].value);
            break;
         case 'contactPersons':
            //case 'subExpenseItems':
            let tempPerson = [...list];

            let tempPersonElement = {
               ...tempPerson[elIndex],
               [e.target.name]: e.target.value,
               ...(newNotice.id && { noticeId: newNotice.id }),
            };

            tempPerson[elIndex] = tempPersonElement;

            if (
               !tempPersonElement.email &&
               !tempPersonElement.firstName &&
               !tempPersonElement.lastName &&
               !tempPersonElement.phone
            )
               tempPerson = tempPerson.filter(
                  (el) => el.name && el.firstName && el.lastName && el.phone
               );

            setSelect(tempPerson); //as setList
            break;
         case 'targets':
         case 'docsOfAccountabilities':
         case 'expenseItems':
         case 'allExpenseItems':
         case 'engagementRules':
            let tempList = [...list];

            let tempElement = {
               ...tempList[elIndex],
               [e.target ? e.target.name : 'statementType']: e.target
                  ? e.target.value
                  : e.map((e) => e.value) ?? [],
               ...(newNotice.id && { noticeId: newNotice.id }),
            };

            if (fieldName === 'engagementRules' && tempElement.name === '') tempElement.content = '';

            tempList[elIndex] = tempElement;

            setSelect(tempList); //as setList
            break;
         case 'subExpenseItems':
            let tempAllExpenseItems = [...allExpenseItems];
            let tempListOfSubs = [...list]; //list of subs

            tempListOfSubs[elIndex][e.target.name] = e.target.value;

            tempAllExpenseItems[parentIndex].subExpenseItems = tempListOfSubs;

            setSelect(tempAllExpenseItems); //as setList
            break;
         case 'environmentalTagging':
         case 'digitalTagging':
         case 'genderEquality':
         case 'dnsh':
            value = e.target.checked;
            tempNewNotice[fieldName] = value;

            break;
         case 'advancePaymentPercentage':
         case 'reportableEmployeeDays':
            value = e ? e.value : 0;
            tempNewNotice[fieldName] = value;

            break;
         case 'questionsetId':
            value = e ? e.value : null;
            tempNewNotice[fieldName] = value;

            setSelect(e);
            break;
         default:
            value = e.target.value;
            tempNewNotice[fieldName] = value;
      }

      setNewNotice(tempNewNotice);
   };

   const onAdvancedChange = (e, fieldName, setSelect, elId) => {
      switch (fieldName) {
         case 'advancedSettings':
            const selectedConfig = selectedAdvancedConfig.find(conf => Number(conf.id) === Number(elId)); //se non esiste è undefined

            if (selectedConfig) {
               //se esiste, modifica enabled
               const indexOfConfig = selectedAdvancedConfig.findIndex(conf => Number(conf.id) === Number(elId));
               selectedAdvancedConfig[indexOfConfig].enabled = e.target.checked ? 1 : 0;
            } else {
               //se non esiste, lo aggiunge
               const availableConfig = availableAdvancedConfig.find(avail => Number(avail.id) === Number(elId));
               availableConfig.enabled = 1;
               selectedAdvancedConfig.push(availableConfig);
            }

            setSelect(selectedAdvancedConfig);
            break;
         default:
            break;
      }
   };

   const onBlur = (value, regex, setState) => {
      if ((value && value.match(regex)) || value === '') {
         setState(false);
      } else if (value && !value.match(regex)) {
         setState(true);
      }
   };

   const checkConfirmation = async (boolean) => {
      setIsConfirmed(boolean);

      boolean ? handleSave() : setShowModalWarning(false);
   };

   const handleSave = async (e) => {
      setSbShow(null);
      setShowModalWarning(false);

      if (
         !newNotice.name ||
         !newNotice.fundId ||
         !newNotice.instrumentId ||
         !newNotice.institutionId ||
         !newNotice.institutionCode ||
         !newNotice.publishedAt ||
         !newNotice.expiresAt ||
         !newNotice.call ||
         !newNotice.questionsetId
      ) {
         handleStatusBar({
            success: false,
            message: translate('common.error.requiredFields', 'Compila i campi obbligatori'),
         });
         return;
      }

      if (new Date(newNotice.publishedAt).getTime() > new Date(newNotice.expiresAt).getTime()) {
         handleStatusBar({
            success: false,
            message: translate(
               'common.error.previousdatepublished',
               'La pubblicazione deve avere una data antecedente alla scadenza'
            ),
         });
         return;
      }

      if (
         contactPersons.length > 0 &&
         !contactPersons.every(
            (contactPerson) => contactPerson.email && contactPerson.email.match(emailRegex)
         )
      ) {
         handleStatusBar({
            success: false,
            message: translate('common.error.email', 'Email non conforme'),
         });
         return;
      }

      if (newNotice.id) {
         const setDOA = new Set();
         const isNotNameDocsOfAccountabilityUnique = docsOfAccountability.some(
            (docOfAccountability) => setDOA.size === (setDOA.add(docOfAccountability.name), setDOA.size)
         );
         if (isNotNameDocsOfAccountabilityUnique) {
            handleStatusBar({
               success: false,
               message: translate(
                  'common.error.reportingdocumentsname',
                  'I documenti di rendicontazione non possono avere nomi uguali'
               ),
            });
            return;
         }

         if (
            docsOfAccountability.length > 0 &&
            !docsOfAccountability.every(
               (el) => el.name && el.description && el.statementType.length > 0
            )
         ) {
            handleStatusBar({
               success: false,
               message: translate(
                  'common.error.reportingdocumentsnamedescription',
                  'I documenti di rendicontazione devono avere un nome, una descrizione e un tipo'
               ),
            });

            return;
         }

         const set = new Set();
         const isNotNameTargetsUnique = targets.some(
            (target) => set.size === (set.add(target.name), set.size)
         );
         //console.log('isNotNameTargetsUnique', isNotNameTargetsUnique);

         if (isNotNameTargetsUnique) {
            handleStatusBar({
               success: false,
               message: translate(
                  'common.error.targetsname',
                  'Gli obiettivi non possono avere nomi uguali'
               ),
            });
            return;
         } else if (targets.length > 0 && !targets.every((el) => el.name && el.uom)) {
            handleStatusBar({
               success: false,
               message: translate(
                  'common.error.targetsnameuom',
                  'Gli obiettivi devono avere un nome e un’unità di misura'
               ),
            });
            return;
         }

         const set1 = new Set();
         const isNotNameExpenseItemsUnique = allExpenseItems.some(
            (parentExpenseItem) => set1.size === (set1.add(parentExpenseItem.name), set1.size)
         );

         if (isNotNameExpenseItemsUnique) {
            handleStatusBar({
               success: false,
               message: translate(
                  'common.error.expenseitemsname',
                  'Le voci di spesa non possono avere nomi uguali'
               ),
            });
            return;
         }

         if (allExpenseItems.length > 0) {
            let test = false;

            allExpenseItems.forEach((expenseItem) => {
               const set2 = new Set();
               const isNotNameSubExpenseItemsUnique = expenseItem.subExpenseItems.some(
                  (subExpenseItem) => set2.size === (set2.add(subExpenseItem.name), set2.size)
               );

               test = test || isNotNameSubExpenseItemsUnique;
            });

            if (test) {
               handleStatusBar({
                  success: false,
                  message: translate(
                     'common.error.subexpenseitemsname',
                     'Le sottovoci di spesa non possono avere nomi uguali nella stessa voce'
                  ),
               });
               return;
            }
         }
      }

      if (
         newNotice.id &&
         isUsed &&
         (JSON.stringify(originTargets) !== JSON.stringify(targets) ||
            JSON.stringify(originDocsOfAccountability) !== JSON.stringify(docsOfAccountability) ||
            JSON.stringify(originAllExpenseItems) !==
            JSON.stringify(allExpenseItems.map(({ fakeId, ...rest }) => rest))) &&
         !isConfirmed
      ) {
         setShowModalWarning(true);
         return;
      }

      const { createdAt, createdBy, updatedAt, updatedBy, ...restNewNotice } = newNotice;

      let result;

      setShowSpinner(true);

      if (newNotice.id) {
         let advancedSettingsResult = { success: true, message: '' };

         advancedSettingsResult = await editAdvancedEnabledConfig('notices', newNotice.id, { advancedConfig: selectedAdvancedConfig });

         result = await editNotice(restNewNotice);

         if (result.success) {
            let resultOfActions = [true];
            let resultOfMessages = [];
            let contactPersonsResult;
            let docsOfAccountabilityResult;
            let targetsResult;
            let expenseItemsResult;
            let engagementRulesResult;

            let questionSetsResult;
            let questionSetsResultBool;

            if (contactPersons.length > 0) {
               let contactPersonsCleaned = contactPersons.filter(
                  (contactPerson) =>
                     contactPerson.firstName &&
                     contactPerson.lastName &&
                     contactPerson.email &&
                     contactPerson.phone
               );

               [contactPersonsResult] = await Promise.all([
                  handleItemList(
                     'contactPersons',
                     originContactPersons,
                     contactPersonsCleaned,
                     deleteNoticeContactPerson,
                     editNoticeContactPerson,
                     createNoticeContactPerson,
                     getNoticesContactPerson,
                     setContactPersons,
                     setOriginContactPersons,
                     result.data.id
                  ),
               ]);
            }

            if (docsOfAccountability.length > 0) {
               let docOfAccountabilityCleaned = docsOfAccountability.filter(
                  (docOfAccountability) =>
                     docOfAccountability.name &&
                     docOfAccountability.description &&
                     docOfAccountability.statementType.length > 0
               );

               [docsOfAccountabilityResult] = await Promise.all([
                  handleItemList(
                     'docsOfAccountabilities',
                     originDocsOfAccountability,
                     docOfAccountabilityCleaned,
                     deleteNoticesDocumentsOfAccountability,
                     editNoticesDocumentsOfAccountability,
                     createNoticesDocumentsOfAccountability,
                     getNoticesDocumentsOfAccountability,
                     setDocsOfAccountability,
                     setOriginDocsOfAccountability,
                     result.data.id
                  ),
               ]);
            }

            if (targets.length > 0) {
               let targetsCleaned = targets.filter((target) => target.name && target.uom);

               [targetsResult] = await Promise.all([
                  handleItemList(
                     'targets',
                     originTargets,
                     targetsCleaned,
                     deleteNoticeTarget,
                     editNoticeTarget,
                     createNoticeTarget,
                     getNoticesTargets,
                     setTargets,
                     setOriginTargets,
                     result.data.id
                  ),
               ]);
            }

            if (allExpenseItems.length > 0) {
               let allExpenseItemsCleaned = allExpenseItems
                  .filter(
                     (expItem) =>
                        expItem.name &&
                        expItem.subExpenseItems.length >
                        0 /* || expItem.subExpenseItems.every(el => el.name) */
                  )
                  .map((expItemFiltered, i) => ({ ...expItemFiltered, position: i }));

               allExpenseItemsCleaned.forEach((expItem) => {
                  let tempSubExpenseItems = expItem.subExpenseItems.map((subExpItem, i) => ({
                     ...subExpItem,
                     position: i,
                  }));

                  expItem.subExpenseItems = tempSubExpenseItems;
               });
               //console.log('allExpenseItemsCleaned', allExpenseItemsCleaned);

               [expenseItemsResult] = await Promise.all([
                  handleItemList(
                     'allExpenseItems',
                     originAllExpenseItems,
                     allExpenseItemsCleaned,
                     deleteNoticeExpenseItems,
                     editNoticeExpenseItems,
                     createNoticeExpenseItems,
                     getNoticesExpenseItems,
                     setAllExpenseItems,
                     setOriginAllExpenseItems,
                     result.data.id
                  ),
               ]);
            }

            if (engagementRules.length > 0) {
               let engagementRulesCleaned = engagementRules.filter(
                  (engagementRule) => engagementRule.name
               );

               [engagementRulesResult] = await Promise.all([
                  handleItemList(
                     'engagementRules',
                     originEngagementRules,
                     engagementRulesCleaned,
                     deleteNoticeEngagementRules,
                     editNoticeEngagementRules,
                     createNoticeEngagementRules,
                     getNoticesEngagementRules,
                     setEngagementRules,
                     setOriginEngagementRules,
                     result.data.id
                  ),
               ]);
            }

            if (
               !isUsed ||
               newNotice.questionsetId !== Number(selectedQuestionSet.map((el) => el.value).join())
            ) {
               questionSetsResult = await editQuestionSetByEntity(
                  'notices',
                  newNotice.id,
                  newNotice.questionsetId
               );
               //console.log('questionSetsResult prima', questionSetsResult);

               questionSetsResultBool = questionSetsResult.success;
               //console.log('questionSetsResult dopo', questionSetsResult);

               if (!questionSetsResultBool) {
                  handleStatusBar(questionSetsResult);
                  return;
               }
            }

            const resultOfAll = resultOfActions.concat(
               contactPersonsResult ? contactPersonsResult.resultOfActionsTemp : [],
               targetsResult ? targetsResult.resultOfActionsTemp : [],
               docsOfAccountabilityResult ? docsOfAccountabilityResult.resultOfActionsTemp : [],
               expenseItemsResult ? expenseItemsResult.resultOfActionsTemp : [],
               engagementRulesResult ? engagementRulesResult.resultOfActionsTemp : [],
               isUsed ? [true] : [questionSetsResultBool],
               advancedSettingsResult.success ? [true] : [false]
            );
            const resultOfAllMessages = resultOfMessages.concat(
               contactPersonsResult ? contactPersonsResult.messageResult : [],
               docsOfAccountabilityResult ? docsOfAccountabilityResult.messageResult : [],
               targetsResult ? targetsResult.messageResult : [],
               expenseItemsResult ? expenseItemsResult.messageResult : [],
               engagementRulesResult ? engagementRulesResult.messageResult : [],
               !advancedSettingsResult.success ? [advancedSettingsResult.message] : []
            );
            //console.log('resultOfAll', resultOfAll);
            //console.log('resultOfAllMessages', resultOfAllMessages);

            //setNewNotice(result.data);
            //setTaxonomyInstances(result.data.taxonomyinstances);

            if (
               resultOfAll.length === 0 ||
               (resultOfAll.length > 0 && resultOfAll.every((el) => el === true))
            ) {
               setTimeout(() => {
                  getNotice(result.data.id).then((retrievedNotice) => {
                     //console.log('retrievedNotice', retrievedNotice.data);
                     setNewNotice(retrievedNotice.data);

                     setSelectedAdvancedConfig(retrievedNotice.data.advancedConfig ?? []);

                     //Add position to taxonomyinstances
                     /*const taxonomyInstancesWithPosition = retrievedNotice.data.taxonomyinstances.map(
                                      (el) => {
                                         const taxonomy = fundTaxonomies.find((tax) => tax.code === el.fundTaxonomyCode);
                                         return { ...el, position: taxonomy.position };
                                      }
                                   );*/
                     setTaxonomyInstances(retrievedNotice.data.taxonomyinstances);

                     if (retrievedNotice.data) {
                        let tempAllExpenseItems = [...retrievedNotice.data.expenseItems];

                        tempAllExpenseItems.forEach((expItem) => {
                           if (expItem.subExpenseItems.length === 0) {
                              expItem.subExpenseItems = [
                                 {
                                    name: '',
                                    description: '',
                                    fakeExpenseItemId: expItem.id || expItem.fakeId,
                                 },
                              ];
                           }
                        });

                        setAllExpenseItems(tempAllExpenseItems);
                        setOriginAllExpenseItems(retrievedNotice.data.expenseItems);
                     }
                  });

                  getNoticesContactPerson(result.data.id).then((retrievedContactPersons) => {
                     setContactPersons(retrievedContactPersons.data);
                     setOriginContactPersons(retrievedContactPersons.data);
                  });

                  getNoticesDocumentsOfAccountability(result.data.id).then(
                     (retrievedDocsOfAccountability) => {
                        setDocsOfAccountability(retrievedDocsOfAccountability.data);
                        setOriginDocsOfAccountability(retrievedDocsOfAccountability.data);
                     }
                  );

                  getNoticesTargets(result.data.id).then((retrievedTargets) => {
                     setTargets(retrievedTargets.data);
                     setOriginTargets(retrievedTargets.data);
                  });

                  /* getNoticesExpenseItems(result.data.id).then(retrievedExpItems => {
                                             setExpenseItems(retrievedExpItems.data);
                                             setOriginExpenseItems(retrievedExpItems.data);
                                          }); */

                  getNoticesEngagementRules(result.data.id).then((retrievedRules) => {
                     setEngagementRules(retrievedRules.data);
                     setOriginEngagementRules(retrievedRules.data);
                  });
               }, 2000);

               handleStatusBar({
                  success: true,
                  message: translate('common.message.edited', 'Elemento modificato con successo'),
               });
            } else {
               handleStatusBar(resultOfAllMessages);
            }
         } else {
            handleStatusBar(result);
         }

         setShowSpinner(false);
      } else {
         result = await createNotice(newNotice);

         if (result.success) {
            setNewNotice(result.data);

            let resultOfActions = [true];
            let resultOfMessages = [];
            let contactPersonsResult;

            if (contactPersons.length > 0) {
               let contactPersonsCleaned = contactPersons.filter(
                  (contactPerson) =>
                     contactPerson.firstName &&
                     contactPerson.lastName &&
                     contactPerson.email &&
                     contactPerson.phone
               );

               [contactPersonsResult] = await Promise.all([
                  handleItemList(
                     'contactPersons',
                     originContactPersons,
                     contactPersonsCleaned,
                     deleteNoticeContactPerson,
                     editNoticeContactPerson,
                     createNoticeContactPerson,
                     getNoticesContactPerson,
                     setContactPersons,
                     setOriginContactPersons,
                     result.data.id
                  ),
               ]);
            }

            const resultOfAll = resultOfActions.concat(
               contactPersonsResult ? contactPersonsResult.resultOfActionsTemp : []
            );
            const resultOfAllMessages = resultOfMessages.concat(
               contactPersonsResult ? contactPersonsResult.messageResult : []
            );
            //console.log('resultOfAll', resultOfAll);
            //console.log('resultOfAllMessages', resultOfAllMessages);

            getNotice(result.data.id).then((retrievedNotice) => {
               //console.log('retrievedNotice', retrievedNotice.data);

               if (retrievedNotice.data) {
                  setNewNotice(retrievedNotice.data);
                  setTaxonomyInstances(retrievedNotice.data.taxonomyinstances);
                  setSelectedAdvancedConfig(retrievedNotice.data.advancedConfig ?? []);

                  let tempAllExpenseItems = [...retrievedNotice.data.expenseItems];

                  tempAllExpenseItems.push({
                     name: '',
                     description: '',
                     noticeId: retrievedNotice.data.id,
                     fakeId: 'item-0',
                     subExpenseItems: [{ name: '', description: '', fakeExpenseItemId: 'item-0' }],
                  });

                  setAllExpenseItems(tempAllExpenseItems);
                  setOriginAllExpenseItems(tempAllExpenseItems);
               }
            });

            getNoticesDocumentsOfAccountability(result.data.id).then(
               (retrievedDocsOfAccountability) => {
                  setDocsOfAccountability(retrievedDocsOfAccountability.data);
                  setOriginDocsOfAccountability(retrievedDocsOfAccountability.data);
               }
            );

            getNoticesTargets(result.data.id).then((retrievedTargets) => {
               setTargets(retrievedTargets.data);
               setOriginTargets(retrievedTargets.data);
            });

            getNoticesEngagementRules(result.data.id).then((retrievedRules) => {
               setEngagementRules(retrievedRules.data);
               setOriginEngagementRules(retrievedRules.data);
            });

            if (
               resultOfAll.length === 0 ||
               (resultOfAll.length > 0 && resultOfAll.every((el) => el === true))
            ) {
               handleStatusBar({
                  success: true,
                  message: translate('common.message.created', 'Elemento creato con successo'),
               });

               //Add position to taxonomyinstances
               const taxonomyInstancesWithPosition = result.data.taxonomyinstances.map((el) => {
                  const taxonomy = fundTaxonomies.find((tax) => tax.code === el.fundTaxonomyCode);
                  return { ...el, position: taxonomy.position };
               });
               setTaxonomyInstances(taxonomyInstancesWithPosition);
               setActiveKey('taxonomyInstances');
            } else {
               handleStatusBar(resultOfAllMessages);
            }
         } else {
            handleStatusBar(result);
         }
      }

      setShowSpinner(false);
      setIsConfirmed(false);
      setDeletedSubExpenseItems([]);
   };

   const handleItemList = async (
      entityType,
      originItemList,
      itemList,
      deleteAPI,
      editAPI,
      createAPI,
      getUpdatedItems,
      setItems,
      setOriginItems,
      entityId
   ) => {
      let resultOfActionsTemp = [];
      let messageResult;

      if (entityType === 'allExpenseItems') {
         const filteredSubItems = deletedSubExpenseItems.filter((object1) => {
            return allExpenseItems.some((object2) => {
               return object1.parentId === object2.id;
            });
         });
         //console.log('filteredSubItems', filteredSubItems);

         for (let filteredSubItem of filteredSubItems) {
            const deleteResult = await deleteNoticeSubExpenseItems(
               entityId,
               filteredSubItem.parentId,
               filteredSubItem.subId
            );
            //console.log('deleteResult sub:', deleteResult);
            if (deleteResult.success === false) messageResult = deleteResult;
            resultOfActionsTemp.push(deleteResult.success);
            //console.log('deleteResult sub:', deleteResult.success);
         }
      }

      const oldItemsDeleted = originItemList.filter((object1) => {
         return !itemList.some((object2) => {
            return object1.id === object2.id;
         });
      });
      //console.log('oldItemsDeleted', entityType, oldItemsDeleted);

      if (oldItemsDeleted.length > 0) {
         oldItemsDeleted.forEach(async (item) => {
            if (item.id) {
               const deleteResult = await deleteAPI(entityId, item.id);
               if (deleteResult.success === false) messageResult = deleteResult;
               resultOfActionsTemp.push(deleteResult.success);
               //console.log('deleteResult:', deleteResult.success);
            }
         });
      }

      let validationFields = [];

      switch (entityType) {
         case 'docsOfAccountabilities':
            validationFields = ['name', 'description', 'statementType'];
            break;
         /* case 'contactPersons':
                        validationFields = []
                        break; */
         case 'targets':
            validationFields = ['name', 'uom'];
            break;
         /* case 'expenseItems':
                        validationFields = ['name']
                        break; */
         case 'allExpenseItems':
            validationFields = ['name'];
            break;
         case 'engagementRules':
            validationFields = ['name'];
            break;
         default:
            validationFields = [];
      }

      let result;
      let subResult;

      for (let item of itemList) {
         if (validationFields.every((field) => item[field])) {
            if (item.id) {
               if (entityType === 'allExpenseItems') {
                  for (let subItem of item.subExpenseItems) {
                     if (subItem.name) {
                        if (subItem.id) {
                           const { createdAt, createdBy, updatedAt, updatedBy, ...restSubItem } = subItem;
                           subResult = await editNoticeSubExpenseItems(entityId, item.id, restSubItem);
                           if (subResult.success === false) messageResult = subResult;
                           resultOfActionsTemp.push(subResult.success);
                           //console.log('result.success 1', subResult.success);
                        } else {
                           subResult = await createNoticeSubExpenseItems(entityId, item.id, subItem);
                           if (subResult.success === false) messageResult = subResult;
                           resultOfActionsTemp.push(subResult.success);
                           //console.log('result.success 2', subResult.success);
                        }
                     } /*  else {
                     } */
                  }
               }

               const { createdAt, createdBy, updatedAt, updatedBy, notice, ...restItem } = item;
               result = await editAPI(entityId, restItem);
               if (result.success === false) messageResult = result;
               resultOfActionsTemp.push(result.success);
            } else {
               result = await createAPI(entityId, item);
               if (result.success === false) messageResult = result;
               resultOfActionsTemp.push(result.success);
               //console.log('result.success 3', result.success);

               if (
                  result.success === true &&
                  entityType === 'allExpenseItems' &&
                  item.subExpenseItems.length > 0
               ) {
                  let filteredItems = item.subExpenseItems.filter((el) => el.name);

                  for (let subItem of filteredItems) {
                     subResult = await createNoticeSubExpenseItems(entityId, result.data.id, subItem);
                     if (subResult.success === false) messageResult = subResult;
                     //console.log('subResult.success 4', subResult.success);
                     resultOfActionsTemp.push(subResult.success);
                  }
               }

               if (entityType !== 'allExpenseItems') {
                  getUpdatedItems(result.data.noticeId).then((retrievedItems) => {
                     setItems(retrievedItems.data);
                     setOriginItems(retrievedItems.data);
                  });
               }
            }
         } else {
            messageResult = {
               success: false,
               data: {
                  validation: [`${entityType} ${translate('common.error.notsaved', 'non salvati')}`],
               },
            };
            resultOfActionsTemp.push(false);
            return;
         }
      }

      messageResult = messageResult ?? { success: true };
      return { resultOfActionsTemp, messageResult };
   };

   const addElement = (section, typeArray, setTypeArray, fakeId, parentIndex, childIndex) => {
      let tempTypeArray = [...typeArray];

      let objTypeArray = {};

      switch (section) {
         case 'docsOfAccountabilities':
            objTypeArray = { name: '', description: '', statementType: [], noticeId: newNotice.id };
            break;
         case 'targets':
            objTypeArray = { name: '', uom: '', noticeId: newNotice.id };
            break;
         case 'subExpenseItems':
            objTypeArray = {
               name: '',
               description: '',
               noticeId: newNotice.id,
          /* position: childIndex, */ fakeExpenseItemId: fakeId,
            };
            break;
         case 'allExpenseItems':
            objTypeArray = {
               name: '',
               description: '',
               noticeId: newNotice.id,
               fakeId: fakeId,
               subExpenseItems: [
                  {
                     name: '',
                     description: '',
              /* position: 0, */ fakeExpenseItemId: fakeId,
                  },
               ],
            };
            break;
         case 'engagementRules':
            objTypeArray = { name: '', content: '', noticeId: newNotice.id };
            break;
         default:
            objTypeArray = {};
      }

      if (section === 'subExpenseItems') {
         tempTypeArray[parentIndex].subExpenseItems.push(objTypeArray);
      } else {
         tempTypeArray.push(objTypeArray);
      }

      setTypeArray(tempTypeArray);
   };

   const removeElement = (
      typeArray,
      setTypeArray,
      index,
      subIndex,
      type,
      fakeId,
      parentId,
      subId
   ) => {
      //allExpenseItems, setAllExpenseItems, index, i, 'subExpenseItems'
      let tempTypeArray = [...typeArray];

      if (type === 'subExpenseItems') {
         tempTypeArray[index].subExpenseItems.splice(subIndex, 1);
         if (tempTypeArray[index].subExpenseItems.length === 0)
            tempTypeArray[index].subExpenseItems.push({
               name: '',
               description: '',
               fakeExpenseItemId: fakeId,
            });

         if (subId) {
            setDeletedSubExpenseItems((prevState) => [
               ...prevState,
               ...[{ parentId: parentId, subId: subId }],
            ]);
         }
      } else {
         tempTypeArray.splice(index, 1);
      }

      setTypeArray(tempTypeArray);
   };

   const expenseItemsRows = (allExpenseItems, setAllExpenseItems) => {
      let rows = [];
      let isThisRowFilled;
      let parentIndex;

      if (allExpenseItems) {
         if (allExpenseItems.length === 0) {
            allExpenseItems = [
               {
                  name: '',
                  description: '',
                  noticeId: newNotice.id,
                  subExpenseItems: [{ name: '', description: '' }],
               },
            ];
         }

         rows = allExpenseItems
            .sort((a, b) => a.position - b.position)
            .map((el, i) => {
               el.fakeId = el.id ?? 'item-' + i;
               isThisRowFilled = el.name ? true : false;
               parentIndex = i;

               return (
                  <Fragment key={'item-' + i}>
                     <Row style={{ marginBottom: '40px' }}>
                        <Col className='col-auto ps-1'>
                           {/* index padre:{el.position} */}
                           <Button
                              variant='link'
                              className='me-1'
                              style={{
                                 visibility: allExpenseItems.length !== 1 ? 'visible' : 'hidden',
                              }}
                              onClick={() => removeElement(allExpenseItems, setAllExpenseItems, i)}>
                              <IcoMinusCircled className='red-color ico-small indicators' />
                           </Button>
                        </Col>
                        <Col className='col-6'>
                           <Form.Control
                              className={el.name ? 'fw-bold' : ''}
                              type='text'
                              name='name'
                              placeholder={translate('common.placeholder.expenseitem', 'voce di spesa')}
                              value={el.name ?? ''}
                              onChange={(e) =>
                                 onChange(
                                    e,
                                    'allExpenseItems',
                                    setAllExpenseItems,
                                    undefined,
                                    i,
                                    allExpenseItems
                                 )
                              }
                           />
                        </Col>
                     </Row>
                     {el.subExpenseItems &&
                        el.subExpenseItems
                           .sort((a, b) => a.position - b.position)
                           .map((subExpenseItem, index) => {
                              //subExpenseItem.fakeExpenseItemId = el.id ?? 'item-' + i;
                              const isThisSubRowFilled = subExpenseItem.name ? true : false;
                              let lastIndexSub = el.subExpenseItems.length - 1;

                              return (
                                 <Row style={{ marginBottom: '40px' }} key={'subItem-' + index}>
                                    <Col className='col-1'></Col>
                                    <Col className='col-6'>
                                       {/* index figlio:{subExpenseItem.position} */}
                                       <Form.Control
                                          type='text'
                                          name='name'
                                          placeholder={translate(
                                             'common.placeholder.subexpenseitem',
                                             'sottovoce di spesa'
                                          )}
                                          value={subExpenseItem.name ?? ''}
                                          onChange={(e) =>
                                             onChange(
                                                e,
                                                'subExpenseItems',
                                                setAllExpenseItems,
                                                undefined,
                                                index,
                                                el.subExpenseItems,
                                                i
                                             )
                                          }
                                       />
                                    </Col>
                                    <Col className='ps-1'>
                                       {index === lastIndexSub ? (
                                          <Button
                                             variant='link'
                                             className='me-2'
                                             onClick={() =>
                                                addElement(
                                                   'subExpenseItems',
                                                   allExpenseItems,
                                                   setAllExpenseItems,
                                                   el.id ?? 'item-' + i,
                                                   i,
                                                   index
                                                )
                                             }
                                             disabled={!isThisSubRowFilled}>
                                             <IcoPlus className='second-color ico-small indicators' />
                                          </Button>
                                       ) : null}
                                       <Button
                                          variant='link'
                                          className='me-1'
                                          onClick={() =>
                                             removeElement(
                                                allExpenseItems,
                                                setAllExpenseItems,
                                                i,
                                                index,
                                                'subExpenseItems',
                                                el.id ?? 'item-' + i,
                                                el.id,
                                                subExpenseItem.id
                                             )
                                          }>
                                          <IcoMinusCircled className='red-color ico-small indicators' />
                                       </Button>
                                    </Col>
                                 </Row>
                              );
                           })}
                  </Fragment>
               );
            });
      }

      return (
         <>
            {rows}

            <Button
               className='mt-4 ms-5'
               variant='primary'
               onClick={() =>
                  addElement(
                     'allExpenseItems',
                     allExpenseItems,
                     setAllExpenseItems,
                     'item-' + (parentIndex + 1)
                  )
               }
               disabled={!isThisRowFilled}>
               <IcoPlus className='light-color ico-small plus new-button' />
               <span className='d-md-inline'>
                  &nbsp;
                  <OETrans code='common.addexpenseitem' fallback={'Aggiungi voce di spesa'} />
               </span>
            </Button>
         </>
      );
   };

   const engagementRulesRows = (engagementRules, setExpenseItems) => {
      let rows = [];

      if (engagementRules) {
         if (engagementRules.length === 0) {
            engagementRules = [{ name: '', content: '', noticeId: newNotice.id }];
         }

         let lastIndex = engagementRules.length - 1;

         rows = engagementRules.map((el, i) => {
            const isThisRowFilled = el.name ? true : false;

            return (
               <Row className='align-items-center mb-3' key={'rule-' + i}>
                  <Col className='col-4'>
                     <Form.Control
                        type='text'
                        name='name'
                        placeholder={translate('common.placeholder.engagementrulename', 'nome')}
                        value={el.name ?? ''}
                        onChange={(e) =>
                           onChange(e, 'engagementRules', setEngagementRules, undefined, i, engagementRules)
                        }
                     />
                  </Col>
                  <Col className='col-6'>
                     <Form.Control
                        type='text'
                        name='content'
                        placeholder={translate(
                           'common.placeholder.engagementruledescription',
                           'descrizione (opzionale)'
                        )}
                        value={el.content ?? ''}
                        onChange={(e) =>
                           onChange(e, 'engagementRules', setEngagementRules, undefined, i, engagementRules)
                        }
                        disabled={!el.name}
                     />
                  </Col>
                  <Col className='col-2 pe-0'>
                     {engagementRules.length !== 1 ? (
                        <Button
                           variant='link'
                           className='me-3'
                           onClick={() => removeElement(engagementRules, setExpenseItems, i)}>
                           <IcoMinusCircled className='red-color ico-small indicators' />
                        </Button>
                     ) : null}
                     {i === lastIndex ? (
                        <Button
                           variant='link'
                           onClick={() => addElement('engagementRules', engagementRules, setExpenseItems)}
                           disabled={!isThisRowFilled}>
                           <IcoPlus className='second-color ico-small indicators' />
                        </Button>
                     ) : null}
                  </Col>
               </Row>
            );
         });
      }

      return rows;
   };

   const docsOfAccountabilityRows = (docsOfAccountability, setDocsOfAccountability) => {
      let rows = [];

      if (docsOfAccountability) {
         if (docsOfAccountability.length === 0) {
            docsOfAccountability = [
               { name: '', description: '', statementType: [], noticeId: newNotice.id },
            ];
         }

         let lastIndex = docsOfAccountability.length - 1;

         rows = docsOfAccountability.map((el, i) => {
            const isThisRowFilled =
               el.name && el.description && el.statementType.length > 0 ? true : false;

            return (
               <Row style={{ marginBottom: '40px' }} key={'docsOfAccountability-' + i}>
                  <Col className='col-4'>
                     <Form.Control
                        type='text'
                        name='name'
                        placeholder={translate('common.placeholder.documentname', 'nome documento')}
                        value={el.name ?? ''}
                        onChange={(e) =>
                           onChange(
                              e,
                              'docsOfAccountabilities',
                              setDocsOfAccountability,
                              undefined,
                              i,
                              docsOfAccountability
                           )
                        }
                     />
                  </Col>
                  <Col className='col-3'>
                     <Form.Control
                        className='custom-disabled'
                        type='text'
                        name='description'
                        placeholder={translate(
                           'common.placeholder.documentdescription',
                           'descrizione documento'
                        )}
                        value={el.description ?? ''}
                        onChange={(e) =>
                           onChange(
                              e,
                              'docsOfAccountabilities',
                              setDocsOfAccountability,
                              undefined,
                              i,
                              docsOfAccountability
                           )
                        }
                     />
                  </Col>
                  <Col className='col-3'>
                     <Select
                        components={{
                           IndicatorSeparator: () => null,
                        }}
                        name='statementType'
                        placeholder={
                           translate(
                              'common.placeholder.selectstatementtypes',
                              'Seleziona tipi di rendiconto'
                           ) + '...'
                        }
                        value={
                           optionsStatementTypes.filter((opt) => {
                              return el.statementType.some((typeCode) => {
                                 return typeCode === opt.value;
                              });
                           }) ?? []
                        }
                        options={optionsStatementTypes}
                        className='react-select-container'
                        classNamePrefix='react-select'
                        closeMenuOnSelect={false}
                        onChange={(e) =>
                           onChange(
                              e,
                              'docsOfAccountabilities',
                              setDocsOfAccountability,
                              undefined,
                              i,
                              docsOfAccountability
                           )
                        }
                        isMulti
                        isClearable={false}
                     />
                  </Col>
                  <Col className='col-2'>
                     {i === lastIndex ? (
                        <Button
                           variant='link'
                           className='me-3'
                           onClick={() =>
                              addElement(
                                 'docsOfAccountabilities',
                                 docsOfAccountability,
                                 setDocsOfAccountability
                              )
                           }
                           disabled={!isThisRowFilled && (!el.name || !el.description)}>
                           <IcoPlus className='second-color ico-small indicators' />
                        </Button>
                     ) : null}
                     {docsOfAccountability.length !== 1 ? (
                        <Button
                           disabled={isUsed}
                           variant='link'
                           onClick={() => removeElement(docsOfAccountability, setDocsOfAccountability, i)}>
                           <IcoMinusCircled className='red-color ico-small indicators' />
                        </Button>
                     ) : null}
                  </Col>
               </Row>
            );
         });
      }
      return rows;
   };

   const targetsRows = (targets, setTargets) => {
      let rows = [];

      if (targets) {
         if (targets.length === 0) {
            targets = [{ name: '', uom: '', noticeId: newNotice.id }];
         }

         let lastIndex = targets.length - 1;

         rows = targets.map((el, i) => {
            const isThisRowFilled = el.name && el.uom ? true : false;

            return (
               <Row style={{ marginBottom: '40px' }} key={'target-' + i}>
                  <Col className='col-6'>
                     <Form.Control
                        type='text'
                        name='name'
                        placeholder={translate('common.placeholder.target', 'obiettivo')}
                        value={el.name ?? ''}
                        onChange={(e) => onChange(e, 'targets', setTargets, undefined, i, targets)}
                     />
                  </Col>
                  <Col className='col-4'>
                     <Form.Control
                        className='custom-disabled'
                        type='text'
                        name='uom'
                        placeholder={translate('common.placeholder.uom', 'udm') + '*'}
                        value={el.uom ?? ''}
                        onChange={(e) => onChange(e, 'targets', setTargets, undefined, i, targets)}
                     />
                  </Col>
                  <Col className='col-2'>
                     {i === lastIndex ? (
                        <Button
                           variant='link'
                           onClick={() => addElement('targets', targets, setTargets)}
                           disabled={!isThisRowFilled && (!el.name || !el.uom)}>
                           <IcoPlus className='second-color ico-small indicators' />
                        </Button>
                     ) : null}
                     {targets.length !== 1 ? (
                        <Button
                           variant='link'
                           className='me-3'
                           onClick={() => removeElement(targets, setTargets, i)}>
                           <IcoMinusCircled className='red-color ico-small indicators' />
                        </Button>
                     ) : null}
                  </Col>
               </Row>
            );
         });
      }

      return rows;
   };

   const taxValue = (fundTaxonomyCode, taxonomyValue, options) => {
      if (options) {
         const selectedValue = options.filter((opt) => opt.value === taxonomyValue);
         return selectedValue;
      } else {
         return taxonomyValue ?? '';
      }
   };

   const renderTooltip = (props) => {
      let message = '';

      if (props.popper.state) {
         message = props.popper.state.options.testObj;
      }

      return (
         <Tooltip id='button-tooltip' {...props}>
            {message}
         </Tooltip>
      );
   };

   return (
      <>
         <div className='simple-box scenaritab'>
            <div className='row d-flex align-items-center mb-3'>
               <div className='col-4'></div>
               <div className='col-8 text-right px-0 py-1'>
                  <ButtonToolbar className='justify-content-end'>
                     <Button variant='success' onClick={() => handleShowModal(setShowModalNew)}>
                        <IcoPlus className='light-color ico-small plus new-button' />
                        <span className='d-md-inline'>
                           <OETrans code='common.notices.addnotice' fallback={'Crea Bando'} />
                        </span>
                     </Button>
                     <CustomQuickFilterBox
                        tableRef={gridRef}
                        dataMeta={noticesMeta}
                        setData={setNotices}
                        dataLength={notices?.length}
                        isFirstResearch={isFirstResearch}
                        setIsFirstResearch={setIsFirstResearch}
                        refTable='notices'
                     />
                  </ButtonToolbar>
               </div>
            </div>
            <div className='row'>
               {Array.isArray(notices) ? (
                  <div className='col-12' id='grid-wrapper'>
                     <div className='ag-theme-alpine list-table'>
                        <AgGridReact
                           ref={gridRef}
                           columnDefs={columnDefs}
                           rowData={notices}
                           defaultColDef={defaultColDef}
                           domLayout='autoHeight'
                           tooltipShowDelay={0}
                           tooltipHideDelay={7000}
                           tooltipMouseTrack={true}
                           onGridReady={onGridReady}
                           //onFirstDataRendered={onFirstDataRendered}
                           //onModelUpdated={onModelUpdated}
                           //pagination={true}
                           //paginationPageSize={100}
                           headerHeight={50}
                           rowHeight={50}
                           localeText={localeText}
                           ariaDescription='ag-grid-table'
                           suppressCellFocus={true}
                        //animateRows={true}
                        />
                        {Object.keys(noticesMeta).length > 0 && (
                           <CustomPaginationMui
                              dataMeta={noticesMeta}
                              setData={setNewNotice}
                              setDataMeta={setNoticesMeta}
                              refTable='notices'
                              setIsFirstResearch={setIsFirstResearch}
                           />
                        )}
                     </div>
                  </div>
               ) : (
                  <div className='text-center my-4'>
                     <Spinner animation='border' role='status'>
                        <span style={{ visibility: 'hidden' }}>
                           <OETrans code='common.loading' fallback={'Caricamento'} />
                           ...
                        </span>
                     </Spinner>
                  </div>
               )}
            </div>
         </div>

         <Modal
            show={showModalNew}
            className='modal-with-nav'
            size='xl'
            dialogClassName='modal-75w'
            onHide={() => handleCloseModal(setShowModalNew)}
            centered>
            <Modal.Header closeButton>
               <Modal.Title>
                  <OETrans code='common.notices.createnotice' fallback={'Crea Bando'} />
               </Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <Tabs
                  className='mb-4'
                  activeKey={activeKey}
                  onSelect={(k) => setActiveKey(k)}
                  variant='pills'
                  fill>
                  {/* Anagrafica */}
                  <Tab eventKey='registry' title={translate('common.registry', 'Anagrafica')}>
                     <Form onSubmit={handleSave} id='form-new-notice'>
                        <Row style={{ marginBottom: '40px' }}>
                           <Col className='col-6'>
                              <Form.Group as={Col}>
                                 <Form.Label>
                                    <OETrans code='notices.call' fallback={'Avviso/Call'} />
                                    &nbsp;<span className='required-field'>*</span>
                                 </Form.Label>
                                 <Form.Control
                                    type='text'
                                    name='call'
                                    value={newNotice.call}
                                    onChange={(e) => onChange(e, 'call')}
                                 />
                              </Form.Group>
                           </Col>
                           <Col className='col-3'>
                              <Form.Group as={Col}>
                                 <Form.Label>
                                    <OETrans code='notices.publishedAt' fallback={'Pubblicazione'} />
                                    &nbsp;<span className='required-field'>*</span>
                                 </Form.Label>
                                 <Form.Control
                                    type='date'
                                    name='publishedAt'
                                    value={
                                       newNotice.publishedAt
                                          ? moment(newNotice.publishedAt).format('YYYY-MM-DD')
                                          : ''
                                    }
                                    onChange={(e) => onChange(e, 'publishedAt')}
                                    isInvalid={
                                       new Date(newNotice.publishedAt).getTime() >
                                       new Date(newNotice.expiresAt).getTime()
                                    }
                                 />
                                 <Form.Control.Feedback type='invalid' className='ps-1 position-absolute'>
                                    {translate(
                                       'common.error.published',
                                       'la pubblicazione deve essere antecedente alla scadenza'
                                    ).toLowerCase()}
                                 </Form.Control.Feedback>
                              </Form.Group>
                           </Col>
                           <Col className='col-3'>
                              <Form.Group as={Col}>
                                 <Form.Label>
                                    <OETrans code='notices.expiresAt' fallback={'Scadenza'} />
                                    &nbsp;<span className='required-field'>*</span>
                                 </Form.Label>
                                 <Form.Control
                                    type='date'
                                    name='expiresAt'
                                    value={
                                       newNotice.expiresAt
                                          ? moment(newNotice.expiresAt).format('YYYY-MM-DD')
                                          : ''
                                    }
                                    onChange={(e) => onChange(e, 'expiresAt')}
                                    isInvalid={
                                       new Date(newNotice.publishedAt).getTime() >
                                       new Date(newNotice.expiresAt).getTime()
                                    }
                                 />
                              </Form.Group>
                           </Col>
                        </Row>

                        <Row style={{ marginBottom: '40px' }}>
                           <Col>
                              <Form.Group as={Col}>
                                 <Form.Label>
                                    <OETrans code='notices.name' fallback={'Bando'} />
                                    &nbsp;<span className='required-field'>*</span>
                                 </Form.Label>
                                 <Form.Control
                                    as='textarea'
                                    rows={3}
                                    name='name'
                                    value={newNotice.name}
                                    onChange={(e) => onChange(e, 'name')}
                                 />
                              </Form.Group>
                           </Col>
                        </Row>

                        <Row style={{ marginBottom: '40px' }}>
                           <Col>
                              <Form.Group as={Col}>
                                 <Form.Label>
                                    <OETrans code='notices.fundId' fallback={'Fondo'} />
                                    &nbsp;<span className='required-field'>*</span>
                                 </Form.Label>
                                 <Select
                                    components={{
                                       IndicatorSeparator: () => null,
                                    }}
                                    name='fundId'
                                    placeholder={translate('common.placeholder.select', 'Seleziona...')}
                                    value={selectedFund}
                                    options={optionsFunds}
                                    className='react-select-container'
                                    classNamePrefix='react-select'
                                    onChange={(e) => onChange(e, 'fundId', setSelectedFund)}
                                 />
                              </Form.Group>
                           </Col>
                           <Col>
                              <Form.Group as={Col}>
                                 <Form.Label>
                                    <OETrans code='notices.instrumentId' fallback={'Strumento'} />
                                    &nbsp;<span className='required-field'>*</span>
                                 </Form.Label>
                                 <Select
                                    components={{
                                       IndicatorSeparator: () => null,
                                    }}
                                    className='react-select-container'
                                    classNamePrefix='react-select'
                                    name='instrumentId'
                                    placeholder={translate('common.placeholder.select', 'Seleziona...')}
                                    value={selectedInstrument}
                                    options={optionsInstruments}
                                    onChange={(e) => onChange(e, 'instrumentId', setSelectedInstrument)}
                                 />
                              </Form.Group>
                           </Col>
                           <Col>
                              <Form.Group as={Col}>
                                 <Form.Label>
                                    <OETrans code='notices.institutionId' fallback={'Ente'} />
                                    &nbsp;<span className='required-field'>*</span>
                                 </Form.Label>
                                 <Select
                                    components={{
                                       IndicatorSeparator: () => null,
                                    }}
                                    name='institutionId'
                                    placeholder={translate('common.placeholder.select', 'Seleziona...')}
                                    value={selectedInstitution}
                                    options={optionsInstitutions}
                                    className='react-select-container'
                                    classNamePrefix='react-select'
                                    onChange={(e) => onChange(e, 'institutionId', setSelectedInstitution)}
                                 />
                              </Form.Group>
                           </Col>
                           <Col>
                              <Form.Group as={Col}>
                                 <Form.Label>
                                    <OETrans code='common.setchecklist' fallback={'Set Domande'} />
                                    &nbsp;<span className='required-field'>*</span>
                                 </Form.Label>
                                 <Select
                                    components={{
                                       IndicatorSeparator: () => null,
                                    }}
                                    name='questionsetId'
                                    placeholder={translate('common.placeholder.select', 'Seleziona...')}
                                    value={selectedQuestionSet}
                                    options={optionsQuestionSet}
                                    className='react-select-container'
                                    classNamePrefix='react-select'
                                    onChange={(e) => onChange(e, 'questionsetId', setSelectedQuestionSet)}
                                 />
                              </Form.Group>
                           </Col>
                        </Row>

                        <Row style={{ marginBottom: '40px' }}>
                           <Col>
                              <Form.Group as={Col}>
                                 <Form.Label>
                                    <OETrans code='notices.contactpersons' fallback={'Referente UDM/RUP'} />
                                 </Form.Label>
                                 <FloatingLabel
                                    label={translate('common.placeholder.contactpersons.firstname', 'Nome')}>
                                    <Form.Control
                                       type='text'
                                       name='firstName'
                                       placeholder={translate(
                                          'common.placeholder.contactpersons.firstname',
                                          'Nome'
                                       )}
                                       value={contactPersons[0]?.firstName}
                                       onChange={(e) =>
                                          onChange(
                                             e,
                                             'contactPersons',
                                             setContactPersons,
                                             undefined,
                                             0,
                                             contactPersons
                                          )
                                       }
                                    />
                                 </FloatingLabel>
                              </Form.Group>
                           </Col>
                           <Col>
                              <Form.Group as={Col}>
                                 <Form.Label>&nbsp;</Form.Label>
                                 <FloatingLabel
                                    label={translate('common.placeholder.contactpersons.lastname', 'Cognome')}>
                                    <Form.Control
                                       type='text'
                                       name='lastName'
                                       placeholder={translate(
                                          'common.placeholder.contactpersons.lastname',
                                          'Cognome'
                                       )}
                                       value={contactPersons[0]?.lastName}
                                       onChange={(e) =>
                                          onChange(
                                             e,
                                             'contactPersons',
                                             setContactPersons,
                                             undefined,
                                             0,
                                             contactPersons
                                          )
                                       }
                                    />
                                 </FloatingLabel>
                              </Form.Group>
                           </Col>
                           <Col>
                              <Form.Group as={Col}>
                                 <Form.Label>&nbsp;</Form.Label>
                                 <FloatingLabel
                                    label={translate('common.placeholder.contactpersons.email', 'Email')}>
                                    <Form.Control
                                       type='text'
                                       name='email'
                                       placeholder={translate(
                                          'common.placeholder.contactpersons.email',
                                          'email'
                                       )}
                                       value={contactPersons[0]?.email}
                                       onChange={(e) =>
                                          onChange(
                                             e,
                                             'contactPersons',
                                             setContactPersons,
                                             undefined,
                                             0,
                                             contactPersons
                                          )
                                       }
                                       onBlur={(e) => onBlur(e.target.value, emailRegex, setEmailInvalid)}
                                       isInvalid={emailInvalid}
                                    />
                                 </FloatingLabel>
                                 <Form.Control.Feedback type='invalid' className='ps-1 position-absolute'>
                                    {translate('common.error.email', 'email non conforme').toLowerCase()}
                                 </Form.Control.Feedback>
                              </Form.Group>
                           </Col>
                           <Col>
                              <Form.Group as={Col}>
                                 <Form.Label>&nbsp;</Form.Label>
                                 <FloatingLabel
                                    label={translate(
                                       'common.placeholder.contactpersons.phone',
                                       'Recapito telefonico'
                                    )}>
                                    <Form.Control
                                       type='text'
                                       name='phone'
                                       placeholder={translate(
                                          'common.placeholder.contactpersons.phone',
                                          'recapito telefonico'
                                       )}
                                       value={contactPersons[0]?.phone}
                                       onChange={(e) =>
                                          onChange(
                                             e,
                                             'contactPersons',
                                             setContactPersons,
                                             undefined,
                                             0,
                                             contactPersons
                                          )
                                       }
                                    />
                                 </FloatingLabel>
                              </Form.Group>
                           </Col>
                        </Row>
                     </Form>
                     {sbShow}
                  </Tab>

                  {/* Tassonomie & Obiettivi */}
                  <Tab
                     eventKey='taxonomyInstances'
                     title={translate('common.taxonomiestargets', 'Tassonomie & Obiettivi')}
                     disabled={!newNotice.id}>
                     <Form onSubmit={handleSave} id='form-new-notice'>
                        {taxonomyInstances.length > 0 && (
                           <>
                              {
                                 // Dividi taxonomyInstances in gruppi di 3
                                 taxonomyInstances
                                    .sort((a, b) => a.taxonomy.position - b.taxonomy.position)
                                    .reduce((acc, taxonomy, i) => {
                                       if (i % 3 === 0) acc.push([]);
                                       acc[acc.length - 1].push(taxonomy);
                                       return acc;
                                    }, [])
                                    .map((group, groupIndex) => (
                                       <Row key={groupIndex} style={{ marginBottom: '40px' }}>
                                          {group.map((taxonomy) => {
                                             const fundTax = fundTaxonomies.filter(
                                                (fundTax) => fundTax.code === taxonomy.fundTaxonomyCode
                                             );
                                             const label = fundTax.map((el) => el.name).pop();
                                             let options = [];

                                             if (Object.keys(currentPnrrTaxonomies).length > 0) {
                                                options = currentPnrrTaxonomies[label.toLowerCase()]?.map(
                                                   (pnrrTax) => ({
                                                      value: pnrrTax.code,
                                                      label: `${pnrrTax.code === pnrrTax.description
                                                         ? ''
                                                         : `${pnrrTax.code} - `
                                                         }${pnrrTax.description}`,
                                                   })
                                                );
                                             }

                                             return (
                                                <Col key={taxonomy.id} className='col-4'>
                                                   <Form.Group as={Col}>
                                                      <Form.Label>
                                                         {fundTax.map((el) => el.name)}{' '}
                                                         {/* traduzione non disponibile */}
                                                      </Form.Label>
                                                      {newNotice.fundId === 1 &&
                                                         taxonomy.fundTaxonomyCode !== 'PNRRAMM' ? (
                                                         /* se fondo=PNRR ed è il campo non è Amn. Titolare */
                                                         <Select
                                                            components={{
                                                               IndicatorSeparator: () => null,
                                                            }}
                                                            className='react-select-container'
                                                            classNamePrefix='react-select'
                                                            name={taxonomy.fundTaxonomyCode}
                                                            placeholder={translate(
                                                               'common.placeholder.select',
                                                               'Seleziona...'
                                                            )}
                                                            value={taxValue(
                                                               taxonomy.fundTaxonomyCode,
                                                               taxonomy.value,
                                                               options
                                                            )}
                                                            options={options}
                                                            onChange={(e) =>
                                                               onChange(
                                                                  e,
                                                                  'taxonomyinstances',
                                                                  setTaxonomyInstances,
                                                                  taxonomy.fundTaxonomyCode
                                                               )
                                                            }
                                                         />
                                                      ) : (
                                                         /* se fondo=PNRR ed è il campo Amn. Titolare */
                                                         <Form.Control
                                                            type={
                                                               fundTax.map((el) => el.valueType).pop() === 'string'
                                                                  ? 'text'
                                                                  : 'number'
                                                            }
                                                            name={taxonomy.fundTaxonomyCode}
                                                            value={taxValue(taxonomy.fundTaxonomyCode, taxonomy.value)}
                                                            onChange={(e) =>
                                                               onChange(
                                                                  e,
                                                                  'taxonomyinstances',
                                                                  setTaxonomyInstances,
                                                                  taxonomy.fundTaxonomyCode
                                                               )
                                                            }
                                                            disabled={
                                                               newNotice.fundId === 1 &&
                                                               taxonomy.fundTaxonomyCode === 'PNRRAMM'
                                                            }
                                                         />
                                                      )}
                                                   </Form.Group>
                                                </Col>
                                             );
                                          })}
                                       </Row>
                                    ))
                              }
                           </>
                        )}

                        <Row style={{ marginBottom: '40px' }}>
                           <Form.Group as={Col}>
                              <Form.Label>
                                 <OETrans code='common.targets' fallback={'Obiettivi'} />
                              </Form.Label>
                              {targetsRows(targets, setTargets)}
                           </Form.Group>
                        </Row>
                     </Form>
                     {sbShow}
                  </Tab>

                  {/* Documenti del Rendiconto */}
                  <Tab
                     eventKey='docsOfAccountability'
                     title={translate('common.reportdocuments', 'Documenti del Rendiconto')}
                     disabled={!newNotice.id}>
                     <Form onSubmit={handleSave} id='form-new-notice'>
                        <Row>
                           <Form.Group as={Col}>
                              <Form.Label>
                                 <OETrans code='common.documents' fallback={'Documenti'} />
                                 &nbsp;<span className='required-field'>*</span>
                              </Form.Label>
                              {docsOfAccountabilityRows(docsOfAccountability, setDocsOfAccountability)}
                           </Form.Group>
                        </Row>
                        {sbShow}
                     </Form>
                  </Tab>

                  {/* Voci di spesa */}
                  <Tab
                     eventKey='expenseItems'
                     title={translate('common.expenseitems', 'Voci di spesa')}
                     disabled={!newNotice.id}>
                     <Form onSubmit={handleSave} id='form-new-notice'>
                        <Row>
                           <Col className='py-2'>
                              <Row>
                                 <Form.Group as={Col}>
                                    <Form.Label>
                                       <OETrans code='common.expenseitems' fallback={'Voci di spesa'} />
                                       <OverlayTrigger
                                          placement='top'
                                          delay={{ show: 200, hide: 400 }}
                                          overlay={renderTooltip}
                                          popperConfig={{
                                             testObj: translate(
                                                'common.expenseitems',
                                                'Le sottovoci di spesa devono essere univoche. In caso di nome uguale, inserire tra parentesi la macro voce di spesa. Es. Altro (Altri beni ad utilità pluriennale)',
                                                'description'
                                             ),
                                          }}>
                                          <TooltipInfoIcon className='tooltip-ico' style={{ width: '1rem' }} />
                                       </OverlayTrigger>
                                    </Form.Label>
                                    {expenseItemsRows(allExpenseItems, setAllExpenseItems)}
                                 </Form.Group>
                              </Row>
                           </Col>
                        </Row>
                     </Form>
                     {sbShow}
                  </Tab>

                  {/* Regole d'ingaggio */}
                  <Tab
                     eventKey='engagementRules'
                     title={translate('common.engagementrules', 'Regole d’ingaggio')}
                     disabled={!newNotice.id}>
                     <Form onSubmit={handleSave} id='form-new-notice'>
                        <Row style={{ marginBottom: '40px' }}>
                           <Col className='col-6'>
                              <Form.Group as={Col}>
                                 <Form.Label>
                                    <OETrans code='notices.objectives' fallback={'Obiettivi'} />
                                 </Form.Label>
                                 <Form.Control
                                    as='textarea'
                                    rows={2}
                                    name='objectives'
                                    value={newNotice.objectives ?? ''}
                                    onChange={(e) => onChange(e, 'objectives')}
                                 />
                              </Form.Group>
                           </Col>
                           <Col className='col-6'>
                              <Form.Group as={Col}>
                                 <Form.Label>
                                    <OETrans
                                       code='notices.timeframe'
                                       fallback={'Data di avvio/ultimazione evento'}
                                    />
                                 </Form.Label>
                                 <Form.Control
                                    as='textarea'
                                    rows={2}
                                    name='timeframe'
                                    value={newNotice.timeframe ?? ''}
                                    onChange={(e) => onChange(e, 'timeframe')}
                                 />
                              </Form.Group>
                           </Col>
                        </Row>
                        <Row style={{ marginBottom: '40px' }}>
                           <Col className='col-6'>
                              <Form.Group as={Col}>
                                 <Form.Label>
                                    <OETrans
                                       code='notices.managingSystem'
                                       fallback={'Sistema informatico di monitoraggio e rendicontazione'}
                                    />
                                 </Form.Label>
                                 <Form.Control
                                    as='textarea'
                                    rows={2}
                                    name='managingSystem'
                                    value={newNotice.managingSystem ?? ''}
                                    onChange={(e) => onChange(e, 'managingSystem')}
                                 />
                              </Form.Group>
                           </Col>
                           <Col className='col-6'>
                              <Form.Group as={Col}>
                                 <Form.Label>
                                    <OETrans code='notices.compounding' fallback={'Possibilità di cumulo'} />
                                 </Form.Label>
                                 <Form.Control
                                    as='textarea'
                                    rows={2}
                                    name='compounding'
                                    value={newNotice.compounding ?? ''}
                                    onChange={(e) => onChange(e, 'compounding')}
                                 />
                              </Form.Group>
                           </Col>
                        </Row>
                        <Row style={{ marginBottom: '40px' }}>
                           <Col className='col-3'>
                              <Form.Group as={Col}>
                                 <Form.Label>
                                    <OETrans code='notices.changeManagement' fallback={'Gestione varianti'} />
                                 </Form.Label>
                                 <Form.Control
                                    as='textarea'
                                    rows={3}
                                    name='changeManagement'
                                    value={newNotice.changeManagement ?? ''}
                                    onChange={(e) => onChange(e, 'changeManagement')}
                                 />
                              </Form.Group>
                           </Col>
                           <Col className='col-3'>
                              <Form.Group as={Col}>
                                 <Form.Label>
                                    <OETrans code='notices.disbursement' fallback={'Richieste di erogazione'} />
                                 </Form.Label>
                                 <Form.Control
                                    as='textarea'
                                    rows={3}
                                    name='disbursement'
                                    value={newNotice.disbursement ?? ''}
                                    onChange={(e) => onChange(e, 'disbursement')}
                                 />
                              </Form.Group>
                           </Col>
                           <Col className='col-3'>
                              <Form.Group as={Col}>
                                 <Form.Label>
                                    <OETrans code='notices.admissibleCosts' fallback={'Costi ammissibili'} />
                                 </Form.Label>
                                 <Form.Control
                                    as='textarea'
                                    rows={3}
                                    name='admissibleCosts'
                                    value={newNotice.admissibleCosts ?? ''}
                                    onChange={(e) => onChange(e, 'admissibleCosts')}
                                 />
                              </Form.Group>
                           </Col>
                           <Col className='col-3'>
                              <Form.Group as={Col}>
                                 <Form.Label>
                                    <OETrans
                                       code='notices.advancePaymentPercentage'
                                       fallback={'Percentuale di anticipo'}
                                    />
                                 </Form.Label>
                                 <InputNumber
                                    name='advancePaymentPercentage'
                                    min={0}
                                    max={100}
                                    step={1}
                                    minFractionDigits={0}
                                    maxFractionDigits={2}
                                    mode='decimal'
                                    locale={localLang}
                                    onChange={(e) => onChange(e, 'advancePaymentPercentage')}
                                    value={newNotice.advancePaymentPercentage ?? 0}
                                 />
                              </Form.Group>
                           </Col>
                        </Row>

                        <Row>
                           <Form.Group as={Col}>
                              <Form.Label>
                                 <OETrans code='notices.engagementrules.otheritems' fallback={'Altre voci'} />
                              </Form.Label>
                              {engagementRulesRows(engagementRules, setEngagementRules)}
                           </Form.Group>
                        </Row>
                     </Form>
                     {sbShow}
                  </Tab>

                  {/* Condizionalità */}
                  <Tab
                     eventKey='conditionality'
                     title={translate('common.conditionality', 'Condizionalità')}
                     disabled={!newNotice.id}>
                     <Form onSubmit={handleSave} id='form-new-notice'>
                        <Row style={{ marginBottom: '40px' }}>
                           <Form.Group as={Col}>
                              <Form.Check
                                 inline
                                 type='checkbox'
                                 name='environmentalTagging'
                                 defaultChecked={newNotice.environmentalTagging}
                                 onClick={(e) => onChange(e, 'environmentalTagging')}
                              />
                              <Form.Label>
                                 <OETrans code='notices.environmentalTagging' fallback={'Tagging Climatico'} />
                              </Form.Label>
                           </Form.Group>

                           <Form.Group as={Col}>
                              <Form.Check
                                 inline
                                 type='checkbox'
                                 name='digitalTagging'
                                 defaultChecked={newNotice.digitalTagging}
                                 onClick={(e) => onChange(e, 'digitalTagging')}
                              />
                              <Form.Label>
                                 <OETrans code='notices.digitalTagging' fallback={'Tagging digitale'} />
                              </Form.Label>
                           </Form.Group>
                        </Row>

                        <Row style={{ marginBottom: '40px' }}>
                           <Form.Group as={Col}>
                              <Form.Check
                                 inline
                                 type='checkbox'
                                 name='genderEquality'
                                 defaultChecked={newNotice.genderEquality}
                                 onClick={(e) => onChange(e, 'genderEquality')}
                              />
                              <Form.Label>
                                 <OETrans
                                    code='notices.genderEquality'
                                    fallback={'Parità di genere e politiche giovanili'}
                                 />
                              </Form.Label>
                           </Form.Group>
                           <Form.Group as={Col}>
                              <Form.Check
                                 inline
                                 type='checkbox'
                                 name='dnsh'
                                 defaultChecked={newNotice.dnsh}
                                 onClick={(e) => onChange(e, 'dnsh')}
                              />
                              <Form.Label>
                                 <OETrans code='notices.dnsh' fallback={'DNSH'} />
                              </Form.Label>
                           </Form.Group>
                        </Row>
                     </Form>
                     {sbShow}
                  </Tab>

                  {/* Documenti */}
                  <Tab
                     eventKey='docs'
                     title={<OETrans code='common.documents' fallback={'Documenti'} />}
                     disabled={!newNotice.id}>
                     <UploadDocument entity='notices' entityId={newNotice.id} />
                  </Tab>

                  {/* Impostazioni Avanzate */}
                  <Tab
                     eventKey='advancedSettings'
                     title={translate('common.advancedSettings', 'Impostazioni avanzate')}
                     disabled={!newNotice.id}>
                     <Form onSubmit={handleSave} id='form-new-notice'>
                        {activeKey === 'advancedSettings' && availableAdvancedConfig.length > 0 &&
                           availableAdvancedConfig.sort((a, b) => a.id - b.id)
                              .map((el, i) => (
                                 <Row key={el.name} className="mt-4 align-items-center">
                                    <Form.Group as={Col}>
                                       <Form.Check
                                          inline
                                          type='checkbox'
                                          name={el.name}
                                          defaultChecked={selectedAdvancedConfig.some(advConfig => Number(advConfig.id) === Number(el.id) && advConfig.enabled)}
                                          onClick={(e) =>
                                             onAdvancedChange(
                                                e,
                                                'advancedSettings',
                                                setSelectedAdvancedConfig,
                                                el.id
                                             )
                                          }
                                       />
                                       <Form.Label className='my-0'>{el.label}</Form.Label>
                                       {el.description && (
                                          <OverlayTrigger
                                             placement='top'
                                             delay={{ show: 200, hide: 400 }}
                                             overlay={<Tooltip id='button-tooltip'>{el.description}</Tooltip>}>
                                             <TooltipInfoIcon className='tooltip-ico' style={{ width: '1rem' }} />
                                          </OverlayTrigger>
                                       )}
                                    </Form.Group>
                                    {el.name === 'enable_external_tools' && (
                                       <Form.Group as={Col} className='text-right'>
                                          <Form.Label className='px-2 my-0'>Numero massimo giorni rendicontabili per dipendente:</Form.Label>
                                          <InputNumber
                                             style={{ maxWidth: '5rem' }}
                                             name='reportableEmployeeDays'
                                             min={0}
                                             step={1}
                                             minFractionDigits={0}
                                             maxFractionDigits={0}
                                             locale={localLang}
                                             onChange={(e) => onChange(e, 'reportableEmployeeDays')}
                                             value={newNotice.reportableEmployeeDays ?? 0}
                                          />
                                       </Form.Group>
                                    )}
                                 </Row>
                              ))}
                     </Form>
                     {sbShow}
                  </Tab>
               </Tabs>

               {(activeKey === 'registry' || activeKey === 'docs') && (
                  <Row>
                     <Col className='col-12'>
                        <span className='label'>
                           <span className='required-field'>*</span>&nbsp;
                           <OETrans code='common.requiredFields' fallback={'Campi obbligatori'} />
                        </span>
                     </Col>
                  </Row>
               )}
            </Modal.Body>

            <Modal.Footer>
               <Button variant='secondary' onClick={() => handleCloseModal(setShowModalNew)}>
                  <OETrans code='common.cancel' fallback={'Annulla'} />
               </Button>
               <Button variant='primary' onClick={(e) => handleSave(e)} disabled={activeKey === 'docs'}>
                  {newNotice.id ? (
                     <OETrans code='common.save' fallback={'Salva'} />
                  ) : (
                     <OETrans code='common.create' fallback={'Crea'} />
                  )}
                  {showSpinner && (
                     <Spinner
                        as='span'
                        className='ms-2'
                        animation='border'
                        size='sm'
                        role='status'
                        aria-hidden='true'
                     />
                  )}
               </Button>
            </Modal.Footer>
         </Modal>

         <Modal show={showModalWarning} onHide={() => setShowModalWarning(false)} centered>
            <Modal.Header closeButton>
               <Modal.Title>
                  <OETrans code='common.warning' fallback={'Attenzione'} />
               </Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <OETrans
                  code='common.warning.editingtargetsexpenseitems'
                  fallback={
                     'Le modifiche agli obiettivi e alle voci di spesa di un bando in uso non avranno effetto sui progetti esistenti'
                  }
               />
               .
               <br />
               <br />
               <OETrans
                  code='common.warning.wantcontinue'
                  fallback={'Sei sicuro di voler continuare?'}
               />
            </Modal.Body>
            <Modal.Footer>
               <Button variant='secondary' onClick={() => checkConfirmation(false)}>
                  <OETrans code='common.cancel' fallback={'Annulla'} />
               </Button>
               <Button variant='primary' onClick={() => checkConfirmation(true)}>
                  <OETrans code='common.confirm' fallback={'Conferma'} />
               </Button>
            </Modal.Footer>
         </Modal>
      </>
   );
};
export default NoticesList;
