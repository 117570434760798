import React, { useEffect, useState, useRef, useCallback } from "react";
import AG_GRID_LOCALE_IT from "@components/ag-grid/locale-it";
import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-community/core/dist/styles/ag-theme-alpine.css";
import { columnCentered } from "@lib/helpers/agGridHelpers";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import { IcoEdit, IcoCanc, IcoPlus, Lens } from "@icons";
import CustomTooltip from "@components/ag-grid/CustomTooltip";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { dateFormatter } from "@lib/helpers/formatting";
import { ButtonToolbar, FormControl, InputGroup, Col } from "react-bootstrap";
import { Alert } from "components/atomic/atoms";
import { getDeliverables } from "@lib/api";
import { OETrans } from "components/translation/OETrans";
// import { getUsersForResponsible } from "components/projects/getUsersForResponsible";

const DeliverableTable = ({
  projectId,
  setDeliverableData,
  setShowFormModal,
  setShowDeleteModal,
  setShowAlert,
  isLoading,
  setIsLoading,
  canEdit,
  isConfig,
  streams,
  users
}) => {
  // State
  const localeText = AG_GRID_LOCALE_IT;
  const gridRef = useRef();

  const defaultColDef = {
    resizable: true,
    flex: 1,
    wrapText: true,
    autoHeight: true,
    suppressMovable: true,
    cellStyle: {
      paddingLeft: "1rem",
      paddingRight: "1rem",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
    tooltipComponent: CustomTooltip,
    tooltipValueGetter: (params) => {
      return params.value;
    },
  };

  const columnDefs = [
    {
      headerName: "N. Deliverable",
      headerTooltip: "N. Deliverable",
      field: "number",
      filter: "agTextColumnFilter",
      resizable: true,
      flex: 2,
    },
    ...(isConfig ? [{
      headerName: "Titolo",
      headerTooltip: "Titolo",
      field: "title",
      filter: "agTextColumnFilter",
      resizable: true,
      flex: 4,
    }] : []),
    {
      headerName: "Descrizione",
      headerTooltip: "Descrizione",
      field: "description",
      filter: "agTextColumnFilter",
      resizable: true,
      flex: 4,
    },
    {
      headerName: "WP di riferimento",
      headerTooltip: "WP di riferimento",
      // field: "wp",
      field: "wpStreamId",
      filter: "agTextColumnFilter",
      resizable: true,
      flex: 4,
      valueGetter: (params) => {
        // const selectedStream = streams.find(stream => stream.id === params.data.wp);
        const selectedStream = streams.find(stream => {
            return stream.id === params.data.wpStreamId
          });
        if (!selectedStream) return "";
        return `${selectedStream.streamId} - ${selectedStream.name}`;
      }
    },
    ...(isConfig ? [{
      headerName: "Responsabile",
      headerTooltip: "Responsabile",
      field: "responsible",
      filter: "agTextColumnFilter",
      resizable: true,
      flex: 4,
      valueGetter: (params) => {
        if (streams) {
         const tempStream = streams.filter(stream => stream.id === params.data.wpStreamId).pop();
         if (!tempStream || !tempStream.responsibles) return [];
   
         const tempUsers = tempStream.responsibles.reduce((acc, obj) => {
               const objUsers = obj.users ?? [];
               const arrayWithProperties = objUsers.map((user) => ({
                  value: user.id,
                  label: `${user.firstName} ${user.lastName} (${user.email})`,
               }))
   
               return acc.concat(arrayWithProperties);
            }, []);

         const selectedUser = tempUsers.find((user) => {
            return user.value === params.data.responsible;
         });

         return selectedUser?.label;
      } else {
         return [];
      }
      }
    }] : []),
    ...(!isConfig ? [{
      headerName: "Tipologia",
      headerTooltip: "Tipologia",
      field: "type",
      filter: "agTextColumnFilter",
      resizable: true,
      flex: 4,
    }] : []),
    {
      headerName: isConfig ? "Data produzione prevista" : "Data prevista",
      headerTooltip: isConfig ? "Data produzione prevista" : "Data prevista",
      field: "forecastDate",
      filter: "agDateColumnFilter",
      valueGetter: (params) => {
        if (!params.data.forecastDate) return "";
        const date = dateFormatter(params.data.forecastDate);
        return date
        // .split("-").slice(1, 3).join("-");
      },
      ...columnCentered(),
      minWidth: 150,
    },
    ...(!isConfig ? [{
      headerName: "Data effettiva",
      headerTooltip: "Data effettiva",
      field: "effectiveDate",
      filter: "agTextColumnFilter",
      valueGetter: (params) => {
        if (!params.data.effectiveDate) return "";
        return dateFormatter(params.data.effectiveDate);
      },
      ...columnCentered(),
      minWidth: 150,
    }] : []),
    // ...(!isConfig ? [{
    //   headerName: "Inviato",
    //   headerTooltip: "Inviato",
    //   field: "sent",
    //   filter: "agTextColumnFilter",
    //   resizable: true,
    //   flex: 3,
    //   valueGetter: (params) => {
    //     if (typeof params.data.sent !== "string") {
    //       return params.data.sent === true ? "SI" : "NO";
    //     }
    //     return params.data.sent === "1" ? "SI" : "NO";
    //   },
    //   ...columnCentered(),
    // }] : []),
    // ...(!isConfig ? [{
    //   headerName: "Approvato",
    //   headerTooltip: "Approvato",
    //   field: "approved",
    //   filter: "agTextColumnFilter",
    //   resizable: true,
    //   flex: 3,
    //   valueGetter: (params) => {
    //     if (typeof params.data.approved !== "string") {
    //       return params.data.approved === true ? "SI" : "NO";
    //     }
    //     return params.data.approved === "1" ? "SI" : "NO";
    //   },
    //   ...columnCentered(),
    // }] : []),
    ...(!isConfig ? [{
      headerName: "Note",
      field: "note",
      filter: "agTextColumnFilter",
      resizable: true,
      flex: 4,
      hide: true,
    }] : []),
    {
      headerName: "",
      minWidth: 110,
      maxWidth: 110,
      sortable: false,
      cellRenderer: (params) => {
        let buttons = [];

        if (canEdit) {
          buttons = [
            {
              action: "edit",
              icon: <IcoEdit className="second-color ico-small edit" />,
              tooltip: <OETrans code='common.tooltip.edit' fallback={'Modifica'}/>,
            },
            {
              action: "delete",
              icon: <IcoCanc className="second-color ico-small cancel" />,
              tooltip: <OETrans code='common.tooltip.delete' fallback={'Elimina'}/>,
            },
          ];
        } else {
          buttons = [
            {
              action: "view",
              icon: <Lens className="second-color ico-small edit path" />,
              tooltip: "Visualizza",
            },
          ];
        }

        return (
          <div className="d-flex justify-content-center align-items-center mt-2">
            {buttons.map((button, index) => (
              <OverlayTrigger
                key={index}
                placement="top"
                overlay={<Tooltip>{button.tooltip}</Tooltip>}
              >
                <Button
                  variant="link"
                  className={`me-${index === 0 ? 3 : 0}`}
                  onClick={() => {
                    // convert params.data.sent and params.data.approved to string
                    if (typeof params.data.sent !== "string") {
                      params.data.sent = params.data.sent === true ? "1" : "0";
                    }

                    if (typeof params.data.approved !== "string") {
                      params.data.approved =
                        params.data.approved === true ? "1" : "0";
                    }

                    // Remove createdAt, createdBy, updatedAt, updatedBy
                    delete params.data.createdAt;
                    delete params.data.createdBy;
                    delete params.data.updatedAt;
                    delete params.data.updatedBy;
                    setDeliverableData(params.data);
                    if (button.action === "edit") {
                      setShowFormModal(true);
                    } else if (button.action === "delete") {
                      setShowDeleteModal(true);
                    } else {
                      setShowFormModal(true);
                    }
                  }}
                >
                  {button.icon}
                </Button>
              </OverlayTrigger>
            ))}
          </div>
        );
      },
    },
  ]

  const onFilterTextBoxChanged = useCallback((e) => {
    gridRef.current.api.setQuickFilter(e.target.value);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [deliverables, setDeliverables] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      await getDeliverables(projectId).then((res) => {
        if (res.success) {
          setDeliverables(res.data);
        } else {
          setShowAlert({
            show: true,
            message: res.message,
            type: "danger",
          });
        }
      });
    };
    if (!isLoading && projectId) {
      fetchData();
    }
  }, [projectId, isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="row d-flex align-items-center mb-3">
        <div className="col-12 text-right py-1">
          <ButtonToolbar className="justify-content-end">
            {canEdit && isConfig && (
              <Button
                variant="success"
                {...(isLoading && { disabled: true })}
                onClick={() => setShowFormModal(true)}
              >
                <IcoPlus className="light-color ico-small plus new-button" />
                <span className="d-md-inline">Aggiungi nuovo deliverable</span>
              </Button>
            )}
            <InputGroup className="search-table align-items-center mt-3 mt-md-0">
              <FormControl
                type="text"
                className="filter-text-box"
                onInput={(e) => onFilterTextBoxChanged(e)}
              />
              <Lens />
            </InputGroup>
          </ButtonToolbar>
        </div>
      </div>
      <div className="row d-flex align-items-center mb-3">
        <div className="col-12 p-0">
          {alert.show && (
            <Col className="pt-3 col-12">
              <Alert
                show={alert.show}
                message={alert.message}
                variant={alert.type}
                delay={5000}
              />
            </Col>
          )}
          {isLoading ? (
            <div className="d-flex justify-content-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden"><OETrans code='common.loading' fallback={'Caricamento'}/>...</span>
              </div>
            </div>
          ) : (
            <div className="ag-theme-alpine">
              <AgGridReact
                ref={gridRef}
                columnDefs={columnDefs}
                rowData={deliverables}
                defaultColDef={defaultColDef}
                domLayout="autoHeight"
                tooltipShowDelay={0}
                tooltipHideDelay={2000}
                tooltipMouseTrack={true}
                onGridReady={null}
                headerHeight={50}
                rowHeight={50}
                localeText={localeText}
                ariaDescription="ag-grid-table"
                suppressCellFocus={true}
                autoSizeStrategy={{ type: "fitCellContents" }}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DeliverableTable;
