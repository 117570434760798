import { defaultFetch } from "lib/api_ts/baseApi"

// company related

export type PartnerType = {
    id: number
    name: string
    vat: string
    address: string | null
    pec: string | null,
    attorney: string | null,
    code: string | null,
    href: {
        action: string
        url: string
        entity: string
    },
    pivot: {
        companyId: number
        partnerCompanyId: number,
        createdAt: number
        updatedAt: number
    },
    sizeCode: string | null,
    legalRepresentative: string | null,
    powerOfAttorney: number,
    payrollMgmtModule: boolean
    aiEnabled: boolean
    createdAt: string
    updatedAt: string
    createdBy: number
    updatedBy: number
    hasModulePartnership: boolean
}

export const getPartnersByCompanyId = async (companyId: string) => {
    return defaultFetch<PartnerType[]>(`/v0/companies/${companyId}/partners`)
}

export const createPartnership = async (companyId: string, partnerCompanyIds: number[]) => {
    return defaultFetch<PartnerType[]>(`/v0/companies/${companyId}/partners`, {
        method: 'POST',
        body: JSON.stringify({ partnerCompanyIds })
    })
}

// project related

export type PartnerByProjectIdType = {
    id: number,
    company: {
        id: number,
        name: string
        code: string
    },
    pic: string
    country: string
    role: {
        code: string
        name: string
    },
    contact: {
        id: number
        name: string | null,
        email: string
    },
    projectId: number
}
export const getPartnersByProjectId = async (projectId: string) => {
    return defaultFetch<PartnerByProjectIdType[]>(`/v0/projects/${projectId}/partners`)
}

export type PartnerToManageType = {
    companyId: number
    pic: string | null
    country: string | null
    roleCode: string
    contactUserId: number | null
}

export const addPartnersToProject = async (projectId: string, partnersToAdd: PartnerToManageType[]) => {
    return defaultFetch<PartnerByProjectIdType[]>(`/v0/projects/${projectId}/partners`, {
        method: 'POST',
        body: JSON.stringify({ companies: partnersToAdd })
    })
}

export const updateProjectPartner = async (projectId: string, partnerId: string, partnerData: PartnerToManageType) => {
    return defaultFetch<PartnerByProjectIdType[]>(`/v0/projects/${projectId}/partners/${partnerId}`, {
        method: 'PUT',
        body: JSON.stringify(partnerData)
    })
}

export const deleteProjectPartner = async (projectId: string, partnerId: string) => {
    return defaultFetch<PartnerByProjectIdType[]>(`/v0/projects/${projectId}/partners/${partnerId}`, {
        method: 'DELETE'
    })
}

export type AvailableRoleType = {
    code: string
    name: string
    description: string
}

export const getAvailableRoles = async () => {
    return defaultFetch<AvailableRoleType[]>('/oeprojectcompanyroles')
}
