import React, { useState, useEffect } from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import { fetchForPagination } from "@lib/api";
import TablePagination from '@mui/material/TablePagination';

const CustomPaginationMui = ({ dataMeta, setData, setDataMeta, rowsPerPageOptions = [100, 200, 500], refTable, setIsFirstResearch,
   setIsChangedPagination }) => {
   const [isLoading, setIsLoading] = useState(false);
   const [rowsPerPage, setRowsPerPage] = useState(dataMeta?.meta?.perPage);
   let infoRowsPerTable = localStorage.getItem('rowsPerTable');
   //console.log('dataMeta?.meta?.perPage:', refTable, dataMeta?.meta?.perPage);

   useEffect(() => {
      //let infoRowsPerTable = localStorage.getItem('rowsPerTable');
      let localStorageRowsPerTable = infoRowsPerTable ? JSON.parse(infoRowsPerTable) : infoRowsPerTable;

      if (Array.isArray(localStorageRowsPerTable) && localStorageRowsPerTable.some(el => el.refTable === refTable)) {
         //se c'è la ref
         setRowsPerPage(Number(localStorageRowsPerTable.filter(el => el.refTable === refTable).map(el => el.perPage).join()));
      }

   }, [infoRowsPerTable, refTable]);

   const handleChangePage = async (
      event,
      newPage,
      setList,
      listMeta,
      setListMeta
   ) => {
      setIsLoading(true);
      if (setIsChangedPagination) setIsChangedPagination(false);

      const resp = await fetchForPagination(
         listMeta.meta.links
            .filter((el) => Number(el.label) === newPage + 1)
            .map((el) => el.url)
            .pop()
      );

      if (resp.success) {
         setList(resp.data);

         const { data, success, ...restResp } = resp;
         setListMeta(restResp);
         if (setIsChangedPagination) setIsChangedPagination(true);
      }

      setIsLoading(false);
   };

   const handleChangeRowsPerPage = async (
      event,
      setList,
      listMeta,
      setListMeta
   ) => {
      setIsLoading(true);
      if (setIsChangedPagination) setIsChangedPagination(false);

      let url = new URL(listMeta.links.first);
      let searchParams = url.searchParams;

      // set new value of "id"
      searchParams.set('perPage', event.target.value);

      // change the search property of the main url
      url.search = searchParams.toString();

      const newUrl = url.toString();
      //console.log('newUrl', newUrl);

      const resp = await fetchForPagination(newUrl);

      setList(resp.data);

      const { data, success, ...restResp } = resp;
      setListMeta(restResp);
      if (setIsChangedPagination) setIsChangedPagination(true);

      let infoRowsPerTable = localStorage.getItem('rowsPerTable');
      infoRowsPerTable = infoRowsPerTable ? JSON.parse(infoRowsPerTable) : infoRowsPerTable;

      if (Array.isArray(infoRowsPerTable) && infoRowsPerTable.some(el => el.refTable === refTable)) {
         //se c'è già la ref
         const indexOfArray = infoRowsPerTable.findIndex(el => el.refTable === refTable);
         infoRowsPerTable[indexOfArray].perPage = event.target.value;

         //disabled
         /* localStorage.setItem('rowsPerTable', JSON.stringify(infoRowsPerTable)); */
      } else {
         if (!Array.isArray(infoRowsPerTable)) { infoRowsPerTable = [] };
         infoRowsPerTable.push({refTable: refTable, perPage: event.target.value});

         //disabled
         /* localStorage.setItem('rowsPerTable', JSON.stringify(infoRowsPerTable)); */
      } 

      setRowsPerPage(event.target.value);
      if (setIsFirstResearch) setIsFirstResearch(true);
      setIsLoading(false);
   };

   if (!dataMeta?.meta) return null;
   return (
      <Row className='flex-row align-items-baseline'>
         <Col className="col-auto ms-auto p-0">
            {isLoading && <Spinner animation="border" role="status" variant="dark" size="sm" />}
         </Col>
         <Col className="col-auto">
            <TablePagination
               component="div"
               rowsPerPageOptions={rowsPerPage === 100 || rowsPerPage === 200 || rowsPerPage === 500 ? rowsPerPageOptions : rowsPerPageOptions.concat([rowsPerPage])}
               count={dataMeta?.meta?.total}
               page={dataMeta?.meta?.currentPage - 1}
               rowsPerPage={rowsPerPage}
               onPageChange={(e, newPage) =>
                  handleChangePage(
                     e,
                     newPage,
                     setData,
                     dataMeta,
                     setDataMeta
                  )
               }
               onRowsPerPageChange={(e) =>
                  handleChangeRowsPerPage(
                     e,
                     setData,
                     dataMeta,
                     setDataMeta
                  )
               }
            />
         </Col>
      </Row>
   );
}
export default CustomPaginationMui;