import React, {
   useState,
   useCallback,
   useRef,
   Fragment,
   useEffect,
   useContext,
} from "react";
import {
   Row,
   Col,
   Spinner,
   OverlayTrigger,
   Tooltip,
   Button,
   Tabs,
   Tab,
   ButtonToolbar,
   Dropdown,
} from "react-bootstrap";
import {
   getModalData,
   getTenderProcedures,
   getTenderClassifications,
   getTenderCriteria,
   getProjectResumeInvoices,
   getProjectResumeTimesheets,
   getProjectResumeTotals,
   downloadProjectDocument,
   getExpenseItemReportability,
} from "@lib/api";
import {
   IcoRefresh,
   IcoCheck,
   IcoCheckFailed,
   IcoReported,
   IcoReportedPartially,
   IcoDanger,
   ExcelIcon,
} from "@icons";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-community/core/dist/styles/ag-theme-alpine.css";
import AG_GRID_LOCALE_IT from '@components/ag-grid/locale-it';
import CustomTooltip from '@components/ag-grid/CustomTooltip';
import {
   customCellStyle,
   columnToRight,
   columnRightGreen,
   columnRightYellow,
   columnRightRed,
   columnCentered,
   columnCenteredWithFilter,
} from "@lib/helpers/agGridHelpers";
import { dateFormatter, formatToDecimals } from "@lib/helpers/formatting";
import OEFilterExternalSupplies from "components/oefilter/OEFilterExternalSupplies";
import OEFilterStaff from "components/oefilter/OEFilterStaff";
import TendersSummaryModal from "components/summary/TendersSummaryModal";
import FrameworkContractDetailModal from "components/summary/FrameworkContractDetailModal";
import ContractDetailSummaryModal from "components/summary/ContractDetailSummaryModal";
import InvoiceDetailSummaryModal from "components/summary/InvoiceDetailSummaryModal";
import ProjectContractModal from "components/contracts/projectContractModal";
import ProjectInvoiceModal from "components/invoice/projectInvoiceModal";
import ProjectPaymentModal from "components/payments/projectPaymentModal";
import ProjectTimesheetModal from "components/timesheet/projectTimesheetModal";
import DataHeader from "components/DataHeader";
import { months } from "@lib/helpers/calendar";
import OeApiResponseRenderer from "components/OeApiResponseRenderer";
import OESimpleTable from "components/oetable/OESimpleTable";
import { LevelContext } from 'components/contexts/LevelContext';
import TimesheetDetailSummaryModal from "components/summary/TimesheetDetailSummaryModal";
import CustomPaginationMui from "@components/materialComponents/CustomPaginationMui";
import CustomQuickFilterBox from "components/ag-grid/CustomQuickFilterBox";
import { OETrans } from "components/translation/OETrans";
import { IcoExclamationPoint } from "icone/index";

const ExecutionSummary = ({ project }) => {
   const { userRole } = useContext(LevelContext);
   const [modalData, setModalData] = useState({});
   const [externalSupplies, setExternalSupplies] = useState(null);
   const [externalSuppliesMeta, setExternalSuppliesMeta] = useState({});
   const [staff, setStaff] = useState(null);
   const [staffMeta, setStaffMeta] = useState({});
   const [reports, setReports] = useState(null);
   const [totals, setTotals] = useState({});
   const [columnDefsExternalSupplies, setColumnDefsExternalSupplies] = useState([]);
   const [columnDefsStaff, setColumnDefsStaff] = useState([]);
   const [columnDefsReports, setColumnDefsReports] = useState([]);
   const [activeKey, setActiveKey] = useState("external-supplies");
   const [apiToCall, setApiToCall] = useState("");

   const [optionsProcedures, setOptionsProcedures] = useState([]);
   const [optionsClassifications, setOptionsClassifications] = useState([]);
   const [optionsCriteria, setOptionsCriteria] = useState([]);
   const [optionsDedicated, setOptionsDedicated] = useState([]);
   const [optionsGazettePubIT, setOptionsGazettePubIT] = useState([]);
   const [optionsGazettePubUE, setOptionsGazettePubUE] = useState([]);

   const [spinnerVisible, setSpinnerVisible] = useState(false);
   const [refresh, setRefresh] = useState(false);
   const [isLoading, setIsLoading] = useState(false);
   const [show, setShow] = useState(false);

   const [isFiltered, setIsFiltered] = useState(false);
   const [filteredTotal, setFilteredTotal] = useState(null);
   const [isDownloadingSynthetic, setIsDownloadingSynthetic] = useState(false);
   const [isDownloadingAnalytical, setIsDownloadingAnalytical] = useState(false);
   const [modelFilterString, setModelFilterString] = useState("");
   const [filterQueryParamsString, setFilterQueryParamsString] = useState("");
   const [sbShow, setSbShow] = useState(<></>);
   const [/* gridApi */, setGridApi] = useState(null);
   const [isFirstResearch1, setIsFirstResearch1] = useState(true);
   const [isFirstResearch2, setIsFirstResearch2] = useState(true);
   const localeText = AG_GRID_LOCALE_IT;
   const externalSuppliesRef = useRef(null);
   const staffRef = useRef(null);
   const filter1Ref = useRef(null);
   const filter2Ref = useRef(null);
   
   const handleStatusBar = (dataToHandle) => {
      setSbShow(<OeApiResponseRenderer dataToHandle={dataToHandle} />);
   };

   useEffect(() => {
      const optionsYesNo = [
         {
            value: true,
            label: "SÌ",
         },
         {
            value: false,
            label: "NO",
         },
      ];

      getTenderProcedures().then((retrievedProcedures) => {
         setOptionsProcedures(
            retrievedProcedures.data.map((procedure) => ({
               value: procedure.code,
               label: procedure.name,
            }))
         );
      });

      getTenderClassifications().then((retrievedClassification) => {
         setOptionsClassifications(
            retrievedClassification.data.map((classification) => ({
               value: classification.code,
               label: classification.name,
            }))
         );
      });

      getTenderCriteria().then((retrievedCriteria) => {
         setOptionsCriteria(
            retrievedCriteria.data.map((criteria) => ({
               value: criteria.code,
               label: criteria.name,
            }))
         );
      });

      setOptionsGazettePubIT(optionsYesNo);
      setOptionsDedicated(optionsYesNo);
      setOptionsGazettePubUE(optionsYesNo);
   }, []);

   const loadData = useCallback(() => {
      if (project.id) {
         switch (activeKey) {
            case "external-supplies":
               getProjectResumeInvoices(project.id, undefined, 'externalSupplies').then((retrievedResults) => {
                  if (retrievedResults.success) {
                     setExternalSupplies(retrievedResults.data);
                     const { data, success, ...restResp } = retrievedResults;
                     setExternalSuppliesMeta(restResp);
                  }
               });

               break;
            case "intern-workers":
               getProjectResumeTimesheets(project.id, undefined, 'staff').then((retrievedResults) => {
                  if (retrievedResults.success) {
                     setStaff(retrievedResults.data);
                     const { data, success, ...restResp } = retrievedResults;
                     setStaffMeta(restResp);
                  }
               });

               break;
            case "report":
               getExpenseItemReportability(project.id).then((retrievedResults) => {
                  if (retrievedResults.success) {
                     let finalMockReport = [];
                     retrievedResults.data.forEach((elDB) => {
                        //Vedo se trovo un duplicato in base agli id voce e sottovoce
                        const res = finalMockReport.filter(
                           (elRes) =>
                              elRes.projectNoticeExpenseItemId ===
                              elDB.projectNoticeExpenseItemId
                        );

                        //Se non trova nulla, allora crea un nuovo oggetto
                        if (res.length === 0) {
                           finalMockReport.push({
                              projectNoticeExpenseItemId: elDB.projectNoticeExpenseItemId,
                              projectNoticeExpenseItemName:
                                 elDB.projectNoticeExpenseItemName,
                              projectNoticeExpenseItemPosition:
                                 elDB.projectNoticeExpenseItemPosition,
                              projectId: elDB.projectId,
                              reportability: [
                                 {
                                    reportabilityColor: elDB.reportabilityColor,
                                    amount: elDB.reportabilityColor === 'green' ? elDB.reportableAmount : elDB.amount,
                                    projectExpenseItemId: elDB.projectExpenseItemId,
                                    projectExpenseItemName: elDB.projectExpenseItemName,
                                    projectExpenseItemPosition:
                                       elDB.projectExpenseItemPosition,
                                 },
                              ],
                           });

                           //Se lo trova, aggiungi colore e valore all'oggetto trovato
                        } else {
                           finalMockReport[
                              finalMockReport.indexOf(res[0])
                           ].reportability.push({
                              reportabilityColor: elDB.reportabilityColor,
                              amount: elDB.reportabilityColor === 'green' ? elDB.reportableAmount : elDB.amount,
                              projectExpenseItemId: elDB.projectExpenseItemId,
                              projectExpenseItemName: elDB.projectExpenseItemName,
                              projectExpenseItemPosition: elDB.projectExpenseItemPosition,
                           });
                        }
                     });

                     finalMockReport.forEach((el, index) => {
                        finalMockReport[index].totalGreen = el.reportability
                           .filter((el) => el.reportabilityColor === "green")
                           .reduce((prev, curr) => prev + curr.amount, 0);
                        finalMockReport[index].totalYellow = el.reportability
                           .filter((el) => el.reportabilityColor === "yellow")
                           .reduce((prev, curr) => prev + curr.amount, 0);
                        finalMockReport[index].totalRed = el.reportability
                           .filter((el) => el.reportabilityColor === "red")
                           .reduce((prev, curr) => prev + curr.amount, 0);
                        finalMockReport[index].totalColors = el.reportability.reduce(
                           (prev, curr) => prev + curr.amount,
                           0
                        );
                     });

                     finalMockReport = finalMockReport.sort(
                        (valueA, valueB) =>
                           valueA.projectNoticeExpenseItemPosition -
                           valueB.projectNoticeExpenseItemPosition
                     );

                     setReports(finalMockReport);
                  }
               });

               break;
            default:
               break;
         }

         getProjectResumeTotals(project.id).then((retrievedTotals) => {
            if (retrievedTotals.success) {
               setTotals(retrievedTotals.data);
            }
         });
      }
   }, [project.id, activeKey]);

   useEffect(() => {
      if (project.id) {
         loadData();
      }
   }, [loadData, project.id]);

   useEffect(() => {
      if (refresh) {
         loadData();

         setRefresh(false);
      }
   }, [loadData, refresh]);

   const fetchData = async (url) => {
      setSpinnerVisible(true);
      try {
         const response = await getModalData(url);
         setModalData(response.data);
      } catch (error) {
         console.error("Error fetching data:", error);
      }
      setSpinnerVisible(false);
   };

   const renderCorrectModal = (modalToRender) => {
      switch (modalToRender) {
         case "tender_number":
            return (
               <TendersSummaryModal
                  data={modalData}
                  optionsProcedures={optionsProcedures}
                  optionsClassifications={optionsClassifications}
                  optionsCriteria={optionsCriteria}
                  optionsGazettePubIT={optionsGazettePubIT}
                  optionsGazettePubUE={optionsGazettePubUE}
                  optionsDedicated={optionsDedicated}
                  modalIsVisible={show}
                  setModalIsVisible={setShow}
                  spinnerVisible={spinnerVisible}
                  setModalData={setModalData}
                  isSummary
                  setRefresh={setRefresh}
               />
            );
         case "framework_contract_number":
            return (
               <FrameworkContractDetailModal
                  data={modalData}
                  setModalIsVisible={setShow}
                  modalIsVisible={show}
                  spinnerVisible={spinnerVisible}
                  setModalData={setModalData}
                  isSummary
                  setRefresh={setRefresh}
               />
            );
         case "contract_number":
            return (
               Number(userRole) <= 40 ?
                  <ProjectContractModal
                     data={modalData}
                     setModalIsVisible={setShow}
                     modalIsVisible={show}
                     isSummary
                     spinnerVisible={spinnerVisible}
                     setModalData={setModalData}
                     setRefresh={setRefresh}
                  /> :
                  <ContractDetailSummaryModal
                     data={modalData}
                     setModalIsVisible={setShow}
                     modalIsVisible={show}
                     isSummary
                     spinnerVisible={spinnerVisible}
                     setModalData={setModalData}
                     setRefresh={setRefresh}
                  />
            );
         case "rp_code":
            return (
               Number(userRole) <= 40 ?
                  <ProjectInvoiceModal
                     data={modalData}
                     setModalIsVisible={setShow}
                     modalIsVisible={show}
                     isSummary
                     spinnerVisible={spinnerVisible}
                     setModalData={setModalData}
                     setRefresh={setRefresh}
                  /> :
                  <InvoiceDetailSummaryModal
                     data={modalData}
                     setModalIsVisible={setShow}
                     modalIsVisible={show}
                     isSummary
                     spinnerVisible={spinnerVisible}
                     setModalData={setModalData}
                     setRefresh={setRefresh}
                  />
            );
         case "payment_number":
            return (
               <ProjectPaymentModal
                  data={modalData}
                  setModalIsVisible={setShow}
                  modalIsVisible={show}
                  isSummary
                  spinnerVisible={spinnerVisible}
                  setModalData={setModalData}
                  setRefresh={setRefresh}
               />
            );
         case "number":
            return (
               Number(userRole) <= 40 ?
                  <ProjectTimesheetModal
                     data={modalData}
                     setModalIsVisible={setShow}
                     modalIsVisible={show}
                     isSummary
                     spinnerVisible={spinnerVisible}
                     setModalData={setModalData}
                     setRefresh={setRefresh}
                     isProjectSco={project.sco}
                  /> :
                  <TimesheetDetailSummaryModal
                     data={modalData}
                     setModalIsVisible={setShow}
                     modalIsVisible={show}
                     isSummary
                     spinnerVisible={spinnerVisible}
                     setModalData={setModalData}
                     setRefresh={setRefresh}
                     isProjectSco={project.sco}
                  />
            );
         default:
      }
   };

   const ResumeChecklistCellRenderer = useCallback((params) => {
      const stoplightColor = {
         red: "#C94723",
         yellow: "#D4BE31",
         green: "#689689",
         white: "#ffffff",
         gray: "#C4C4C4",
         grey: "#C4C4C4",
         green_white_settled: "#689689",
      };

      if (params.value && params?.value.keyValue) {
         let circleStyle;
         let message = "";

         if (!params.value.checkliststatus) {
            circleStyle = {
               display: "inherit",
               width: "1.2em",
               height: "1.2em",
               borderRadius: "2em",
               MozBorderRadius: "2em",
               WebkitBorderRadius: "2em",
               backgroundColor: "#ffffff",
               border: `2px solid ${"#C4C4C4"}`,
            };

            message = "Da lavorare";
         } else {
            let checklistColor = params.value.checkliststatus.color;

            if (checklistColor === "green_white" || checklistColor === "green_white_settled") {
               circleStyle = {
                  display: "inherit",
                  width: "23px",
                  height: "23px",
                  border: "4px solid #689689",
                  borderRadius: "50%",
                  padding: "0px",
                  background: checklistColor === "green_white_settled" ? "linear-gradient(to right, #689689 50%, transparent 0)" : "white",
                  boxSizing: "border-box",
               };

               message = checklistColor === "green_white_settled" ? "Conforme - validata" : 'Conforme - da validare';
            } else {
               circleStyle = {
                  display: "inherit",
                  width: "1.2em",
                  height: "1.2em",
                  borderRadius: "2em",
                  MozBorderRadius: "2em",
                  WebkitBorderRadius: "2em",
                  backgroundColor:
                     stoplightColor[checklistColor],
                  border: `2px solid ${checklistColor === "white"
                     ? stoplightColor.grey
                     : stoplightColor[checklistColor]
                     }`,
               };

               switch (checklistColor) {
                  case "white":
                     message = "Da lavorare";
                     break;
                  case "grey":
                  case "gray":
                     message = "In lavorazione";
                     break;
                  case "red":
                     message = "Non conforme";
                     break;
                  case "yellow":
                     message = "Incompleta";
                     break;
                  case "green":
                     message = "Conforme";
                     break;
                  default:
                     break;
               }
            }
         }

         if (params?.queryParam === "tender_number" && (
            params.value.key.keyValue?.startsWith("GARATEMP_") ||
            params.value.key.keyValue?.startsWith("GARA_")
         )) {
            let circleColor = params.value.key.keyValue?.startsWith("GARATEMP_") ? "yellow" :
               (params.value.key.keyValue?.startsWith("GARA_") ? "grey" : params.value?.checkliststatus?.color);

            circleStyle = {
               display: "inherit",
               width: "1.2em",
               height: "1.2em",
               borderRadius: "2em",
               MozBorderRadius: "2em",
               WebkitBorderRadius: "2em",
               backgroundColor: stoplightColor[circleColor],
               border: `2px solid ${stoplightColor[circleColor]}`,
            };

            switch (circleColor) {
               case "grey":
               case "gray":
                  message = "Non applicabile";
                  break;
               case "yellow":
                  message = "Non ancora disponibile";
                  break;
               default:
                  break;
            }
         }

         let value;

         if (
            params.value.key === "tender_number" &&
            params.value.keyValue.startsWith("GARA_")
         ) {
            value = "N.A.";
         } else if (
            params.value.key === "tender_number" &&
            params.value.keyValue.startsWith("GARATEMP_")
         ) {
            value = "N.D.";
         } else {
            value = params.value.keyValue;
         }

         const ConditionalWrapper = ({ condition, wrapper, children }) =>
            condition ? wrapper(children) : children;

         return (
            <>
               <Row
                  className="align-items-center justify-content-between h-100 flex-nowrap"
                  style={{
                     justifyContent: params.align ?? "justify-content-between",
                  }}
               >
                  {userRole < 70 && 
                     (<>
                        <Col className={`col-auto ${params?.queryParam === "rp_code" && params?.data?.postReportedCheck ? 'pe-0' : ''}`}>
                           <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip>{message}</Tooltip>}
                           >
                              <i style={{ ...circleStyle }} />
                           </OverlayTrigger>
                        </Col>
                        <Col className="col-auto px-0">
                           {params?.queryParam === "rp_code" && params?.data?.postReportedCheck ?
                              <IcoExclamationPoint style={{ verticalAlign: 'sub' }} /> : 
                              <span className={params?.queryParam === "rp_code" && !params?.data?.postReportedCheck ? 'pe-1' : ''}>&nbsp;</span>
                           }
                        </Col>
                     </>)
                  }
                  <Col className={`overflow-hidden ${userRole < 70 ? 'ps-0' : ''}`}>
                     <ConditionalWrapper
                        condition={
                           params.link &&
                           !params.value.keyValue.startsWith("GARA_" || "GARATEMP_")
                        }
                        wrapper={(children) => (
                           // TODO LOGIC TO OPEN MODAL
                           <Button
                              variant="link"
                              className="d-flex align-items-start projectModalRenderBtn"
                              onClick={() => {
                                 setApiToCall(params.value.key);
                                 fetchData(params.value.href.url);
                                 params.setShow(true);
                              }}
                           >
                              {children}
                           </Button>
                        )}
                     >
                        <span>
                           {params.value.keyValue ? (
                              typeof params.value.keyValue === "number" ? (
                                 formatToDecimals(params.data.taxableAmount) + " €"
                              ) : (
                                 value
                              )
                           ) : (
                              <>&zwnj;</>
                           )}
                        </span>
                     </ConditionalWrapper>
                  </Col>
               </Row>
            </>
         );
      }
   }, [userRole]);

   const TotalResumeChecklistCellRenderer = useCallback((params) => {
      //console.log('params', params);

      const red = "#C94723";
      const green = "#689689";
      const yellow = "#D4BE31";
      let stoplightColor = green;
      let isRendicontable = false;
      let isStoplightYellow = false;

      if (params.value && params.data) {
         const arrayEntities = ['tender',  'frameworkContract', 'contract','invoice', 'payment'];

         arrayEntities.forEach(entity => {
            if (params.data[entity]?.checkliststatus && params.data[entity]?.checkliststatus.color === "green_white") {
               stoplightColor = yellow;
               isStoplightYellow = true;
            }
         });

         isRendicontable = true;
      } else {
         stoplightColor = red;
         isRendicontable = false;
      }

      const reportableTooltip = (props) => (
         <Tooltip {...props}>
            {isRendicontable ? (isStoplightYellow ? 'Eleggibile - da validare' : "Eleggibile") : "Non eleggibile"}
         </Tooltip>
      );

      return (
         <OverlayTrigger placement="top" overlay={reportableTooltip}>
            <Row className="align-items-center justify-content-center h-100">
               <Col className="col-auto text-center">
                  {isRendicontable ? (
                     <IcoCheck style={{ fill: stoplightColor }} />
                  ) : (
                     <IcoCheckFailed style={{ fill: stoplightColor }} />
                  )}
               </Col>
            </Row>
         </OverlayTrigger>
      );
   }, []);

   const OnlyChecklistCellRenderer = useCallback((params) => {
      //console.log('params.value', params.value);
      const paramsValue = params?.value;

      if (paramsValue) {
         const stoplightColor = {
            red: "#C94723",
            yellow: "#D4BE31",
            green: "#689689",
            white: "#ffffff",
            gray: "#C4C4C4",
            grey: "#C4C4C4",
            green_white: "#689689",
         };

         let circleStyle;
         let message = "";

         if (!paramsValue) {
            circleStyle = {
               display: "inherit",
               width: "1.2em",
               height: "1.2em",
               borderRadius: "2em",
               MozBorderRadius: "2em",
               WebkitBorderRadius: "2em",
               backgroundColor: "#ffffff",
               border: `2px solid ${"#C4C4C4"}`,
            };

            message = "Da lavorare";
         } else {
            if (paramsValue === "green_white") {
               circleStyle = {
                  display: "inherit",
                  width: "23px",
                  height: "23px",
                  border: "4px solid #689689",
                  borderRadius: "50%",
                  padding: "0px",
                  background: "linear-gradient(to right, #689689 50%, transparent 0)",
                  boxSizing: "border-box",
               };

               message = "Conforme - validata";
            } else {
               circleStyle = {
                  display: "inherit",
                  width: "1.2em",
                  height: "1.2em",
                  borderRadius: "2em",
                  MozBorderRadius: "2em",
                  WebkitBorderRadius: "2em",
                  backgroundColor: stoplightColor[paramsValue],
                  border: `2px solid ${paramsValue === "white"
                     ? stoplightColor.grey
                     : stoplightColor[paramsValue]
                  }`,
               };

               switch (paramsValue) {
                  case "white":
                     message = "Non lavorata";
                     break;
                  case "grey":
                  case "gray":
                     message = "In lavorazione";
                     break;
                  case "red":
                     message = "Non conforme";
                     break;
                  case "yellow":
                     message = "Incompleta";
                     break;
                  case "green":
                     message = "Conforme";
                     break;
                  default:
                     break;
               }
            }
         }

         return (
            <>
               <Row
                  className="align-items-center justify-content-center h-100 flex-nowrap"
                  //style={{ justifyContent: params.align ?? "justify-content-between"}}
               >
                  <Col className="col-auto">
                     <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>{message}</Tooltip>}
                     >
                        <i style={{ ...circleStyle }} />
                     </OverlayTrigger>
                  </Col>
               </Row>
            </>
         );
      }
   }, []);

   const OnlyWarningCellRenderer = useCallback((params) => {
      const paramsValue = params?.value; //bool
      let icon;
      let message = "";
      //console.log('paramsValue', paramsValue);

      if (typeof paramsValue === 'boolean') {
         if (paramsValue) {
            //icona warning
            icon = <IcoDanger />;
            message = "Attenzione";
         } else {
            //spunta verde
            message = "Conforme";
            icon = <IcoCheck style={{ fill: "#689689" }} />;
         }
         
         return (
            <>
               <Row
                  className="align-items-center justify-content-center h-100 flex-nowrap"
                  //style={{ justifyContent: params.align ?? "justify-content-between"}}
               >
                  <Col className="col-auto">
                     <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>{message}</Tooltip>}
                     >
                        <span>{icon}</span>
                     </OverlayTrigger>
                  </Col>
               </Row>
            </>
         );
      }
   }, []);

   const handleFileDownload = async (
      projectId,
      resumeSection,
      setIsDownloadingButton
   ) => {
      setSbShow(<></>);

      setIsDownloadingButton(true);

      let filterString = isFiltered ? filterQueryParamsString : modelFilterString;

      const result = await downloadProjectDocument([
         projectId,
         "resume" + resumeSection,
         filterString,
      ]);

      if (!result.success) {
         handleStatusBar(result);

         if (activeKey === "external-supplies") {
            onFilterChanged(externalSuppliesRef, true);
         } else if (activeKey === "intern-workers") {
            onFilterChanged(staffRef, true);
         }
      }

      setIsDownloadingButton(false);
   };

   const ReportedCheckRenderer = useCallback((params) => {
      const reporting = params?.data?.reporting;
      const reported = params?.data?.reported;
      const reportedTooltip = (props) => (
         <Tooltip {...props}>Rendicontato</Tooltip>
      );
      const partialTooltip = (props) => (
         <Tooltip {...props}>Parzialmente Rendicontato</Tooltip>
      );
      return (
         <>
            {reporting && (
               <OverlayTrigger placement="top" overlay={partialTooltip}>
                  <Row className="align-items-center justify-content-center h-100">
                     <Col className="col-auto text-center">
                        <IcoReportedPartially />
                     </Col>
                  </Row>
               </OverlayTrigger>
            )}
            {reported && !reporting && (
               <OverlayTrigger placement="top" overlay={reportedTooltip}>
                  <Row className="align-items-center justify-content-center h-100 px-0">
                     <Col className="col-auto text-center">
                        <IcoReported />
                     </Col>
                  </Row>
               </OverlayTrigger>
            )}
         </>
      );
   }, []);

   const defaultColDef = {
      resizable: true,
      // wrapText: true,
      // autoHeight: true,
      flex: 1,
      suppressMovable: true,
      //suppressSizeToFit: false,
      //suppressColumnVirtualisation=true
      cellStyle: {
         padding: "0 1rem",
         textOverflow: "ellipsis",
         whiteSpace: "nowrap",
         overflow: "hidden",
      },
      sortable: true,
      filterParams: {
         suppressAndOrCondition: true, //for disable AND/OR login in filter
         filterOptions: ["contains"],
      },
      suppressCellFocus: false,
      suppressHeaderFocus: false,
   };

   /* const colSpan = function (params) {
       return params.data === 2 ? 3 : 1;
    }; */

   const getValueFilterStoplight = (value) => {
      let string = '';

      switch (value) {
         case 'green':
            string = 'verde';
            break;
         case 'yellow':
            string = 'giallo';
            break;
         case 'red':
            string = 'rosso';
            break;
         case 'grey':
         case 'gray':
            string = 'grigio';
            break;
         case 'white':
            string = 'bianco';
            break;
         default:
            break;
      }

      return string;
   };

   /* useEffect(() => {
      if (!columnGroupIsOpened || !gridApi) {
         return;
      }

      myInput.current.addEventListener(
         "keydown",
         function (event) {
            if (event.key !== "Tab") {
               return;
            }

            console.log(gridApi);

            event.preventDefault();
            gridApi.ensureIndexVisible(0);

            const firstCol = gridApi.getAllDisplayedColumns()[8];
            console.log(gridApi.getAllDisplayedColumns());

            gridApi.ensureColumnVisible(firstCol);
            gridApi.setFocusedCell(0, firstCol);
         },
         true
      );
   }, []); */

   const onColumnGroupOpened = useCallback((params) => {
      //setColumnGroupIsOpened(true);

      /* const listener = event => {
         console.log('Column visibility changed', event)

         event.preventDefault();
         params.api.ensureIndexVisible(0);

         const lastChildId = params.columnGroup.children[params.columnGroup.children.length - 1]?.instanceId;
         console.log('lastChildId', lastChildId);

         const columns = params.columnApi.getAllDisplayedColumns();
         console.log('columns', columns);

         const thisColumn = columns.filter(col => col.instanceId === lastChildId);
         console.log('thisColumn', thisColumn);

         params.api.ensureColumnVisible(thisColumn);
         params.api.setFocusedCell(0, thisColumn);
      };

      params.api.eventService.addEventListener('visibleChanged', listener); */

      //params.columnApi.setColumnVisible(thisColumn, true);

      /*    params.api.ensureColumnVisible(thisColumn);
         params.api.setFocusedCell(0, thisColumn); */
   }, []);

   useEffect(() => {
      setColumnDefsExternalSupplies([
         ...(userRole < 70 ? [
            {
               headerName: "",
               children: [
                  {
                     headerName: "",
                     headerTooltip: 'Rendicontato',
                     tooltipComponent: CustomTooltip,
                     field: "reported",
                     cellRenderer: ReportedCheckRenderer,
                     cellRendererParams: {
                        queryParam: "reported",
                     },
                     cellStyle: { padding: 0 },
                     minWidth: 60,
                     maxWidth: 60,
                     resizable: false,
                     ...columnCentered(),
                  },
                  {
                     headerName: "",
                     headerTooltip: 'Eleggibile',
                     tooltipComponent: CustomTooltip,
                     field: "reportable",
                     cellRenderer: TotalResumeChecklistCellRenderer,
                     cellRendererParams: {
                        queryParam: "reportable",
                     },
                     minWidth: 60,
                     maxWidth: 60,
                     ...columnCentered(),
                  },
               ],
            },
         ] : []),
         {
            headerName: "Contratto",
            children: [
               {
                  headerName: "Procedura",
                  columnGroupShow: 'open',
                  field: "tender",
                  cellRenderer: ResumeChecklistCellRenderer,
                  cellRendererParams: {
                     link: true,
                     setShow: setShow,
                     setApiToCall: setApiToCall,
                     queryParam: "tender_number",
                  },
                  minWidth: 250,
                  filter: "agTextColumnFilter",
                  filterValueGetter: (params) => {
                     let value;

                     if (params.data.tender.keyValue.startsWith("GARA_")) {
                        value = "N.A.";
                     } else if (params.data.tender.keyValue.startsWith("GARATEMP_")) {
                        value = "N.D.";
                     } else {
                        value = params.data.tender.keyValue;
                     }

                     return value;
                  },
                  comparator: (valueA, valueB) => {
                     let tempValueA = valueA.keyValue.startsWith("GARA_")
                        ? "N.A."
                        : valueA.keyValue.startsWith("GARATEMP_")
                           ? "N.D."
                           : valueA.keyValue;
                     let tempValueB = valueB.keyValue.startsWith("GARA_")
                        ? "N.A."
                        : valueB.keyValue.startsWith("GARATEMP_")
                           ? "N.D."
                           : valueB.keyValue;

                     if (tempValueA === tempValueB) return 0;
                     return tempValueA > tempValueB ? 1 : -1;
                  },
               },
               {
                  headerName: "Contratto aperto",
                  columnGroupShow: 'open',
                  field: "frameworkContract",
                  cellRenderer: ResumeChecklistCellRenderer,
                  cellRendererParams: {
                     link: true,
                     setShow: setShow,
                     setApiToCall: setApiToCall,
                     queryParam: "framework_contract_number",
                  },
                  minWidth: 230,
                  filter: "agTextColumnFilter",
                  filterValueGetter: (params) => {
                     return params.data.frameworkContract.keyValue;
                  },
                  comparator: (valueA, valueB) => {
                     if (valueA.keyValue === valueB.keyValue) return 0;
                     return valueA.keyValue > valueB.keyValue ? 1 : -1;
                  },
               },
               {
                  headerName: "Incarico/attingimento",
                  field: "contract",
                  cellRenderer: ResumeChecklistCellRenderer,
                  cellRendererParams: {
                     link: true,
                     setShow: setShow,
                     setApiToCall: setApiToCall,
                     queryParam: "contract_number",
                  },
                  minWidth: 240,
                  filter: "agTextColumnFilter",
                  filterValueGetter: (params) => {
                     return params.data.contract.keyValue;
                  },
                  comparator: (valueA, valueB) => {
                     if (valueA.keyValue === valueB.keyValue) return 0;
                     return valueA.keyValue > valueB.keyValue ? 1 : -1;
                  },
               },
            ],
         },
         {
            headerName: "Fattura",
            children: [
               {
                  headerName: "Fattura",
                  field: 'invoice',
                  cellRenderer: ResumeChecklistCellRenderer,
                  cellRendererParams: {
                     link: true,
                     setShow: setShow,
                     setApiToCall: setApiToCall,
                     queryParam: "rp_code",
                  },
                  minWidth: 230,
                  filter: "agTextColumnFilter",
                  filterValueGetter: (params) => {
                     return params.data.invoice.keyValue;
                  },
                  comparator: (valueA, valueB) => {
                     if (valueA.keyValue === valueB.keyValue) return 0;
                     return valueA.keyValue > valueB.keyValue ? 1 : -1;
                  },
               },
               ...(userRole < 70 ? [
               {
                  headerName: "Ammissibilità",
                  columnGroupShow: 'open',
                  field: "sections.ammissibilita",
                  cellRenderer: OnlyChecklistCellRenderer,
                  cellRendererParams: {
                     queryParam: "ammissibilita",
                  },
                  minWidth: 160,
                  filter: "agTextColumnFilter",
                  filterValueGetter: (params) => {
                     return getValueFilterStoplight(params.data.sections.ammissibilita);
                  },
                  comparator: (valueA, valueB) => {
                     if (valueA.keyValue === valueB.keyValue) return 0;
                     return valueA.keyValue > valueB.keyValue ? 1 : -1;
                  },
                  ...columnCentered(),
               }] : []),
               ...(userRole < 70 ? [
               {
                  headerName: "Conformità",
                  columnGroupShow: 'open',
                  field: "sections.fattura",
                  cellRenderer: OnlyChecklistCellRenderer,
                  cellRendererParams: {
                     queryParam: "fattura",
                  },
                  minWidth: 150,
                  filter: "agTextColumnFilter",
                  filterValueGetter: (params) => {
                     return getValueFilterStoplight(params.data.sections.fattura);
                  },
                  comparator: (valueA, valueB) => {
                     if (valueA.keyValue === valueB.keyValue) return 0;
                     return valueA.keyValue > valueB.keyValue ? 1 : -1;
                  },
                  ...columnCentered(),
               }] : []),
               ...(userRole < 70 ? [
               {
                  headerName: "Inerenza",
                  columnGroupShow: 'open',
                  field: "sections.inerenza",
                  cellRenderer: OnlyChecklistCellRenderer,
                  cellRendererParams: {
                     queryParam: "inerenza",
                  },
                  minWidth: 130,
                  filter: "agTextColumnFilter",
                  filterValueGetter: (params) => {
                     return getValueFilterStoplight(params.data.sections.inerenza);
                  },
                  comparator: (valueA, valueB) => {
                     if (valueA.keyValue === valueB.keyValue) return 0;
                     return valueA.keyValue > valueB.keyValue ? 1 : -1;
                  },
                  ...columnCentered(),
               }] : []),
               ...(userRole < 70 ? [
               {
                  headerName: "Quietanza",
                  columnGroupShow: 'open',
                  field: "sections.quietanza",
                  cellRenderer: OnlyChecklistCellRenderer,
                  cellRendererParams: {
                     queryParam: "quietanza",
                  },
                  minWidth: 130,
                  filter: "agTextColumnFilter",
                  filterValueGetter: (params) => {
                     return getValueFilterStoplight(params.data.sections.quietanza);
                  },
                  comparator: (valueA, valueB) => {
                     if (valueA.keyValue === valueB.keyValue) return 0;
                     return valueA.keyValue > valueB.keyValue ? 1 : -1;
                  },
                  ...columnCentered(),
               }] : []),
               ...(userRole < 70 ? [
               {
                  headerName: "Timbratura",
                  columnGroupShow: 'open',
                  field: "sections.dicituraDiAnnullamento",
                  cellRenderer: OnlyChecklistCellRenderer,
                  cellRendererParams: {
                     queryParam: "dicituraDiAnnullamento",
                  },
                  minWidth: 150,
                  filter: "agTextColumnFilter",
                  filterValueGetter: (params) => {
                     return getValueFilterStoplight(params.data.sections.dicituraDiAnnullamento);
                  },
                  comparator: (valueA, valueB) => {
                     if (valueA.keyValue === valueB.keyValue) return 0;
                     return valueA.keyValue > valueB.keyValue ? 1 : -1;
                  },
                  ...columnCentered(),
               }] : []),
               {
                  headerName: "Data fattura",
                  columnGroupShow: 'open',
                  field: 'date',
                  filter: "agTextColumnFilter",
                  valueGetter: (params) => {
                     return dateFormatter(params.data.date);
                  },
                  filterValueGetter: (params) => {
                     return dateFormatter(params.data.date);
                  },
                  comparator: (valueA, valueB) => {
                     if (valueA.keyValue === valueB.keyValue) return 0;
                     return valueA.keyValue > valueB.keyValue ? 1 : -1;
                  },
                  cellRendererParams: {
                     queryParam: "date",
                  },
                  minWidth: 200,
                  maxWidth: 200,
                  ...columnCenteredWithFilter,
               },
            ],
         },
         {
            headerName: "Pagamento",
            field: "payment",
            cellRenderer: ResumeChecklistCellRenderer,
            cellRendererParams: {
               link: true,
               setShow: setShow,
               setApiToCall: setApiToCall,
               queryParam: "payment_number",
            },
            minWidth: 230,
            filter: "agTextColumnFilter",
            filterValueGetter: (params) => {
               return params.data.payment.keyValue;
            },
            comparator: (valueA, valueB) => {
               if (valueA.keyValue === valueB.keyValue) return 0;
               return valueA.keyValue > valueB.keyValue ? 1 : -1;
            },
         },
         ...(userRole < 70 ? [
            {
               headerName: "Pubblicità",
               field: "advertisingWarning",
               cellRenderer: OnlyWarningCellRenderer,
               cellRendererParams: {
                  queryParam: "pubblicita",
               },
               minWidth: 150,
               maxWidth: 170,
               filter: "agTextColumnFilter",
               filterValueGetter: (params) => {
                  return getValueFilterStoplight(params.data.advertisingWarning);
               },
               comparator: (valueA, valueB) => {
                  if (valueA.keyValue === valueB.keyValue) return 0;
                  return valueA.keyValue > valueB.keyValue ? 1 : -1;
               },
               ...columnCentered(),
            },
         ] : []),
         ...(userRole < 70 ? [
            {
               headerName: "DNSH",
               field: "dnshWarning",
               cellRenderer: OnlyWarningCellRenderer,
               cellRendererParams: {
                  queryParam: "dnsh",
               },
               minWidth: 150,
               maxWidth: 170,
               filter: "agTextColumnFilter",
               filterValueGetter: (params) => {
                  return getValueFilterStoplight(params.data.dnshWarning);
               },
               comparator: (valueA, valueB) => {
                  if (valueA.keyValue === valueB.keyValue) return 0;
                  return valueA.keyValue > valueB.keyValue ? 1 : -1;
               },
               ...columnCentered(),
            },
         ] : []),
         {
            headerName: "Dettagli",
            children: [
               {
                  headerName: "Quota inerente €",
                  field: "totalCost",
                  cellRendererParams: {
                     queryParam: "total_cost",
                     typeOf: "number",
                  },
                  valueFormatter: (params) => {
                     return formatToDecimals(params.data.totalCost);
                  },
                  filter: "agTextColumnFilter",
                  filterParams: {
                     filterOptions: ["equals"],
                     textMatcher: ({ filterOption, value, filterText }) => {
                        if (filterText == null) return false;

                        switch (filterOption) {
                           case "equals":
                              return (
                                 value === filterText ||
                                 formatToDecimals(Number(value)) === filterText
                              );
                           default:
                              return false;
                        }
                     },
                  },
                  comparator: (valueA, valueB, nodeA, nodeB) => {
                     if (nodeA.data.totalCost === nodeB.data.totalCost) return 0;
                     return nodeA.data.totalCost > nodeB.data.totalCost ? 1 : -1;
                  },
                  ...columnToRight(),
                  minWidth: 200,
               },
               {
                  headerName: "Quota rendicontabile €",
                  field: "totalEligibleAmount",
                  cellRendererParams: {
                     queryParam: "total_eligible_amount",
                     typeOf: "number",
                  },
                  valueFormatter: (params) => {
                     return formatToDecimals(params.data.totalEligibleAmount);
                  },
                  filter: "agTextColumnFilter",
                  filterParams: {
                     filterOptions: ["equals"],
                     textMatcher: ({ filterOption, value, filterText }) => {
                        if (filterText == null) return false;

                        switch (filterOption) {
                           case "equals":
                              return (
                                 value === filterText ||
                                 formatToDecimals(Number(value)) === filterText
                              );
                           default:
                              return false;
                        }
                     },
                  },
                  comparator: (valueA, valueB, nodeA, nodeB) => {
                     if (nodeA.data.totalEligibleAmount === nodeB.data.totalEligibleAmount) return 0;
                     return nodeA.data.totalEligibleAmount > nodeB.data.totalEligibleAmount ? 1 : -1;
                  },
                  ...columnToRight(),
                  minWidth: 240,
               },
               {
                  headerName: "Imponibile €",
                  columnGroupShow: 'open',
                  field: "taxableAmount",
                  cellRendererParams: {
                     queryParam: "taxable_amount",
                     typeOf: "number",
                  },
                  valueFormatter: (params) => {
                     return formatToDecimals(params.data.taxableAmount);
                  },
                  filter: "agTextColumnFilter",
                  filterParams: {
                     filterOptions: ["equals"],
                     textMatcher: ({ filterOption, value, filterText }) => {
                        if (filterText == null) return false;

                        switch (filterOption) {
                           case "equals":
                              return (
                                 value === filterText ||
                                 formatToDecimals(Number(value)) === filterText
                              );
                           default:
                              return false;
                        }
                     },
                  },
                  comparator: (_valueA, _valueB, nodeA, nodeB) => {
                     if (nodeA.data.taxableAmount === nodeB.data.taxableAmount) return 0;
                     return nodeA.data.taxableAmount > nodeB.data.taxableAmount ? 1 : -1;
                  },
                  ...columnToRight(),
                  minWidth: 180,
               },
               {
                  headerName: "Fornitore",
                  columnGroupShow: 'open',
                  field: "supplierName",
                  filter: "agTextColumnFilter",
                  cellStyle: { ...customCellStyle },
                  cellRendererParams: {
                     queryParam: "supplier_name",
                  },
                  minWidth: 190,
               },
            ],
         },
      ]);

      setColumnDefsStaff([
         ...(userRole < 70 ? [
            {
               headerName: "",
               headerTooltip: 'Rendicontato',
               tooltipComponent: CustomTooltip,
               field: "reported",
               cellRenderer: ReportedCheckRenderer,
               cellRendererParams: {
                  queryParam: "reported",
               },
               minWidth: 70,
               maxWidth: 70,
               //flex: 1,
               resizable: false,
               ...columnCentered(),
            },
         ] : []),
         ...(userRole < 70 ? [
            {
               headerName: "",
               headerTooltip: 'Eleggibile',
               tooltipComponent: CustomTooltip,
               field: "reportable",
               cellRenderer: TotalResumeChecklistCellRenderer,
               cellRendererParams: {
                  queryParam: "reportable",
               },
               minWidth: 70,
               maxWidth: 70,
               //flex: 1,
               ...columnCentered(),
            },
         ] : []),
         {
            headerName: "Dipendente",
            field: "employee.keyValue",
            filter: "agTextColumnFilter",
            cellStyle: { ...customCellStyle },
            cellRendererParams: {
               queryParam: "serial_number",
            },
            flex: 1,
         },
         {
            headerName: "Anno",
            field: "year",
            headerClass: "text-center",
            cellRendererParams: {
               queryParam: "year",
            },
            cellStyle: { ...customCellStyle, justifyContent: "center" },
            flex: 1,
         },
         {
            headerName: "Mese",
            field: "month",
            headerClass: "text-center",
            cellStyle: { ...customCellStyle, justifyContent: "center" },
            valueGetter: (params) => {
               return months()[params.data.month - 1];
            },
            cellRendererParams: {
               queryParam: "month",
            },
            flex: 1,
         },
         {
            headerName: "Categoria",
            field: "category",
            filter: "agTextColumnFilter",
            cellStyle: {
               ...customCellStyle,
            },
            cellRendererParams: {
               queryParam: "category",
            },
            flex: 1,
         },
         {
            headerName: "Record",
            field: "timesheet",
            cellRenderer: ResumeChecklistCellRenderer,
            cellRendererParams: {
               link: true,
               setShow: setShow,
               setApiToCall: setApiToCall,
               queryParam: "number",
            },
            minWidth: 230,
            filter: "agTextColumnFilter",
            filterValueGetter: (params) => {
               return params.data.timesheet.keyValue;
            },
            comparator: (valueA, valueB) => {
               if (valueA.keyValue === valueB.keyValue) return 0;
               return valueA.keyValue > valueB.keyValue ? 1 : -1;
            },
            flex: 1,
         },
         {
            headerName: "Costo totale €",
            field: "totalCost",
            valueFormatter: (params) => {
               return formatToDecimals(params.data.totalCost);
            },
            cellRendererParams: {
               queryParam: "total_cost",
               typeOf: "number",
            },
            filter: "agTextColumnFilter",
            filterParams: {
               filterOptions: ["equals"],
               textMatcher: ({ filterOption, value, filterText }) => {
                  if (filterText == null) return false;

                  switch (filterOption) {
                     case "equals":
                        return (
                           value === filterText ||
                           formatToDecimals(Number(value)) === filterText
                        );
                     default:
                        return false;
                  }
               },
            },
            comparator: (_valueA, _valueB, nodeA, nodeB) => {
               if (nodeA.data.totalCost === nodeB.data.totalCost) return 0;
               return nodeA.data.totalCost > nodeB.data.totalCost ? 1 : -1;
            },
            flex: 1,
            minWidth: 170,
            ...columnToRight(),
         },
      ]);

      setColumnDefsReports([
         {
            headerName: "Voci di spesa",
            field: "projectNoticeExpenseItemName",
            filter: "agTextColumnFilter",
            //minWidth: 250,
            flex: 2,
         },
         {
            headerName: "Avanzamento (€)",
            field: "totalColors",
            filter: "agTextColumnFilter",
            valueFormatter: (params) => {
               return params.data.totalColors > 0
                  ? formatToDecimals(params.data.totalColors)
                  : "-";
            },
            filterValueGetter: (params) => {
               return params.data.totalColors > 0
                  ? formatToDecimals(params.data.totalColors)
                  : "-";
            },
            valueGetter: (params) => {
               return params.data.totalColors > 0 ? params.data.totalColors : 0;
            },
            minWidth: 190,
            ...columnToRight(),
         },
         {
            headerName: "Eleggibile (€)",
            field: "totalGreen",
            filter: "agTextColumnFilter",
            valueFormatter: (params) => {
               return params.data.totalGreen > 0
                  ? formatToDecimals(params.data.totalGreen)
                  : "-";
            },
            filterValueGetter: (params) => {
               return params.data.totalGreen > 0
                  ? formatToDecimals(params.data.totalGreen)
                  : "-";
            },
            valueGetter: (params) => {
               return params.data.totalGreen > 0 ? params.data.totalGreen : 0;
            },
            minWidth: 190,
            ...columnRightGreen,
         },
         {
            headerName: "In lavorazione (€)",
            field: "totalYellow",
            filter: "agTextColumnFilter",
            valueFormatter: (params) => {
               return params.data.totalYellow > 0
                  ? formatToDecimals(params.data.totalYellow)
                  : "-";
            },
            filterValueGetter: (params) => {
               return params.data.totalYellow > 0
                  ? formatToDecimals(params.data.totalYellow)
                  : "-";
            },
            valueGetter: (params) => {
               return params.data.totalYellow > 0 ? params.data.totalYellow : 0;
            },
            minWidth: 190,
            ...columnRightYellow,
         },
         {
            headerName: "Non eleggibile (€)",
            field: "totalRed",
            filter: "agTextColumnFilter",
            valueFormatter: (params) => {
               return params.data.totalRed > 0
                  ? formatToDecimals(params.data.totalRed)
                  : "-";
            },
            filterValueGetter: (params) => {
               return params.data.totalRed > 0
                  ? formatToDecimals(params.data.totalRed)
                  : "-";
            },
            valueGetter: (params) => {
               return params.data.totalRed > 0 ? params.data.totalRed : 0;
            },
            minWidth: 190,
            ...columnRightRed,
         },
      ]);
   }, [
      ResumeChecklistCellRenderer,
      TotalResumeChecklistCellRenderer,
      ReportedCheckRenderer,
      OnlyChecklistCellRenderer,
      OnlyWarningCellRenderer,
      userRole,
      project.sco,
   ]);

   const onFilterChanged = (e, reset) => {
      //console.log('log1 e:', e);

      if (!reset) {
         const model = e.api.getFilterModel();

         let queryParamsString = "";

         for (const [key, value] of Object.entries(model)) {
            let keyFormatted = key;

            switch (key) {
               case "tender":
                  keyFormatted = "tender_number";
                  break;
               case "frameworkContract":
                  keyFormatted = "framework_contract_number";
                  break;
               case "contract":
                  keyFormatted = "contract_number";
                  break;
               case "invoice":
                  keyFormatted = "rp_code";
                  break;
               case "employee.keyValue":
                  keyFormatted = "serial_number";
                  break;
               case "timesheet":
                  keyFormatted = "number";
                  break;
               case "totalCost":
                  keyFormatted = "total_cost";
                  break;
               default:
                  break;
            }

            queryParamsString += `${queryParamsString === "" ? "?" : "&"
               }${keyFormatted}=${value.filter}`;
         }

         if (setModelFilterString) setModelFilterString(queryParamsString);
      } else {
         e?.api?.setFilterModel(null);

         if (setModelFilterString) setModelFilterString("");
      }
   };

   const refreshButton = () => {
      return (
         <OverlayTrigger placement="top" overlay={<Tooltip>Refresh</Tooltip>}>
            <Button 
               onClick={() => {setIsLoading(true); setTimeout(() => {
                  setRefresh(true)
               }, 1000)}}
               className="me-2" 
               style={{ padding: "0.2rem", height: '2.235rem', width: '2.211rem' }}
            >
               {isLoading ? 
                  <Spinner
                     as="span"
                     className="mx-1"
                     animation="border"
                     size="sm"
                     role="status"
                     aria-hidden="true"
                     style={{verticalAlign: 'middle'}}
                  /> : 
                  <IcoRefresh />
               }
            </Button>
         </OverlayTrigger>
      );
   };

   const downloadExcelButtons = (resumeSection, fileName) => {
      return (
         <>
            <Dropdown className="me-2" autoClose="outside">
               <Dropdown.Toggle
                  id="dropdown-autoclose-outside"
                  style={{ padding: "0.25rem 0.4rem" }}
               >
                  <ExcelIcon style={{ width: "24px", height: "24px" }} />
               </Dropdown.Toggle>
               <Dropdown.Menu>
                  <Dropdown.Header>
                     Scarica il riepilogo dei Costi esterni
                  </Dropdown.Header>
                  <Dropdown.Item
                     as="button"
                     className="d-flex align-items-center"
                     onClick={() => {
                        handleFileDownload(
                           project.id,
                           resumeSection,
                           setIsDownloadingSynthetic
                        );
                     }}
                  >
                     Riepilogo sintetico
                     {isDownloadingSynthetic && (
                        <Spinner
                           style={{
                              height: "20px",
                              width: "20px",
                              marginLeft: "0.5rem",
                           }}
                           animation="border"
                           size="md"
                           role="status"
                        >
                           <span style={{ visibility: "hidden" }}><OETrans code='common.loading' fallback={'Caricamento'} />...</span>
                        </Spinner>
                     )}
                  </Dropdown.Item>
                  <Dropdown.Item
                     as="button"
                     className="d-flex align-items-center"
                     onClick={() => {
                        handleFileDownload(
                           project.id,
                           "analytic" + resumeSection,
                           setIsDownloadingAnalytical
                        );
                     }}
                  >
                     Riepilogo analitico
                     {isDownloadingAnalytical && (
                        <Spinner
                           style={{
                              height: "20px",
                              width: "20px",
                              marginLeft: "0.5rem",
                           }}
                           animation="border"
                           size="md"
                           role="status"
                        >
                           <span style={{ visibility: "hidden" }}><OETrans code='common.loading' fallback={'Caricamento'} />...</span>
                        </Spinner>
                     )}
                  </Dropdown.Item>
               </Dropdown.Menu>
            </Dropdown>
         </>
      );
   };

   const handleClick = (ref) => {
      ref.current?.handleReset();
   };

   const total = (isFilteredDiv) => {
      if (activeKey === "external-supplies") {
         if (isFilteredDiv && isFiltered && filteredTotal) {
            return `${filteredTotal.all ? formatToDecimals(filteredTotal.all) : 0
               } €`;
         } else {
            return `${totals.totalFt ? formatToDecimals(totals.totalFt) : 0} €`;
         }
      }

      if (activeKey === "intern-workers") {
         if (isFilteredDiv && isFiltered && filteredTotal) {
            return `${filteredTotal.all ? formatToDecimals(filteredTotal.all) : 0
               } €`;
         } else {
            return `${totals.totalTs ? formatToDecimals(totals.totalTs) : 0} €`;
         }
      }
   };

   const ofWhichReportable = (isFilteredDiv, indirectCost) => {
      if (activeKey === "external-supplies") {
         //const e = { target: { value: "" } };

         if (isFilteredDiv && isFiltered) {
            //onFilterTextBoxChanged(e, externalSuppliesRef);
            return `${filteredTotal.rend ? formatToDecimals(filteredTotal.rend) : 0
               } €`;
         } else {
            //onFilterTextBoxChanged(e, externalSuppliesRef);
            return `${totals.totalRendFt ? formatToDecimals(totals.totalRendFt) : 0
               } €`;
         }
      }

      if (activeKey === "intern-workers") {
         if (isFilteredDiv && isFiltered) {
            const result = indirectCost ? filteredTotal.totalTsIndirect : filteredTotal.rend;

            return `${result ? formatToDecimals(result) : 0} €`;
         } else {
            return `${totals.totalRendTs ? formatToDecimals(totals.totalRendTs) : 0
               } €`;
         }
      }
   };

   const dataHeaderName = (activeKey) => {
      let name = "";

      switch (activeKey) {
         case "external-supplies":
            name = "Costi esterni";
            break;
         case "intern-workers":
            name = "Costi interni";
            break;
         default:
            break;
      }

      return name;
   };

   return (
      <>
         {userRole < 70 && (
            <Row>
               <Col>
                  <div className="candidacy simple-box scenaritab" style={{ paddingBottom: '0.5rem' }}>
                     <div className='row d-flex align-items-center mb-3'>
                        <div className=''>
                           <h5 className='title-simple-box'>Avanzamento</h5>
                        </div>
                     </div>

                     <DataHeader
                        data={[
                           {
                              name: "Spesa effettuata",
                              value: (totals.total ? formatToDecimals(totals.total) : 0) + " €",
                           }, //A
                        ]}
                     />
                     <DataHeader
                        data={[
                           {
                              name: "di cui eleggibile",
                              value: (totals.totalRend ? formatToDecimals(totals.totalRend) : 0) + " €",
                           }, //A
                        ]}
                     />
                  </div>
               </Col>

               {activeKey === "report" ?
                  null : <>
                     <Col>
                        <div className="candidacy simple-box scenaritab" style={{ paddingBottom: '0.5rem' }}>
                           <div className='row d-flex align-items-center mb-3'>
                              <div className=''>
                                 <h5 className='title-simple-box'>{dataHeaderName(activeKey)}</h5>
                              </div>
                           </div>

                           <DataHeader
                              data={[
                                 {
                                    name: `Spesa in ${dataHeaderName(activeKey)}`,
                                    value: total(false),
                                 }, //B
                              ]}
                           />
                           <DataHeader
                              data={[
                                 {
                                    name: "di cui eleggibile",
                                    value: ofWhichReportable(false)
                                 }, //B
                                 ...(activeKey === "intern-workers" && project.sco ? [{ 
                                    name: 'costi indiretti', 
                                    value: (totals.totalTsIndirect ? formatToDecimals(totals.totalTsIndirect) : 0) + " €",
                                 }] : []), //B
                              ]}
                           />
                        </div>
                     </Col>

                     <Col>
                        <div className="candidacy simple-box scenaritab" style={{ paddingBottom: '0.5rem' }}>
                           <div className='row d-flex align-items-center mb-3'>
                              <div className=''>
                                 <h5 className='title-simple-box'>
                                    Filtro
                                 </h5>
                              </div>
                           </div>

                           <DataHeader
                              data={[
                                 activeKey === "report"
                                    ? null
                                    : {
                                       name: `Spesa filtrata`,
                                       value: total(true),
                                    }, //C
                              ]}
                           />
                           <DataHeader
                              data={[
                                 activeKey === "report"
                                    ? null
                                    : { name: "di cui eleggibile", value: ofWhichReportable(true) }, //C
                                    ...(activeKey === "intern-workers" && project.sco ? [{ 
                                       name: 'costi indiretti', 
                                       value: isFiltered ? ofWhichReportable(true, true) : (totals.totalTsIndirect ? formatToDecimals(totals.totalTsIndirect) : 0) + " €",
                                    }] : []), //C
                              ]}
                           />
                        </div>
                     </Col>
                  </>
               }
            </Row>
         )}

         <div className="candidacy simple-box scenaritab">
            <Row>
               <Tabs
                  activeKey={activeKey}
                  variant='pills'
                  id="uncontrolled-tab-example"
                  className="mb-3"
                  onSelect={(k) => {
                     setActiveKey(k);
                     setFilteredTotal(null);
                     setIsFiltered(false);
                     handleClick(k === "external-supplies" ? filter1Ref : filter2Ref);
                     setModelFilterString("");
                  }}
               >
                  <Tab eventKey="external-supplies" title="Costi esterni">
                     {activeKey === "external-supplies" && (
                        <Fragment>
                           <Row className="align-items-center mb-3">
                              <Col className="col-4"></Col>
                              <Col className="col-8 text-right py-1">
                                 <ButtonToolbar className="justify-content-end align-items-center" style={{ height: '38px' }}>
                                    {downloadExcelButtons(
                                       "invoices",
                                       `Forniture-Esterne-${project?.name}-${project?.cup}`
                                    )}

                                    <OEFilterExternalSupplies
                                       columnDefs={columnDefsExternalSupplies}
                                       projectId={project.id}
                                       setTable={setExternalSupplies}
                                       setTableMeta={setExternalSuppliesMeta}
                                       isFiltered={isFiltered}
                                       setIsFiltered={setIsFiltered}
                                       setFilteredTotal={setFilteredTotal}
                                       handleClick={() => handleClick(filter1Ref)}
                                       setFilterQueryParamsString={
                                          setFilterQueryParamsString
                                       }
                                       onFilterChanged={onFilterChanged}
                                       tableRef={externalSuppliesRef}
                                       ref={filter1Ref}
                                    />
                                    {refreshButton()}
                                    <CustomQuickFilterBox
                                       tableRef={externalSuppliesRef}
                                       dataMeta={externalSuppliesMeta}
                                       setData={setExternalSupplies}
                                       dataLength={externalSupplies?.length}
                                       isFirstResearch={isFirstResearch1}
                                       setIsFirstResearch={setIsFirstResearch1}
                                       refTable='externalSupplies'
                                       isRefreshing={isLoading}
                                       setIsRefreshing={setIsLoading}
                                    />
                                 </ButtonToolbar>
                              </Col>
                           </Row>
                           <>{sbShow}</>
                           <Row>
                              {Array.isArray(externalSupplies) ? (
                                 <Col className="col-12 col-xxl-9" id="grid-wrapper">
                                    <div className="ag-theme-alpine list-table mb-4" style={{ height: '800px' }}>
                                       <AgGridReact
                                          ref={externalSuppliesRef}
                                          columnDefs={columnDefsExternalSupplies}
                                          rowData={externalSupplies}
                                          defaultColDef={defaultColDef}
                                          tooltipShowDelay={0}
                                          tooltipHideDelay={2000}
                                          tooltipMouseTrack={true}
                                          onGridReady={params => setGridApi(params.api)}
                                          onFilterChanged={onFilterChanged}
                                          headerHeight={50}
                                          rowHeight={50}
                                          ariaDescription="ag-grid-table"
                                          suppressCellFocus={true}
                                          rowSelection="single"
                                          autoSizeStrategy={{ type: "fitCellContents" }}
                                          onColumnGroupOpened={onColumnGroupOpened}
                                          localeText={localeText}
                                       />
                                       {Object.keys(externalSuppliesMeta).length > 0 && (
                                          <CustomPaginationMui
                                             dataMeta={externalSuppliesMeta}
                                             setData={setExternalSupplies}
                                             setDataMeta={setExternalSuppliesMeta}
                                             refTable='externalSupplies'
                                             setIsFirstResearch={setIsFirstResearch1}
                                          />
                                       )}
                                    </div>
                                 </Col>
                              ) : (
                                 <div className="text-center my-4">
                                    <Spinner animation="border" role="status">
                                       <span style={{ visibility: "hidden" }}>
                                          Caricamento...
                                       </span>
                                    </Spinner>
                                 </div>
                              )}
                           </Row>
                        </Fragment>
                     )}
                  </Tab>

                  <Tab eventKey="intern-workers" title="Costi interni">
                     {activeKey === "intern-workers" && (
                        <Fragment>
                           <Row className="align-items-center mb-3">
                              <Col className="col-4"></Col>
                              <Col className="col-8 text-right py-1">
                                 <ButtonToolbar className="justify-content-end">
                                    {downloadExcelButtons(
                                       "timesheets",
                                       `Personale-Interno-${project?.name}-${project?.cup}`
                                    )}

                                    <OEFilterStaff
                                       columnDefs={columnDefsStaff}
                                       projectId={project.id}
                                       setTable={setStaff}
                                       setTableMeta={setStaffMeta}
                                       isFiltered={isFiltered}
                                       setIsFiltered={setIsFiltered}
                                       setFilteredTotal={setFilteredTotal}
                                       handleClick={() => handleClick(filter2Ref)}
                                       setFilterQueryParamsString={
                                          setFilterQueryParamsString
                                       }
                                       tableRef={staffRef}
                                       ref={filter2Ref}
                                    />
                                    {refreshButton()}
                                    <CustomQuickFilterBox
                                       tableRef={staffRef}
                                       dataMeta={staffMeta}
                                       setData={setStaff}
                                       dataLength={staff?.length}
                                       isFirstResearch={isFirstResearch2}
                                       setIsFirstResearch={setIsFirstResearch2}
                                       refTable='staff'
                                       isRefreshing={isLoading}
                                       setIsRefreshing={setIsLoading}
                                    />
                                    {/* <InputGroup className="search-table align-items-center mt-md-0 ms-1">
                                       <FormControl
                                          type="text"
                                          className="filter-text-box"
                                          placeholder={translate('common.search', 'Cerca') + '...'}
                                          onInput={(e) => onFilterTextBoxChanged(e, staffRef)}
                                       />
                                       <Lens />
                                    </InputGroup> */}
                                 </ButtonToolbar>
                              </Col>
                           </Row>

                           {Array.isArray(staff) ? (
                              <Row className="mb-5">
                                 <Col className="col-12 col-xxl-9" id="grid-wrapper">
                                    {Object.keys(staffMeta).length > 0 ? (
                                       <div className="ag-theme-alpine list-table" style={{ height: '800px' }}>
                                          <AgGridReact
                                             ref={staffRef}
                                             columnDefs={columnDefsStaff}
                                             rowData={staff}
                                             defaultColDef={defaultColDef}
                                             tooltipShowDelay={0}
                                             tooltipHideDelay={2000}
                                             tooltipMouseTrack={true}
                                             // onGridSizeChanged={() => sizeToFit(staffRef)}
                                             // onFirstDataRendered={() => onFirstDataRendered(staffRef)}
                                             onFilterChanged={onFilterChanged}
                                             headerHeight={50}
                                             rowHeight={50}
                                             ariaDescription="ag-grid-table"
                                             suppressCellFocus={true}
                                             autoSizeStrategy={{ type: "fitCellContents" }}
                                             rowSelection="single"
                                             localeText={localeText}
                                          />
                                          {Object.keys(staffMeta).length > 0 && (
                                             <CustomPaginationMui
                                                dataMeta={staffMeta}
                                                setData={setStaff}
                                                setDataMeta={setStaffMeta}
                                                refTable='staff'
                                                setIsFirstResearch={setIsFirstResearch2}
                                             />
                                          )}
                                       </div>
                                    ) : (
                                       <Row className="text-center my-5">
                                          <span>Non sono presenti elementi caricati</span>
                                       </Row>
                                    )}
                                 </Col>
                              </Row>
                           ) : (
                              <div className="text-center my-4">
                                 <Spinner animation="border" role="status">
                                    <span style={{ visibility: "hidden" }}>
                                       Caricamento...
                                    </span>
                                 </Spinner>
                              </div>
                           )}
                        </Fragment>
                     )}
                  </Tab>

                  {userRole < 70 && (
                     <Tab eventKey="report" title="Report">
                        {activeKey === "report" && (
                           <Fragment>
                              <Row>
                                 <Col className="col-12">
                                    <OESimpleTable
                                       columnDefs={columnDefsReports}
                                       rowData={reports}
                                       buttonToolbarVisible={true}
                                       pageSizeId={"reports-table"}
                                       sortable={true}
                                       pinnedBottomRow={true}
                                       bottomColumns={[
                                          { projectNoticeExpenseItemName: "TOTALE" },
                                          "totalColors",
                                          "totalGreen",
                                          "totalYellow",
                                          "totalRed",
                                       ]}
                                       fullWidth={true}
                                    />
                                 </Col>
                              </Row>
                           </Fragment>
                        )}
                     </Tab>
                  )}
               </Tabs>
            </Row>
            {renderCorrectModal(apiToCall)}
         </div>
      </>
   );
};
export default ExecutionSummary;
