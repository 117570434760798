const BASE_URL = process.env.REACT_APP_BASE_URL ?? '';

const getAuth = () => localStorage.getItem('token') || '';

const getDefaultHeaders = (): HeadersInit => ({
    Authorization: `Bearer ${getAuth()}`,
    'Content-Type': 'application/json',
    Accept: 'application/json',
});

export const defaultFetch = async <T>(url: string, options: RequestInit = {}): Promise<T> => {
    try {
        const resp = await fetch(BASE_URL + url, {
            ...options,
            headers: {
                ...getDefaultHeaders(),
                ...(options.headers || {}), // Unisce eventuali headers personalizzati passati in options
            }
        });

        const data = await resp.json();
        if (data.success) {
            return data.data as T;
        } else {
            throw new Error(`Errore durante il recupero dei dati: ${data.message}`);
        }
    } catch (error) {
        throw error;
    }
};
