import { TablePagination } from "@mui/material";
import { Col, Row, Spinner } from "react-bootstrap";

export default function CustomPagination({
  loading,
  count,
  currentPage,
  setCurrentPage,
  perPage,
  setPerPage,
  gridRef,
}) {
  const handlePageChange = (e, newPage) => {
    setCurrentPage(newPage);
    if (gridRef.current) {
      gridRef.current.api.paginationGoToPage(newPage);
    }
  };
  const handleChangeRowsPerPage = (e) => {
    const value = parseInt(e.target.value);
    setPerPage(value);
    setCurrentPage(0);
    if (gridRef.current) {
      gridRef.current.api.paginationSetPageSize(value);
      gridRef.current.api.paginationGoToPage(0);
    }
  };

  return (
    <Row className="flex-row align-items-baseline mt-4">
      <Col className="col-auto ms-auto p-0">
        {loading ? (
          <Spinner animation="border" role="status" variant="dark" size="sm" />
        ) : null}
      </Col>
      <Col className="col-auto">
        <TablePagination
          // sx={{
          //   "& .MuiTablePagination-displayedRows, & .MuiTablePagination-actions":
          //     {
          //       display: "none",
          //     },
          // }}
          component="div"
          rowsPerPageOptions={[5, 10, 50, 100, 200, 500]}
          count={count}
          // count={timesheetsMeta?.meta?.total || 0}
          page={currentPage || 0}
          rowsPerPage={perPage}
          onPageChange={
            handlePageChange
            //     (e, newPage) => {
            //     setCurrentPage(newPage);
            //     // setUrlParams(`?page=${newPage}&perPage=${perPage}`);
            //     console.log("went to newPage", newPage);
            //     if (gridRef.current) {
            //       gridRef.current.api.paginationGoToPage(newPage);
            //       // handleData(gridRef.current);
            //     }
            //   }
          }
          onRowsPerPageChange={
            handleChangeRowsPerPage
            // (e) => {
            //   console.log("change rows per page", e.target.value);
            //   const value = parseInt(e.target.value);
            //   setPerPage(value);
            //   setCurrentPage(0);
            //   if (gridRef.current) {
            //     gridRef.current.api.paginationSetPageSize(value);
            //     gridRef.current.api.paginationGoToPage(0);
            //     // handleData(gridRef.current);
            //   }
            //   // setUrlParams(`?perPage=${value}`);
            //   // setUrlParams(value > 100 ? `?perPage=${value}` : "");
            //   // if (gridRef.current) {
            //   //   gridRef.current.api.paginationGoToPage(0);
            //   //   handleData(gridRef.current);
            //   // }
            // }
            // handleChangeRowsPerPage(
            //    e,
            //    setData,
            //    dataMeta,
            //    setDataMeta
            // )
          }
        />
      </Col>
    </Row>
  );
}
