import React, { useState, useEffect, useContext } from "react";
import { withRouter, useLocation, Link } from "react-router-dom";
import {
  Nav,
  Navbar,
  Dropdown,
  DropdownButton,
  ButtonGroup,
  Form,
} from "react-bootstrap";
import {
  Logout,
  UsersGear,
  IcoGear,
  IcoItaly,
  IcoUnitedKingdom,
  IcoSpain,
} from "@icons";
import { getLanguages } from "@lib/api";
import { OpenRepLogo, /* PoweredbyOE, */ ExternalyticsIco } from "loghi";
import { Fragment } from "react";
//import logoCompliance from 'img/logo-compliance.jpg';
import { LevelContext } from "@components/contexts/LevelContext";
import { OETrans } from "./translation/OETrans";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { translate } from "./translation/translation";

const Header = () => {
  const { setUserLanguage, isOe, devMode, setDevMode, userRole } =
    useContext(LevelContext);
  //const [userLanguage, setUserLanguage] = useState([]);
  const location = useLocation();
  const [languages, setLanguages] = useState(
    JSON.parse(localStorage.getItem("languages")) ?? []
  ); // eslint-disable-line
  const [activeLang, setActiveLang] = useState(
    localStorage.getItem("userLanguage") ?? "IT"
  );
  const userEmail = localStorage.getItem("userEmail");
  const isLoginPage = location.pathname === "/" ? true : false;
  const isClientThanks =
    location.pathname.indexOf("thanks") === 1 ? true : false;
  const [apiError, setApiError] = useState(false);
  //const isOEClient = Number(localStorage.getItem('userRole')) === 1 ? true : false;
  //const isSuperAdmin = Number(localStorage.getItem('userRole')) === 3 ? true : false;

  useEffect(() => {
    getLanguages()
      .then((retrievedLanguages) => {
        setLanguages(retrievedLanguages.data);
      })
      .catch((error) => {
        setApiError(true);
        console.error("Errore nel caricamento delle lingue");
      });
  }, []);

  useEffect(() => {
    if (setUserLanguage) {
      localStorage.setItem("userLanguage", activeLang);
      setUserLanguage(activeLang);
    }
  }, [activeLang, setUserLanguage]);

  const handleSetDevMode = () => {
    setDevMode(!devMode);
  };

  // eslint-disable-next-line
  const LanguageDropdown = () => {
    //const { i18n } = useTranslation()

    /* const handleChange = ({ value }) => {
             i18n.changeLanguage(value)
             localStorage.setItem('externalyticsLanguage', value)
             window.location.reload()
          } */

    let activeFlagIcon = "";

    const icoItaly = <IcoItaly style={{ width: "25px", height: "25px" }} />;
    const icoUnitedKingdom = (
      <IcoUnitedKingdom style={{ width: "25px", height: "25px" }} />
    );
    const icoSpain = <IcoSpain style={{ width: "25px", height: "25px" }} />;

    switch (activeLang) {
      case "IT":
        activeFlagIcon = icoItaly;
        break;
      case "EN":
        activeFlagIcon = icoUnitedKingdom;
        break;
      case "ES":
        activeFlagIcon = icoSpain;
        break;
      default:
        break;
    }

    /* const flagIcon = (languageCode) => {
           let flagIcon = '';
  
           switch (languageCode) {
              case 'IT':
                 flagIcon = icoItaly;
                 break;
              case 'EN':
                 flagIcon = icoUnitedKingdom;
                 break;
              case 'ES':
                 flagIcon = icoSpain;
                 break;
              default:
                 break;
           };
  
           return flagIcon;
        } */

    return (
      <>
        <Nav activeKey={activeLang} onSelect={(k) => setActiveLang(k)}>
          <DropdownButton
            as={ButtonGroup}
            className="header-nav-dropdown lang ms-3"
            align="end"
            variant="outline-header"
            title={activeFlagIcon}
          >
            {languages
              .sort((a, b) => b.code.localeCompare(a.code))
              .map((language) => (
                <Fragment key={language.code}>
                  <Dropdown.Item eventKey={language.code} className="px-2">
                    {/* {flagIcon(language.code)}  */}
                    {language.code}
                  </Dropdown.Item>
                </Fragment>
              ))}
          </DropdownButton>
        </Nav>
      </>
    );
  };

  return (
    <>
      {apiError ? (
        <div>
          <OETrans
            code="common.error.backend"
            fallback={"Errore di connessione col backend"}
          />
        </div>
      ) : (
        <>
          {isClientThanks ? (
            <></>
          ) : (
            <>
              <header id="site-header">
                <Navbar expand="lg" variant="light" className="me-0">
                  {/* <Navbar.Collapse className="col-4 pe-0 ps-2rem"> */}
                  <div className="col-3">
                    <Link
                      to={"/projects"}
                      className={`${isLoginPage ? "d-block d-lg-none" : ""}`}
                    >
                      <OpenRepLogo className="openrep-logo-header" />
                    </Link>
                  </div>
                  {/* </Navbar.Collapse> */}
                  {/* per avere hamburger menu automaticamente su schermi mobile */}
                  <Navbar.Toggle aria-controls="central-nav" />
                  <Navbar.Collapse className="col-6 p-0">
                    <Nav
                      activeKey={location.pathname
                        .split("/")
                        .slice(0, 2)
                        .join("/")}
                      id="central-nav"
                      className={`m-auto ${
                        isLoginPage ? "d-block d-lg-none" : ""
                      }`}
                    >
                      <Nav.Link href="/projects" eventKey="/projects">
                        <OETrans
                          code="common.header.projects"
                          fallback={"Progetti"}
                        />
                      </Nav.Link>
                      {/* <Nav.Link href='/reports' eventKey='/reports'>Reportistica</Nav.Link> */}
                      {isOe && (
                        <Nav.Link href="/tenders" eventKey="/tenders">
                          <OETrans
                            code="common.header.procedures"
                            fallback={"Procedure"}
                          />
                        </Nav.Link>
                      )}
                      {isOe && (
                        <Nav.Link href="/setup?funds" eventKey="/setup">
                          <OETrans
                            code="common.header.instruments"
                            fallback={"Strumenti"}
                          />
                        </Nav.Link>
                      )}

                      {/* {userRole < 70 && (
                        <Nav.Link href="/opportunity" eventKey="/opportunity">
                          <OETrans
                            code="common.header.opportunity"
                            fallback={"Opportunità"}
                          />
                        </Nav.Link>
                      )} */}
                    </Nav>
                  </Navbar.Collapse>

                  <Navbar.Collapse className="col-3 ps-0 pe-2rem">
                    <Nav
                      activeKey={location.pathname
                        .split("/")
                        .slice(0, 2)
                        .join("/")}
                      className="ms-auto"
                    >
                      <DropdownButton
                        as={ButtonGroup}
                        className={`header-nav-dropdown general ${
                          isLoginPage ? "d-block d-lg-none" : ""
                        }`}
                        align="end"
                        variant="outline-header"
                        title={<IcoGear className="userico" />}
                      >
                        {userEmail ? (
                          <>
                            <Dropdown.Header
                              className="text-end"
                              style={{ padding: "0.7rem 0" }}
                            >
                              {userEmail}
                            </Dropdown.Header>
                            <Dropdown.Divider />
                          </>
                        ) : null}
                        {isOe ? (
                          <>
                            {userRole === 1 && (
                              <>
                                <Dropdown.ItemText>
                                  <Form.Check // prettier-ignore
                                    type="switch"
                                    label={translate(
                                      "common.devmode",
                                      "Modalità sviluppatore"
                                    )}
                                    checked={!!devMode}
                                    {...(devMode && { checked: true })}
                                    onChange={() => handleSetDevMode()}
                                  />
                                </Dropdown.ItemText>
                                <Dropdown.Divider />
                              </>
                            )}
                            <Dropdown.Item
                              href="/administration?users"
                              eventKey="/administration"
                              className="text-end p-2"
                            >
                              <OETrans
                                code="common.menu.administration"
                                fallback={"Amministrazione OE"}
                              />
                              &nbsp;
                              <ExternalyticsIco className="dropdown-icon" />
                            </Dropdown.Item>
                            <Dropdown.Item
                              href="/clients?groups"
                              eventKey="/clients"
                              className="text-end p-2"
                            >
                              <OETrans
                                code="common.menu.clients"
                                fallback={"Gestione clienti"}
                              />
                              &nbsp;
                              <UsersGear className="dropdown-icon" />
                            </Dropdown.Item>
                          </>
                        ) : null}
                        <Dropdown.Item href="/profile" className="text-end p-2">
                          <OETrans code="common.profile" fallback={"Profilo"} />
                          &nbsp;
                          <FontAwesomeIcon
                            icon={faUser}
                            className="dropdown-icon"
                          />
                        </Dropdown.Item>
                        <Dropdown.Item href="/logout" className="text-end p-2">
                          <OETrans
                            code="common.menu.logout"
                            fallback={"Esci"}
                          />
                          &nbsp;
                          <Logout className="dropdown-icon" />
                        </Dropdown.Item>
                      </DropdownButton>

                      {/* <LanguageDropdown /> */}
                    </Nav>
                  </Navbar.Collapse>
                </Navbar>
              </header>
            </>
          )}
        </>
      )}
    </>
  );
};

export default withRouter(Header);
