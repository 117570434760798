import { CSSObject } from "@emotion/styled";
import { Alert } from "components/atomic/atoms";
import { CompanyUserType, getUsersByCompanyId } from "lib/api_ts/companiesApi";
import {
  addPartnersToProject,
  AvailableRoleType,
  PartnerToManageType,
  PartnerType,
  updateProjectPartner,
} from "lib/api_ts/parternshipApi";
import { SelectOptionType } from "lib/api_ts/types";
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import Select from "react-select";
import { ProjectPartnershipDataType, ShowAlertType } from "./PartnershipTab";

interface PartnershipFormProps {
  isModalOpen: boolean;
  toggleIsOpenModal: () => void;
  companyPartnershipData: PartnerType[];
  projectPartnershipData: ProjectPartnershipDataType[];
  availableRolesData: AvailableRoleType[];
  project: {
    id: number;
    company: {
      id: number;
      name: string;
    }
  };
  isManagingPartner: boolean;
  setIsManagingPartner: Dispatch<SetStateAction<boolean>>;
  isCreate: boolean;
  selectedPartner?: PartnerToManageType;
  setSelectedPartnerId?: Dispatch<SetStateAction<number | undefined>>;
  // showAlert: ShowAlertType;
  // setShowAlert: Dispatch<SetStateAction<ShowAlertType>>;
}

const PartnershipForm: React.FC<PartnershipFormProps> = ({
  isModalOpen,
  toggleIsOpenModal,
  projectPartnershipData,
  companyPartnershipData,
  availableRolesData,
  project,
  isManagingPartner,
  setIsManagingPartner,
  isCreate,
  selectedPartner,
  setSelectedPartnerId,
  // showAlert,
  // setShowAlert,
}) => {
  const [companyUsersData, setCompanyUsersData] = React.useState<
    CompanyUserType[]
  >([]);
  const [showAlert, setShowAlert] = useState<ShowAlertType>({
    show: false,
    message: "",
    type: "",
  });

  const DELAY = 3000;

  const fields = [
    {
      name: "name",
      label: "Nome",
      type: "select",
      options: [{ id: project.company.id, name: project.company.name}, ...companyPartnershipData].map((company) => ({
        value: company.id,
        label: company.name,
      })),
      required: true,
    },
    {
      name: "pic",
      label: "PIC",
      type: "text",
    },
    {
      name: "country",
      label: "Nazione",
      type: "text",
      required: true,
    },
    {
      name: "roleCode",
      label: "Ruolo",
      type: "select",
      options: availableRolesData.map((role) => ({
        value: role.code,
        label: role.name,
      })),
      required: true,
    },
    {
      name: "contactUserId",
      label: "Contatto principale",
      type: "select",
      options: companyUsersData.map((user) => ({
        value: user.id,
        label: `${user.firstName} ${user.lastName} (${user.email})`,
      })),
      required: true,
      depends: "name",
    },
  ] as const;
  const [formData, setFormData] = React.useState<
    Record<(typeof fields)[number]["name"], string | number>
  >(
    fields.reduce((acc, field) => {
      acc[field.name] = "";
      return acc;
    }, {} as Record<(typeof fields)[number]["name"], string | number>)
  );

  const resetForm = useCallback(() => {
    const blankFormData = {
      name: "",
      pic: "",
      country: "",
      roleCode: "",
      contactUserId: "",
    };
    setFormData(blankFormData);
  }, []);

  const resetAlert = () => {
    setShowAlert({
      show: false,
      message: "",
      type: "",
    });
  };

  useEffect(() => {
    if (!isCreate && selectedPartner) {
      setFormData({
        name: selectedPartner.companyId,
        pic: selectedPartner.pic || "",
        country: selectedPartner.country || "",
        roleCode: selectedPartner.roleCode,
        contactUserId: selectedPartner.contactUserId || "",
      });
    } else {
      resetForm();
    }
  }, [isCreate, selectedPartner, resetForm]);

  useEffect(() => {
    if (!formData.name) return;
    (async () => {
      try {
        const companyUsersData = await getUsersByCompanyId(
          formData.name as string
        );
        setCompanyUsersData(companyUsersData);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [formData.name]);
  // console.log("companyUsersData", companyUsersData);

  const [formErrors, setFormErrors] = React.useState<
    Record<(typeof fields)[number]["name"], boolean | string>
  >(
    fields.reduce((acc, field) => {
      acc[field.name] = false; // inizializza lo stato degli errori
      return acc;
    }, {} as Record<(typeof fields)[number]["name"], boolean | string>)
  );

  useEffect(() => {
    setFormErrors((prevFormErrors) => ({ ...prevFormErrors, name: false }));
  }, [selectedPartner]);

  // const [isManagingPartner, setIsManagingPartner] = React.useState(false);

  const isFormValid = () => {
    const errors: Record<string, string | boolean> = {};

    fields.forEach((field) => {
      if ("required" in field && field.required && !formData[field.name]) {
        errors[field.name] = "Campo obbligatorio";
      }
    });

    // Validazione della company
    if (
      isCreate &&
      projectPartnershipData.find((partner) => partner.id === formData.name)
    ) {
      errors.name = "Company già in uso";
    }

    setFormErrors(errors);

    return Object.keys(errors).length === 0; // Ritorna true solo se non ci sono errori
  };

  const handleOnSubmit = async () => {
    const isValid = isFormValid();
    // if (!isFormValid()) return;
    if (!isValid) return;

    const { name, pic, country, roleCode, contactUserId } = formData;

    // if (companyPartnershipData.find((partner) => partner.id === name)) {
    //   setFormErrors((prevErrors) => ({
    //     ...prevErrors,
    //     name: "Company già in uso",
    //   }));
    //   return;
    // }
    setIsManagingPartner(true);
    try {
      switch (isCreate) {
        case true:
          // const result = await addPartnersToProject(project.id.toString(), [
          await addPartnersToProject(project.id.toString(), [
            {
              companyId: name as number,
              pic: pic as string,
              country: country as string,
              roleCode: roleCode as string,
              contactUserId: contactUserId as number,
            },
          ]);
          // console.log("result", result);
          setShowAlert({
            show: true,
            message: "Partnership aggiunta con successo",
            type: "success",
          });
          setTimeout(() => {
            // setIsManagingPartner(false);
            toggleIsOpenModal();
            // setShowAlert({
            //   show: false,
            //   message: "",
            //   type: "",
            // });
            resetAlert();
            resetForm();
          }, DELAY);
          break;

        case false:
          // const result = await updateProjectPartner(
          await updateProjectPartner(
            project.id.toString(),
            selectedPartner?.companyId.toString() || "",
            {
              companyId: name as number,
              pic: pic as string,
              country: country as string,
              roleCode: roleCode as string,
              contactUserId: contactUserId as number,
            }
          );
          setShowAlert({
            show: true,
            message: "Partnership modificata con successo",
            type: "success",
          });
          setTimeout(() => {
            // setIsManagingPartner(false);
            setSelectedPartnerId(undefined);
            toggleIsOpenModal();
            resetForm();
            // setShowAlert({
            //   show: false,
            //   message: "",
            //   type: "",
            // });
          }, DELAY);
          // console.log("result", result);
          break;
      }
      resetForm();
    } catch (error) {
      console.error(error);
      setShowAlert({
        show: true,
        message: "Errore durante l'aggiunta della partnership",
        type: "danger",
      });
      // setTimeout(() => {
      //   setShowAlert({
      //     show: false,
      //     message: "",
      //     type: "",
      //   });
      // }, DELAY);
    } finally {
      setTimeout(() => {
        // setShowAlert({
        //   show: false,
        //   message: "",
        //   type: "",
        // });
        resetAlert();
        setIsManagingPartner(false);
        // setShowAlert({
        //   show: false,
        //   message: "",
        //   type: "",
        // });
      }, DELAY);
    }
    // console.log("name", name);
    // console.log("pic", pic);
    // console.log("country", country);
    // console.log("roleCode", roleCode);
    // console.log("contactUserId", contactUserId);
  };

  return (
    <Modal
      size="lg"
      show={isModalOpen}
      onHide={() => {
        toggleIsOpenModal();
        if (!isCreate) setSelectedPartnerId(undefined);
        // setShowAlert({
        //   show: false,
        //   message: "",
        //   type: "",
        // });
        resetAlert();
        resetForm();
      }}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{isCreate ? "Aggiungi" : "Modifica"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            {fields.map((field) => (
              <Form.Group
                as={Col}
                md={6}
                xs={12}
                key={field.name}
                className="mb-3"
              >
                <Form.Label htmlFor={field.name}>
                  {"required" in field && field.required ? (
                    <>
                      {field.label} <span className="required-field"> *</span>
                    </>
                  ) : (
                    field.label
                  )}
                </Form.Label>
                {/* renders a text field */}
                {field.type === "text" ? (
                  <Form.Control
                    type="text"
                    id={field.name}
                    value={formData[field.name]}
                    onChange={(event) => {
                      setFormData((prevFormData) => ({
                        ...prevFormData,
                        [field.name]: event.target.value,
                      }));
                      setFormErrors((prevErrors) => ({
                        ...prevErrors,
                        [field.name]: false,
                      }));
                    }}
                    isInvalid={!!formErrors[field.name]}
                    {...field}
                  />
                ) : null}
                {/* renders a select field */}
                {field.type === "select" ? (
                  <Form.Control
                    as="div"
                    id={field.name}
                    isInvalid={!!formErrors[field.name]}
                    className="p-0"
                    {...field}
                  >
                    <Select
                      value={field.options.find(
                        (option) => option.value === formData[field.name]
                      )}
                      onChange={(selectedOption: SelectOptionType) => {
                        setFormData((prevFormData) => ({
                          ...prevFormData,
                          [field.name]: selectedOption?.value || "", // Aggiorna con il valore selezionato
                        }));
                        setFormErrors((prevErrors) => ({
                          ...prevErrors,
                          [field.name]: false,
                        }));
                      }}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      placeholder="Seleziona..."
                      className="react-select-container"
                      styles={{
                        control: (base: CSSObject) => ({
                          ...base,
                          backgroundColor: "transparent",
                          border: "0 !important",
                        }),
                        indicatorsContainer: (base: CSSObject) => ({
                          ...base,
                          marginRight: formErrors[field.name]
                            ? "calc(1.5em + 0.75rem)"
                            : 0,
                        }),
                      }}
                      classNamePrefix="react-select"
                      options={field.options}
                      isDisabled={
                        "depends" in field && !formData[field.depends]
                      }
                    />
                  </Form.Control>
                ) : null}

                <Form.Control.Feedback type="invalid">
                  {typeof formErrors[field.name] === "string"
                    ? formErrors[field.name]
                    : "Campo obbligatorio"}
                </Form.Control.Feedback>
              </Form.Group>
            ))}
          </Row>
        </Form>
        {showAlert.show && (
          <Row className="pt-3">
            <Col className="col-12 p-0">
              <Alert
                show={showAlert.show}
                message={showAlert.message}
                variant={showAlert.type}
                delay={DELAY}
              />
            </Col>
          </Row>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleOnSubmit} disabled={isManagingPartner}>
          {isCreate ? "Aggiungi" : "Modifica"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PartnershipForm;
