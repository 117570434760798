import React, { useCallback, useState, useEffect } from 'react';
import { InputGroup, FormControl, Spinner } from "react-bootstrap";
import { getByPath } from "@lib/api";
import { Lens } from "@icons";
import { translate } from 'components/translation/translation';

const CustomQuickFilterBox = ({ tableRef, dataMeta, dataLength, setData, setDataMeta, isFirstResearch, setIsFirstResearch,
   refTable, isQuickFilterDisabled, isRefreshing, setIsRefreshing }) => {
   const [term, setTerm] = useState('');
   const [isLoading, setIsLoading] = useState(false);

   const fetchData = useCallback((ref, string, setSpinnerTemp) => {
      if (dataMeta && dataMeta.meta && dataMeta.meta.path) {
         let perPageRounded;

         if (dataMeta.meta.total > 500) {
            perPageRounded = Math.ceil(dataMeta.meta.total / 100) * 100;
         } else if (dataMeta.meta.total > 200) {
            perPageRounded = 500;
         } else if (dataMeta.meta.total > 100) {
            perPageRounded = 200;
         } else if (dataMeta.meta.total <= 100) {
            perPageRounded = 100;
         }

         const withPerPageUrl = dataMeta.meta.path + (dataMeta.meta.path.includes('?') ? '&' : '?') + `perPage=${perPageRounded}`;

         getByPath(withPerPageUrl).then((retrievedAllRows) => {
            setData(retrievedAllRows.data);

            const { data, success, ...restResp } = retrievedAllRows;
            if (setDataMeta) setDataMeta(restResp);

            ref.api.setQuickFilter(string);

            //Start Blocco: vai alla pagina che contiene i risultati
            let rowNodes = [];

            ref.api.forEachNodeAfterFilter((node) => {
               // Controlla se il nodo ha un indice di riga definito
               if (node.rowIndex !== undefined) {
                  rowNodes.push(node);
               }
            });

            // Se ci sono righe corrispondenti, calcola la pagina corretta
            if (rowNodes.length > 0) {
               let rowIndex = rowNodes[0].rowIndex;  // Prendi la prima corrispondenza
               let pageSize = ref.api.paginationGetPageSize();
               let pageIndex = Math.floor(rowIndex / pageSize);  // Calcola la pagina

               // Vai alla pagina che contiene i risultati
               ref.api.paginationGoToPage(pageIndex);
            }
            //End Blocco: vai alla pagina che contiene i risultati

            setTimeout(() => { 
               setSpinnerTemp(false);
            }, 2000);

            if (setIsRefreshing) setIsRefreshing(false);
         });

         let infoRowsPerTable = localStorage.getItem('rowsPerTable');
         infoRowsPerTable = infoRowsPerTable ? JSON.parse(infoRowsPerTable) : infoRowsPerTable;

         if (Array.isArray(infoRowsPerTable) && infoRowsPerTable.some(el => el.refTable === refTable)) {
            //se c'è già la ref
            const indexOfArray = infoRowsPerTable.findIndex(el => el.refTable === refTable);
            infoRowsPerTable[indexOfArray].perPage = perPageRounded;

            //disabled
            /* localStorage.setItem('rowsPerTable', JSON.stringify(infoRowsPerTable)); */
         } else {
            if (!Array.isArray(infoRowsPerTable)) { infoRowsPerTable = [] };
            infoRowsPerTable.push({ refTable: refTable, perPage: perPageRounded });

            //disabled
            /* localStorage.setItem('rowsPerTable', JSON.stringify(infoRowsPerTable)); */
         }
      }
   }, [dataMeta, refTable, setData, setDataMeta, setIsRefreshing]);

   useEffect(() => {
      if (isRefreshing && term && tableRef && tableRef.current) {
         fetchData(tableRef.current, term, setIsLoading);
      }
   }, [isRefreshing, term, fetchData, tableRef]);

   const onFilterTextBoxChanged = useCallback((e, gridRef, setSpinner) => {
      if (gridRef && gridRef.current && Object.keys(dataMeta).length > 0) {

         if (e.target.value) {
            if (dataLength !== dataMeta.meta.total && isFirstResearch) {
               fetchData(gridRef.current, e.target.value, setSpinner);
            } else {
               gridRef.current.api.setQuickFilter(e.target.value);
               setTimeout(() => { setSpinner(false) }, 2000);
            }
         } else if (e.target.value === '') {
            if (isFirstResearch) {
               getByPath(dataMeta.meta.path).then((retrievedRows) => {
                  setData(retrievedRows.data);

                  const { data, success, ...restResp } = retrievedRows;
                  if (setDataMeta) setDataMeta(restResp);

                  gridRef.current.api.setQuickFilter(e.target.value);
                  setTimeout(() => { setSpinner(false) }, 2000);
               });
            } else {
               gridRef.current.api.setQuickFilter(e.target.value);
               setTimeout(() => { setSpinner(false) }, 2000);
            }
         }
      }
   }, [dataLength, dataMeta, fetchData, isFirstResearch, setData, setDataMeta]);

   return (
      <InputGroup className="search-table align-items-center mt-md-0 ms-1">
         <FormControl
            type="text"
            disabled={isQuickFilterDisabled}
            className="filter-text-box"
            placeholder={translate('common.search', 'Cerca') + '...'}
            onInput={(e) => {
               setTerm(e.target.value);
               setIsFirstResearch(false);
               setIsLoading(true);
               setTimeout(() => { onFilterTextBoxChanged(e, tableRef, setIsLoading) }, 1500);
            }}
            value={term}
         />
         <div style={{ width: '40px', textAlign: 'center' }}>
            {isLoading ? <div style={{ marginRight: '5px', marginLeft: '5px' }}>
               <Spinner style={{ borderRadius: '50%' }} animation="border" role="status" variant="dark" size="sm" />
            </div>
               : <Lens className="lens" />
            }
         </div>
      </InputGroup>
   );
};
export default CustomQuickFilterBox;