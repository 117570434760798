import React, { useEffect, useState } from "react";
import PartnershipTable from "./PartnershipTable";
// import { useLocation } from "react-router-dom-v5-compat";
import {
  AvailableRoleType,
  getAvailableRoles,
  getPartnersByCompanyId,
  getPartnersByProjectId,
  PartnerByProjectIdType,
  // PartnerToAddType,
  PartnerType,
} from "../../../lib/api_ts/parternshipApi";
import { OETrans } from "components/translation/OETrans";
import PartnershipForm from "./PartnershipForm";

export type ShowAlertType = {
  show: boolean;
  message: string;
  type: "success" | "danger" | "";
};

export type ProjectPartnershipDataType = {
  id?: number;
  name: string;
  // acronym: string;
  pic: string;
  country: string;
  roleCode: string;
  contactUserId: string;
};

interface PartnershipTabProps {
  // companyId: number;
  project: {
    id: number;
    companyId: number;
    company: {
      id: number;
      name: string;
    }
  };
}

const PartnershipTab: React.FC<PartnershipTabProps> = (
  /*{ companyId }*/ { project }
) => {
  // console.log("companyId", companyId, typeof companyId);
  // const { pathname } = useLocation();
  // const [, , projectId] = pathname.split("/");
  // console.log("projectId", projectId);
  // console.log("project", project);
  // console.log("users", users);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isManagingPartner, setIsManagingPartner] = useState<boolean>(false);
  const [selectedPartnerId, setSelectedPartnerId] = useState<number>();
  // console.log("selectedPartnerId", selectedPartnerId);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const toggleIsOpenModal = () => {
    setIsModalOpen((prevModalOpen) => !prevModalOpen);
  };
  const [projectPartnershipData, setProjectPartnershipData] = useState<
    PartnerByProjectIdType[]
  >([]);

  // const [showAlert, setShowAlert] = useState<ShowAlertType>({
  //   show: false,
  //   message: "",
  //   type: "",
  // });
  // useEffect(() => {
  //   (async () => {
  //     setIsLoading(true);
  //     try {
  //       // const projectPartnershipDataFromAPI = await getPartnersByProjectId(projectId);
  //       const projectPartnershipDataFromAPI = await getPartnersByProjectId(
  //         project.id.toString()
  //       );

  //       setprojectPartnershipData(projectPartnershipDataFromAPI);
  //     } catch (error) {
  //       console.error(error);
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   })();
  // }, [project.id]);

  const [companyPartnershipData, setCompanyPartnershipData] = useState<
    PartnerType[]
  >([]);
  // useEffect(() => {
  //   (async () => {
  //     setIsLoading(true);
  //     try {
  //       const companyPartnershipDataFromAPI = await getPartnersByCompanyId(
  //         project.companyId.toString()
  //       );
  //       setCompanyPartnershipData(companyPartnershipDataFromAPI);
  //     } catch (error) {
  //       console.error(error);
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   })();
  // }, [project.companyId]);

  const [availableRolesData, setAvailableRolesData] = useState<
    AvailableRoleType[]
  >([]);
  // useEffect(() => {
  //   (async () => {
  //     setIsLoading(true);
  //     try {
  //       const rolesFromAPI = await getAvailableRoles();
  //       setAvailableRoles(
  //         rolesFromAPI.map((role) => ({
  //           value: role.code,
  //           label: role.name,
  //         }))
  //       );
  //     } catch (error) {
  //       console.error(error);
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   })();
  // }, []);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const [/*projectData,*/ companyData, rolesData] = await Promise.all([
          // getPartnersByProjectId(project.id.toString()),
          getPartnersByCompanyId(project.companyId.toString()),
          getAvailableRoles(),
        ]);

        // setProjectPartnershipData(projectData);
        setCompanyPartnershipData(companyData);
        setAvailableRolesData(
          rolesData
          // .map((role) => ({
          //   value: role.code,
          //   label: role.name,
          // }))
        );
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [/*project.id,*/ project.companyId]);
  const firstRenderRef = React.useRef(true);
  useEffect(() => {
    (async () => {
      if (firstRenderRef.current) {
        setIsLoading(true);
      }
      try {
        const projectData = await getPartnersByProjectId(project.id.toString());

        setProjectPartnershipData(projectData);
      } catch (error) {
        console.error(error);
      } finally {
        if (firstRenderRef.current) {
          setIsLoading(false);
          firstRenderRef.current = false;
        }
      }
    })();
  }, [project.id, project.companyId, isManagingPartner]);

  const selectedPartner = projectPartnershipData.find(
    (partner) => partner.id === selectedPartnerId
  );

  // console.log("selectedPartner", selectedPartner);

  // console.log("projectPartnershipData", projectPartnershipData);
  // console.log("availableRoles", availableRolesData);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">
            <OETrans
              code="common.loading"
              fallback={"Caricamento"}
              type={undefined}
            />
            ...
          </span>
        </div>
      </div>
    );
  }

  const rowData: ProjectPartnershipDataType[] = projectPartnershipData.map(
    (partner) => ({
      id: partner.id,
      name: partner.company.name,
      acronym: partner.company.code,
      pic: partner.pic,
      country: partner.country,
      roleCode: partner.role.code,
      contactUserId: partner.contact.name,
    })
  );

  const isCreate = selectedPartnerId === undefined;
  //  [
  //   {
  //     id: 0,
  //     name: "Nome 1",
  //     acronym: "ACR",
  //     pic: "PIC",
  //     nation: "ITA",
  //     role: "Ruolo",
  //     mainContact: "Contatto",
  //   },
  //   {
  //     id: 1,
  //     name: "Nome 2",
  //     acronym: "ACR",
  //     pic: "PIC",
  //     nation: "ITA",
  //     role: "Ruolo",
  //     mainContact: "Contatto",
  //   },
  //   {
  //     id: 2,
  //     name: "Nome 3",
  //     acronym: "ACR",
  //     pic: "PIC",
  //     nation: "ITA",
  //     role: "Ruolo",
  //     mainContact: "Contatto",
  //   },
  // ];
  return (
    <>
      <PartnershipTable
        projectPartnershipData={rowData}
        toggleIsOpenModal={toggleIsOpenModal}
        selectedPartnerId={selectedPartnerId}
        setSelectedPartnerId={setSelectedPartnerId}
        projectId={project.id}
        setIsManagingPartner={setIsManagingPartner}
        availableRolesData={availableRolesData}
        // showAlert={showAlert}
        // setShowAlert={setShowAlert}
      />
      <PartnershipForm
        projectPartnershipData={rowData}
        isModalOpen={isModalOpen}
        toggleIsOpenModal={toggleIsOpenModal}
        companyPartnershipData={companyPartnershipData}
        availableRolesData={availableRolesData}
        project={project}
        isManagingPartner={isManagingPartner}
        setIsManagingPartner={setIsManagingPartner}
        isCreate={isCreate}
        // showAlert={showAlert}
        // setShowAlert={setShowAlert}
        {...(!isCreate
          ? {
              selectedPartner: {
                companyId: selectedPartner.company.id,
                pic: selectedPartner.pic,
                country: selectedPartner.country,
                roleCode: selectedPartner.role.code,
                contactUserId: selectedPartner.contact.id,
              },
              setSelectedPartnerId,
            }
          : {})}
      />
    </>
  );
};

export default PartnershipTab;
