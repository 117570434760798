import { defaultFetch } from 'lib/api_ts/baseApi';

type UserForResponsibleType = {
  id: string
  email: string
  firstName: string
  lastName: string
}

export const getUsersForResponsible = async (companyId: string) => {
  return defaultFetch<UserForResponsibleType[]>(`/v0/companies/${companyId}/users`);
};
