import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import {
   Button,
   OverlayTrigger,
   Tooltip,
   Modal,
   Form,
   Row,
   Col,
   Spinner,
   Accordion,
} from 'react-bootstrap';
import { getEntity } from '@lib/api';
import { IcoEdit, IcoReported } from '@icons';
import CustomTooltip from '@components/ag-grid/CustomTooltip';
import UploadDocument from 'components/form/upload/uploadDocuments';
import CheckList from 'components/checklist/checkList';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
import { dateFormatter, formatToDecimals } from '@lib/helpers/formatting';
import { columnCentered, columnToRight } from '@lib/helpers/agGridHelpers';
import { LevelContext } from 'components/contexts/LevelContext';
import { months } from '@lib/helpers/calendar';
import OESimpleTable from 'components/oetable/OESimpleTable';
import { OETrans } from 'components/translation/OETrans';

const ProjectTimesheetModal = ({
   data,
   setRefresh,
   modalIsVisible,
   setModalIsVisible,
   isSummary,
   spinnerVisible,
   setModalData,
   isProjectSco,
}) => {
   const { /* isOe, */ userRole } = useContext(LevelContext);
   const { projectId } = useParams();
   const [timesheet, setTimesheet] = useState({});
   const [showModalEdit, setShowModalEdit] = useState(false);
   const [isEdited, setIsEdited] = useState(false);
   const [columnDef, setColumnDef] = useState([]);
   const [isOpenDetails, setIsOpenDetails] = useState(false);
   //console.log('timesheet.details', timesheet?.details);

   useEffect(() => {
      if ((modalIsVisible || (modalIsVisible === undefined && showModalEdit)) && projectId && data.id) {
         getEntity('timesheets', projectId, data.id).then((retrievedTimesheet) => {
            if (retrievedTimesheet.success) {
               setTimesheet(retrievedTimesheet.data);
            }
         });
      }
   }, [data, modalIsVisible, projectId, showModalEdit]);

   const ReportedCheckRenderer = useCallback((params) => {
      const reported = params?.data?.reported;

      const reportedTooltip = (props) => (
         <Tooltip {...props}>Rendicontato</Tooltip>
      );

      return (
         <>
            {reported && (
               <OverlayTrigger placement="top" overlay={reportedTooltip}>
                  <Row className="align-items-center justify-content-center h-100 px-0">
                     <Col className="col-auto text-center">
                        <IcoReported />
                     </Col>
                  </Row>
               </OverlayTrigger>
            )}
         </>
      );
   }, []);

   useEffect(() => {
      setColumnDef([
         {
            headerName: "R",
            headerTooltip: 'Rendicontato',
            tooltipComponent: CustomTooltip,
            field: "reported",
            cellRenderer: ReportedCheckRenderer,
            cellRendererParams: {
               queryParam: "reported",
            },
            minWidth: 70,
            maxWidth: 70,
            //flex: 1,
            resizable: false,
            ...columnCentered(),
         },
         {
            headerName: 'Anno',
            field: 'year',
            //filter: 'agTextColumnFilter',
            maxWidth: 120,
            minWidth: 100,
            ...columnCentered(),
         },
         {
            headerName: 'Mese',
            field: 'month',
            cellRenderer: (params) => {
               return months()[params.value - 1];
            },
            //filter: 'agTextColumnFilter',
            maxWidth: 140,
            minWidth: 120,
            ...columnCentered(),
         },
         {
            headerName: 'Giorno',
            field: 'day',
            //filter: 'agTextColumnFilter',
            // cellRenderer: ChecklistCellRenderer,

            maxWidth: 130,
            minWidth: 110,
            ...columnCentered(),
         },
         {
            headerName: 'Ore Totali',
            field: 'workHours',
            valueGetter: (params) => {
               return params.data.workHours ? params.data.workHours.toString().replace(".", ',') : '';
            },
            //filter: 'agTextColumnFilter',
            ...columnCentered(),
            maxWidth: 140,
            minWidth: 120,
         },
         {
            headerName: 'Ore Sul Progetto',
            field: 'projectHours',
            valueGetter: (params) => {
               return params.data.projectHours ? params.data.projectHours.toString().replace(".", ',') : '';
            },
            ...columnCentered(),
            maxWidth: 190,
            minWidth: 170,
            //filter: 'agTextColumnFilter',
         },
         {
            headerName: 'Costo Orario €',
            field: 'hourlyRate',
            valueGetter: (params) => {
               return formatToDecimals(params.data.hourlyRate);
            },
            filter: 'agTextColumnFilter',
            //cellStyle: { textAlign: 'right', alignItems: 'center' },
            maxWidth: 190,
            minWidth: 170,
            ...columnToRight(),
         },
         {
            headerName: 'Costo sul Progetto €',
            field: 'projectCost',
            filter: 'agTextColumnFilter',
            valueGetter: (params) => {
               return formatToDecimals(params.data.projectCost);
            },
            //cellStyle: { textAlign: 'right', alignItems: 'center' },
            maxWidth: 240,
            minWidth: 220,
            ...columnToRight(),
         },
         {
            headerName: 'Resp. budget',
            field: 'budgetResponsability',
            filter: 'agTextColumnFilter',
            minWidth: 210,
            maxWidth: 240,
            valueGetter: (params) => {
               return params.data.budgetResponsability ?? 'N.D.';
            },
         },
         {
            headerName: 'Ordine',
            field: 'orderNumber',
            filter: 'agTextColumnFilter',
            minWidth: 210,
            maxWidth: 240,
            valueGetter: (params) => {
               return params.data.orderNumber ?? 'N.D.';
            },
         },
         {
            headerName: 'WBE',
            field: 'wbe',
            filter: 'agTextColumnFilter',
            minWidth: 210,
            maxWidth: 240,
            valueGetter: (params) => {
               return params.data.wbe ?? 'N.D.';
            },
         },
         {
            headerName: 'CID',
            field: 'cid',
            filter: 'agTextColumnFilter',
            minWidth: 210,
            maxWidth: 240,
            valueGetter: (params) => {
               return params.data.cid ?? 'N.D.';
            },
         }
         /*{
            headerName: 'Codice Impianto',
            field: 'plantCode',
            filter: 'agTextColumnFilter',
            minWidth: 210,
            valueGetter: (params) => {
               return params.data.plant?.plantCode ?? 'N.D.';
            },
         },
         {
            headerName: 'Tipo Impianto',
            field: 'plantType',
            filter: 'agTextColumnFilter',
            minWidth: 210,
            valueGetter: (params) => {
               return params.data.plant?.plantType ?? 'N.D.';
            },
         },
         {
            headerName: 'Denominazione Impianto',
            field: 'plantName',
            tooltipField: 'plantName',
            filter: 'agTextColumnFilter',
            minWidth: 250,
            valueGetter: (params) => {
               return params.data.plant?.plantName ?? 'N.D.';
            },
         },
         {
            headerName: 'Codice Impianto Primario',
            field: 'plantCode',
            filter: 'agTextColumnFilter',
            minWidth: 250,
            valueGetter: (params) => {
               return params.data.parentPlant?.plantCode ?? 'N.D.';
            },
         },
         {
            headerName: 'Tipo Impianto Primario',
            field: 'plantType',
            tooltipField: 'plantType',
            filter: 'agTextColumnFilter',
            minWidth: 240,
            valueGetter: (params) => {
               return params.data.parentPlant?.plantType ?? 'N.D.';
            },
         },
         {
            headerName: 'Denominazione Impianto Primario',
            field: 'plantName',
            tooltipField: 'plantName',
            filter: 'agTextColumnFilter',
            minWidth: 320,
            valueGetter: (params) => {
               return params.data.parentPlant?.plantName ?? 'N.D.';
            },
         },*/
      ]);
   }, [ReportedCheckRenderer]);

   const handleCloseModal = (setter) => {
      if (isEdited) {
         setRefresh(true);
         setIsEdited(false);
      }
      if (isSummary) {
         setModalData([]);
         setModalIsVisible(false);
      } else {
         setter(false);
      }
   };

   const handleShowModal = (setter) => {
      if (isSummary) {
         setModalIsVisible(true);
      } else {
         setter(true);
      }
   };
   const whichStateToUse = isSummary ? modalIsVisible : showModalEdit;

   const editTooltip = (props) => <Tooltip {...props}>Dettaglio</Tooltip>;

   return (
      <div>
         {!isSummary && (
            <OverlayTrigger placement='top' overlay={editTooltip}>
               <Button variant='link' className='me-3' onClick={() => handleShowModal(setShowModalEdit)}>
                  <IcoEdit className='second-color path ico-small edit' />
               </Button>
            </OverlayTrigger>
         )}

         {whichStateToUse && (
            <Modal
               fullscreen
               className='modal-with-nav'
               show={whichStateToUse}
               onHide={() => handleCloseModal(setShowModalEdit)}
               centered>
               <Modal.Header closeButton />
               <Modal.Body className='overflow-hidden'>
                  {spinnerVisible ? (
                     <Row>
                        <Col className='d-flex justify-content-center align-items-center'>
                           <Spinner animation='border' role='status' variant='dark'>
                              <span className='visually-hidden'>Loading...</span>
                           </Spinner>
                        </Col>
                     </Row>
                  ) : (timesheet.id &&
                     <>
                        <div className='workpanel-container'>
                           <Accordion className='workpanel-top' id="detail-modal-accordion" flush>
                              <Accordion.Item eventKey='0' className='accordionBorder'>
                                 <Accordion.Header className='expanded-accordion title-fixed'>
                                    <h4 className='mb-0 me-2'>Record {Number(userRole) === 1 || Number(userRole) <= 30 ? timesheet?.id : ''}</h4>
                                    <span
                                       className="fake-link-detail ms-2"
                                       {...(isOpenDetails ? { style: { color: 'white', fontWeight: 600 } } : {})}
                                    >
                                       dettagli
                                    </span>
                                 </Accordion.Header>

                                 <Accordion.Body /* className="border-bottom" */
                                    onEnter={() => setIsOpenDetails(true)}
                                    onExit={() => setIsOpenDetails(false)}
                                 >
                                    <div /* className='overflow-auto' */>
                                       <Row className='mb-2rem mt-3'>
                                          <Form.Group as={Col} className='col-2 px-4'>
                                             <Form.Label className='mb-3'>CUP</Form.Label>
                                             <Form.Text as='p'>{timesheet?.project?.cup ?? 'N.D.'}</Form.Text>
                                          </Form.Group>

                                          <Form.Group as={Col} className='col-2 px-4'>
                                             <Form.Label className='mb-3'>Stream</Form.Label>
                                             <Form.Text as='p'>
                                                {timesheet?.stream?.streamId
                                                   ? `${timesheet.stream.streamId} - ${timesheet.stream.name}`
                                                   : 'N.D.'}
                                             </Form.Text>
                                          </Form.Group>

                                          <Form.Group as={Col} className='col-2 px-4'>
                                             <Form.Label className='mb-3'>Sottovoce di Spesa</Form.Label>
                                             <Form.Text as='p'>
                                                {timesheet?.projectExpenseItem?.name ?? 'N.D.'}
                                             </Form.Text>
                                          </Form.Group>

                                          {/*<Form.Group as={Col} className='col-2 px-4'>
                                             <Form.Label className='mb-3'>Resp. budget</Form.Label>
                                             <Form.Text as='p'>{timesheet?.details?.budgetResponsability ?? 'N.D.'}</Form.Text>
                                          </Form.Group>}*/}

                                          {/*<Form.Group as={Col} className='col-2 px-4'>
                                             <Form.Label className='mb-3'>Ordine</Form.Label>
                                             <Form.Text as='p'>{timesheet?.details?.orderNumber ?? 'N.D.'}</Form.Text>
                                          </Form.Group>*/}

                                          {/*<Form.Group as={Col} className='col-2 px-4'>
                                             <Form.Label className='mb-3'>WBE</Form.Label>
                                             <Form.Text as='p'>{timesheet?.wbe ?? 'N.D.'}</Form.Text>
                                          </Form.Group>*/}

                                          <Form.Group as={Col} className='col-2 px-4'>
                                             <Form.Label className='mb-3'>Nr. Record</Form.Label>
                                             <Form.Text as='p'>{timesheet.number ?? 'N.D.'}</Form.Text>
                                          </Form.Group>

                                          {/*<Form.Group as={Col} className='col-2 px-4'>
                                             <Form.Label className='mb-3'>CID</Form.Label>
                                             <Form.Text as='p'>{timesheet?.details?.cid ?? 'N.D.'}</Form.Text>
                                          </Form.Group>*/}

                                          <Form.Group as={Col} className='col-2 px-4'>
                                             <Form.Label className='mb-3'>Matricola</Form.Label>
                                             <Form.Text as='p'>{timesheet.serialNumber ?? 'N.D.'}</Form.Text>
                                          </Form.Group>

                                          {!isProjectSco && (
                                             <Form.Group as={Col} className='col-2 px-4'>
                                                <Form.Label className='mb-3'>Cognome</Form.Label>
                                                <Form.Text as='p'>{timesheet?.employee?.surname ?? 'N.D.'}</Form.Text>
                                             </Form.Group>
                                          )}

                                          {!isProjectSco && (
                                             <Form.Group as={Col} className='col-2 px-4'>
                                                <Form.Label className='mb-3'>Nome</Form.Label>
                                                <Form.Text as='p'>{timesheet?.employee?.name ?? 'N.D.'}</Form.Text>
                                             </Form.Group>
                                          )}

                                          <Form.Group as={Col} className='col-2 px-4'>
                                             <Form.Label className='mb-3'>Unità Operativa</Form.Label>
                                             <Form.Text as='p'>{timesheet?.operationalUnit ?? 'N.D.'}</Form.Text>
                                          </Form.Group>

                                          <Form.Group as={Col} className='col-2 px-4'>
                                             <Form.Label className='mb-3'>Società</Form.Label>
                                             <Form.Text as='p'>{timesheet?.company ?? 'N.D.'}</Form.Text>
                                          </Form.Group>

                                          <Form.Group as={Col} className='col-2 px-4'>
                                             <Form.Label className='mb-3'>Data Ingresso</Form.Label>
                                             <Form.Text as='p'>{timesheet?.entryDate ?? 'N.D.'}</Form.Text>
                                          </Form.Group>

                                          <Form.Group as={Col} className='col-2 px-4'>
                                             <Form.Label className='mb-3'>Data Uscita</Form.Label>
                                             <Form.Text as='p'>{timesheet?.exitDate ?? 'N.D.'}</Form.Text>
                                          </Form.Group>

                                          <Form.Group as={Col} className='col-2 px-4'>
                                             <Form.Label className='mb-3'>Categoria</Form.Label>
                                             <Form.Text as='p'>{timesheet.category ?? 'N.D.'}</Form.Text>
                                          </Form.Group>

                                          <Form.Group as={Col} className='col-2 px-4'>
                                             <Form.Label className='mb-3'>Malattia</Form.Label>
                                             <Form.Text as='p'>{timesheet?.sickDays ?? 'N.D.'}</Form.Text>
                                          </Form.Group>

                                          <Form.Group as={Col} className='col-2 px-4'>
                                             <Form.Label className='mb-3'>Ferie</Form.Label>
                                             <Form.Text as='p'>{timesheet?.vacationDays ?? 'N.D.'}</Form.Text>
                                          </Form.Group>

                                          <Form.Group as={Col} className='col-2 px-4'>
                                             <Form.Label className='mb-3'>Permessi</Form.Label>
                                             <Form.Text as='p'>{timesheet?.leaveDays ?? 'N.D.'}</Form.Text>
                                          </Form.Group>

                                          <Form.Group as={Col} className='col-2 px-4'>
                                             <Form.Label className='mb-3'>Fattispecie RSI</Form.Label>
                                             <Form.Text as='p'>{timesheet.rdiTypeCode ?? 'N.D.'}</Form.Text>
                                          </Form.Group>

                                          <Form.Group as={Col} className='col-2 px-4'>
                                             <Form.Label className='mb-3'>Avvio Progetto</Form.Label>
                                             <Form.Text as='p'>{timesheet?.project?.startDate ? dateFormatter(timesheet?.project?.startDate) : 'N.D.'}</Form.Text>
                                          </Form.Group>

                                          <Form.Group as={Col} className='col-2 px-4' />
                                          <Form.Group as={Col} className='col-2 px-4' />
                                       </Row>

                                       <Row>
                                          <h2>Dettaglio</h2>
                                          {timesheet?.details && timesheet?.details.length > 0 ? (
                                             <div><OESimpleTable
                                                columnDefs={columnDef}
                                                rowData={timesheet?.details}
                                                buttonToolbarVisible={false}
                                                pageSizeId={'payment_timesheets_details'}
                                             /></div>
                                          ) : (
                                             <Row className='text-center my-5'>
                                                <span>Non sono presenti fatture.</span>
                                             </Row>
                                          )}
                                       </Row>
                                    </div>
                                 </Accordion.Body>
                              </Accordion.Item>
                           </Accordion>

                           <div className='workpanel-left border-tab right'>
                              <UploadDocument entity='timesheets' entityId={timesheet.id} projectId={projectId} disableEdit={Number(userRole) > 40 && isSummary} />
                           </div>

                           <div className='workpanel-right pe-0'>
                              <CheckList
                                 entityType='timesheets'
                                 entityId={timesheet.id}
                                 disabled={(timesheet?.reported || timesheet?.reporting) || Number(userRole) > 40}
                              />
                           </div>
                        </div>
                     </>
                  )}
               </Modal.Body>

               <Modal.Footer>
                  <Button variant='secondary' onClick={() => handleCloseModal(setShowModalEdit)}>
                     Chiudi
                  </Button>
                  {!isSummary || /* isOe || */ userRole < 50 ? (
                     <Button
                        type='submit'
                        form='checklist-timesheets'
                        onClick={() => setIsEdited(true)}
                        variant='primary'
                        disabled={timesheet?.reported || timesheet?.reporting}>
                        <OETrans code='common.edit' fallback={'Modifica'} />
                     </Button>
                  ) : (
                     ''
                  )}
               </Modal.Footer>
            </Modal>
         )}
      </div>
   );
};

export default ProjectTimesheetModal;
