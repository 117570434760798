import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
   Button,
   OverlayTrigger,
   Tooltip,
   Modal,
   Form,
   Row,
   Col,
   Tab,
   Tabs,
   Spinner,
   FormControl,
} from 'react-bootstrap';
import {
   // getStatementInvoicesOrTimesheets,
   // pickUnpickProjectStatement,
   // pickUnpickAllProjectStatement,
   getToSettleChecklistFilter,
   getStatementAnalysisInvoices,
   getProjectStatementAmounts,
   getProjectStatementGeneralExpenses,
   // downloadProjectStatementInvoices,
   settleAnalysisInvoices,
   editProjectStatement,
   getProjectStatement,
   deleteStatement,
   refreshProjectStats,
} from "@lib/api";
import Select from "react-select";
import moment from "moment";
import { IcoEdit, IcoCanc, IcoRefresh, IcoCheck, IcoLens } from "@icons";
import StatusBar from "@components/StatusBar";
import { InputNumber } from "primereact";
import { /*customCellStyle,*/ columnCentered } from "@lib/helpers/agGridHelpers";
import { formatToDecimals, dateFormatter, dateFormatterInput } from "@lib/helpers/formatting";
import OETableAgGrid from "components/oetable/OETableAgGrid";
// import { months } from "@lib/helpers/calendar";
import CheckDocTab from "@components/projectStatements/CheckDocTab";
import DataHeader from "components/DataHeader";
import UploadDocument from "components/form/upload/uploadDocuments";
// import DownloadExcelButton from '@components/form/DownloadExcelButton';
import { translate } from "components/translation/translation";
import { OETrans } from "components/translation/OETrans";
import CheckInvoice from "./CheckInvoice";
import ValidateSettleModal from "./ValidateSettleModal";
import { ExpenseReportInternalCosts, ExpenseReportExternalCosts } from "components/atomic/organisms";
// import ExpenseReportExternalCosts from "components/atomic/organisms/ProjectReporting/ExpenseReportExternalCosts";
import { TooltipInfoIcon } from 'icone/index';
import { debounce } from '@lib/helpers/debounce';
import { updateAdvancedHelpPercentageIntensity } from '@lib/api/index';

const MakeReportingModal = ({
   data,
   isOe,
   projectId,
   newStatement,
   setRefresh,
   isShowNew,
   setIsShowNew,
   localLang,
   optionsStatementTypes,
   refreshTotals,
   isAdvancedSettings,
}) => {
   const [detail, setDetail] = useState({});
   const [amounts, setAmounts] = useState({ total: 0, requestedAmount: 0 });
   // const [timesheets, setTimesheets] = useState(null);
   // const [timesheetsMeta, setTimesheetsMeta] = useState({});
   // const [invoices, setInvoices] = useState(null);
   // const [invoicesMeta, setInvoicesMeta] = useState({});
   const [analysisInvoices, setAnalysisInvoices] = useState(null);
   const [analysisInvoicesMeta, setAnalysisInvoicesMeta] = useState({});
   // const [invoicesColumn, setInvoicesColumn] = useState([]);
   const [invoicesReportColumn, setInvoicesReportColumn] = useState([]);
   // const [timesheetsColumn, setTimesheetsColumn] = useState([]);
   // const [isAllSelected, setIsAllSelected] = useState({ invoices: { condition: false, action: '' }, timesheets: { condition: false, action: '' } });
   const [generalExpenses, setGeneralExpenses] = useState([]);
   const [selectedQuotaId, setSelectedQuotaId] = useState(null);
   // const [originCheckedInvoices, setOriginCheckedInvoices] = useState([]);
   // const [originCheckedTimesheets, setOriginCheckedTimesheets] = useState([]);
   const [firstValueFilter, setFirstValueFilter] = useState('');
   const [secondValueFilter, setSecondValueFilter] = useState([]);
   const [thirdValueFilter, setThirdValueFilter] = useState([]);
   const [optionsToSettleChecklist, setOptionsToSettleChecklist] = useState({ settleCl: [], isLoading: false });
   const [selectedQuotas, setSelectedQuotas] = useState([]);
   const [selectedQuestions, setSelectedQuestions] = useState({ answers: [] });
   const [showModal, setShowModal] = useState(
      typeof isShowNew === "boolean" ? isShowNew : false
   );
   const [showModalDelete, setShowModalDelete] = useState(false);
   const [showModalValidate, setShowModalValidate] = useState(false);
   const [showModalInfo, setShowModalInfo] = useState(false);
   const [showModalValidateSettle, setShowModalValidateSettle] = useState(false);
   const [activeKey, setActiveKey] = useState(
      data && Object.values(data).length > 0 && data.state === "VALIDATED"
         ? "inputs"
         : data?.statementTypeCode === "ADV" ||
            newStatement?.statementTypeCode === "ADV"
            ? "documents"
            : "invoices"
   );
   const [sbShow, setSbShow] = useState(<></>);
   const [sbShowQuestions, setSbShowQuestions] = useState(<></>);
   const [sbShowValidate, setSbShowValidate] = useState(<></>);
   const [isValidated, setIsValidated] = useState(false);
   const [isLoadingAmount, setIsLoadingAmount] = useState(false);
   const [isLoadingPickUnpick, setIsLoadingPickUnpick] = useState(false);
   const [indirectCosts, setIndirectCosts] = useState(0);
   const [isLoadingIndirectCosts, setIsLoadingIndirectCosts] = useState(false);
   const [isLoadingAnalysisInvoices, setIsLoadingAnalysisInvoices] = useState(false);
   const [isFiltered, setIsFiltered] = useState(false);
   const [errorAdv, setErrorAdv] = useState({ isAdv: false, isError: false });
   const [helpIntensityPercentageDetail, setHelpIntensityPercentageDetail] = useState({ helpPercentageIntensity: 0 });
   console.log('detail', detail);
   console.log('helpIntensityPercentageDetail', helpIntensityPercentageDetail);

   // console.log("selectedQuotas", selectedQuotas);
   // console.log("selectedQuotaId", selectedQuotaId);

   const handleRefreshDataAmount = useCallback(() => {
      if (detail.id) {
         if (detail.statementTypeCode === "ADV") {
            setTimeout(() => {
               setIsLoadingAmount(false);
            }, 500);
            return;
         }

         if (projectId && detail.id) {
            getProjectStatementAmounts(projectId, detail.id).then((retrievedElement) => {
               if (retrievedElement.success) {
                  //console.log('retrievedElement', retrievedElement);
                  setAmounts(retrievedElement.data);
                  setIsLoadingAmount(false);
               }
            });
         }
      }
   }, [detail.id, detail.statementTypeCode, projectId]);

   useEffect(() => {
      if (showModal && (data || newStatement)) {
         const tempDetail = data ?? newStatement;
         setDetail(tempDetail);

         setHelpIntensityPercentageDetail({
            helpPercentageIntensity: tempDetail.helpPercentageIntensity || 0,
         });
         if (tempDetail.id) {
            handleRefreshDataAmount();
         } else {
            setAmounts({
               total: tempDetail.amount,
               requestedAmount: tempDetail.requestedAmount,
               totalEligibles: tempDetail.totalEligibles,
               totalToSettle: tempDetail.totalToSettle,
            });
         }
      }
      setIndirectCosts(data?.indirectCost ?? newStatement?.indirectCost ?? 0);
   }, [showModal, data, handleRefreshDataAmount, newStatement]);

   useEffect(() => {
      if (detail.state) {
         setIsValidated(detail.state === 'VALIDATED' ? true : false);
      }
   }, [detail.state]);

   useEffect(() => {
      if (detail.statementTypeCode === "ADV") return;
      if (projectId && detail.id) {
         setIsLoadingIndirectCosts(true);
         getProjectStatement(projectId, detail.id).then((retrievedElement) => {
            if (retrievedElement.success) {
               setIndirectCosts(retrievedElement.data.indirectCost);
            }
            setIsLoadingIndirectCosts(false);
         });
      }
   }, [detail.id, detail.statementTypeCode, projectId]);

   useEffect(() => {
      if (showModal && projectId && detail.id) {
         handleRenderData();
         setSbShow(<></>);
         setSbShowQuestions(<></>);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [projectId, detail.id, showModal]);

   const fetchSettleChecklist = async (secondValue) => {
      setOptionsToSettleChecklist({ settleCl: [], isLoading: true });
      let queryParamsString = `${firstValueFilter && 'rp_code=' + firstValueFilter.trim() + '&'
         }type=${secondValue}`;

      getToSettleChecklistFilter(projectId, detail.id, queryParamsString).then((retrievedCl) => {
         setOptionsToSettleChecklist({
            settleCl: retrievedCl
               ? retrievedCl.map((cl) => ({
                  value: cl.code,
                  label: `${cl.code} ${cl.title}`,
               }))
               : [],
            isLoading: false,
         });
      });
   };

   const handleRenderData = useCallback(k => {
      const key = k ?? 'invoices';

      if (detail.statementTypeCode === 'ADV') return;

      // if (key === "invoices") {
      //    getStatementInvoicesOrTimesheets(
      //       projectId,
      //       detail.id,
      //       "invoices",
      //       "invoicesStatements"
      //    ).then((retrievedElements) => {
      //       //Confronta lo stato attuale con il nuovo stato prima di continuare
      //       if (retrievedElements.success && JSON.stringify(retrievedElements.data) !== JSON.stringify(invoices)) {

      //          setInvoices(retrievedElements.data);
      //          const { data, success, ...restResp } = retrievedElements;

      //          setInvoicesMeta(restResp);
      //          setOriginCheckedInvoices(retrievedElements.data.filter((el) => el.checked));
      //       }
      //    });
      // }

      // if (key === "timesheets") {
      //    getStatementInvoicesOrTimesheets(
      //       projectId,
      //       detail.id,
      //       "timesheets",
      //       "timesheetsStatements"
      //    ).then((retrievedElements) => {
      //       //Confronta lo stato attuale con il nuovo stato prima di continuare
      //       if (retrievedElements.success && JSON.stringify(retrievedElements.data) !== JSON.stringify(timesheets)) {
      //          setTimesheets(retrievedElements.data);
      //          const { data, success, ...restResp } = retrievedElements;

      //          setTimesheetsMeta(restResp);
      //          setOriginCheckedTimesheets(retrievedElements.data.filter((el) => el.checked));
      //       }
      //    });
      // }

      if (key === 'general-expenses') {
         getProjectStatementGeneralExpenses(projectId, detail.id).then(
            (retrievedGeneralExpenses) => {
               if (retrievedGeneralExpenses.success) {
                  setGeneralExpenses(retrievedGeneralExpenses.data.statementGe);
               }
            }
         );
      }

   }, [detail.id, detail.statementTypeCode, /*invoices,*/ projectId /*, timesheets*/]);

   const handleAnalysisInvoices = async () => {
      let queryParamsString = `${firstValueFilter ? ('rp_code=' + firstValueFilter.trim() + (secondValueFilter.length > 0 || thirdValueFilter.length > 0 ? '&' : '')) : ''}${secondValueFilter.length > 0 ? ('type=' + secondValueFilter[0].value + (firstValueFilter || thirdValueFilter.length > 0 ? '&' : '')) : ''}${thirdValueFilter.length > 0 ? thirdValueFilter.map(rc => 'rc[]=' + rc.value).join('&') : ''}`;
      setIsLoadingAnalysisInvoices(true);

      getStatementAnalysisInvoices(projectId, detail.id, queryParamsString, 'recordAnalysis').then(
         (retrievedResults) => {
            if (retrievedResults.success) {
               setAnalysisInvoices(retrievedResults.data);
               const { data, success, ...restResp } = retrievedResults;
               setAnalysisInvoicesMeta(restResp);
               setIsFiltered(true);
            }

            setIsLoadingAnalysisInvoices(false);
         }
      );
   };

   const handleSettleAnalysisInvoices = async () => {
      //valida tutte le quote selezionate
      let queryParamsString = `${firstValueFilter || secondValueFilter.length > 0 || thirdValueFilter.length > 0 ? '?' : ''}${firstValueFilter ? ('rp_code=' + firstValueFilter.trim() + (secondValueFilter.length > 0 || thirdValueFilter.length > 0 ? '&' : '')) : ''}${secondValueFilter.length > 0 ? ('type=' + secondValueFilter[0].value + (firstValueFilter || thirdValueFilter.length > 0 ? '&' : '')) : ''}${thirdValueFilter.length > 0 ? thirdValueFilter.map(rc => 'rc[]=' + rc.value).join('&') : ''}`;

      const data = { quotaIds: selectedQuotas.map(quota => quota.id) };

      settleAnalysisInvoices(projectId, detail.id, data, queryParamsString).then(result => {
         if (result.success) {
            handleStatusBar('success', result.data, true);
            handleAnalysisInvoices()
            setShowModalValidateSettle(false);
         } else {
            handleStatusBar(
               "error",
               result.message ??
               translate(
                  "common.error.save",
                  "Si è verificato un errore durante l'elaborazione delle informazioni"
               ),
               false
            );
         }
      });
   };

   const handleShowModal = (setShowModal) => setShowModal(true);

   const handleCloseModal = (setShowModal) => {
      setShowModal(false);
      setActiveKey('invoices');
      setSbShow(<></>);
      setSbShowQuestions(<></>);
      setRefresh(true);
      setIsShowNew(false);
      if (refreshTotals) refreshTotals();
   };

   const handleStatusBar = (status, message, timeout, setSb = setSbShow) => {
      setSb(<StatusBar status={status} message={message} timeout={timeout} />);
   };

   const StatusInfoCellRenderer = useCallback((params) => {
      //console.log('params', params);
      const green = '#689689';
      const yellow = '#D4BE31';
      let stoplightColor = '#ffffff';
      let message;

      if (params.value !== undefined) {
         if (params.queryParam === 'status') {
            if (params.value) {
               //check giallo, da validare
               stoplightColor = yellow;
               message = 'Da validare';
            } else {
               //check verde, rendicontabile
               stoplightColor = green;
               message = 'Rendicontabile';
            }
         } else if (
            params.queryParam === 'info' &&
            params.data.checked === 1 &&
            params.queryParam === 'info' &&
            params.value !== 0
         ) {
            message = 'Info';
         }

         const statusInfoTooltip = (props) => <Tooltip {...props}>{message}</Tooltip>;

         const settledComponent = () => {
            return params.value !== 0 && (
               <Button
                  variant="link"
                  onClick={() => { setSelectedQuotaId(params.data.id); handleShowModal(setShowModalInfo) }}
               >
                  <IcoLens className="edit" />
               </Button>
            )
         };

         const checkIsAnalysis = () => {
            let bool = true;

            //Se è in analysisInvoices e non è stato selezionato
            if (params.queryParam1 === 'analysisInvoices' && !selectedQuotas.find(item => item.id === params.data.id)) {
               bool = false;
            }

            return bool;
         };

         return (message &&
            <OverlayTrigger placement="top" overlay={statusInfoTooltip}>
               <Row className="align-items-center justify-content-center h-100">
                  <Col className="col-auto text-center">
                     {params.queryParam === 'status' && <IcoCheck style={{ fill: stoplightColor }} />}
                     {params.queryParam === 'info' && params.data.checked === 1 && checkIsAnalysis() && settledComponent()}
                  </Col>
               </Row>
            </OverlayTrigger>
         );
      }
   }, [selectedQuotas]);

   // const CustomHeader = useCallback((params) => {
   //    if (params && params.entityData && params.entityType && params.entityData.length > 0) {
   //       const setItemList = params.entityType === 'invoices' ? setInvoices : setTimesheets;
   //       const setOriginItemList = params.entityType === 'invoices' ? setOriginCheckedInvoices : setOriginCheckedTimesheets;

   //       const handlePickUnpickAll = async (isCheckedAll) => {
   //          setIsLoadingPickUnpick(true);
   //          const action = isCheckedAll ? 'pickall' : 'unpickall';

   //          setIsAllSelected(prevState => ({
   //             ...prevState,
   //             [params.entityType]: {
   //                condition: true,
   //                action: action
   //             }
   //          }));

   //          const tempEntityData = params.entityData.map(obj => ({ ...obj, checked: isCheckedAll ? 1 : 0 }));
   //          //per aggiornare manualmente l'icona info
   //          setItemList(tempEntityData); //aggiorna solo i visibili
   //          if (action === 'unpickall') setOriginItemList(tempEntityData.filter((el) => el.checked)); //aggiorna i check di default se unpick all

   //          const data = { [params.entityType === 'invoices' ? 'quotaIds' : 'timesheetDetailIds']: tempEntityData.map(el => el[params.entityType === 'invoices' ? 'id' : 'timesheetDetailId']) };
   //          const result = await pickUnpickAllProjectStatement(projectId, detail.id, params.entityType, action, data);

   //          if (result.success) {
   //             setIsLoadingPickUnpick(false);
   //             handleRefreshDataAmount();
   //          } else {
   //             setIsLoadingPickUnpick(false);

   //             handleStatusBar(
   //                "error",
   //                result.message ??
   //                translate(
   //                   "common.error.save",
   //                   "Si è verificato un errore durante il salvataggio delle informazioni"
   //                ),
   //                false
   //             );
   //          }

   //          setIsAllSelected(prevState => ({
   //             ...prevState,
   //             [params.entityType]: {
   //                condition: false,
   //                action: ''
   //             }
   //          }));
   //       }

   //       //console.log('conditions:', params?.entityData?.filter(el => el.checked).length, params?.entityData.length);
   //       return (
   //          <>
   //             <Form.Check
   //                inline
   //                className='as-aggrid-checkbox'
   //                type='checkbox'
   //                name='all'
   //                checked={params?.entityData?.filter(el => el.checked).length === params?.entityData.length && params?.entityData.length > 0}
   //                onChange={e => handlePickUnpickAll(e.target.checked)}
   //             />
   //          </>
   //       );
   //    }
   // }, [detail.id, handleRefreshDataAmount, projectId]);

   // useEffect(() => {
   //    // setInvoicesColumn([
   //    //    {
   //    //       headerName: "",
   //    //       //field: "id",
   //    //       hide: isValidated ? true : false,
   //    //       maxWidth: 50,
   //    //       resizable: false,
   //    //       checkboxSelection: isValidated ? false : true,
   //    //       cellStyle: () => { //per disabilitare il click sulla riga in loading
   //    //          return isLoadingPickUnpick ? { 'pointerEvents': 'none', opacity: '0.4' } : null;
   //    //       },
   //    //       headerComponent: CustomHeader,
   //    //       headerComponentParams: {
   //    //          entityData: invoices,
   //    //          entityType: 'invoices',
   //    //          setOriginItemList: setOriginCheckedInvoices,
   //    //       },
   //    //    },
   //    //    {
   //    //       headerName: "Nr. Fattura",
   //    //       field: "number",
   //    //       filter: "agTextColumnFilter",
   //    //    },
   //    //    {
   //    //       headerName: "ID Fattura",
   //    //       field: "rpCode",
   //    //       filter: "agTextColumnFilter",
   //    //    },
   //    //    {
   //    //       headerName: "Fornitore",
   //    //       field: "supplierName",
   //    //       filter: "agTextColumnFilter",
   //    //    },
   //    //    {
   //    //       headerName: "Voce di spesa",
   //    //       field: "name",
   //    //       filter: "agTextColumnFilter",
   //    //       //minWidth: 100,
   //    //    },
   //    //    {
   //    //       headerName: "Importo rendicontabile €",
   //    //       field: "amount",
   //    //       filter: "agTextColumnFilter",
   //    //       type: "rightAligned",
   //    //       resizable: true,
   //    //       valueGetter: (params) => {
   //    //          return params.data.amount ? formatToDecimals(params.data.amount) : 0;
   //    //       },
   //    //    },
   //    //    {
   //    //       headerName: "Status",
   //    //       field: "toSettle",
   //    //       cellRenderer: StatusInfoCellRenderer,
   //    //       cellRendererParams: {
   //    //          queryParam: "status",
   //    //       },
   //    //       minWidth: 90,
   //    //       maxWidth: 90,
   //    //       ...columnCentered(),
   //    //    },
   //    //    {
   //    //       headerName: "Info",
   //    //       field: "toSettle",
   //    //       cellRenderer: StatusInfoCellRenderer,
   //    //       cellRendererParams: {
   //    //          queryParam: "info",
   //    //          queryParam1: 'invoices',
   //    //       },
   //    //       minWidth: 90,
   //    //       maxWidth: 90,
   //    //       ...columnCentered(),
   //    //    },
   //    // ])

   //    // setTimesheetsColumn([
   //    //    {
   //    //       headerName: "",
   //    //       //field: "timesheetId",
   //    //       hide: isValidated ? true : false,
   //    //       maxWidth: 50,
   //    //       resizable: false,
   //    //       checkboxSelection: isValidated ? false : true,
   //    //       cellStyle: () => { //per disabilitare il click sulla riga in loading
   //    //          return isLoadingPickUnpick ? { 'pointerEvents': 'none', opacity: '0.4' } : null;
   //    //       },
   //    //       headerComponent: CustomHeader,
   //    //       headerComponentParams: {
   //    //          entityData: timesheets,
   //    //          entityType: 'timesheets',
   //    //          setOriginItemList: setOriginCheckedTimesheets,
   //    //       },
   //    //    },
   //    //    {
   //    //       headerName: "Matricola",
   //    //       field: "serialNumber",
   //    //       filter: "agTextColumnFilter",
   //    //       minWidth: 150,
   //    //    },
   //    //    {
   //    //       headerName: "Categoria",
   //    //       field: "category",
   //    //       filter: "agTextColumnFilter",
   //    //       cellStyle: {
   //    //          ...customCellStyle,
   //    //       },
   //    //       cellRendererParams: {
   //    //          queryParam: "category",
   //    //       },
   //    //    },
   //    //    {
   //    //       headerName: "Record",
   //    //       field: "number",
   //    //       filter: "agTextColumnFilter",
   //    //    },
   //    //    {
   //    //       headerName: "Giorno",
   //    //       field: "day",
   //    //       headerClass: "text-center",
   //    //       width: 120,
   //    //       cellRendererParams: {
   //    //          queryParam: "day",
   //    //       },
   //    //       cellStyle: { ...customCellStyle, justifyContent: "center" },
   //    //    },
   //    //    {
   //    //       headerName: "Mese",
   //    //       field: "month",
   //    //       headerClass: "text-center",
   //    //       //filter: 'agTextColumnFilter',
   //    //       //   ...columnCentered(),
   //    //       cellStyle: { ...customCellStyle, justifyContent: "center" },
   //    //       valueGetter: (params) => {
   //    //          return months()[params.data.month - 1];
   //    //       },
   //    //       cellRendererParams: {
   //    //          queryParam: "month",
   //    //       },
   //    //    },
   //    //    {
   //    //       headerName: "Anno",
   //    //       field: "year",
   //    //       headerClass: "text-center",
   //    //       width: 120,
   //    //       cellRendererParams: {
   //    //          queryParam: "year",
   //    //       },
   //    //       cellStyle: { ...customCellStyle, justifyContent: "center" },
   //    //    },
   //    //    {
   //    //       headerName: "Ordine",
   //    //       field: "orderNumber",
   //    //       filter: "agTextColumnFilter",
   //    //       cellStyle: {
   //    //          ...customCellStyle,
   //    //       },
   //    //       cellRendererParams: {
   //    //          queryParam: "orderNumber",
   //    //       },
   //    //    },
   //    //    {
   //    //       headerName: "WBE",
   //    //       field: "wbe",
   //    //       filter: "agTextColumnFilter",
   //    //       cellStyle: {
   //    //          ...customCellStyle,
   //    //       },
   //    //       cellRendererParams: {
   //    //          queryParam: "wbe",
   //    //       },
   //    //    },
   //    //    {
   //    //       headerName: "WP/Stream",
   //    //       field: "streamId",
   //    //       filter: "agTextColumnFilter",
   //    //       width: 150,
   //    //       cellStyle: { ...customCellStyle, justifyContent: "center" },
   //    //    },
   //    //    {
   //    //       headerName: "Costo totale €",
   //    //       field: "totalCost",
   //    //       filter: "agTextColumnFilter",
   //    //       type: "rightAligned",
   //    //       cellStyle: {
   //    //          ...customCellStyle,
   //    //          justifyContent: "right",
   //    //       },
   //    //       valueGetter: (params) => {
   //    //          return params.data.totalCost
   //    //             ? formatToDecimals(params.data.totalCost)
   //    //             : 0;
   //    //       },
   //    //    },
   //    // ])
   // }, [CustomHeader, StatusInfoCellRenderer, invoices, isLoadingPickUnpick, isValidated, timesheets]);

   useEffect(() => {
      setInvoicesReportColumn([
         {
            headerName: "",
            //field: "id",
            hide: isValidated ? true : false,
            maxWidth: 50,
            resizable: false,
            checkboxSelection: isValidated ? false : true,
            cellStyle: () => { //per disabilitare il click sulla riga in loading
               return isLoadingPickUnpick ? { 'pointerEvents': 'none', opacity: '0.4' } : null;
            },
            headerCheckboxSelection: true,
         },
         {
            headerName: "Nr. Fattura",
            field: "number",
            filter: "agTextColumnFilter",
         },
         {
            headerName: "ID Fattura",
            field: "rpCode",
            filter: "agTextColumnFilter",
         },
         {
            headerName: "Fornitore",
            field: "supplierName",
            filter: "agTextColumnFilter",
         },
         {
            headerName: "Voce di spesa",
            field: "name",
            filter: "agTextColumnFilter",
            //minWidth: 100,
         },
         {
            headerName: "Importo rendicontabile €",
            field: "amount",
            filter: "agTextColumnFilter",
            type: "rightAligned",
            resizable: true,
            valueGetter: (params) => {
               return params.data.amount ? formatToDecimals(params.data.amount) : 0;
            },
         },
         {
            headerName: "Status",
            field: "toSettle",
            cellRenderer: StatusInfoCellRenderer,
            cellRendererParams: {
               queryParam: "status",
            },
            minWidth: 90,
            maxWidth: 90,
            ...columnCentered(),
         },
         {
            headerName: "Info",
            field: "toSettle",
            cellRenderer: StatusInfoCellRenderer,
            cellRendererParams: {
               queryParam: "info",
               queryParam1: "analysisInvoices",
            },
            minWidth: 90,
            maxWidth: 90,
            ...columnCentered(),
         },
      ])
   }, [StatusInfoCellRenderer, isLoadingPickUnpick, isValidated, selectedQuotas]);

   const handleChanges = (e, fieldName) => {
      let tempDetail = { ...detail };
      let value;

      switch (fieldName) {
         case 'date':
         case 'cashedInDate':
            value = e.target.value;

            tempDetail[fieldName] = value;
            break;
         case 'checkEnvironmentalTagging':
         case 'checkDigitalTagging':
         case 'checkGenderEquality':
         case 'checkDnsh':
         case 'cashedIn':
            value = e.target.checked;

            tempDetail[fieldName] = value;

            if (fieldName === 'cashedIn' && e.target.checked === false) {
               tempDetail.cashedInDate = null;
            }
            break;
         case 'approvedAmount':
            value = Number(e.value);

            tempDetail[fieldName] = value;
            break;
         case 'requestedAmount':
            value = Number(e.value);

            tempDetail[fieldName] = value;

            setAmounts({
               ...amounts,
               requestedAmount: value,
            });

            setErrorAdv((prevState) => ({
               ...prevState,
               isAdv: true,
            })); //per disabilitare validazione se c'è errore adv
            break;
         default:
            break;
      }
      //console.log('tempDetail', tempDetail);
      setDetail(tempDetail);
      debouncedHandleEdit(tempDetail);
   };
   const handleHelpIntensityPercentageChanges = (e, fieldName, setSelect) => {
      let tempDetail = { ...helpIntensityPercentageDetail };
      let value;
      switch (fieldName) {
         case 'helpPercentageIntensity':
            value = Number(e.value);

            tempDetail[fieldName] = value > 100 ? 100 : value;
            break;
         default:
            break;
      }

      setHelpIntensityPercentageDetail(tempDetail);
      debouncedHandleHelpIntensityPercentageEdit(tempDetail);
   };

   const handleChangesSettleChecklist = (e, fieldName, setSelect) => {
      let value;

      switch (fieldName) {
         case 'rpCode':
            value = e.target.value;
            setFirstValueFilter(value);
            break;
         case 'type':
            value = e ? [e] : [];
            setSelect(value);

            if (value.length === 0) {
               setThirdValueFilter([]);
            } else {
               fetchSettleChecklist(value[0]?.value);
            }
            break;
         case 'rc':
            value = e ?? [];
            setSelect(e);
            break;
         default:
            break;
      }

      setAnalysisInvoices(null);
      setAnalysisInvoicesMeta({});
   };

   const handleEdit = useCallback(
      async (tempDetail) => {
         setSbShow(<></>);
         setSbShowQuestions(<></>);
         setSbShowValidate(<></>);

         const { createdAt, createdBy, updatedAt, updatedBy, ...restDetail } = tempDetail;

         if (tempDetail.date && data && data.date === tempDetail.date) {
            delete restDetail.date;
         }
         let result = await editProjectStatement(projectId, restDetail);
         //console.log('result', result);

         if (result.success) {
            setErrorAdv((prevState) => ({
               ...prevState,
               isError: false,
            })); //per disabilitare validazione se c'è errore adv

            setTimeout(() => {
               handleRenderData();
               setDetail(result.data);
            }, 500);
         } else {
            setErrorAdv((prevState) => ({
               ...prevState,
               isError: true,
            })); //per disabilitare validazione se c'è errore adv

            handleStatusBar(
               'error',
               result.message ??
               translate(
                  'common.error.save',
                  'Si è verificato un errore durante il salvataggio delle informazioni'
               ),
               false
            );
         }
      },
      [data, handleRenderData, projectId]
   );

   const handleHelpIntensityPercentageEdit = useCallback(
      async (tempDetail) => {
         setSbShow(<></>);
         setSbShowQuestions(<></>);
         setSbShowValidate(<></>);

         let result = await updateAdvancedHelpPercentageIntensity(projectId, detail.id, tempDetail);

         if (result.success) {
            setTimeout(() => {
               handleRefreshDataAmount();
               //setHelpIntensityPercentageDetail(result.data);
            }, 500);
         } else {
            handleStatusBar(
               'error',
               result.message ??
               translate(
                  'common.error.save',
                  'Si è verificato un errore durante il salvataggio delle informazioni'
               ),
               false
            );
         }
      },
      [projectId, detail.id, handleRefreshDataAmount]
   );

   const debouncedHandleEdit = useMemo(() => debounce(handleEdit, 700), [handleEdit]);

   const debouncedHandleHelpIntensityPercentageEdit = useMemo(
      () => debounce(handleHelpIntensityPercentageEdit, 700),
      [handleHelpIntensityPercentageEdit]
   );

   // const handleItemList = async ([entityType, originItemList, setOriginItemList], selectedItemList,
   //    isChangedPagination, setIsChangedPagination) => {
   //    let setItemList;
   //    let entityData;
   //    let elId;

   //    setIsLoadingPickUnpick(true);

   //    switch (entityType) {
   //       case "invoices":
   //          setItemList = setInvoices;
   //          entityData = [...invoices];
   //          elId = 'id';
   //          break;
   //       case "timesheets":
   //          setItemList = setTimesheets;
   //          entityData = [...timesheets];
   //          elId = 'timesheetDetailId';
   //          break;
   //       case "analysisInvoices":
   //          setItemList = setAnalysisInvoices;
   //          entityData = [...analysisInvoices];
   //          elId = 'id';
   //          break;
   //       default:
   //          break;
   //    }

   //    //aggiorna originItemList dopo il pick/unpick all
   //    if (entityType !== 'analysisInvoices' && isAllSelected[entityType].condition) {
   //       setOriginItemList(isAllSelected[entityType].action === 'pickall' ? entityData : [])
   //       return;
   //    }

   //    let resultOfActionsTemp = [];
   //    let messageResult = [];

   //    //console.log('selectedItemList', selectedItemList);
   //    //console.log('originItemList', originItemList)

   //    const oldItemsDeleted = originItemList.filter((object1) => {

   //       return !selectedItemList.some((object2) => {
   //          return Number(object1[elId]) === Number(object2[elId])
   //       });
   //    });
   //    //console.log('oldItemsDeleted', oldItemsDeleted);

   //    if (oldItemsDeleted.length > 0) {
   //       //per aggiornare manualmente l'icona info
   //       oldItemsDeleted.forEach(item => {
   //          const indexOfItem = entityData.findIndex(el => el[elId] === item[elId]);
   //          if (indexOfItem >= 0) {
   //             entityData[indexOfItem].checked = 0;
   //          }
   //       });

   //       if (entityType !== 'analysisInvoices') {
   //          await Promise.all(
   //             oldItemsDeleted.map(async (item) => {
   //                const deleteResult = await pickUnpickProjectStatement(
   //                   projectId,
   //                   detail.id,
   //                   entityType === "invoices" ? "invoices" : "timesheets",
   //                   "unpick",
   //                   {
   //                      [entityType === "invoices" ? "quotaId" : "timesheetDetailId"]: item[elId],
   //                   }
   //                );
   //                //console.log('deleteResult', deleteResult);

   //                if (deleteResult.success === false) messageResult.push(deleteResult);
   //                resultOfActionsTemp.push(deleteResult.success);
   //             })
   //          )
   //       }

   //    }

   //    const newItems = selectedItemList.filter((object1) => {
   //       return !originItemList.some((object2) => {
   //          return Number(object1[elId]) === Number(object2[elId])
   //       });
   //    });
   //    //console.log('newItems', newItems);

   //    if (newItems.length > 0) {
   //       //per aggiornare manualmente l'icona info
   //       newItems.forEach(item => {
   //          const indexOfItem = entityData.findIndex(el => el[elId] === item[elId]);
   //          //console.log('indexOfItem', indexOfItem);
   //          if (indexOfItem >= 0) {
   //             entityData[indexOfItem].checked = 1;
   //          }
   //       });

   //       if (entityType !== 'analysisInvoices') {
   //          await Promise.all(
   //             newItems.map(async (newItem) => {
   //                const result = await pickUnpickProjectStatement(
   //                   projectId,
   //                   detail.id,
   //                   entityType === "invoices" ? "invoices" : "timesheets",
   //                   "pick",
   //                   {
   //                      [entityType === "invoices" ? "quotaId" : "timesheetDetailId"]: newItem[elId],
   //                   }
   //                );
   //                //console.log('result:', result);

   //                if (result.success === false) messageResult.push(result);
   //                resultOfActionsTemp.push(result.success);
   //             })
   //          );
   //       }
   //    }

   //    //console.log('resultOfActionsTemp', resultOfActionsTemp);
   //    //console.log('messageResult', messageResult);

   //    if (oldItemsDeleted.length > 0 || newItems.length > 0) {
   //       setItemList(entityData);
   //    }

   //    if (
   //       resultOfActionsTemp.length === 0 ||
   //       (resultOfActionsTemp.length > 0 &&
   //          resultOfActionsTemp.every((el) => el === true))
   //    ) {
   //       //Elementi selezionati
   //       setOriginItemList(selectedItemList);

   //       if (!isChangedPagination && entityType !== "analysisInvoices") handleRefreshDataAmount();

   //       setIsLoadingPickUnpick(false);
   //    } else {
   //       setIsLoadingPickUnpick(false);

   //       handleStatusBar(
   //          "error",
   //          messageResult.length > 0
   //             ? (messageResult[0].data ? Object.values(messageResult[0].data).join() : messageResult[0].message)
   //             : translate(
   //                "common.error.save",
   //                "Si è verificato un errore durante il salvataggio delle informazioni"
   //             ),
   //          false
   //       );
   //    }

   //    if (setIsChangedPagination) setIsChangedPagination(false);
   // };

   const handleSimpleSelection = (selectedItemList) => {
      if (activeKey === "record-analysis") setSelectedQuotas(selectedItemList);
   };

   const handleSubmit = async (e) => {
      e.preventDefault();

      setSbShow(<></>);
      setSbShowValidate(<></>);
      setSbShowQuestions(<></>);

      //const { createdAt, createdBy, updatedAt, updatedBy, ...restDetail } = detail;
      //restDetail.state = 'VALIDATED';

      let result = await editProjectStatement(projectId, {
         id: detail.id,
         state: 'VALIDATED',
      });
      //console.log('result', result);

      if (result.success) {
         handleStatusBar('success', 'Elemento validato con successo', true, setSbShowValidate);

         setTimeout(() => {
            handleCloseModal(setShowModal);
         }, 500);
      } else {
         handleStatusBar(
            'error',
            result.message ??
            translate(
               'common.error.save',
               'Si è verificato un errore durante il salvataggio delle informazioni'
            ),
            false,
            setSbShowValidate
         );
      }
   };

   const handleDelete = async () => {
      const deleteResult = await deleteStatement(projectId, data.id);

      if (deleteResult.success) {
         handleStatusBar(
            'success',
            translate('common.message.deleted', 'Elemento eliminato correttamente'),
            true
         );

         setTimeout(() => {
            setShowModalDelete(false);
            setRefresh(true);
         }, 500);
      } else {
         handleStatusBar(
            'error',
            deleteResult.message ??
            translate(
               'common.error.delete',
               'Si è verificato un errore durante l’eliminazione delle informazioni'
            ),
            false
         );
      }
   };

   const editTooltip = (props) => (
      <Tooltip {...props}>
         <OETrans code='common.tooltip.edit' fallback={'Modifica'} />
      </Tooltip>
   );
   const deleteTooltip = (props) => (
      <Tooltip {...props}>
         <OETrans code='common.tooltip.delete' fallback={'Elimina'} />
      </Tooltip>
   );

   const buttonRefresh = () => {
      return (
         <>
            {!isValidated && (
               <Button
                  onClick={() => {
                     setIsLoadingAmount(true);
                     handleRefreshDataAmount();
                  }}
                  className='m-0'
                  style={{ padding: '0 0.2rem', width: '27px' }}>
                  {isLoadingAmount ? (
                     <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                  ) : (
                     <IcoRefresh width='25px' height='25px' />
                  )}
               </Button>
            )}
         </>
      );
   };

   const fieldAdv = (field) => {
      if (field === 'requestedAmount') {
         return (
            <InputNumber
               name='requestedAmount'
               onChange={(e) => handleChanges(e, 'requestedAmount')}
               min={0}
               minFractionDigits={2}
               maxFractionDigits={2}
               mode='currency'
               currency='EUR'
               locale={localLang}
               className='md'
               value={amounts.requestedAmount || detail.requestedAmount}
               placeholder='0.00'
            />
         );
      } else if (field === 'date') {
         return (
            <Form.Control
               type='date'
               name='date'
               value={detail.date ? dateFormatterInput(detail.date) : ''}
               onChange={(e) => handleChanges(e, 'date')}
            />
         );
      }
   };

   const fieldSalPercentage = (field) => {
      if (field === 'helpPercentageIntensity') {
         return (
            <>
               <InputNumber
                  name='helpPercentageIntensity'
                  step={1}
                  minFractionDigits={0}
                  maxFractionDigits={2}
                  onChange={(e) => handleHelpIntensityPercentageChanges(e, 'helpPercentageIntensity')}
                  mode='decimal'
                  locale={localLang}
                  className='md'
                  value={helpIntensityPercentageDetail.helpPercentageIntensity}
                  min={0}
                  max={100}
                  inputClassName={Number(helpIntensityPercentageDetail.helpPercentageIntensity) === 0 ? 'red-font' : ''}
               />
               <span>
                  <OverlayTrigger
                     placement='bottom'
                     delay={{ show: 200, hide: 400 }}
                     overlay={(props) => (
                        <Tooltip {...props}>
                           <OETrans
                              code='common.tooltip.advanced'
                              fallback={
                                 "L'intensità d'aiuto supplementare sarà applicata con valori > 0. Se si desidera applicare l'intensità d'aiuto, definita nel quadro economico, inserire 0"
                              }
                           />
                        </Tooltip>
                     )}>
                     <TooltipInfoIcon
                        className='tooltip-ico position-absolute'
                        style={{ width: '1rem' }}
                     />
                  </OverlayTrigger>
               </span>
            </>
         );
      }
   };

   // const reportDownloadButton = (
   //    <DownloadExcelButton
   //       apiDownload={downloadProjectStatementInvoices}
   //       apiProps={[projectId, detail.id]}
   //       label={translate('common.downloadreport', 'Scarica report')}
   //    />
   // );

   // console.log("selectedQuestions", selectedQuestions);

   const filters = () => {
      return (<>
         <Row>
            <Col className="col-3">
               <Form.Group as={Col} className="px-2">
                  <Form.Label className="mb-0">ID Fattura</Form.Label>
                  <FormControl
                     name="rpCode"
                     type="text"
                     className="filter-text-box"
                     placeholder={translate('common.search', 'Cerca') + '...'}
                     value={firstValueFilter}
                     onChange={e => handleChangesSettleChecklist(e, "rpCode")}
                     style={{ height: "2.375rem" }}
                  />
               </Form.Group>
            </Col>
            <Col className="col-3 ps-0">
               <Form.Group as={Col} className="px-2">
                  <Form.Label className="mb-0">Entità</Form.Label>
                  <Select
                     components={{
                        IndicatorSeparator: () => null,
                     }}
                     className="react-select-container"
                     classNamePrefix="react-select"
                     name="type"
                     placeholder={translate('common.placeholder.select', 'Seleziona...')}
                     value={secondValueFilter}
                     options={[
                        { value: 'invoices', label: 'Fattura' },
                        { value: 'tenders  ', label: 'Procedura' },
                        { value: 'contracts', label: 'Attingimento' },
                        { value: 'frameworkcontracts', label: 'Contratto Aperto' },
                        { value: 'invoicepayments', label: 'Pagamento' },
                        { value: 'otplants', label: 'DNSH' },
                     ]}
                     onChange={e => handleChangesSettleChecklist(e, "type", setSecondValueFilter)}
                     isClearable
                  />
               </Form.Group>
            </Col>
            <Col className="col-3 ps-0">
               <Form.Group as={Col} className="px-2">
                  <Form.Label className="mb-0">Checklist</Form.Label>
                  <Select
                     components={{
                        IndicatorSeparator: () => null,
                     }}
                     className="react-select-container"
                     classNamePrefix="react-select"
                     closeMenuOnSelect={false}
                     name="rc"
                     placeholder={translate('common.placeholder.select', 'Seleziona...')}
                     value={thirdValueFilter}
                     options={optionsToSettleChecklist.settleCl}
                     isMulti
                     isClearable
                     onChange={e => handleChangesSettleChecklist(e, "rc", setThirdValueFilter)}
                     isDisabled={secondValueFilter.length === 0}
                     isLoading={optionsToSettleChecklist.isLoading}
                  />
               </Form.Group>
            </Col>
            <Col className="col-auto ps-0">
               <Form.Group as={Col} className="px-2">
                  <Form.Label className="mb-0"></Form.Label>
                  <Button
                     onClick={() => handleAnalysisInvoices()}
                     className="d-block"
                     style={{ padding: 0, height: '2.235rem', width: '2.211rem' }}
                  >
                     {isLoadingAnalysisInvoices ?
                        <Spinner
                           as="span"
                           animation="border"
                           size="sm"
                           role="status"
                           aria-hidden="true"
                        /> :
                        <IcoLens className="ico-small edit" />
                     }
                  </Button>
               </Form.Group>
            </Col>
         </Row>
      </>)
   };

   return (
      <>
         {isOe ? (
            <>
               <OverlayTrigger placement='top' overlay={editTooltip}>
                  <Button
                     variant='link'
                     //className={isValidated ? "" : "me-3"}
                     onClick={() => handleShowModal(setShowModal)}>
                     <IcoEdit className='second-color ico-small edit' />
                  </Button>
               </OverlayTrigger>
               {data?.state === 'DRAFT' && (
                  <OverlayTrigger placement='top' overlay={deleteTooltip}>
                     <Button
                        variant='link'
                        className='ms-3'
                        onClick={() => handleShowModal(setShowModalDelete)}>
                        <IcoCanc className='second-color ico-small cancel' />
                     </Button>
                  </OverlayTrigger>
               )}
            </>
         ) : null}

         {showModal && Object.values(detail).length > 0 && (
            <Modal
               fullscreen={true}
               className='modal-with-nav'
               show={showModal}
               onHide={() => {
                  refreshProjectStats(projectId);
                  handleCloseModal(setShowModal);
               }}
               centered>
               <Modal.Header style={{ maxHeight: '7rem', paddingLeft: '2.5rem' }} closeButton>
                  <Modal.Title className='col-auto pt-0 px-2'>
                     <DataHeader
                        customStyle={{
                           margin: 0,
                           paddingTop: '5px',
                           paddingBottom: 0,
                           marginLeft: isValidated ? '5px' : '-35px',
                        }}
                        data={[
                           {
                              name: 'ID',
                              value:
                                 detail.name.trim() ||
                                 optionsStatementTypes
                                    .filter((optType) => optType.value === detail.statementTypeCode)
                                    .map((type) => type.label)
                                    .pop(),
                           },
                           {
                              name: 'Importo',
                              value:
                                 (detail.state === 'DRAFT' && Object.keys(amounts).length > 0
                                    ? formatToDecimals(amounts.total)
                                    : formatToDecimals(detail.amount)) + ' €',
                              icon: buttonRefresh(),
                           },

                           {
                              name: <span className='text-nowrap'>Contributo richiesto</span>,
                              value:
                                 detail.statementTypeCode === 'ADV' && !isValidated
                                    ? fieldAdv('requestedAmount')
                                    : (detail.state === 'DRAFT' && Object.keys(amounts).length > 0
                                       ? formatToDecimals(amounts.requestedAmount)
                                       : formatToDecimals(detail.requestedAmount)) + ' €',
                           },

                           ...(detail.statementTypeCode === 'ADV' && !isValidated
                              ? [
                                 {
                                    name: <span className='text-nowrap'>Data Sottomissione</span>,
                                    value: !isValidated ? fieldAdv('date') : dateFormatter(detail.date),
                                 },
                              ]
                              : []),
                           ...(isAdvancedSettings && detail.statementTypeCode === 'SAL'
                              ? [
                                 {
                                    name: (
                                       <span className='text-nowrap'>Intensità d'aiuto supplementare (%)</span>
                                    ),
                                    value: !isValidated
                                       ? fieldSalPercentage('helpPercentageIntensity')
                                       : detail.helpPercentageIntensity + ' %',
                                 },
                              ]
                              : []),
                        ]}
                     />
                  </Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  <Tabs
                     className='mb-4'
                     activeKey={activeKey}
                     onSelect={(k) => {
                        setActiveKey(k);
                        handleRenderData(k);
                        setSbShow(<></>);
                        setSbShowQuestions(<></>);
                        setSelectedQuotas([]);
                        setSelectedQuestions({ answers: [] });
                     }}
                     fill={detail.statementTypeCode !== 'ADV' ? true : false}
                     variant='pills'>
                     {isValidated && (
                        <Tab eventKey='inputs' title='Approvazione'>
                           <Form>
                              <Row style={{ marginBottom: '40px' }}>
                                 <Col className='col'>
                                    <Form.Group>
                                       <Form.Label>Sottomissione al Ministero</Form.Label>
                                       <Form.Control
                                          type='date'
                                          name='date'
                                          value={detail.date ? dateFormatterInput(detail.date) : ''}
                                          onChange={(e) => handleChanges(e, 'date')}
                                       />
                                    </Form.Group>
                                 </Col>

                                 <Col className='col'>
                                    <Form.Group>
                                       <Form.Label>Contributo approvato €</Form.Label>
                                       <InputNumber
                                          name='approvedAmount'
                                          min={0}
                                          step={1}
                                          minFractionDigits={0}
                                          maxFractionDigits={2}
                                          //onChange={(e) => onChange(e, 'approvedAmount')}
                                          onValueChange={(e) => handleChanges(e, 'approvedAmount')}
                                          mode='decimal'
                                          locale={localLang}
                                          className='md d-block'
                                          //suffix={' €'}
                                          value={detail.approvedAmount ?? ''}
                                       />
                                    </Form.Group>
                                 </Col>

                                 <Col className='col'>
                                    <Form.Group className='col-auto'>
                                       <Form.Label>Incassato</Form.Label>
                                       <Form.Check
                                          type='checkbox'
                                          name='cashedIn'
                                          defaultChecked={detail.cashedIn}
                                          onClick={(e) => handleChanges(e, 'cashedIn')}
                                       />
                                    </Form.Group>
                                 </Col>

                                 <Col className='col'>
                                    <Form.Group as={Col}>
                                       <Form.Label>In data</Form.Label>
                                       <Form.Control
                                          type='date'
                                          name='cashedInDate'
                                          value={
                                             detail.cashedInDate
                                                ? moment(detail.cashedInDate).format('YYYY-MM-DD')
                                                : ''
                                          }
                                          onChange={(e) => handleChanges(e, 'cashedInDate')}
                                          disabled={detail.cashedIn === false}
                                       />
                                    </Form.Group>
                                 </Col>
                              </Row>
                           </Form>
                        </Tab>
                     )}

                     {isValidated && detail.statementTypeCode !== 'ADV' && (
                        <Tab eventKey='integration' title='Integrazione'>
                           <Row style={{ marginBottom: '40px' }}>
                              <Col>
                                 {activeKey === 'integration' && (
                                    <CheckDocTab
                                       tab='integration'
                                       //conditionalitiesFilterCodes={docCodeIntegration}
                                       conditionalitiesFilterCodes={['Integrazione']}
                                       detail={detail}
                                       isValidated={isValidated}
                                       setDetail={setDetail}
                                    />
                                 )}
                              </Col>
                           </Row>
                        </Tab>
                     )}

                     {detail.statementTypeCode !== "ADV" && (
                        <Tab eventKey="invoices" title="Costi esterni">
                           {activeKey === "invoices" && (
                              <>
                                 <ExpenseReportExternalCosts
                                    projectId={projectId}
                                    detail={detail}
                                    isValidated={isValidated}
                                    callback={handleRefreshDataAmount}
                                    pickLoading={isLoadingPickUnpick}
                                    setPickLoading={setIsLoadingPickUnpick}
                                    amounts={amounts}
                                    handleShowModal={handleShowModal}
                                    isShowModal={showModalInfo}
                                    setShowModal={setShowModalInfo}
                                    selectedQuotas={selectedQuotas}
                                    setSelectedQuotaId={setSelectedQuotaId}
                                 />
                                 {/* <OETableAgGrid
                                 //key="invoices"
                                 column={invoicesColumn}
                                 {...(isValidated
                                    ? { data: invoices, dataMeta: invoicesMeta, otherButtons: reportDownloadButton }
                                    : {
                                       data: invoices,
                                       dataMeta: invoicesMeta,
                                       conditionForCheck: "data.checked === 1",
                                       handleItemListSelection: handleItemList,
                                       handleItemListSelectionProps: [
                                          "invoices",
                                          originCheckedInvoices,
                                          setOriginCheckedInvoices,
                                          setInvoices,
                                          setInvoicesMeta,
                                          CustomHeader,
                                       ],
                                       otherButtons: reportDownloadButton,
                                       isCustomSelection: true,
                                    })}
                                    refTable="invoicesStatements"
                              >
                                 <DataHeader
                                    customStyle={{
                                       margin: 0,
                                       paddingTop: "5px",
                                       paddingBottom: 0,
                                       marginLeft: "15px",
                                    }}
                                    data={[
                                       {
                                          name: (<span className="text-nowrap">Contributo richiesto eleggibile</span>),
                                          value: (Object.keys(amounts).length > 0 && formatToDecimals(amounts.totalEligibles ?? 0) + " €"),
                                       },
                                       {
                                          name: (<span className="text-nowrap">Contributo richiesto eleggibile - elementi da validare</span>),
                                          value: (Object.keys(amounts).length > 0 && formatToDecimals(amounts.totalToSettle ?? 0) + " €"),
                                       },
                                    ]}
                                 />
                              </OETableAgGrid> */}
                              </>
                           )}
                        </Tab>
                     )}

                     {detail.statementTypeCode !== "ADV" && (
                        <Tab eventKey="timesheets" title="Costi interni">
                           {activeKey === "timesheets" && (
                              <>
                                 <ExpenseReportInternalCosts
                                    projectId={projectId}
                                    detail={detail}
                                    callback={handleRefreshDataAmount}
                                    pickLoading={isLoadingPickUnpick}
                                    setPickLoading={setIsLoadingPickUnpick}
                                    isLoadingIndirectCosts={isLoadingIndirectCosts}
                                    indirectCosts={indirectCosts}
                                 />
                                 {/* <OETableAgGrid
                                 //key="timesheets"
                                 children={
                                 <Row className="justify-content-start align-items-center">
                                    <Col>
                                       <Row>
                                       {isLoading ? (
                                          <Spinner animation="border" role="status" />
                                       ) : (
                                          indirectCosts > 0 && (
                                             <>
                                             <h5 className="m-0">
                                                {formatToDecimals(indirectCosts) + " €"}
                                             </h5>
                                             <p className="m-0 dataHeaderName">
                                                Costi indiretti
                                             </p>
                                             </>
                                          )
                                       )}
                                       </Row>
                                    </Col>
                                 </Row>
                                 }
                                 column={timesheetsColumn}
                                 {...(isValidated
                                 ? { data: timesheets, dataMeta: timesheetsMeta }
                                 : {
                                       data: timesheets,
                                       dataMeta: timesheetsMeta,
                                       conditionForCheck: "data.checked === 1",
                                       handleItemListSelection: handleItemList,
                                       handleItemListSelectionProps: [
                                       "timesheets",
                                       originCheckedTimesheets,
                                       setOriginCheckedTimesheets,
                                       setTimesheets,
                                       setTimesheetsMeta,
                                       CustomHeader,
                                       ],
                                       isCustomSelection: true,
                                    })}
                                 refTable="timesheetsStatements"
                              />*/}
                              </>
                           )}
                        </Tab>
                     )}

                     {detail.statementTypeCode !== "ADV" && (
                        <Tab eventKey="record-analysis" title="Analisi record">
                           {activeKey === "record-analysis" && (
                              <>
                                 {filters()}
                                 {isFiltered && Array.isArray(analysisInvoices) && (<>
                                    <OETableAgGrid
                                       column={invoicesReportColumn}
                                       data={analysisInvoices}
                                       dataMeta={analysisInvoicesMeta}
                                       hideSearchBar={true}
                                       refTable="recordAnalysis"
                                       isCustomSelection={false}
                                       handleItemListSelection={handleSimpleSelection}
                                       handleItemListSelectionProps={[
                                          "analysisInvoices"
                                       ]}
                                    />
                                 </>
                                 )}
                              </>
                           )}
                        </Tab>
                     )}

                     {detail.statementTypeCode !== 'ADV' && (
                        <Tab eventKey='general-expenses' title='Spese generali'>
                           {generalExpenses.length > 0 ? (
                              <>
                                 <Row className='mx-5 mt-5 justify-content-between'>
                                    <Col className='col-3'>
                                       <Form.Label className='mb-3'>Descrizione</Form.Label>
                                    </Col>

                                    <Col className='col-3 text-end'>
                                       <Form.Label className='mb-3'>Importo €</Form.Label>
                                    </Col>

                                    <Col className='col-3 text-end'>
                                       <Form.Label className='mb-3'>Percentuale forfettaria %</Form.Label>
                                    </Col>

                                    <Col className='col-3 text-end'>
                                       <Form.Label className='mb-3'>Importo spese generali €</Form.Label>
                                    </Col>
                                 </Row>

                                 {generalExpenses.map((generalExpense) => {
                                    return (
                                       <Row
                                          key={generalExpense.id}
                                          className='mx-5 mt-4 justify-content-between'>
                                          <Col className='col-3'>
                                             <div>{generalExpense.expenseItem.name}</div>
                                          </Col>

                                          <Col className='col-3'>
                                             <div className='text-end'>
                                                {generalExpense.amount
                                                   ? formatToDecimals(generalExpense.amount)
                                                   : 0}
                                             </div>
                                          </Col>

                                          <Col className='col-3'>
                                             <div className='text-end'>
                                                {generalExpense.geShare
                                                   ? formatToDecimals(generalExpense.geShare, 0, 2)
                                                   : 0}
                                             </div>
                                          </Col>

                                          <Col className='col-3'>
                                             <div className='text-end'>
                                                {generalExpense.geAmount
                                                   ? formatToDecimals(generalExpense.geAmount)
                                                   : 0}
                                             </div>
                                          </Col>
                                       </Row>
                                    );
                                 })}
                              </>
                           ) : (
                              <Row className='text-center my-5'>
                                 <span>Non sono presenti spese generali per questo rendiconto</span>
                              </Row>
                           )}
                        </Tab>
                     )}

                     {/* {detail.statementTypeCode !== "ADV" && (
                <Tab
                  eventKey="administrative-accounting"
                  title="Altra documentazione"
                >
                  <Row className="mb-2rem mt-5">
                    <Col>
                      {activeKey === "administrative-accounting" && (
                        <CheckDocTab
                          tab="administrative-accounting"
                          //conditionalitiesFilterCodes={docCodeAdministrativeAccounting}
                          conditionalitiesFilterCodes={[
                            "Personale",
                            "Forniture",
                          ]}
                          detail={detail}
                          isValidated={isValidated}
                          setDetail={setDetail}
                        />
                      )}
                    </Col>
                  </Row>
                </Tab>
              )} */}

                     <Tab
                        eventKey='documents'
                        title={detail.statementTypeCode === 'ADV' ? 'Richiesta' : 'Domanda di Rimborso'}>
                        <Row className='mb-2rem mt-5'>
                           <Col>
                              {activeKey === 'documents' && (
                                 <UploadDocument
                                    isValidated={isValidated}
                                    displayName={true}
                                    filterBySection={
                                       detail?.statementTypeCode === 'ADV'
                                          ? ['R_ANTICIPOALLEGATIALTRO', detail.oestatementtype.name]
                                          : [
                                             `R_${detail?.statementTypeCode === 'SAL' ? 'SAL' : 'SALDO'
                                             }ALLEGATIALTRO`,
                                             detail.oestatementtype.name,
                                          ]
                                    }
                                    entity='statements'
                                    entityId={detail.id}
                                    enableUpload={true}
                                 />
                              )}
                           </Col>
                        </Row>
                     </Tab>
                  </Tabs>

                  {sbShow}
               </Modal.Body>

               <Modal.Footer>
                  <Col className='col'>&zwnj;</Col>

                  <Col className="col text-center">
                     {activeKey !== 'record-analysis' && !isValidated && (
                        <Button
                           style={{ maxWidth: "fit-content" }}
                           type="submit"
                           onClick={() => handleShowModal(setShowModalValidate)}
                           form="valid-report"
                           variant="primary"
                           disabled={errorAdv.isAdv && errorAdv.isError}
                        >
                           VALIDA RENDICONTO
                        </Button>
                     )}
                     {activeKey === 'record-analysis' && isFiltered && analysisInvoices && analysisInvoices.length > 0 ? (
                        <Button
                           style={{ maxWidth: "fit-content" }}
                           type="submit"
                           variant="primary"
                           onClick={(e) => setShowModalValidateSettle(true)}
                           disabled={selectedQuotas.length === 0}
                        >
                           SANA LE QUOTE SELEZIONATE
                        </Button>
                     ) : null}
                  </Col>

                  <Col>
                     <Row className='justify-content-end'>
                        {/* <Col className="col-auto">
                           <Button as={Col} variant='secondary' onClick={() => handleCloseModal(setShowModal)}>
                              CHIUDI
                           </Button>
                        </Col> */}
                        {/* {activeKey !== 'documents' ? <Col className="col-auto">
                           <Button
                              as={Col}
                              type='submit'
                              onClick={(e) => handleEdit(e)}
                              form='edit-statement'
                              variant='primary'
                              disabled={isValidated || activeKey !== 'conditionality'}
                           >
                              <OETrans code='common.edit' fallback={'Modifica'}/>
                           </Button>
                        </Col> :
                           null
                        } */}
                     </Row>
                  </Col>
               </Modal.Footer>
            </Modal>
         )}

         <Modal show={showModalDelete} onHide={() => handleCloseModal(setShowModalDelete)} centered>
            <Modal.Header closeButton>
               <Modal.Title>
                  <OETrans code='common.warning' fallback={'Attenzione'} />
               </Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <div className='my-3'>
                  <span>
                     <OETrans
                        code='common.warning.delete'
                        fallback={'Sei sicuro di voler eliminare questo elemento?'}
                     />
                  </span>
               </div>
               {sbShow}
            </Modal.Body>
            <Modal.Footer>
               <Button variant='secondary' onClick={() => handleCloseModal(setShowModalDelete)}>
                  <OETrans code='common.cancel' fallback={'Annulla'} />
               </Button>
               <Button variant='danger' onClick={() => handleDelete()}>
                  <OETrans code='common.delete' fallback={'Elimina'} />
               </Button>
            </Modal.Footer>
         </Modal>

         <Modal show={showModalValidate} onHide={() => setShowModalValidate(false)} centered>
            <Modal.Header closeButton>
               <Modal.Title>
                  <OETrans code='common.warning' fallback={'Attenzione'} />
               </Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <div className='my-3'>
                  <span>Sei sicuro di voler validare il rendiconto?</span>
               </div>
               {sbShowValidate}
            </Modal.Body>
            <Modal.Footer>
               <Button variant='secondary' onClick={() => setShowModalValidate(false)}>
                  <OETrans code='common.cancel' fallback={'Annulla'} />
               </Button>
               <Button variant='primary' type='submit' onClick={(e) => handleSubmit(e)}>
                  Valida
               </Button>
            </Modal.Footer>
         </Modal>

         <Modal
            show={showModalInfo}
            onHide={() => { setShowModalInfo(false) }}
            centered
            size="xl"
         >
            <Modal.Header closeButton>
               <Modal.Title>
                  Analisi record {selectedQuotaId}
               </Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <div>
                  <CheckInvoice
                     projectId={projectId}
                     statementId={detail.id}
                     quotaId={selectedQuotaId}
                     selectedQuotas={selectedQuotas}
                     selectedQuestions={selectedQuestions}
                     setSelectedQuestions={setSelectedQuestions}
                     showModalValidateSettle={showModalValidateSettle}
                     setShowModalValidateSettle={setShowModalValidateSettle}
                     setShowModalInfo={setShowModalInfo}
                     handleRenderData={handleRenderData}
                  />
               </div>
               {sbShowQuestions}
            </Modal.Body>
            <Modal.Footer>
               <Button
                  variant="secondary"
                  onClick={() => {
                     setSelectedQuestions({ answers: [] });
                     setShowModalInfo(false)
                     setSelectedQuotaId(null)
                  }}
               >
                  <OETrans code="common.cancel" fallback={"Annulla"} />
               </Button>
               <Button
                  variant="primary"
                  type="submit"
                  onClick={() => setShowModalValidateSettle(true)}
                  disabled={selectedQuestions.answers.filter(quest => quest.check && quest.quotaId === selectedQuotaId).length === 0}
               >
                  Sana/Valida
               </Button>
            </Modal.Footer>
         </Modal>

         {!showModalInfo && <ValidateSettleModal
            selectedQuestions={selectedQuestions}
            setSelectedQuestions={setSelectedQuestions}
            showModalValidateSettle={showModalValidateSettle}
            setShowModalValidateSettle={setShowModalValidateSettle}
            setShowModalInfo={setShowModalInfo}
            handleRenderData={handleRenderData}
            handleSettleAll={handleSettleAnalysisInvoices}
            isQuota={false} //TUTTE LE QUOTE
         />}
      </>
   );
};

export default MakeReportingModal;
