import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Row, Spinner, Form, Col, Modal, Button } from "react-bootstrap";
import Select from "react-select";
import OESimpleTable from "components/oetable/OESimpleTable";
import StatusBar from "@components/StatusBar";
import { IcoPlus } from "@icons";
import {
  columnCentered,
  columnCenteredWithFilter,
} from "@lib/helpers/agGridHelpers";
import {
  dateFormatter /*, dateFormatterInput */,
} from "@lib/helpers/formatting";
import { getMilestones, createMilestone, editMilestone } from "@lib/api/index";
// import UploadDocument from "@components/form/upload/uploadDocuments";
import ActionBtnRendererMilestones from "components/actionBtnRenderer/ActionBtnRendererMilestones";
import { translate } from "components/translation/translation";
import { OETrans } from "components/translation/OETrans";
import ForecastDateInput from "./ForecastDateInput";
// import UploadDocument from "components/form/upload/uploadDocuments";

const initialState = {
  number: "", //obbl
  title: "", //obbl
  description: "",
  wpStreamId: "",
  // probativeDocumentation: "",
  responsible: "",
  forecastDate: null,
  // effectiveDate: null,
  // completed: false,
  note: "",
};

const Milestones = ({ projectId, canEdit, streams, isConfig }) => {
  const [newMilestone, setNewMilestone] = useState(initialState);
  const [showModalNew, setShowModalNew] = useState(false);
  const [columnDefs, setColumnDefs] = useState([]);
  const [milestones, setMilestones] = useState(null);
  const optionsCompleted = useMemo(
    () => [
      { value: true, label: translate("common.yes", "Sì") },
      { value: false, label: translate("common.no", "No") },
    ],
    []
  );
  const optionsUsers = useMemo(() => {
     if (newMilestone.wpStreamId && streams) {
        const tempStream = streams.filter(stream => stream.id === newMilestone.wpStreamId).pop();
        if (!tempStream || !tempStream.responsibles) return [];
  
        return tempStream.responsibles.reduce((acc, obj) => {
              const objUsers = obj.users ?? [];
              const arrayWithProperties = objUsers.map((user) => ({
                 value: user.id,
                 label: `${user.firstName} ${user.lastName} (${user.email})`,
              }))
  
              return acc.concat(arrayWithProperties);
           }, []);
     } else {
        return [];
     }
  }, [newMilestone.wpStreamId, streams]);
  // const [selectedCompleted, setSelectedCompleted] = useState({
  //   value: false,
  //   label: "No",
  // });
  const [refresh, setRefresh] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [sbShow, setSbShow] = useState(<></>);
  const [isLoading, setIsLoading] = useState(false);
  const [activeKey, setActiveKey] = useState("registry");
  //console.log('optionsUsers', optionsUsers);

  const handleReset = () => {
    setSbShow(<></>);
    setActiveKey("registry");
    // setSelectedCompleted({ value: false, label: "No" });
    setNewMilestone(initialState);
  };

  const handleShowModal = (setShowModal) => setShowModal(true);

  const handleCloseModal = (setShowModal) => {
    handleReset();
    setShowModal(false);
  };

  const handleStatusBar = (status, message, timeout) => {
    setSbShow(
      <StatusBar status={status} message={message} timeout={timeout} />
    );
  };

  const fetchData = useCallback(async () => {
    getMilestones(projectId).then((retrievedMilestones) => {
      setMilestones(retrievedMilestones.data);
    });
  }, [projectId]);

  useEffect(() => {
    if (projectId) {
      fetchData();
    }
  }, [fetchData, projectId]);

  // useEffect(() => {
  //   setSelectedCompleted(
  //     optionsCompleted
  //       .filter((option) => option.value === newMilestone.completed)
  //       .map((filteredOpt) => ({
  //         value: filteredOpt.value,
  //         label: filteredOpt.label,
  //       }))
  //   );
  // }, [newMilestone.completed, optionsCompleted]);

  useEffect(() => {
    if (refresh && projectId) {
      fetchData();
      setRefresh(false);
    }
  }, [refresh, projectId, fetchData]);

  useEffect(() => {
    setColumnDefs([
      {
        headerName: "N. Milestone",
        headerTooltip: "N. Milestone",
        field: "number",
        filter: "agTextColumnFilter",
        flex: 2,
        // minWidth: 90,
        // maxWidth: 90,
      },
      ...(isConfig
        ? [
            {
              headerName: "Titolo",
              headerTooltip: "Titolo",
              field: "title",
              filter: "agTextColumnFilter",
              minWidth: 150,
              tooltipField: "title",
              wrapText: false,
              autoHeight: false,
              flex: 4,
            },
          ]
        : []),
      {
        headerName: "Descrizione",
        headerTooltip: "Descrizione",
        field: "description",
        filter: "agTextColumnFilter",
        minWidth: 150,
        tooltipField: "description",
        wrapText: false,
        autoHeight: false,
        flex: 4,
      },
      {
        headerName: "WP di riferimento",
        headerTooltip: "WP di riferimento",
        // field: 'wp',
        field: "wpStreamId",
        filter: "agTextColumnFilter",
        minWidth: 100,
        flex: 4,
        valueGetter: (params) => {
          // const selectedStream = streams.find(stream => stream.id === params.data.wp);
          const selectedStream = streams.find(
            (stream) => stream.id === params.data.wpStreamId
          );
          if (!selectedStream) return "";
          return `${selectedStream.streamId} - ${selectedStream.name}`;
        },
      },
      ...(!isConfig
        ? [
            {
              headerName: "Doc. probatoria",
              headerTooltip: "Doc. probatoria",
              field: "probativeDocumentation",
              filter: "agTextColumnFilter",
              tooltipField: "probativeDocumentation",
              wrapText: false,
              autoHeight: false,
              minWidth: 100,
              flex: 4,
            },
          ]
        : []),
      ...(isConfig
        ? [
            {
              headerName: "Responsabile",
              headerTooltip: "Responsabile",
              field: "responsible",
              filter: "agTextColumnFilter",
              minWidth: 150,
              //tooltipField: "responsible",
              wrapText: false,
              autoHeight: false,
              flex: 4,
              valueGetter: (params) => {
                if (streams) {
                  const tempStream = streams.filter(stream => stream.id === params.data.wpStreamId).pop();
                  if (!tempStream || !tempStream.responsibles) return [];
            
                  const tempUsers = tempStream.responsibles.reduce((acc, obj) => {
                        const objUsers = obj.users ?? [];
                        const arrayWithProperties = objUsers.map((user) => ({
                           value: user.id,
                           label: `${user.firstName} ${user.lastName} (${user.email})`,
                        }))
            
                        return acc.concat(arrayWithProperties);
                     }, []);

                  const selectedUser = tempUsers.find((user) => {
                     return user.value === params.data.responsible;
                  });

                  return selectedUser?.label;
               } else {
                  return [];
               }
              },
            },
          ]
        : []),
      {
        headerName: "Data prevista",
        headerTooltip: "Data prevista",
        field: "forecastDate",
        //filter: 'agTextColumnFilter',
        valueGetter: (params) => {
          if (!params.data.forecastDate) return "";
          return dateFormatter(params.data.forecastDate /*, "MM-YYYY" */);
        },
        ...columnCentered(),
        minWidth: 150,
        // flex: 4
        // maxWidth: 170,
      },
      ...(!isConfig
        ? [
            {
              headerName: "Data effettiva",
              headerTooltip: "Data effettiva",
              field: "effectiveDate",
              //filter: 'agTextColumnFilter',
              valueGetter: (params) => {
                if (!params.data.effectiveDate) return "";
                return dateFormatter(params.data.effectiveDate);
              },
              ...columnCentered(),
              minWidth: 150,
              flex: 4,
              // maxWidth: 170,
            },
          ]
        : []),
      ...(!isConfig
        ? [
            {
              headerName: "Completata",
              headerTooltip: "Completata",
              field: "completed",
              filter: "agTextColumnFilter",
              valueGetter: (params) => {
                const value = params.data.completed ? "SÌ" : "NO";
                return value;
              },
              filterValueGetter: (params) => {
                const value = params.data.completed ? "SÌ" : "NO";
                return value;
              },
              minWidth: 150,
              maxWidth: 180,
              ...columnCenteredWithFilter,
            },
          ]
        : []),
      ...(!isConfig
        ? [
            {
              headerName: "Note",
              headerTooltip: "Note",
              field: "note",
              filter: "agTextColumnFilter",
              tooltipField: "note",
              wrapText: false,
              autoHeight: false,
              minWidth: 160,
              flex: 4,
            },
          ]
        : []),
      {
        headerName: "",
        minWidth: 100,
        maxWidth: 100,
        cellRenderer: ActionBtnRendererMilestones,
        cellRendererParams: {
          setRefresh,
          optionsCompleted,
          canEdit,
          streams,
          isConfig,
        },
        resizable: false,
        cellStyle: { textAlign: "center", padding: 0 },
      },
    ]);
  }, [optionsCompleted, canEdit, isConfig, streams, optionsUsers]);

  const onChange = (e, fieldName, setSelect) => {
    //console.log('e', e);

    let tempNewMilestone = { ...newMilestone };
    let value;

    switch (fieldName) {
      case "number":
      case "title":
      case "responsible":
      case "description":
      /* case 'wp': */ case "wpStreamId":
      /* case "probativeDocumentation": */ case "note":
      case "forecastDate":
        /* case "effectiveDate":*/
        value = e.target.value;
        tempNewMilestone[fieldName] = value;
        break;
      // case "completed":
      //   value = e.value;
      //   tempNewMilestone[fieldName] = value;

      //   setSelect(e);
      //   break;
      default:
        break;
    }

    setNewMilestone(tempNewMilestone);
  };

  const handleSave = async (e) => {
    e?.preventDefault();

    setSbShow(<></>);

    const { createdAt, createdBy, updatedAt, updatedBy, ...restMilestone } =
      newMilestone;

    if (
      !newMilestone.number ||
      !newMilestone.title ||
      !newMilestone.wpStreamId ||
      !newMilestone.forecastDate
    ) {
      handleStatusBar(
        "error",
        translate("common.error.requiredFields", "Compila i campi obbligatori"),
        false
      );
      return;
    }

    if (restMilestone.forecastDate) {
      restMilestone.forecastDate =
        restMilestone.forecastDate.length === 10
          ? restMilestone.forecastDate
          : restMilestone.forecastDate + "-01";
    }

    setIsLoading(true);
    setShowSpinner(true);

    let result = restMilestone.id
      ? await editMilestone(
          restMilestone.projectId,
          restMilestone.id,
          restMilestone
        )
      : await createMilestone(projectId, restMilestone);
    //console.log('result', result);

    if (result.success) {
      handleStatusBar(
        "success",
        translate("common.message.created", "Elemento creato con successo"),
        true
      );

      setTimeout(() => {
        setNewMilestone(result.data);
        setRefresh(true);
        handleCloseModal(setShowModalNew)
      }, 2000);
    } else {
      handleStatusBar(
        "error",
        result.success === false
          ? Object.values(result.data).join()
          : translate(
              "common.error.save",
              "Si è verificato un errore durante il salvataggio delle informazioni"
            ),
        false
      );
    }

    setIsLoading(false);
    setShowSpinner(false);
  };

  return (
    <>
      <OESimpleTable
        columnDefs={columnDefs}
        rowData={milestones}
        buttonToolbarVisible={true}
        buttonChildren={
          canEdit &&
          isConfig && (
            <Button
              variant="success"
              onClick={() => handleShowModal(setShowModalNew)}
            >
              <IcoPlus className="light-color ico-small plus new-button" />
              <span className="d-md-inline">Aggiungi Nuova Milestone</span>
            </Button>
          )
        }
        showModal={handleShowModal}
        setShowModal={setShowModalNew}
        pageSizeId={"milestones"}
        fullWidth={true}
      />

      <Modal
        show={showModalNew}
        className="modal-with-nav"
        size="lg"
        onHide={() => handleCloseModal(setShowModalNew)}
        centered
      >
        <Modal.Header closeButton>
          {/* <Modal.Title>Nuova Milestone</Modal.Title> */}
          <Modal.Title>Aggiungi</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <Tabs
            className="mb-4"
            activeKey={activeKey}
            onSelect={(k) => setActiveKey(k)}
            fill
          > */}
          {/* <Tab eventKey="registry" title={"Dettagli"}> */}
          <Form id="form-new-milestone">
            <Row className="mb-3" style={{ marginTop: "2rem" }}>
              {/* <Col className="col-4"> */}
              <Col className="col-6">
                <Form.Group as={Col} className="pe-2">
                  <Form.Label className="mb-3">
                    N. Milestone<span className="required-field">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="number"
                    value={newMilestone.number ?? ""}
                    onChange={(e) => onChange(e, "number")}
                  />
                </Form.Group>
              </Col>
              {/* <Col className="col-8"> */}
              <Col className="col-6">
                <Form.Group as={Col} className="pe-2">
                  <Form.Label className="mb-3">
                    Titolo<span className="required-field">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="title"
                    value={newMilestone.title ?? ""}
                    onChange={(e) => onChange(e, "title")}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3" style={{ marginTop: "2rem" }}>
              {/* <Col className="col-8"> */}
              <Col className="col-6">
                <Form.Group as={Col} className="pe-2">
                  <Form.Label className="mb-3">Descrizione</Form.Label>
                  <Form.Control
                    type="text"
                    name="description"
                    value={newMilestone.description ?? ""}
                    onChange={(e) => onChange(e, "description")}
                  />
                </Form.Group>
              </Col>
              {/* <Col className="col-4"> */}
              <Col className="col-6">
                <Form.Group as={Col} className="pe-2">
                  <Form.Label className="mb-3">
                    WP di riferimento<span className="required-field">*</span>
                  </Form.Label>
                  <Select
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    className="react-select-container"
                    classNamePrefix="react-select"
                    name="wpStreamId"
                    placeholder="Seleziona..."
                    options={
                      streams && streams.length !== 0
                        ? streams.sort((a, b) => a.streamId - b.streamId).map((stream) => ({
                            value: stream.id,
                            label: `${stream.streamId} - ${stream.name}`,
                          }))
                        : []
                    }
                    onChange={(selectedOption) =>
                      onChange(
                        { target: { value: selectedOption?.value || "" } },
                        "wpStreamId"
                      )
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3" style={{ marginTop: "2rem" }}>
              {/* <Col className="col-4"> */}
              <Col className="col-6">
                <Form.Group as={Col} className="pe-2">
                  <Form.Label className="mb-3">Responsabile</Form.Label>
                  <Select
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    className="react-select-container"
                    classNamePrefix="react-select"
                    name="responsible"
                    placeholder="Seleziona..."
                    options={optionsUsers}
                    onChange={(selectedOption) =>
                      onChange(
                        { target: { value: selectedOption?.value || "" } },
                        "responsible"
                      )
                    }
                    isDisabled={!newMilestone.wpStreamId}
                  />
                </Form.Group>
              </Col>
              <ForecastDateInput
                onChange={(e) => onChange(e, "forecastDate")}
                value={newMilestone?.forecastDate || ""}
                className="col-6"
              />
            </Row>

            <Row className="mb-3" style={{ marginTop: "2rem" }}>
              <Col>
                <Form.Group as={Col} className="pe-2">
                  <Form.Label>Note</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={2}
                    name="note"
                    value={newMilestone.note ?? ""}
                    onChange={(e) => onChange(e, "note")}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
          {sbShow}

          <Row>
            <Col className="col-12">
              <span className="label">
                <span className="required-field">*</span>&nbsp;
                <OETrans
                  code="common.requiredFields"
                  fallback={"Campi obbligatori"}
                />
              </span>
            </Col>
          </Row>
          {/* </Tab> */}

          {/* <Tab
              eventKey="docs"
              title={<OETrans code="common.documents" fallback={"Documenti"} />}
              disabled={!newMilestone.id}
            >
              <UploadDocument
                entity="projectmilestones"
                entityId={newMilestone.id}
              />
              {sbShow}
            </Tab> */}
          {/* </Tabs> */}
        </Modal.Body>

        <Modal.Footer>
          <Button
            disabled={isLoading}
            variant="secondary"
            onClick={() => handleCloseModal(setShowModalNew)}
          >
            <OETrans code="common.cancel" fallback={"Annulla"} />
          </Button>
          <Button
            disabled={activeKey === "docs" || isLoading}
            variant="primary"
            onClick={(e) => handleSave(e)}
          >
            {newMilestone.id ? (
              <OETrans code="common.save" fallback={"Salva"} />
            ) : (
              <OETrans code="common.create" fallback={"Crea"} />
            )}

            {showSpinner && (
              <Spinner
                as="span"
                className="ms-2"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Milestones;
