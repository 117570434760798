import {
  downloadProjectStatementInvoices,
  getStatementInvoicesOrTimesheets,
  pickUnpickAllProjectStatement,
} from "lib/api";
import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-community/core/dist/styles/ag-theme-alpine.css";
import AG_GRID_LOCALE_IT from "@components/ag-grid/locale-it";
import { formatToDecimals } from "@lib/helpers/formatting";
import {
  InputGroup,
  FormControl,
  Tooltip,
  Button,
  OverlayTrigger,
  Row,
  Col,
  ButtonToolbar,
} from "react-bootstrap";
import { Lens } from "@icons";
import { translate } from "components/translation/translation";
import { IcoCheck, IcoLens } from "icone";
import { columnCentered } from "lib/helpers/agGridHelpers";
import DataHeader from "components/DataHeader";
import DownloadExcelButton from "components/form/DownloadExcelButton";
// import { TablePagination } from "@mui/material";
import CustomPagination from "./CustomPagination";
import { debounce } from "@mui/material";
// import CustomPaginationMui from "components/materialComponents/CustomPaginationMui";

const ExpenseReportExternalCosts = ({
  projectId,
  detail,
  callback,
  pickLoading,
  setPickLoading,
  isValidated = false,
  amounts,
  selectedQuotas,
  setSelectedQuotaId,
  setShowModal,
  isShowModal,
}) => {
  const [invoices, setInvoices] = useState(null);
  const [count, setCount] = useState(0);
  // const [invoicesMeta, setInvoicesMeta] = useState({});
  const DEFAULT_PER_PAGE = 100;
  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE);
  const [defaultPerPage, setDefaultPerPage] = useState(DEFAULT_PER_PAGE);
  // console.log("invoicesMeta", invoicesMeta);
  const [loading, setLoading] = useState(true);
  //   const [pickLoading, setPickLoading] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  // const [urlParams, setUrlParams] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  // const [isBoolean, setIsBoolean] = useState({
  //   boolean: false,
  //   selected: [],
  //   unselected: [],
  // });

  // State for table
  const gridRef = useRef();
  const localeText = AG_GRID_LOCALE_IT;

  const [filterInputValue, setFilterInputValue] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  // console.log("currentPage", currentPage);
  // console.log("perPage", perPage);

  const isFirstRenderRef = useRef(true);

  // const handleRenderData = useCallback(() => {
  //   // const key = k ?? 'invoices';

  //   // console.log("rendered data");

  //   // if (detail.statementTypeCode === "ADV") return;

  //   setLoading(true);

  //   // if (key === "invoices") {
  //   getStatementInvoicesOrTimesheets(
  //     projectId,
  //     detail.id,
  //     "invoices",
  //     "invoicesStatements",
  //     searchTerm === ""
  //       ? `?page=${currentPage + 1}&perPage=${perPage}`
  //       : `?page=${currentPage + 1}&perPage=${perPage}&term=${searchTerm}`
  //   )
  //     .then((retrievedElements) => {
  //       //Confronta lo stato attuale con il nuovo stato prima di continuare

  //       console.log(
  //         "boolean",
  //         retrievedElements.success &&
  //           JSON.stringify(retrievedElements.data) !== JSON.stringify(invoices)
  //       );

  //       // console.log("JSON api", JSON.stringify(retrievedElements.data));
  //       // console.log("JSON state", JSON.stringify(invoices));

  //       if (
  //         retrievedElements.success &&
  //         JSON.stringify(retrievedElements.data) !== JSON.stringify(invoices)
  //       ) {
  //         setInvoices(retrievedElements.data);
  //         const { data, success, ...restResp } = retrievedElements;

  //         //  setInvoicesMeta(restResp);
  //         // console.log("restResp", restResp);
  //         // setInvoicesMeta(restResp);
  //         setCount(restResp.meta.total);
  //         setPerPage(restResp.meta.perPage);
  //         setDefaultPerPage(restResp.meta.perPage);
  //         // if (searchTerm !== "") {
  //         //   setCurrentPage(0);
  //         //   gridRef.current.api.paginationGoToPage(0);
  //         // }
  //         //    setOriginCheckedInvoices(retrievedElements.data.filter((el) => el.checked));
  //       }
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });

  //   // }

  //   // if (key === "timesheets") {
  //   //    getStatementInvoicesOrTimesheets(
  //   //       projectId,
  //   //       detail.id,
  //   //       "timesheets",
  //   //       "timesheetsStatements"
  //   //    ).then((retrievedElements) => {
  //   //       //Confronta lo stato attuale con il nuovo stato prima di continuare
  //   //       if (retrievedElements.success && JSON.stringify(retrievedElements.data) !== JSON.stringify(timesheets)) {
  //   //          setTimesheets(retrievedElements.data);
  //   //          const { data, success, ...restResp } = retrievedElements;

  //   //          setTimesheetsMeta(restResp);
  //   //          setOriginCheckedTimesheets(retrievedElements.data.filter((el) => el.checked));
  //   //       }
  //   //    });
  //   // }

  //   // if (key === 'general-expenses') {
  //   //    getProjectStatementGeneralExpenses(projectId, detail.id).then(
  //   //       (retrievedGeneralExpenses) => {
  //   //          if (retrievedGeneralExpenses.success) {
  //   //             setGeneralExpenses(retrievedGeneralExpenses.data.statementGe);
  //   //          }
  //   //       }
  //   //    );
  //   // }
  // }, [projectId, detail.id, invoices, currentPage, perPage, searchTerm]);

  const handleData = (params) => {
    params.api.forEachNode((node) => {
      // console.log(node);
      node.setSelected(node.data.checked === 1, false, true);
    });
  };

  const isSearchRef = useRef(false);

  useEffect(() => {
    if (isShowModal || isFirstRenderRef.current) {
      isFirstRenderRef.current = false;
      return;
    }

    // handleRenderData();

    setLoading(true);

    // if (key === "invoices") {
    getStatementInvoicesOrTimesheets(
      projectId,
      detail.id,
      "invoices",
      "invoicesStatements",
      searchTerm === ""
        ? `?page=${currentPage + 1}&perPage=${perPage}`
        : `?page=${currentPage + 1}&perPage=${perPage}&term=${searchTerm}`
    )
      .then((retrievedElements) => {
        //Confronta lo stato attuale con il nuovo stato prima di continuare

        // console.log(
        //   "boolean",
        //   retrievedElements.success &&
        //     JSON.stringify(retrievedElements.data) !==
        //       JSON.stringify(invoices) &&
        //     !isSearchRef.current
        // );

        // console.log("JSON api", JSON.stringify(retrievedElements.data));
        // console.log("JSON state", JSON.stringify(invoices));

        if (
          retrievedElements.success &&
          JSON.stringify(retrievedElements.data) !== JSON.stringify(invoices) &&
          !isSearchRef.current
        ) {
          isSearchRef.current = true;
          handleData(gridRef.current);
          setInvoices(retrievedElements.data);
          const { data, success, ...restResp } = retrievedElements;

          //  setInvoicesMeta(restResp);
          // console.log("restResp", restResp);
          // setInvoicesMeta(restResp);
          setCount(restResp.meta.total);
          setPerPage(restResp.meta.perPage);
          setDefaultPerPage(restResp.meta.perPage);
          // if (searchTerm !== "") {
          //   setCurrentPage(0);
          //   gridRef.current.api.paginationGoToPage(0);
          // }
          //    setOriginCheckedInvoices(retrievedElements.data.filter((el) => el.checked));
        } else {
          isSearchRef.current = false;
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
        // handleData(gridRef.current);
      });
  }, [
    isShowModal,
    projectId,
    detail.id,
    invoices,
    currentPage,
    perPage,
    searchTerm,
  ]);
  // }, [isShowModal, handleRenderData]);

  const StatusInfoCellRenderer = useCallback(
    (params) => {
      const isSelected =
        (params.selectedIds && params.selectedIds.includes(params.data.id)) ||
        undefined;
      // if (params.queryParam === "info" && isSelected !== undefined) {
      params.data.checked = isSelected ? 1 : 0;
      // }
      // if (params.queryParam === "info") {
      //   // console.log("params", params);
      //   // console.log("params.selectedIds", params.selectedIds);
      //   isSelected =
      //     params.selectedIds && params.selectedIds.includes(params.data.id);

      //   console.log(
      //     params.data.number,
      //     isSelected,
      //     params.data.checked,
      //     params.value
      //   );
      // }
      const green = "#689689";
      const yellow = "#D4BE31";
      let stoplightColor = "#ffffff";
      let message;

      // console.log("params.value", params.getValue());
      // console.log(
      //   "Value: ",
      //   params.value !== undefined ? params.value : "undefined"
      // );

      // console.log("selectedQuotas", selectedQuotas);

      // console.log("params.data.toSettle", params.data.toSettle);
      // console.log("params.data.toSettle", params.data.toSettle);
      // console.log("params.value", params.value);

      if (params.value !== undefined) {
        if (params.queryParam === "status") {
          if (params.value) {
            //check giallo, da validare
            stoplightColor = yellow;
            message = "Da validare";
          } else {
            //check verde, rendicontabile
            stoplightColor = green;
            message = "Rendicontabile";
          }
        } else if (
          params.queryParam === "info" &&
          params.data.checked === 1 &&
          params.queryParam === "info" &&
          params.value !== 0
        ) {
          message = "Info";
        }

        const statusInfoTooltip = (props) => (
          <Tooltip {...props}>{message}</Tooltip>
        );

        const settledComponent = () => {
          return (
            params.value !== 0 && (
              <Button
                variant="link"
                onClick={() => {
                  setSelectedQuotaId(params.data.id);
                  setShowModal(true);
                }}
              >
                <IcoLens className="edit" />
              </Button>
            )
          );
        };

        const checkIsAnalysis = () => {
          let bool = true;

          //Se è in analysisInvoices e non è stato selezionato
          if (
            params.queryParam1 === "analysisInvoices" &&
            !selectedQuotas.find((item) => item.id === params.data.id)
          ) {
            bool = false;
          }

          return bool;
        };

        return (
          message && (
            <Row className="align-items-center justify-content-center h-100">
              <Col className="col-auto text-center">
                {params.queryParam === "status" && (
                  <OverlayTrigger placement="top" overlay={statusInfoTooltip}>
                    <IcoCheck style={{ fill: stoplightColor }} />
                  </OverlayTrigger>
                )}
                {params.queryParam === "info" &&
                  // isSelected &&
                  params.data.checked === 1 &&
                  checkIsAnalysis() &&
                  settledComponent()}
              </Col>
            </Row>
          )
        );
      }
    },
    [selectedQuotas, setShowModal, setSelectedQuotaId]
  );

  const defaultColDef = {
    resizable: false,
    flex: 1,
    suppressMovable: true,
    sortable: false,
    filterParams: {
      suppressAndOrCondition: true,
      filterOptions: ["contains"],
    },
    cellStyle: {
      padding: "3px 1rem",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
  };

  const columnDefs = useMemo(
    () => [
      {
        headerName: "",
        //field: "id",
        hide: isValidated ? true : false,
        maxWidth: 50,
        resizable: false,
        headerCheckboxSelection: true,
        checkboxSelection: isValidated ? false : true,
        cellStyle: () => {
          //per disabilitare il click sulla riga in loading
          return loading || pickLoading
            ? { pointerEvents: "none", opacity: "0.4" }
            : null;
        },
        // headerComponent: CustomHeader,
        // headerComponentParams: {
        //   entityData: invoices,
        //   entityType: "invoices",
        //   //    setOriginItemList: setOriginCheckedInvoices,
        // },
      },
      {
        headerName: "Nr. Fattura",
        field: "number",
        filter: "agTextColumnFilter",
      },
      {
        headerName: "ID Fattura",
        field: "rpCode",
        filter: "agTextColumnFilter",
      },
      {
        headerName: "Fornitore",
        field: "supplierName",
        filter: "agTextColumnFilter",
      },
      {
        headerName: "Voce di spesa",
        field: "name",
        filter: "agTextColumnFilter",
        //minWidth: 100,
      },
      {
        headerName: "Importo rendicontabile €",
        field: "amount",
        filter: "agTextColumnFilter",
        type: "rightAligned",
        resizable: true,
        valueGetter: (params) => {
          return params.data.amount ? formatToDecimals(params.data.amount) : 0;
        },
      },
      {
        headerName: "Status",
        field: "toSettle",
        cellRenderer: StatusInfoCellRenderer,
        cellRendererParams: {
          queryParam: "status",
          selectedIds,
        },
        minWidth: 90,
        maxWidth: 90,
        ...columnCentered(),
      },
      {
        headerName: "Info",
        field: "toSettle",
        cellRenderer: StatusInfoCellRenderer,
        cellRendererParams: {
          queryParam: "info",
          queryParam1: "invoices",
          selectedIds,
        },
        minWidth: 90,
        maxWidth: 90,
        ...columnCentered(),
      },
    ],
    [isValidated, StatusInfoCellRenderer, loading, pickLoading, selectedIds]
  );

  // const gridRefCurrent = gridRef.current;

  // useEffect(() => {
  //   // console.log("selectedQuotas", selectedQuotas);
  //   // console.log("gridRef", gridRef.current);
  //   // console.log("gridRefApi", gridRef.current.api);

  //   if (gridRefCurrent && gridRefCurrent.api) {
  //     // console.log("gridRefApi is available", gridRefCurrent.api);
  //     //   console.log("selectedQuotas", selectedQuotas);
  //     gridRefCurrent.api.refreshCells({ force: true });
  //   } else {
  //     // console.log("gridRefApi is undefined");
  //   }

  //   // if (!gridRef.current || !gridRef.current.api) return;
  //   // console.log("selectedQuotas", selectedQuotas);
  //   // gridRef.current.api.refreshCells({ columns: ["toSettle"], force: true });
  //   // gridRef.current.api.refreshCells({ columns: ["toSettle"], force: true });
  // }, [selectedIds, gridRefCurrent]);

  const handlePickAll = async (action, rows) => {
    if (rows.length === 0) return;
    // console.log("rows", rows);
    setPickLoading(true);
    await pickUnpickAllProjectStatement(
      projectId,
      detail.id,
      "invoices",
      action,
      {
        quotaIds: rows ? rows : invoices.map((t) => t.id),
      }
    ).finally(() => {
      // callback && callback();
      setPickLoading(false);
    });
  };

  useEffect(() => {
    getStatementInvoicesOrTimesheets(
      projectId,
      detail.id,
      "invoices",
      "invoicesStatements"
    )
      .then((response) => {
        // console.log("response", response);
        if (response.data && response.success) {
          const { data, success, ...restResp } = response;
          setCount(restResp.meta.total);
          // setInvoicesMeta(restResp);
          // console.log("restResp", restResp);
          setInvoices(response.data);
          setSelectedIds(
            response.data.filter((t) => t.checked === 1).map((t) => t.id)
          );
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [projectId, detail.id]);

  const debouncedSetSearchTermRef = useRef(
    debounce((value) => {
      // console.log("debouncedSetSearchTerm", value);
      setCurrentPage(0);
      setPerPage(defaultPerPage);
      if (gridRef.current) {
        gridRef.current.api.paginationGoToPage(0);
        gridRef.current.api.paginationSetPageSize(defaultPerPage);
      }
      setSearchTerm(value); // Solo l'aggiornamento finale dopo debounce
    }, 500)
  );
  const onFilterChange = (e) => {
    const value = e.target.value;
    setFilterInputValue(value);
    // debouncedSetSearchTerm(value);
    // setSearchTerm(value);
    // if (gridRef.current) {
    //   gridRef.current.api.setQuickFilter(value);
    // }
  };

  useEffect(() => {
    debouncedSetSearchTermRef.current(filterInputValue);
  }, [filterInputValue]);

  // console.log("invoices", invoices);
  // console.log("selectedIds", selectedIds);

  //   const test = (invoices) => {
  //     console.log("invoices", invoices);
  //     console.log("isBoolean", isBoolean);
  //     if (!invoices) return [];
  //     return invoices.map((invoice) => ({
  //       ...invoice,
  //       checked: 1,
  //     }));
  //     // if (isBoolean.boolean) {
  //     //   const array = invoices.map((invoice) => {
  //     //     const rowsToSelect = [];
  //     //     console.log(
  //     //       "condition 1",
  //     //       isBoolean.selected.some((row) => row.id === invoice.id)
  //     //     );
  //     //     rowsToSelect.push(
  //     //       isBoolean.selected.some((row) => row.id === invoice.id)
  //     //         ? {
  //     //             ...invoice,
  //     //             checked: 1,
  //     //           }
  //     //         : invoice
  //     //     );
  //     //     console.log("rowsToSelect", rowsToSelect);

  //     //     const rowsToUnselect = [];
  //     //     console.log(
  //     //       "condition 2",
  //     //       isBoolean.unselected.some((row) => row.id === invoice.id)
  //     //     );
  //     //     rowsToUnselect.push(
  //     //       isBoolean.unselected.some((row) => row.id === invoice.id)
  //     //         ? {
  //     //             ...invoice,
  //     //             checked: 0,
  //     //           }
  //     //         : invoice
  //     //     );

  //     //     console.log("rowsToUnselect", rowsToUnselect);

  //     //     return [...rowsToSelect, ...rowsToUnselect];
  //     //   });
  //     //   setIsBoolean((prevIsBoolean) => ({ ...prevIsBoolean, boolean: false }));
  //     //   console.log("array", array);
  //     //   return array;
  //     // } else {
  //     //   return invoices;
  //     // }
  //     // return invoices.map((invoice) => {
  //     //   return {
  //     //     ...invoice,
  //     //     toSettle: invoice.toSettle ? 1 : 0,
  //     //   };
  //     // });
  //   };
  // console.log("invoicesMeta?.meta?.perPage", parseInt(invoicesMeta?.meta?.perPage));

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <DataHeader
          customStyle={{
            margin: 0,
            paddingTop: "5px",
            paddingBottom: 0,
            marginLeft: "15px",
          }}
          data={[
            {
              name: (
                <span className="text-nowrap">
                  Contributo richiesto eleggibile
                </span>
              ),
              value:
                Object.keys(amounts).length > 0 &&
                formatToDecimals(amounts.totalEligibles ?? 0) + " €",
            },
            {
              name: (
                <span className="text-nowrap">
                  Contributo richiesto eleggibile - elementi da validare
                </span>
              ),
              value:
                Object.keys(amounts).length > 0 &&
                formatToDecimals(amounts.totalToSettle ?? 0) + " €",
            },
          ]}
        />
        <div
          style={{
            // marginBottom: "20px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <ButtonToolbar className="justify-content-end">
            <DownloadExcelButton
              apiDownload={downloadProjectStatementInvoices}
              apiProps={[projectId, detail.id]}
              label={translate("common.downloadreport", "Scarica report")}
            />

            <InputGroup
              className="search-table align-items-center mt-md-0 ms-1"
              style={{ width: "300px" }}
            >
              <FormControl
                type="text"
                className="filter-text-box"
                placeholder={translate("common.search", "Cerca") + "..."}
                value={filterInputValue}
                onChange={onFilterChange}
              />
              <div style={{ width: "40px", textAlign: "center" }}>
                <Lens className="lens" />
              </div>
            </InputGroup>
          </ButtonToolbar>
        </div>
      </div>
      <div className="ag-theme-alpine list-table">
        <Row>
          <Col className="col-12 col-xxl-9" id="grid-wrapper">
            <AgGridReact
              ref={gridRef}
              columnDefs={columnDefs}
              rowData={invoices || []}
              //   rowData={test(invoices) || []}
              loading={loading || pickLoading}
              defaultColDef={defaultColDef}
              domLayout="autoHeight"
              tooltipShowDelay={0}
              tooltipHideDelay={2000}
              tooltipMouseTrack={true}
              colResizeDefault={"shift"}
              headerHeight={50}
              rowHeight={50}
              ariaDescription="ag-grid-table"
              suppressCellFocus={true}
              rowSelection={"multiple"}
              onFirstDataRendered={handleData}
              onGridReady={handleData}
              suppressPaginationPanel={true}
              // onCellValueChanged={(e) => {
              //   console.log("onCellValueChanged", e);
              //   e.api.redrawRows();
              // }}
              onSelectionChanged={async (e) => {
                const selectedRows = [];
                const unselectedRows = [];

                // Check if user has filtered the table
                if (e.api.getDisplayedRowCount() !== invoices.length) {
                  // If the table is filtered, we need to loop through all the nodes
                  e.api.forEachNodeAfterFilter((node) => {
                    // If the node is selected, push it to the selectedRows array
                    if (node.isSelected()) {
                      selectedRows.push(node.data);
                    } else {
                      // If the node is not selected, push it to the unselectedRows array
                      unselectedRows.push(node.data);
                    }
                  });
                } else {
                  // If user has not filtered the table, we can use the selectedRows array
                  selectedRows.push(...e.api.getSelectedRows());

                  // We can also use the unselectedRows array
                  unselectedRows.push(
                    ...invoices.filter(
                      (t) => !selectedRows.some((s) => s.id === t.id)
                    )
                  );
                }

                // console.log("selectedRows", selectedRows);
                // console.log("unselectedRows", unselectedRows);

                // Update the state with the selected IDs
                setSelectedIds((prevSelectedIds) => {
                  // Remove IDs that are in unselectedRows
                  const remainingIds = prevSelectedIds.filter(
                    (id) => !unselectedRows.some((row) => row.id === id)
                  );

                  // Add IDs from selectedRows that aren't already in the state
                  const newIds = selectedRows
                    .map((row) => row.id)
                    .filter((id) => !remainingIds.includes(id));

                  return [...remainingIds, ...newIds];
                });

                const picksPromises = [
                  handlePickAll(
                    "unpickall",
                    unselectedRows.map((s) => s.id)
                  ),
                  handlePickAll(
                    "pickall",
                    selectedRows.map((s) => s.id)
                  ),
                ];

                await Promise.all(picksPromises);
                callback && callback();

                // setInvoices((prevInvoices) => {
                //   const rowToSelect = prevInvoices.map((invoice) =>
                //     selectedRows.find((row) => row.id === invoice.id)
                //       ? { ...invoice, checked: 1 }
                //       : invoice
                //   );
                //   const rowToUnselect = prevInvoices.map((invoice) =>
                //     unselectedRows.find((row) => row.id === invoice.id)
                //       ? { ...invoice, checked: 0 }
                //       : invoice
                //   );
                //   return [...rowToSelect, ...rowToUnselect];
                // });

                // setInvoices((prevInvoices) =>
                //   prevInvoices.map((invoice) => {
                //     if (selectedRows.some((row) => row.id === invoice.id)) {
                //       return { ...invoice, checked: 1 }; // Seleziona
                //     } else if (
                //       unselectedRows.some((row) => row.id === invoice.id)
                //     ) {
                //       return { ...invoice, checked: 0 }; // Deseleziona
                //     }
                //     return invoice; // Mantieni invariato
                //   })
                // );

                // handleData(e);
              }}
              onFilterChanged={(params) => {
                // When the filter changes, we need to update the selected rows
                params.api.forEachNode((node) => {
                  node.setSelected(
                    selectedIds.includes(node.data.id),
                    false,
                    true
                  );
                });
              }}
              suppressRowClickSelection={true}
              rowMultiSelectWithClick={true}
              localeText={localeText}
              pagination={true}
              paginationPageSize={100}
              onPaginationChanged={(e, newPage) => {
                if (e.newData === undefined) return;
                // console.log("onPaginationChanged", e, newPage);
                // setCurrentPage(newPage);
                handleData(e);
              }}
              // paginationPageSize={perPage}
              // paginationPageSize={invoicesMeta?.meta?.perPage ? parseInt(invoicesMeta.meta.perPage) : 100}
              // paginationPageSize={invoicesMeta?.meta?.total || 100}
              // paginationPageSize={elementsPerPage}
              // paginateChildRows={true}
              // paginationPageSizeSelector={true}
              // paginationPageSizeList={[50, 100, 200]}
            />
            {/* <Row className="flex-row align-items-baseline">
            <Col className="col-auto ms-auto p-0">
              {loading && (
                <Spinner
                  animation="border"
                  role="status"
                  variant="dark"
                  size="sm"
                />
              )}
            </Col>
            <Col className="col-auto">
              <TablePagination
                sx={{
                  "& .MuiTablePagination-displayedRows, & .MuiTablePagination-actions":
                    {
                      display: "none",
                    },
                }}
                component="div"
                rowsPerPageOptions={[10, 50, 100, 200, 500]}
                count={invoicesMeta?.meta?.total || 0}
                page={invoicesMeta?.meta?.currentPage - 1 || 0}
                rowsPerPage={perPage}
                onPageChange={(e, newPage) => {}}
                onRowsPerPageChange={
                  (e) => {
                    const value = parseInt(e.target.value);
                    setPerPage(value);
                    gridRef.current.api.paginationSetPageSize(value);
                    setUrlParams(value > 100 ? `?perPage=${value}` : "");
                  }
                  // handleChangeRowsPerPage(
                  //    e,
                  //    setData,
                  //    dataMeta,
                  //    setDataMeta
                  // )
                }
              />
            </Col>
          </Row> */}
          </Col>
          {/* <select 
            // value={perPage}
            defaultValue={invoicesMeta?.meta?.perPage || 100}
            onChange={(e)=> {
              // setPerPage(e.target.value);
              const value = parseInt(e.target.value)
              gridRef.current.api.paginationSetPageSize(value);
              setUrlParams(value > 100 ?  `?perPage=${value}` : '');
            }}
              // setInvoicesMeta(prevInvoicesMeta => 
              //   ({...prevInvoicesMeta, 
              //     meta: {...prevInvoicesMeta.meta, perPage: e.target.value}}
              //   ))}
            >
            <option value="10">10</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="200">200</option>
          </select> */}

          <CustomPagination
            // count={invoicesMeta?.meta?.total || 0}
            count={count}
            loading={loading}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            perPage={perPage}
            setPerPage={setPerPage}
            gridRef={gridRef}
          />

          {/* <Row className="flex-row align-items-baseline mt-4">
            <Col className="col-auto ms-auto p-0">
              {loading && (
                <Spinner
                  animation="border"
                  role="status"
                  variant="dark"
                  size="sm"
                />
              )}
            </Col>
            <Col className="col-auto">
              <TablePagination
                // sx={{
                //   "& .MuiTablePagination-displayedRows, & .MuiTablePagination-actions":
                //     {
                //       display: "none",
                //     },
                // }}
                component="div"
                rowsPerPageOptions={[10, 50, 100, 200, 500]}
                count={invoicesMeta?.meta?.total || 0}
                page={currentPage}
                // page={invoicesMeta?.meta?.currentPage - 1 || 0}
                rowsPerPage={perPage}
                onPageChange={(e, newPage) => {
                  setCurrentPage(newPage);
                  if (gridRef.current) {
                    gridRef.current.api.paginationGoToPage(newPage);
                  }
                }}
                onRowsPerPageChange={
                  (e) => {
                    const value = parseInt(e.target.value);
                    setPerPage(value);
                    gridRef.current.api.paginationSetPageSize(value);
                    setUrlParams(value > 100 ? `?perPage=${value}` : "");
                  }
                  // handleChangeRowsPerPage(
                  //    e,
                  //    setData,
                  //    dataMeta,
                  //    setDataMeta
                  // )
                }
              />
            </Col>
          </Row> */}

          {/* {Object.entries(invoicesMeta).length > 0 ? (
            <CustomPaginationMui
              dataMeta={invoicesMeta}
              setDataMeta={setInvoicesMeta}
              setData={setInvoices}
              refTable="invoices"
              rowsPerPageOptions={[10, 100, 200, 500]}
              setUrlParams={setUrlParams}
            />
          ) : null} */}
        </Row>
      </div>
    </>
  );
};

export default ExpenseReportExternalCosts;
