import React, { useState, useEffect, useContext } from "react";
import {
   Button,
   OverlayTrigger,
   Tooltip,
   Modal,
   Form,
   Row,
   Col,
   InputGroup,
   Tabs,
   Tab,
} from "react-bootstrap";
import Select from "react-select";
import { IcoCanc, IcoEdit, Eye, EyeSlash, TooltipInfoIcon } from "@icons";
import StatusBar from "@components/StatusBar";
import { editUser, deleteUser } from "@lib/api/index";
import { LevelContext } from "@components/contexts/LevelContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import Impersonate from "components/atomic/organisms/Impersonate/Impersonate";
import { translate } from "components/translation/translation";
import { OETrans } from "components/translation/OETrans";

const ActionBtnRendererUsers = ({
   data,
   setRefresh,
   isOEUserManagement,
   optionsRoles,
   optionsCompanies,
   optionsPrivilegeGroups,
   optionsPrivilegeCompanies,
   optionsPrivilegeProjects,
}) => {
  const id = data.id;
  const { userRole } = useContext(LevelContext);
  const [user, setUser] = useState({});
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [selectedPrivilegeGroups, setSelectedPrivilegeGroups] = useState([]);
  const [selectedPrivilegeCompanies, setSelectedPrivilegeCompanies] = useState(
    []
  );
  const [selectedPrivilegeProjects, setSelectedPrivilegeProjects] = useState(
    []
  );
  const [selectedAccountStatus, setSelectedAccountStatus] = useState([]);
  const [selectedOtp, setSelectedOtp] = useState([]);
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [passwordInvalid, setPasswordInvalid] = useState(false);
  //const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [sbShow, setSbShow] = useState(<></>);
  const [activeKey, setActiveKey] = useState("registry");
  const [showFormModal, setShowFormModal] = useState(false);
  const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/; // eslint-disable-line
  //password con almeno una maiuscola, almeno un numero, almeno 8 caratteri, almeno un carattere speciale
  const passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/; // eslint-disable-line
  const optionsAccountStatus = [
    { value: "ACTIVE", label: "Attivo" },
    { value: "LOCKED", label: "Bloccato" },
    { value: "SUSPENDED", label: "Sospeso" },
  ];

  const optionsOtp = [
    { value: true, label: "Attivo" },
    { value: false, label: "Inattivo" },
  ];

   useEffect(() => {
      const { roles, companies, privileges, ...tempUser } = data;
      tempUser.roleIds = roles;
      tempUser.companyIds = companies;

      if (!isOEUserManagement && privileges) {
         let tempPrivileges = privileges.filter(
            (el) => el.name === "DEFAULT_PRIVILEGE"
         );

         tempUser.groupids = tempPrivileges.map((el) => el.groups).flat();
         tempUser.otherCompanyIds = tempPrivileges
            .map((el) => el.companies)
            .flat();
         tempUser.projectIds = tempPrivileges.map((el) => el.projects).flat();
      }

    setUser(tempUser);
    setSelectedAccountStatus(
      optionsAccountStatus.filter(
        (optionAccountStatus) =>
          optionAccountStatus.value === tempUser.accountStatus
      )
    );

    setSelectedOtp(
      optionsOtp.filter(
        (optionOtp) => optionOtp.value === tempUser.settings?.otpEnabled
      )
    );

    setSelectedRoles(optionsRoles.filter((optionRole) => {
         return tempUser.roleIds.some((role) => {
            return Number(optionRole.value) === Number(role.id);
         });
    }));

      setSelectedCompanies(
         optionsCompanies.filter((optionCompany) => {
            return tempUser.companyIds.some((company) => {
               return Number(optionCompany.value) === Number(company.id);
            });
         })
      );

      if (!isOEUserManagement) {
         if (tempUser.groupids) {
            setSelectedPrivilegeGroups(
               optionsPrivilegeGroups.filter((optionPrGroup) => {
                  return tempUser.groupids.some((group) => {
                     return Number(optionPrGroup.value) === Number(group.id);
                  });
               })
            );
         }

         if (tempUser.otherCompanyIds) {
            setSelectedPrivilegeCompanies(
               optionsPrivilegeCompanies.filter((optionPrCompany) => {
                  return tempUser.otherCompanyIds.some((company) => {
                     return Number(optionPrCompany.value) === Number(company.id);
                  });
               })
            );
         }

         if (tempUser.projectIds) {
            setSelectedPrivilegeProjects(
               optionsPrivilegeProjects.filter((optionPrProject) => {
                  return tempUser.projectIds.some((project) => {
                     return Number(optionPrProject.value) === Number(project.id);
                  });
               })
            );
         }
      }

    /* const userRoles = localStorage.getItem('userRoles').split(',').map(Number);
        setIsSuperAdmin(userRoles.some(el => el === 1)); */
  }, [ // eslint-disable-line
    data,
    isOEUserManagement,
    optionsCompanies,
    optionsPrivilegeGroups,
    optionsPrivilegeCompanies,
    optionsPrivilegeProjects,
    optionsRoles,
    showModalEdit,
  ]);

   const handleCloseModal = (setShowModal) => {
      setSbShow(<></>);
      setActiveKey("registry");
      setShowModal(false);
   };

   const handleShowModal = (setShowModal) => setShowModal(true);

   const handleStatusBar = (status, message, timeout) => {
      setSbShow(
         <StatusBar status={status} message={message} timeout={timeout} />
      );
   };

   const clickHandler = () => setShowPass(!showPass);

   const onChange = (e, fieldName, setSelect) => {
      //console.log('e:', e);

      let tempUser = { ...user };
      let value;

      switch (fieldName) {
         case "firstName":
         case "lastName":
         case "email":
         case "password":
            value = e.target.value;

            tempUser[fieldName] = value;
            break;
         case "companyIds":
         case "groupids":
         case "projectIds":
         case "otherCompanyIds":
            value = e ?? [];

            tempUser[fieldName] = value;

        setSelect(e);
        break;
      case "roleIds":
      case "otpEnabled":
      case "accountStatus":
        value = [e];
        tempUser[fieldName] = value;
        setSelect(e);
        break;
      default:
        value = e.target.value;
        tempUser[fieldName] = value;
    }

      setUser(tempUser);
   };

   const onBlur = (value, regex, setState) => {
      if (value && value.match(regex)) {
         setState(false);
      } else {
         setState(true);
      }
   };

   const handleEdit = async (e) => {
      e?.preventDefault();

      setSbShow(<></>);

      const {
         roles,
         roleIds,
         companyIds,
         groupids,
         projectIds,
         otherCompanyIds,
         createdAt,
         createdBy,
         updatedAt,
         updatedBy,
         ...restUser
      } = user;

      const isUserFilled = Object.values(restUser).every((prop) => prop !== "")
         ? true
         : false;

      restUser.roleIds = roleIds.map((role) => role.value || role.id);
      if (isOEUserManagement) {
         restUser.companyIds = [1];
         restUser.groupids = [];
         restUser.otherCompanyIds = [];
         restUser.projectIds = [];
      } else {
         restUser.companyIds = companyIds.map(
            (company) => company.value || company.id
         );
         if (groupids)
            restUser.groupids = groupids.map((group) => group.value || group.id);
         if (otherCompanyIds)
            restUser.otherCompanyIds = otherCompanyIds.map(
               (company) => company.value || company.id
            );
         if (projectIds)
            restUser.projectIds = projectIds.map(
               (project) => project.value || project.id
            );
      }

      if (!isUserFilled || roleIds.length === 0 || (!isOEUserManagement && companyIds.length === 0)) {
         handleStatusBar("error", translate('common.error.requiredFields', 'Compila i campi obbligatori'), false);
         return;
      }

      if (emailInvalid) {
         handleStatusBar("error", translate('common.error.email', 'Email non conforme'), false);
         return;
      }

      if (passwordInvalid) {
         handleStatusBar("error", translate('common.error.password', 'Password non conforme'), false);
         return;
      }

    // Converti account status e otp in stringa
    restUser.accountStatus = selectedAccountStatus.value ?? "ACTIVE";
    restUser.settings = {
      otpEnabled: selectedOtp.value ?? false,
    };

      let result = await editUser(restUser);

      if (result.success) {
         handleStatusBar("success", translate('common.message.edited', 'Elemento modificato con successo'), true);

         setTimeout(() => {
            setUser(result.data);
            setShowModalEdit(false);
            setRefresh(true);
         }, 2000);
      } else {
         let errorMessage = Object.values(result.data)[0].pop();
         handleStatusBar(
            "error",
            errorMessage ??
            translate('common.error.save', 'Si è verificato un errore durante il salvataggio delle informazioni'),
            false
         );
      }
   };

  const handleImpersonate = (id) => {
    setShowFormModal(true);
  };

   const handleDelete = async () => {
      const deleteResult = await deleteUser(id);
      //console.log('deleteResult', deleteResult);

      if (deleteResult.success) {
         handleStatusBar("success", translate('common.message.deleted', 'Elemento eliminato correttamente'), true);

         setTimeout(() => {
            setShowModalDelete(false);
            setRefresh(true);
         }, 2000);
      } else {
         handleStatusBar(
            "error",
            deleteResult.message ??
            translate('common.error.delete', 'Si è verificato un errore durante l’eliminazione delle informazioni'),
            false
         );
      }
   };

   const editTooltip = (props) => <Tooltip {...props}><OETrans code='common.tooltip.edit' fallback={'Modifica'}/></Tooltip>;

   const deleteTooltip = (props) => <Tooltip {...props}><OETrans code='common.tooltip.delete' fallback={'Elimina'}/></Tooltip>;

  const impersonateTooltip = (props) => (
    <Tooltip {...props}>Impersona utente</Tooltip>
  );

   return (
      <div>
         <OverlayTrigger placement="top" overlay={editTooltip}>
            <Button
               variant="link"
               className={userRole === 1 ? "me-3" : ""}
               onClick={() => handleShowModal(setShowModalEdit)}
            >
               <IcoEdit className="second-color ico-small edit" />
            </Button>
         </OverlayTrigger>
         {userRole === 1 && (
        <>
              <OverlayTrigger placement="top" overlay={deleteTooltip}>
                 <Button
                    variant="link"
                    className="me-3"
                    onClick={() => handleShowModal(setShowModalDelete)}
                 >
                    <IcoCanc className="second-color ico-small cancel" />
                 </Button>
              </OverlayTrigger>
          <OverlayTrigger placement="top" overlay={impersonateTooltip}>
            <Button
              className="mt-1"
              variant="link"
              onClick={() => handleImpersonate(data)}
            >
              <FontAwesomeIcon
                icon={faUser}
                className="second-color ico-small"
              />
            </Button>
          </OverlayTrigger>
        </>
      )}

      {userRole === 1 && (
        <Impersonate
          user={data}
          showFormModal={showFormModal}
          setShowFormModal={setShowFormModal}
        />
         )}

      {showModalEdit && (
        <Modal
          size="lg"
          className="modal-with-nav"
          show={showModalEdit}
          onHide={() => handleCloseModal(setShowModalEdit)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title className="pt-0 px-2"><OETrans code='common.edituser' fallback={'Modifica utente'}/></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Tabs
                className="mb-4"
                activeKey={activeKey}
                onSelect={(k) => setActiveKey(k)}
                fill
              >
                {/* Anagrafica */}
                <Tab eventKey="registry" title={translate('common.registry', 'Anagrafica')}>
                  <Row className="mb-2rem" style={{ marginTop: "2rem" }}>
                    <Form.Group as={Col} className="px-4">
                      <Form.Label>
                        <OETrans code='users.firstName' fallback={'Nome'} />&nbsp;<span className="required-field">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="firstName"
                        value={user.firstName}
                        onChange={(e) => onChange(e, "firstName")}
                      />
                    </Form.Group>

                           <Form.Group as={Col} className="px-4">
                              <Form.Label className="mb-3">
                                 <OETrans code='users.lastName' fallback={'Cognome'} />&nbsp;<span className="required-field">*</span>
                              </Form.Label>
                              <Form.Control
                                 type="text"
                                 name="lastName"
                                 value={user.lastName}
                                 onChange={(e) => onChange(e, "lastName")}
                              />
                           </Form.Group>
                        </Row>

                  <Row style={{ marginBottom: "40px" }}>
                    <Form.Group as={Col}>
                      <Form.Label>
                        <OETrans code='users.roleIds' fallback={'Ruolo'}/>&nbsp;<span className="required-field">*</span>
                      </Form.Label>
                      <Select
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        closeMenuOnSelect={false}
                        name="roleIds"
                        placeholder={translate('common.placeholder.select', 'Seleziona...')}
                        value={user?.roleIds?.some(role => role.id === 2) ? [{ value: 2, label: 'Admin', isDisabled: true }] : selectedRoles}
                        options={optionsRoles} //.filter(role => !isSuperAdmin ? optionsRoles.filter(role => opt.value !== 1) : optionsRoles)
                        onChange={(e) =>
                          onChange(e, "roleIds", setSelectedRoles)
                        }
                      />
                    </Form.Group>

                           <Form.Group as={Col} className="px-4">
                              <Form.Label className="mb-3">
                                 <OETrans code='users.email' fallback={'Email'} />&nbsp;<span className="required-field">*</span>
                              </Form.Label>
                              <Form.Control
                                 autoComplete="new-password"
                                 type="text"
                                 name="email"
                                 value={user.email}
                                 onChange={(e) => onChange(e, "email")}
                                 onBlur={(e) =>
                                    onBlur(e.target.value, emailRegex, setEmailInvalid)
                                 }
                                 isInvalid={emailInvalid}
                              />
                              <Form.Control.Feedback
                                 type="invalid"
                                 className="ps-1 position-absolute"
                              >
                                 {translate('common.error.email', 'email non conforme').toLowerCase()}
                              </Form.Control.Feedback>
                           </Form.Group>
                        </Row>

                        {userRole === 1 && (
                           <Row className="mb-2rem">
                              <Form.Group as={Col} className="col-6 px-4">
                                 <Form.Label className="mb-3">
                                    <OETrans code='users.password' fallback={'Password'} />
                                    <OverlayTrigger
                                       placement="top"
                                       delay={{ show: 200, hide: 400 }}
                                       overlay={
                                          <Tooltip id="button-tooltip">
                                             <OETrans code='common.passwordmustcontain' fallback={'La password deve contenere'} />:
                                             <ul
                                                style={{
                                                   textAlign: "left",
                                                   paddingLeft: "20px",
                                                   margin: "auto",
                                                }}
                                             >
                                                <li><OETrans code='common.capitalletter' fallback={'una lettera maiuscola'}/>,</li>
                                                <li><OETrans code='common.number' fallback={'un numero'}/>,</li>
                                                <li><OETrans code='common.specialcharacter' fallback={'un carattere speciale'}/>,</li>
                                                <li><OETrans code='common.minimumlength' fallback={'una lunghezza minima di 8 caratteri'}/>.</li>
                                             </ul>
                                          </Tooltip>
                                       }
                                    >
                                       <TooltipInfoIcon
                                          className="tooltip-ico"
                                          style={{ width: "1rem" }}
                                       />
                                    </OverlayTrigger>
                                 </Form.Label>
                                 <InputGroup>
                                    <Form.Control
                                       autoComplete="new-password"
                                       type={showPass ? "text" : "password"}
                                       rows={3}
                                       name="password"
                                       value={user.password ?? ""}
                                       placeholder="Password"
                                       onChange={(e) => onChange(e, "password")}
                                       /* isInvalid={
                                       user.password &&
                                       user.passwordConfirmation &&
                                       user.password !== user.passwordConfirmation
                                     } */
                                       isInvalid={user.password && passwordInvalid}
                                       onBlur={(e) =>
                                          onBlur(
                                             e.target.value,
                                             passwordRegex,
                                             setPasswordInvalid
                                          )
                                       }
                                    />
                                    <InputGroup.Text
                                       className="addon-for-number"
                                       style={{ cursor: "pointer" }}
                                    >
                                       {showPass ? (
                                          <Eye className="eye" onClick={clickHandler} />
                                       ) : (
                                          <EyeSlash className="eye" onClick={clickHandler} />
                                       )}
                                    </InputGroup.Text>
                                    <Form.Control.Feedback type="invalid" className="ps-1">
                                       {translate('common.error.password', 'password non conforme').toLowerCase()}
                                    </Form.Control.Feedback>
                                 </InputGroup>
                              </Form.Group>

                              <Form.Group as={Col} className="col-6 px-4">
                                 <Form.Label className="mb-3">
                                    <OETrans code='users.passwordConfirmation' fallback={'Conferma password'} />&nbsp;
                                    {user.password && <span className="required-field">*</span>}
                                 </Form.Label>
                                 <InputGroup>
                                    <Form.Control
                                       autoComplete="new-password"
                                       type={showPass ? "text" : "password"}
                                       rows={3}
                                       name="passwordConfirmation"
                                       value={user.passwordConfirmation ?? ""}
                                       placeholder="Password"
                                       onChange={(e) => onChange(e, "passwordConfirmation")}
                                       isInvalid={
                                          user.password &&
                                          user.passwordConfirmation &&
                                          user.password !== user.passwordConfirmation
                                       }
                                    />
                                    <InputGroup.Text
                                       className="addon-for-number"
                                       style={{ cursor: "pointer" }}
                                    >
                                       {showPass ? (
                                          <Eye className="eye" onClick={clickHandler} />
                                       ) : (
                                          <EyeSlash className="eye" onClick={clickHandler} />
                                       )}
                                    </InputGroup.Text>
                                    <Form.Control.Feedback type="invalid" className="ps-1">
                                       {translate('common.passwordmatch', 'le password non coincidono').toLowerCase()}
                                    </Form.Control.Feedback>
                                 </InputGroup>
                              </Form.Group>
                           </Row>
                        )}

                        {!isOEUserManagement && (
                           <Row className="mb-2rem">
                              <Form.Group as={Col} className="col-6 px-4">
                                 <Form.Label className="mb-3">
                                    <OETrans code='users.companyIds' fallback={'Società di appartenenza'} />&nbsp;
                                    <span className="required-field">*</span>
                                 </Form.Label>
                                 <Select
                                    components={{
                                       IndicatorSeparator: () => null,
                                    }}
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    closeMenuOnSelect={false}
                                    name="companyIds"
                                    placeholder={translate('common.placeholder.select', 'Seleziona...')}
                                    value={selectedCompanies}
                                    options={optionsCompanies.filter(
                                       (opt) => opt.value !== 1
                                    )}
                                    isMulti
                                    isClearable
                                    onChange={(e) =>
                                       onChange(e, "companyIds", setSelectedCompanies)
                                    }
                                 />
                              </Form.Group>
                           </Row>
                        )}

                        {sbShow}
                     </Tab>

                <Tab
                  eventKey="privileges"
                  title={translate('common.permissions', 'Permessi')}
                  {...(isOEUserManagement ? { disabled: true } : {})}
                >
                  <Row style={{ marginBottom: "40px" }}>
                    <Form.Group as={Col}>
                      <Form.Label><OETrans code='users.groupids' fallback={'Gruppi'} /></Form.Label>
                      <Select
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        closeMenuOnSelect={false}
                        name="groupids"
                        placeholder={translate('common.placeholder.select', 'Seleziona...')}
                        value={selectedPrivilegeGroups}
                        options={optionsPrivilegeGroups}
                        isMulti
                        isClearable
                        onChange={(e) =>
                          onChange(e, "groupids", setSelectedPrivilegeGroups)
                        }
                      />
                    </Form.Group>
                  </Row>

                           <Row className="mb-2rem">
                              <Form.Group as={Col} className="px-4">
                                 <Form.Label className="mb-3">
                                    <OETrans code='users.otherCompanyIds' fallback={'Società'}/>
                                 </Form.Label>
                                 <Select
                                    classNamePrefix="react-select"
                                    closeMenuOnSelect={false}
                                    name="otherCompanyIds"
                                    placeholder={translate('common.placeholder.select', 'Seleziona...')}
                                    value={selectedPrivilegeCompanies}
                                    options={optionsPrivilegeCompanies}
                                    isMulti
                                    isClearable
                                    onChange={(e) =>
                                       onChange(
                                          e,
                                          "otherCompanyIds",
                                          setSelectedPrivilegeCompanies
                                       )
                                    }
                                 />
                              </Form.Group>
                           </Row>

                           <Row className="mb-5">
                              <Form.Group as={Col} className="px-4">
                                 <Form.Label className="mb-3">
                                    <OETrans code='users.projectIds' fallback={'Progetti'}/>
                                 </Form.Label>
                                 <Select
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    closeMenuOnSelect={false}
                                    name="projectIds"
                                    placeholder={translate('common.placeholder.select', 'Seleziona...')}
                                    value={selectedPrivilegeProjects}
                                    options={optionsPrivilegeProjects}
                                    isMulti
                                    isClearable
                                    onChange={(e) =>
                                       onChange(
                                          e,
                                          "projectIds",
                                          setSelectedPrivilegeProjects
                                       )
                                    }
                                 />
                              </Form.Group>
                           </Row>

                  {sbShow}
                </Tab>
                <Tab
                  eventKey="Settings"
                  title={"Impostazioni"}
                  {...(userRole !== 1) ? { disabled: true } : {}}
                >
                  <Row style={{ marginBottom: "40px" }}>
                    <Form.Group as={Col}>
                      <Form.Label>Stato Account</Form.Label>
                      <Select
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        closeMenuOnSelect={false}
                        name="status"
                        placeholder="Seleziona..."
                        value={selectedAccountStatus}
                        options={optionsAccountStatus}
                        onChange={(e) =>
                          onChange(e, "accountStatus", setSelectedAccountStatus)
                        }
                      />
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Label>Servizio Otp</Form.Label>
                      <Select
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        closeMenuOnSelect={false}
                        name="otpEnabled"
                        placeholder="Seleziona..."
                        value={selectedOtp}
                        options={optionsOtp}
                        onChange={(e) =>
                          onChange(e, "otpEnabled", setSelectedOtp)
                        }
                      />
                    </Form.Group>
                  </Row>
                </Tab>
              </Tabs>
            </Form>

                  <Row className="mt-5 mb-2">
                     <span className="mx-5">
                        <span className="required-field">*</span>&nbsp;<OETrans code='common.requiredFields' fallback={'Campi obbligatori'}/>
                     </span>
                  </Row>
               </Modal.Body>

               <Modal.Footer>
                  <Button
                     variant="secondary"
                     onClick={() => handleCloseModal(setShowModalEdit)}
                  >
                     <OETrans code='common.cancel' fallback={'Annulla'}/>
                  </Button>
                  <Button variant="primary" onClick={(e) => handleEdit(e)}>
                     <OETrans code='common.edit' fallback={'Modifica'}/>
                  </Button>
               </Modal.Footer>
            </Modal>
         )}

         <Modal
            show={showModalDelete}
            onHide={() => handleCloseModal(setShowModalDelete)}
            centered
         >
            <Modal.Header closeButton>
               <Modal.Title><OETrans code='common.warning' fallback={'Attenzione'}/></Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <div className="my-3">
                  <span><OETrans code='common.warning.delete' fallback={'Sei sicuro di voler eliminare questo elemento?'} /></span>
               </div>
               {sbShow}
            </Modal.Body>
            <Modal.Footer>
               <Button
                  variant="secondary"
                  onClick={() => handleCloseModal(setShowModalDelete)}
               >
                  <OETrans code='common.cancel' fallback={'Annulla'}/>
               </Button>
               <Button variant="danger" onClick={() => handleDelete()}>
                  <OETrans code='common.delete' fallback={'Elimina'}/>
               </Button>
            </Modal.Footer>
         </Modal>
      </div>
   );
};
export default ActionBtnRendererUsers;
