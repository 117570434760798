import React, { useState, useEffect, Fragment, useCallback } from "react";
import { Button, Modal, Row, Col, Form, Tabs, Tab, OverlayTrigger, Tooltip } from "react-bootstrap";
import Select from "react-select";
import { InputNumber } from "primereact";
import StatusBar from "@components/StatusBar";
import { IcoPlus, IcoMinusCircled, TooltipInfoIcon } from "@icons";
import {
   getCompanies,
   getVatRates,
   getNotices,
   getProjectGroups,
   getUsers,
   getLocations,
   createProject,
   editProject,
   getAllClusters,
   getProjectClusters,
   editProjectCluster,
   createStream,
   editStream,
   deleteStream,
   getAllStreams,
   getDnshCompassClimateMitigations,
   getDnshCompassClimateAdaptations,
   getDnshElements,
   getGlobalConfigs,
   getProjectPartners,
} from "@lib/api";
import UploadDocument from "components/form/upload/uploadDocuments";
import { translate } from "components/translation/translation";
import { OETrans } from "components/translation/OETrans";
import Deliverable from "components/atomic/organisms/Deliverable/Deliverable";
import Milestones from "components/advancement/Milestones";
import PartnershipTab from "./Partnership/PartnershipTab";
import { dateFormatterInput, dateFormatter } from "lib/helpers/formatting";

const initialState = {
   name: "",
   companyId: "",
   manager: "",
   cup: "",
   sco: false,
   groupids: [], //non su dbdiagram
   noticeId: "",
   oeManagerId: "",
   durationInMonths: "",
   network: false,
   locationCode: null,
   forecastStartDate: "",
   forecastEndDate: "",
   startDate: "",
   endDate: "",
   description: "",
   dnshClimateMitigation: true,
   dnshClimateAdaptation: true,
   dnshMitigationSector: "",
   dnshMitigationActivity: "",
   dnshMitigationActivityNumber: "",
   dnshAdaptationSector: "",
   dnshAdaptationActivity: "",
   dnshAdaptationActivityNumber: "",
   hasPartnership: false,
   streamConfigurationType: "STREAM",
   //dnshElement: '',
   //note: '',
};

function removeWordFromSentence(inputString, wordToRemove) {
   const regex = new RegExp(wordToRemove, "gi");
   return inputString.replace(regex, "");
}

const ProjectModal = ({
   setRefresh,
   showModalProject,
   setShowModalProject,
   project,
   setProjectHeader,
}) => {
   const path = window.location;
   const [thisProject, setThisProject] = useState({});
   const [selectCompany, setSelectCompany] = useState([]);
   const [selectVatRate, setSelectVatRate] = useState([]);
   const [selectNotice, setSelectNotice] = useState([]);
   const [selectedGroups, setSelectedGroups] = useState([]);
   const [selectManager, setSelectManager] = useState([]);
   const [optionsAllLocations, setOptionsAllLocations] = useState([]);
   const [selectedRegion, setSelectedRegion] = useState([]);
   const [selectLocation, setSelectLocation] = useState([]);
   const [clusters, setClusters] = useState([]);
   const [optionsCompanies, setOptionsCompanies] = useState([]);
   const [optionsVatRates, setOptionsVatRates] = useState([]);
   const [optionsGroups, setOptionsGroups] = useState([]);
   const [optionsNotices, setOptionsNotices] = useState([]);
   const [optionsManagers, setOptionsManagers] = useState([]);
   const [optionsRegions, setOptionsRegions] = useState([]);
   const [optionsClusters, setOptionsClusters] = useState([]);
   const [activeKey, setActiveKey] = useState("registry");
   const [streams, setStreams] = useState([]);
   const [originStreams, setOriginStreams] = useState([]);
   const [optionsPartners, setOptionsPartners] = useState([]);
   const [isAiEnabledGlobal, setIsAiEnabledGlobal] = useState(false);
   const [sbShow, setSbShow] = useState(<></>);
   const [optionsDnshClimateAdaptation, setOptionsDnshClimateAdaptation] = useState([]);
   const [optionsDnshClimateMitigation, setOptionsDnshClimateMitigation] = useState([]);
   const [selectedDnshClimateAdaptation, setSelectedDnshClimateAdaptation] = useState([]);
   const [selectedDnshClimateMitigation, setSelectedDnshClimateMitigation] = useState([]);
   const [optionsDnshElements, setOptionsDnshElements] = useState([]);
   const [selectedDnshElement, setSelectedDnshElement] = useState([]);
   const [isPnrr, setIsPnrr] = useState(false);
   const [nameIsInvalid, setNameIsInvalid] = useState(false);
   //console.log('project', project);
   //console.log('thisProject', thisProject);
   //console.log('streams', streams);
   //console.log('optionsPartners', optionsPartners);

   const funcCleanStreams = useCallback(() => {
      if (thisProject?.id && thisProject.streamConfigurationType === 'WP') {
         let tempStreams = [...streams];

         tempStreams = tempStreams.map(wp => {
            return {
               ...wp,
               tasks: wp.tasks?.filter(task => !task.hasOwnProperty('fakeTask') || !task.fakeTask) //Filtro per escludere i fakeTasks
            };
         });

         return tempStreams;
      } else {
         return streams;
      }
   }, [streams, thisProject?.id, thisProject.streamConfigurationType]);
   
   const handleCloseModal = (setShowModal) => {
      setSbShow(<></>);
      setActiveKey("registry");
      setShowModal(false);

      if (path.pathname.includes("projects")) {
         setRefresh(true);
         setThisProject(initialState);
         setSelectCompany([]);
         setSelectVatRate([]);
         setSelectNotice([]);
         setSelectManager([]);
         setSelectLocation([]);
         setClusters([]);
      }
   };

   const handleStatusBar = (status, message, timeout) => {
      setSbShow(
         <StatusBar status={status} message={message} timeout={timeout} />
      );
   };

   const handleChecked = useCallback((field) => {
      let obj = project ?? thisProject;
      let boolean = false;

      if (obj && obj.streamConfigurationType && obj.streamConfigurationType === field) {
         boolean = true;
      }

      return boolean;
   }, [project, thisProject]);

   useEffect(() => {
      if (showModalProject) {
         let tempProject = {};

         if (project) {
            const { groups, ...restProject } = project;
            restProject.groupids = groups;
            tempProject = {
               ...restProject,
            };
         } else {
            tempProject = initialState;
         }

         setThisProject(tempProject);

      getGlobalConfigs("AI_ENABLED").then((retrievedConfigs) => {
        if (retrievedConfigs.success) {
          setIsAiEnabledGlobal(retrievedConfigs.data.value);
        }
      });

         if (project && project.id) {
            getAllStreams(project.id).then((streams) => {
               let tempStreams = JSON.parse(
                  JSON.stringify(streams.data)
               );

               tempStreams.forEach(stream => {
                  if (stream.tasks.length === 0) {
                     stream.tasks = [
                        {
                           name: "",
                           objective: "",
                           responsibles: [],
                           participants: [],
                           startDate: null,
                           endDate: null,
                           fakeTaskId: stream.id || stream.fakeId,
                           configurationType: stream.configurationType,
                           fakeTask: true,
                        },
                     ];
                  }
               });

               setStreams(tempStreams);
               setOriginStreams(tempStreams);
            });

            getProjectPartners(project.id).then((retrievedPartners) => {
               setOptionsPartners(
                  retrievedPartners.data.map(partner => ({
                     value: partner.id,
                     label: partner.company.name,
                  }))
               );
            });
         }

         getCompanies().then((companies) => {
            if (companies.success === true) {
               setOptionsCompanies(
                  companies.data.map((company) => ({
                     value: company.id,
                     label: company.name,
                     aiEnabled: company.aiEnabled,
                  }))
               );
            }
         });

         getVatRates().then((vatRates) => {
            if (vatRates.success === true) {
               setOptionsVatRates(
                  vatRates.data.map((vatRate) => ({
                     value: vatRate.code,
                     label: vatRate.name,
                  }))
               );
            }
         });

         getNotices().then((notices) => {
            if (notices.success === true) {
               setOptionsNotices(
                  notices.data.map((notice, i) => ({
                     value: notice.id,
                     label: notice.call,
                     fund: notice.fund.code,
                     // fund: i === 0 ? 'rrr' : notice.fund.code,
                  }))
               );
            }
         });

         getUsers().then((users) => {
            if (users.success === true) {
               setOptionsManagers(
                  users.data.map((user) => ({
                     value: user.id,
                     label: user.firstName + " " + user.lastName,
                  }))
               );
            }
         });

         getLocations().then((locations) => {
            if (locations.success === true) {
               const tempLocations = [...locations.data];

               setOptionsAllLocations(
                  tempLocations
                     .sort((a, b) => a.nomeComune.localeCompare(b.nomeComune))
                     .map((location) => ({
                        value: location.codComuneNumerico,
                        label: location.nomeComune,
                        codRegione: location.codRegione,
                     }))
               );

               const seen = new Set();

               const onlyRegions = tempLocations.filter((location) => {
                  const duplicate = seen.has(location.codRegione);
                  seen.add(location.codRegione);
                  return !duplicate;
               });

               setOptionsRegions(
                  onlyRegions
                     .sort((a, b) => a.nomeRegione.localeCompare(b.nomeRegione))
                     .map((regions) => ({
                        value: regions.codRegione,
                        label: regions.nomeRegione,
                     }))
               );
            }
         });

         getDnshCompassClimateAdaptations().then((dnshClimateAdaptations) => {
            if (dnshClimateAdaptations.success === true) {
               setOptionsDnshClimateAdaptation(
                  dnshClimateAdaptations.data.map((dnshClimateAdaptation) => ({
                     label: `${dnshClimateAdaptation.sector} - ${dnshClimateAdaptation.activity}`,
                     value: dnshClimateAdaptation.activityNumber,
                     sector: dnshClimateAdaptation.sector,
                     activity: dnshClimateAdaptation.activity,
                  }))
               );
            }
         });

         getDnshCompassClimateMitigations().then((dnshClimateMitigations) => {
            if (dnshClimateMitigations.success === true) {
               setOptionsDnshClimateMitigation(
                  dnshClimateMitigations.data.map((dnshClimateMitigation) => ({
                     label: `${dnshClimateMitigation.sector} - ${dnshClimateMitigation.activity}`,
                     value: dnshClimateMitigation.activityNumber,
                     sector: dnshClimateMitigation.sector,
                     activity: dnshClimateMitigation.activity,
                  }))
               );
            }
         });

         getDnshElements().then((dnshElements) => {
            if (dnshElements.success === true) {
               setOptionsDnshElements(
                  dnshElements.data.map((dnshElement) => ({
                     label: `${dnshElement.name}`,
                     value: dnshElement.code,
                  }))
               );
            }
         });
      }
   }, [showModalProject, project]);

   useEffect(() => {
      if (thisProject.companyId) {
         //solo gruppi disponibili in base alla società scelta
         getProjectGroups(thisProject.companyId).then((groups) => {
            if (groups.success === true) {
               let tempOptionsGroups = groups.data.map((group) => ({
                  value: group.id,
                  label: group.name,
               }));

               setOptionsGroups(tempOptionsGroups);

               if (thisProject.id) {
                  //gruppi associati
                  setSelectedGroups(
                     tempOptionsGroups.filter((option) => {
                        return thisProject.groupids.some((group) => {
                           return (group.id || group.value) === option.value;
                        });
                     })
                  );
               }
            }
         });

         getAllClusters(thisProject.companyId).then((clusters) => {
            let tempOptionsClusters = clusters.data.map((cluster) => ({
               value: cluster.id,
               label: cluster.name,
            }));

            setOptionsClusters(tempOptionsClusters);

            if (thisProject.id) {
               //cluster associati
               getProjectClusters(thisProject.id).then((assClusters) => {
                  setClusters(
                     tempOptionsClusters.filter((option) => {
                        return assClusters.data.some((assCluster) => {
                           return assCluster.id === option.value;
                        });
                     })
                  );
               });
            }
         });
      }
   }, [thisProject.companyId, thisProject.groupids, thisProject.id]);

  useEffect(() => {
    if (thisProject.id) {
      setSelectCompany(
        optionsCompanies.filter(
          (option) => option.value === thisProject.companyId
        )
      );

      setSelectVatRate(
        optionsVatRates.filter((option) => option.value === thisProject.vatRate)
      );

         setSelectManager(optionsManagers.filter((option) => option.value === thisProject.oeManagerId));

         setSelectNotice(...optionsNotices.filter((option) => option.value === thisProject.noticeId));

         setSelectedDnshClimateAdaptation(
            optionsDnshClimateAdaptation.filter(
               (option) => option.value === thisProject.dnshAdaptationActivityNumber
            )
         );
         setSelectedDnshClimateMitigation(
            optionsDnshClimateAdaptation.filter(
               (option) => option.value === thisProject.dnshMitigationActivityNumber
            )
         );
         setSelectedDnshElement(
            optionsDnshElements.filter(
               (option) => option.value === thisProject.dnshElement
            )
         );
      }
   }, [
      optionsCompanies,
      optionsManagers,
      optionsNotices,
      optionsVatRates,
      thisProject,
      optionsDnshClimateAdaptation,
      optionsDnshClimateMitigation,
      optionsDnshElements,
   ]);

   useEffect(() => {
      handleChecked();
   }, [handleChecked]);

   useEffect(() => {
      if (thisProject.id) {
         setSelectLocation(
            optionsAllLocations.filter((opt) => opt.value === thisProject.locationCode)
         );
      }
   }, [optionsAllLocations, thisProject]);

   useEffect(() => {
      if (project && project.id && project.location) {
         setSelectedRegion(
            optionsRegions.filter((option) => option.value === project.location.codRegione)
         );
      }
   }, [optionsRegions, project]);

   useEffect(() => {
      if (selectNotice) {
         setIsPnrr(selectNotice?.fund === "PNRR");
      }
   }, [selectNotice]);

   const onChange = (e, index, fieldName, setSelect, list, parentIndex, taskSelect) => {
      //e, i, "wp", setItems, items
      //e, index, "wp", setItems, el.tasks, i
      //console.log('e:', e);

      let tempNewProject = { ...thisProject };
      let tempList = [];
      let value;

      switch (fieldName) {
         case "name":
         case "description":
         case "note":
         case "manager":
         case "cup":
         case "localProjectCode":
         case "projectCodeStamp":
         case "applicant":
         case "applicantEmail":
         case "networkNote":
            value = e.target.value;
            tempNewProject[fieldName] = value;
            break;
         case "forecastStartDate":
         case "forecastEndDate":
         case "startDate":
         case "endDate":
            value = e.target.value === "" ? null : e.target.value;
            tempNewProject[fieldName] = value;
            break;
         case "sco":
         case "aiEnabled":
         case "hasPartnership":
            value = e.target.checked;
            tempNewProject[fieldName] = value;
            break;
         case "network":
            value = e.target.checked;
            tempNewProject[fieldName] = value;

        if (!value) {
          tempNewProject.networkNote = "";
        } else {
          tempNewProject.locationCode = "";
          setSelectLocation([]);
          setSelectedRegion([]);
        }
        break;
      case "dnshClimateAdaptation":
      case "dnshClimateMitigation":
        value = e.target.checked;
        tempNewProject[fieldName] = value;
        if (!value) {
          const correctObjProperty = removeWordFromSentence(
            fieldName,
            "Climate"
          );
          tempNewProject[`${correctObjProperty}ActivityNumber`] = "";
          tempNewProject[`${correctObjProperty}Sector`] = "";
          tempNewProject[`${correctObjProperty}Activity`] = "";
          setSelect([]);
        }
        break;
      case "Adaptation":
      case "Mitigation":
        tempNewProject[`dnsh${fieldName}ActivityNumber`] = e ? e.value : "";
        tempNewProject[`dnsh${fieldName}Sector`] = e ? e.sector : "";
        tempNewProject[`dnsh${fieldName}Activity`] = e ? e.activity : "";

            setSelect(e ?? []);
            break;
         case "streamConfigurationType":
            value = e.target.id;

            tempNewProject[fieldName] = value;
            break;
         case "durationInMonths":
            value = Number(e.target.value);

            tempNewProject[fieldName] = value;
            break;
         case "companyId":
         case "vatRate":
         case "oeManagerId":
         case "dnshElement":
            value = e ? e.value : null;
            tempNewProject[fieldName] = value;

            setSelect(e ?? []);

            if (fieldName === "companyId") {
               tempNewProject.groupids = [];
               setSelectedGroups([]);
            }

            break;
         case "locationCode":
         case "regione":
            setSelect(e ? [e] : []);

            if (fieldName === "regione") {
               tempNewProject.locationCode = "";
               setSelectLocation([]);
            } else if (fieldName === "locationCode") {
               value = e ? e.value : "";
               tempNewProject[fieldName] = value;
            }

            break;
         case "groupids":
            value = e ?? "";
            tempNewProject[fieldName] = value;

            setSelect(e ?? []);
            break;
         case "noticeId":
            value = e ? e.value : "";
            tempNewProject[fieldName] = value;

            if (e.fund !== "PNRR") {
               setSelectedDnshElement([]);
               tempNewProject["dnshElement"] = "";
            }

            setSelect(e ?? []);
            break;
         case "streams":
         case "businessAreaIds":
            tempList = [...list];

            if (fieldName === "streams") {
               tempList[index] = {
                  ...tempList[index],
                  [e.originalEvent ? e.originalEvent.target.name : e.target.name]:
                     e.originalEvent ? Number(e.value) : e.target.value,
               };
            } else if (fieldName === "businessAreaIds") {
               tempList = e ?? [];
            }

            setSelect(tempList); //as setList
            break;
         case "wp":
            tempList = [...list];
            let obj = e.originalEvent ? e.originalEvent : e;
            value = e.originalEvent ? Number(e.value) : (e.target ?
               ((e.target.name === 'startDate' || e.target.name === 'endDate') && e.target.value ?
                  dateFormatterInput(e.target.value) : e.target.value) : e.map(e => ({ id: e.value, name: e.label })) ?? []);

            /* if (taskSelect === 'responsibles') {
               value = value.map(el => {
                  return {
                    ...el,
                    users: users.filter(user => user.id === el.id),
                  }});
            } */

            let tempElement = {
               ...tempList[index],
               [obj.target ? obj.target.name : 'responsibles']: value,
               projectId: thisProject.id,
            };

            tempList[index] = tempElement;

            setSelect(tempList); //as setList
            break;
         case "tasks":
            let tempAllWp = [...streams];
            let tempListOfSubs = [...list]; //list of subs
            let object = e.originalEvent ? e.originalEvent : e;
            value = e.originalEvent ? Number(e.value) : (e.target ? e.target.value : e.map(e => ({ id: e.value, name: e.label })) ?? []);
            const { streamId, id, ...restTempItemOfSubs } = tempListOfSubs[index];

            tempListOfSubs[index][object.target ? object.target.name : taskSelect] = value;
            if (tempListOfSubs[index].hasOwnProperty('fakeTask')) tempListOfSubs[index].fakeTask = !Object.values(restTempItemOfSubs).some(el => el !== null && el !== ''); //per escludere o no il task fake nel salvataggio

            tempAllWp[parentIndex].tasks = tempListOfSubs;

            setSelect(tempAllWp); //as setList
            break;
         default:
            break;
      }

      setThisProject(tempNewProject);
   };

   const onAdvancedChange = (e, fieldName, setSelect, elId) => {
      switch (fieldName) {
         case 'advancedSettings':
            const indexOfConfig = thisProject.advancedConfig.findIndex(conf => Number(conf.id) === Number(elId));
            thisProject.advancedConfig[indexOfConfig].enabled = e.target.checked ? 1 : 0;

            setSelect(thisProject);
            break;
         default:
            break;
      }
   };

   const onBlur = (value, setState) => {
      if (value && value.includes("/")) {
         setState(true);
      } else {
         setState(false);
      }
   };

   const handleSaveProject = async (e) => {
      e?.preventDefault();

      setSbShow(<></>);

      if (
         !thisProject.name ||
         !thisProject.companyId ||
         !thisProject.noticeId ||
         thisProject.groupids.length === 0 ||
         !thisProject.cup ||
         /* !thisProject.durationInMonths || */
         !thisProject.forecastEndDate ||
         !thisProject.forecastStartDate ||
         !thisProject.streamConfigurationType ||
         (!thisProject.network && !thisProject.locationCode)
      ) {
         handleStatusBar("error", translate('common.error.requiredFields', 'Compila i campi obbligatori'), false);
         return;
      }

      if (thisProject.name && thisProject.name.includes("/")) {
         handleStatusBar(
            "error",
            'Carattere vietato: il nome progetto non può contenere il simbolo "/"',
            false
         );
         return;
      }

      if (thisProject.forecastStartDate && thisProject.forecastEndDate) {
         if (new Date(thisProject.forecastStartDate).getTime() > new Date(thisProject.forecastEndDate).getTime()) {
            handleStatusBar(
               "error",
               "L'avvio previsto deve avere una data antecedente alla fine prevista",
               false
            );
            return;
         }

         if (new Date(thisProject.forecastStartDate).getFullYear() < 2000 ||
            new Date(thisProject.forecastEndDate).getFullYear() > 2100) {
            handleStatusBar(
               "error",
               "L'anno di inizio e di fine prevista devono essere compresi tra il 2000 e il 2100",
               false
            );
         }

      }

      if (thisProject.endDate) {
         if (new Date(thisProject.startDate).getTime() > new Date(thisProject.endDate).getTime()) {
            handleStatusBar(
               "error",
               "L'avvio effettivo deve avere una data antecedente alla fine effettiva",
               false
            );
            return;
         }

         if (new Date(thisProject.startDate).getFullYear() < 2000 ||
            new Date(thisProject.endDate).getFullYear() > 2100) {
            handleStatusBar(
               "error",
               "L'anno di inizio e di fine effettiva devono essere compresi tra il 2000 e il 2100",
               false
            );
         }
      }

      if (
         (thisProject.dnshClimateMitigation === true &&
            !thisProject.dnshMitigationActivityNumber) ||
         (thisProject.dnshClimateAdaptation === true &&
            !thisProject.dnshAdaptationActivityNumber)
      ) {
         handleStatusBar(
            "error",
            "Selezionare un'attività nel campo Dnsh Adaptation e/o Dnsh Mitigation oppure disabilitare le opzioni",
            false
         );
         return;
      }

      const { createdAt, createdBy, updatedAt, updatedBy, ...restProject } = thisProject;

      restProject.groupids = restProject.groupids.map((group) => group.value || group.id);

      if (project && thisProject.dnshElement === project.dnshElement) {
         delete restProject.dnshElement;
      }

      if (thisProject.network && !thisProject.locationCode) {
         delete restProject.locationCode;
      }

      if (thisProject.id && thisProject.streamConfigurationType === 'WP') {
         const tempStreams = funcCleanStreams();

         const AllFieldsArePopulatedWp = tempStreams.every(wp => wp.streamId !== '' && wp.name && wp.startDate && wp.endDate);

         if (!AllFieldsArePopulatedWp) {
            handleStatusBar(
               "error",
               translate('common.error.requiredFields', 'Compila i campi obbligatori'),
               false
            );
            return;
         }

         const AllConditionsSatisfiedTasks = tempStreams.every(wp => {
            const projStartDate = new Date(thisProject.startDate || thisProject.forecastStartDate).toISOString();
            const projEndDate = new Date(thisProject.endDate || thisProject.forecastEndDate).toISOString();
            const wpStartDate = new Date(wp.startDate).toISOString();
            const wpEndDate = new Date(wp.endDate).toISOString();

            if (wpStartDate < projStartDate) {
               handleStatusBar(
                  "error",
                  `La data di inizio attività non può essere antecedente al ${dateFormatter(projStartDate)}`,
                  false
               );

               return false;
            }

            if (wpEndDate > projEndDate) {
               handleStatusBar(
                  "error",
                  `La data di fine attività non può essere successiva al ${dateFormatter(projEndDate)}`,
                  false
               );

               return false;
            }

            if (wpStartDate > wpEndDate) {
               handleStatusBar(
                  "error",
                  "La data di inizio attività non può essere maggiore della data di fine attività",
                  false
               );

               return false;
            }

            const datiValidi = wp.tasks.every(task => {
               const taskStartDate = new Date(task.startDate).toISOString();
               const taskEndDate = new Date(task.endDate).toISOString();

               if (taskStartDate > taskEndDate) {
                  handleStatusBar(
                     "error",
                     "L'anno di inizio e di fine attività dei task devono essere compresi nel range di date del WP appartenente",
                     false
                  );
                  return false;
               }

               if (taskStartDate < wpStartDate || taskEndDate > wpEndDate) {
                  handleStatusBar(
                     "error",
                     "L'anno di inizio e di fine attività dei task devono essere compresi nel range di date del WP appartenente",
                     false
                  );
                  return false;
               }

               return true;
            });

            return datiValidi;
         });

         if (!AllFieldsArePopulatedWp || !AllConditionsSatisfiedTasks) return;
      }

      if (thisProject.id) {
         if (streams.length > 0) {
            const set = new Set();
            const isNotNameStreamsUnique = streams.some(
               (object) => set.size === (set.add(object.streamId), set.size)
            );

            if (isNotNameStreamsUnique) {
               handleStatusBar('error', 'Gli ID non possono essere uguali', false);
               return;
            }
         }

         const editResult = await editProject(restProject.id, restProject);

         if (editResult.success) {
            const { groups, ...restResult } = editResult.data;
            const newResult = { groupids: groups, ...restResult };

            setThisProject(newResult);

            if (setProjectHeader) setProjectHeader(editResult.data);
            setNameIsInvalid(false);

            const dataClusters = {
               businessAreaIds: clusters.map((cluster) => cluster.value || cluster.id),
            };

            let resultOfActions = [true];
            let resultOfMessages = [];

            let streamsCleaned = funcCleanStreams();

            let [streamResults, clustersResult] = await Promise.all([
               handleItemList(
                  "streams",
                  originStreams,
                  streamsCleaned,
                  deleteStream,
                  editStream,
                  createStream,
                  thisProject.id
               ),
               editProjectCluster(editResult.data.id, dataClusters),
            ]);
            //console.log('streamResults', streamResults);
            //console.log('clustersResult', clustersResult);

            const resultOfAll = resultOfActions.concat(
               streamResults ? streamResults.resultOfActions : [],
               clustersResult.success ? [true] : [false]
            );
            const resultOfAllMessages = resultOfMessages.concat(
               streamResults ? streamResults.messageResult : [],
               !clustersResult.success ? [clustersResult.message] : []
            );
            //console.log('resultOfAll', resultOfAll);
            //console.log('resultOfAllMessages', resultOfAllMessages);

            if (setRefresh) setRefresh(true);

            if (resultOfAll.length === 0 ||
               (resultOfAll.length > 0 && resultOfAll.every((el) => el === true))) {
               getAllStreams(editResult.data.id).then((retrievedStreams) => {
                  //console.log('retrievedStreams', retrievedStreams);
                  let tempStreams = [...retrievedStreams.data];

                  tempStreams.forEach((stream) => {
                     if (stream.tasks.length === 0) {
                        stream.tasks = [
                           {
                              name: "",
                              objective: "",
                              responsibles: [],
                              participants: [],
                              startDate: null,
                              endDate: null,
                              fakeTaskId: stream.id || stream.fakeId,
                              configurationType: stream.configurationType,
                              fakeTask: true,
                           },
                        ];
                     }
                  });

                  setStreams(tempStreams);
                  setOriginStreams(retrievedStreams.data);
               });

        if (resultOfAll.length > 0 && resultOfAll.every((el) => el === true)) {
          handleStatusBar(
            "success",
            translate(
              "common.message.edited",
              "Elemento modificato con successo"
            ),
            true
          );
        } else {
          /* handleStatusBar(
                  'error',
                  streamResults.messageResult?.success === false
                     ? Object.values(streamResults.messageResult.data).join()
                     : translate('common.error.save', 'Si è verificato un errore durante il salvataggio delle informazioni'),
                  false
               ); */
          handleStatusBar(
            "error",
            resultOfAllMessages.length > 0
              ? Object.values(resultOfAllMessages[0].data).join()
              : translate(
                  "common.error.save",
                  "Si è verificato un errore durante il salvataggio delle informazioni"
                ),
            false
          );
        }
      } else {
        handleStatusBar(
          "error",
          editResult.success === false
            ? Object.values(editResult.data).join()
            : translate(
                "common.error.save",
                "Si è verificato un errore durante il salvataggio delle informazioni"
              ),
          false
        );
      }
         }
      } else {
      const result = await createProject(restProject);

         if (result.success) {
            const { groups, ...restResult } = result.data;
            const newResult = { groupids: groups, ...restResult };

            setThisProject(newResult);

            if (setProjectHeader) setProjectHeader(result.data);
            setNameIsInvalid(false);

            const dataClusters = {
               businessAreaIds: clusters.map(
                  (cluster) => cluster.value || cluster.id
               ),
            };
            const clustersResult = await editProjectCluster(
               result.data.id,
               dataClusters
            );
            //console.log('clustersResult', clustersResult);

            if (clustersResult.success) {
               handleStatusBar("success", "Progetto creato con successo", true);
               setActiveKey("stream");

          setTimeout(() => {
            getProjectClusters(result.data.id).then((associatedClusters) => {
              setClusters(
                optionsClusters.filter((option) => {
                  return associatedClusters.data.some((assCluster) => {
                    return assCluster.id === option.value;
                  });
                })
              );
            });
          }, 2000);
        } else {
          handleStatusBar(
            "error",
            result.success === false
              ? Object.values(result.data).join()
              : translate(
                  "common.error.save",
                  "Si è verificato un errore durante il salvataggio delle informazioni"
                ),
            false
          );
        }
      } else {
        handleStatusBar(
          "error",
          result.success === false
            ? Object.values(result.data).join()
            : translate(
                "common.error.save",
                "Si è verificato un errore durante il salvataggio delle informazioni"
              ),
          false
        );
      }
      }
   };

   const handleItemList = async (
      entityType,
      originItemList,
      itemList,
      deleteAPI,
      editAPI,
      createAPI,
      entityId
   ) => {
      let resultOfActions = [];
      let messageResult = [];

      const oldItemsDeleted = originItemList.filter((object1) => {
         return !itemList.some((object2) => {
            return object1.id === object2.id;
         });
      });

      if (oldItemsDeleted.length > 0) {
         oldItemsDeleted.forEach(async (item) => {
            const deleteResult = await deleteAPI(entityId, item.id);
            resultOfActions.push(deleteResult.success);
         });
      }

      let validationFields = [];

      switch (entityType) {
         case "streams":
            validationFields = ['streamId', 'name'];
            const validationFieldsWp = ['startDate', 'endDate'];

            thisProject.streamConfigurationType === 'WP' && validationFields.push(...validationFieldsWp);
            break;
         default:
            validationFields = [];
      }

      let result;

      if (entityType === "streams" && thisProject.streamConfigurationType === 'WP') {
         itemList = itemList.map(wp => {
            return {
               ...wp,
               tasks: wp.tasks.filter(task => !task.hasOwnProperty('fakeTask') || !task.fakeTask) //Filtro per escludere i fakeTasks
            };
         });
      }

      await Promise.all(
         itemList.map(async (item) => {
            const resultOfEvery = validationFields.every((field) => item[field]);

            if (resultOfEvery) {
               if (item.id) {
                  const { createdAt, createdBy, updatedAt, updatedBy, ...restItem } =
                     item;
                  result = await editAPI(entityId, restItem);
                  //console.log('resultEdit:', result);
                  if (result.success === false) messageResult = result;
                  resultOfActions.push(result.success);
               } else {
                  item.projectId = entityId;
                  result = await createAPI(entityId, item);
                  //console.log('resultNew', result);
                  if (result.success === false) messageResult = result;
                  resultOfActions.push(result.success);
               }
            } else {
               messageResult = {
                  success: false,
                  data: { validation: ["Stream o WP non salvati. Riempi tutti i campi obbligatori"] },
               };
               resultOfActions.push(false);
               return;
            }
         })
      );

      //console.log('resultOfActions:', resultOfActions);
      //console.log('messageResult:', messageResult);
      return { resultOfActions, messageResult };
   };

   const addExpenseItem = (typeArray, setTypeArray) => {
      let tempTypeArray = [...typeArray];

      tempTypeArray.push({});

      setTypeArray(tempTypeArray);
   };

   const removeExpenseItem = (typeArray, setTypeArray, index) => {
      //delStream(typeArray[index])

      let tempTypeArray = [...typeArray];

      tempTypeArray.splice(index, 1);

      setTypeArray(tempTypeArray);
   };

   const streamRows = (items, setItems) => {
      let rows = [];

      if (items) {
         if (items.length === 0) {
            items = [{}];
         }

         let lastIndex = items.length - 1;

         rows = items.map((el, i) => {
            const isThisRowFilled = el.streamId && el.name ? true : false;

            return (
               <Row key={i} style={{ marginBottom: "40px" }}>
                  <Form.Group as={Col} className="col-1" style={{ minWidth: "7rem" }}>
                     {i === 0 && (
                        <Form.Label>
                           ID Stream<span className="required-field">*</span>
                        </Form.Label>
                     )}
                     <InputNumber
                        name="streamId"
                        value={el.streamId}
                        onChange={(e) => onChange(e, i, "streams", setStreams, streams)}
                        min={0}
                        minFractionDigits={0}
                        maxFractionDigits={2}
                        mode="decimal"
                        useGrouping={false}
                        disabled={
                           project?.editables ? !project?.editables?.company : false
                        }
                     />
                  </Form.Group>
                  <Form.Group as={Col} className="col-5">
                     {i === 0 && (
                        <Form.Label>
                           Denominazione<span className="required-field">*</span>
                        </Form.Label>
                     )}
                     <Form.Control
                        type="text"
                        name="name"
                        value={el.name ?? ""}
                        onChange={(e) => onChange(e, i, "streams", setStreams, streams)}
                        disabled={
                           project?.editables ? !project?.editables?.company : false
                        }
                     />
                  </Form.Group>
                  {((project?.editables && project?.editables?.company) ||
                     !project?.editables) && (
                        <Form.Group as={Col} className="col-auto p-0">
                           {i === 0 && <Form.Label className="w-100">&nbsp;</Form.Label>}
                           <div className="mt-1">
                              {i === lastIndex ? (
                                 <Button
                                    variant="link"
                                    className="me-2"
                                    onClick={() => addExpenseItem(items, setItems)}
                                    disabled={!isThisRowFilled}
                                 >
                                    <IcoPlus className="second-color ico-small indicators" />
                                 </Button>
                              ) : null}
                              {items.length !== 1 ? (
                                 <Button
                                    variant="link"
                                    onClick={() => removeExpenseItem(items, setItems, i)}
                                 >
                                    <IcoMinusCircled className="red-color ico-small indicators" />
                                 </Button>
                              ) : null}
                           </div>
                        </Form.Group>
                     )}
               </Row>
            );
         });
      }

    return rows;
  };

  const wpRows = (items, setItems) => {
   const isUsed = project?.editables ? !project?.editables?.company : false;
   let rows = [];
   let isThisRowFilled;
   let parentIndex;

   const header = ['ID WP', 'Denominazione', 'Inizio attività', 'Fine attività', 'Responsabile', 'Partecipante', 'Obiettivo'];

   const addElement = (
      section,
      typeArray,
      setTypeArray,
      fakeId,
      parentIndex
   ) => {
      let tempTypeArray = [...typeArray];

      let objTypeArray = {};

      switch (section) {
         case "items":
            objTypeArray = {
               name: "",
               responsibles: [],
               startDate: null,
               endDate: null,
               projectId: thisProject.id,
               configurationType: thisProject.streamConfigurationType,
               fakeId: fakeId,
               tasks: [
                  { name: "", objective: "", responsibles: [], participants: [], startDate: null, endDate: null, fakeTaskId: fakeId, fakeTask: true }
               ],
            };
            break;
         case "tasks":
            objTypeArray = {
               name: "",
               objective: "",
               responsibles: [],
               participants: [],
               startDate: null,
               endDate: null,
               projectId: thisProject.id,
               fakeTaskId: fakeId,
               fakeTask: true,
            };
            break;
         default:
            objTypeArray = {};
      }

      if (section === "tasks") {
         tempTypeArray[parentIndex].tasks.push(objTypeArray);
      } else {
         tempTypeArray.push(objTypeArray);
      }

      setTypeArray(tempTypeArray);
   };

   if (items) {
      if (items.length === 0) {
         items = [
            {
               streamId: null,
               name: "",
               responsibles: [],
               startDate: null,
               endDate: null,
               projectId: thisProject.id,
               configurationType: thisProject.streamConfigurationType,
               tasks: [{ name: "", objective: "", responsibles: [], participants: [], startDate: null, endDate: null, fakeTask: true }],
            },
         ];
      }

      const removeElement = (
         typeArray,
         setTypeArray,
         index,
         subIndex,
         type,
         fakeId,
         parentId,
         subId
      ) => {
         let tempTypeArray = [...typeArray];

         if (type === "tasks") {
            tempTypeArray[index].tasks.splice(subIndex, 1);

            if (tempTypeArray[index].tasks.length === 0)
               tempTypeArray[index].tasks.push({
                  name: "",
                  objective: "",
                  responsibles: [],
                  participants: [],
                  startDate: null,
                  endDate: null,
                  fakeTaskId: fakeId,
                  fakeTask: true,
               });
         } else {
            tempTypeArray.splice(index, 1);
         }

         setTypeArray(tempTypeArray);
      };

      rows = items
         .sort((valueA, valueB) => valueA.position - valueB.position)
         .map((el, i) => {
            el.fakeId = el.id ?? "item-" + i;
            isThisRowFilled = el.streamId && el.name && el.startDate && el.endDate ? true : false;
            parentIndex = i;

            return (<Fragment key={"item-" + i}>
               <tr className="align-top">
                  <td style={{ minWidth: "2rem" }}>
                     {!isUsed && (
                        <Button
                           variant="link"
                           style={{ visibility: items.length !== 1 ? "visible" : "hidden", marginTop: "0.3rem" }}
                           onClick={() => removeElement(items, setItems, i)}
                        >
                           <IcoMinusCircled className="red-color ico-small indicators" />
                        </Button>
                     )}
                  </td>
                  <td style={{ width: "5rem" }}>
                     <InputNumber
                        className={el.streamId ? "fw-bold" : ""}
                        name="streamId"
                        value={el.streamId}
                        onChange={(e) => onChange(e, i, "wp", setItems, items)}
                        min={0}
                        minFractionDigits={0}
                        maxFractionDigits={2}
                        mode="decimal"
                        useGrouping={false}
                        disabled={isUsed}
                     />
                  </td>
                  <td>
                     <Form.Control
                        disabled={isUsed}
                        className={el.name ? "fw-bold" : ""}
                        type="text"
                        name="name"
                        value={el.name ?? ""}
                        onChange={(e) => onChange(e, i, "wp", setItems, items)}
                     />
                  </td>
                  <td>
                     <Form.Control
                        type="date"
                        name="startDate"
                        style={{ maxWidth: "10rem" }}
                        value={dateFormatterInput(el.startDate) ?? ''}
                        min={dateFormatterInput(thisProject.startDate) ?? dateFormatterInput(thisProject.forecastStartDate)}
                        max={dateFormatterInput(el.endDate) ?? (dateFormatterInput(thisProject.endDate) ?? dateFormatterInput(thisProject.forecastEndDate))}
                        onChange={(e) => onChange(e, i, "wp", setItems, items)}
                        disabled={isUsed}
                     />
                  </td>
                  <td>
                     <Form.Control
                        type="date"
                        name="endDate"
                        style={{ maxWidth: "10rem" }}
                        value={dateFormatterInput(el.endDate) ?? ''}
                        min={dateFormatterInput(el.startDate) ?? (dateFormatterInput(thisProject.startDate) ?? dateFormatterInput(thisProject.forecastStartDate))}
                        max={dateFormatterInput(thisProject.endDate) ?? dateFormatterInput(thisProject.forecastEndDate)}
                        onChange={(e) => onChange(e, i, "wp", setItems, items)}
                        disabled={isUsed}
                     />
                  </td>
                  <td>
                     <Select
                        components={{
                           IndicatorSeparator: () => null,
                        }}
                        name="responsibles"
                        placeholder={translate('common.placeholder.selectpartners', 'Seleziona i partners') + '...'}
                        value={optionsPartners.filter(opt => {
                           return el.responsibles?.some(resp => {
                              return resp.id === opt.value;
                           });
                        }) ?? []}
                        options={optionsPartners}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        closeMenuOnSelect={false}
                        onChange={(e) => onChange(e, i, "wp", setItems, items, undefined, 'responsibles')}
                        isMulti
                        isClearable={false}
                        isDisabled={isUsed}
                     />
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
               </tr>
               {el.tasks &&
                  el.tasks
                     .sort((valueA, valueB) => valueA.position - valueB.position)
                     .map((task, index) => {
                        const isThisSubRowFilled = task.name && task.startDate && task.endDate ? true : false;
                        let lastIndexSub = el.tasks.length - 1;

                        return (<tr key={"task-" + index} className="align-top">
                           <td></td>
                           <td className="ps-2 pt-2">
                              TASK
                           </td>
                           <td>
                              <Form.Control
                                 disabled={isUsed}
                                 type="text"
                                 name="name"
                                 value={task.name ?? ""}
                                 onChange={(e) => onChange(e, index, "tasks", setItems, el.tasks, i)}
                              />
                           </td>
                           <td>
                              <Form.Control
                                 type="date"
                                 name="startDate"
                                 style={{ maxWidth: "10rem" }}
                                 value={dateFormatterInput(task.startDate) ?? ''}
                                 min={dateFormatterInput(el.startDate) ?? "2000-01-01"}
                                 max={dateFormatterInput(el.endDate) ?? ''}
                                 onChange={(e) => onChange(e, index, "tasks", setItems, el.tasks, i)}
                                 disabled={isUsed}
                              />
                           </td>
                           <td>
                              <Form.Control
                                 type="date"
                                 name="endDate"
                                 style={{ maxWidth: "10rem" }}
                                 value={dateFormatterInput(task.endDate) ?? ""}
                                 min={dateFormatterInput(task.startDate) ?? "2000-01-01"}
                                 max={dateFormatterInput(el.endDate) ?? ''}
                                 onChange={(e) => onChange(e, index, "tasks", setItems, el.tasks, i)}
                                 disabled={isUsed}
                              />
                           </td>
                           <td>
                              <Select
                                 components={{
                                    IndicatorSeparator: () => null,
                                 }}
                                 name="responsibles"
                                 placeholder={translate('common.placeholder.selectpartners', 'Seleziona i partners') + '...'}
                                 value={optionsPartners.filter(opt => {
                                    return task.responsibles?.some(resp => {
                                       return resp.id === opt.value;
                                    });
                                 }) ?? []}
                                 options={optionsPartners}
                                 className="react-select-container"
                                 classNamePrefix="react-select"
                                 closeMenuOnSelect={false}
                                 onChange={(e) => onChange(e, index, "tasks", setItems, el.tasks, i, 'responsibles')}
                                 isMulti
                                 isClearable={false}
                                 isDisabled={isUsed}
                              />
                           </td>
                           <td>
                              <Select
                                 components={{
                                    IndicatorSeparator: () => null,
                                 }}
                                 name="participants"
                                 placeholder={translate('common.placeholder.selectpartners', 'Seleziona i partners') + '...'}
                                 value={optionsPartners.filter(opt => {
                                    return task.participants?.some(part => {
                                       return part.id === opt.value;
                                    });
                                 }) ?? []}
                                 options={optionsPartners}
                                 className="react-select-container"
                                 classNamePrefix="react-select"
                                 closeMenuOnSelect={false}
                                 onChange={(e) => onChange(e, index, "tasks", setItems, el.tasks, i, 'participants')}
                                 isMulti
                                 isClearable={false}
                                 isDisabled={isUsed}
                              />
                           </td>
                           <td>
                              <Form.Control
                                 disabled={isUsed}
                                 type="text"
                                 name="objective"
                                 value={task.objective ?? ""}
                                 onChange={(e) => onChange(e, index, "tasks", setItems, el.tasks, i)}
                              />
                           </td>
                           <td style={{ minWidth: "4rem" }}>
                              {!isUsed && index === lastIndexSub ? (
                                 <Button
                                    variant="link"
                                    className="me-2"
                                    style={{ marginTop: "0.3rem" }}
                                    onClick={() =>
                                       addElement(
                                          "tasks",
                                          items,
                                          setItems,
                                          el.id ?? "item-" + i,
                                          i
                                       )
                                    }
                                    disabled={!isThisSubRowFilled}
                                 >
                                    <IcoPlus className="second-color ico-small indicators" />
                                 </Button>
                              ) : null}
                              <Button
                                 variant="link"
                                 style={{ marginTop: "0.3rem" }}
                                 onClick={() =>
                                    removeElement(
                                       items,
                                       setItems,
                                       i,
                                       index,
                                       "tasks",
                                       el.id ?? "item-" + i,
                                       el.id,
                                       task.id
                                    )
                                 }
                              >
                                 <IcoMinusCircled className="red-color ico-small indicators" />
                              </Button>
                           </td>
                        </tr>);
                     })}
            </Fragment>
            )
         });
   }

   return (<>
      <table style={{ width: '100%', borderSpacing: '10px', borderCollapse: 'separate' }}>
         <thead>
            <tr>
               <th>&nbsp;</th>
               {header.map((title, i) => {
                  const isRequired = i === 0 || i === 1 || i === 2 || i === 3 ? true : false;

                  return (
                     <th key={'wp-' + i} className="p-2">
                        {title}{isRequired && <span className="required-field">*</span>}
                     </th>
                  )
               })}
               <th>&nbsp;</th>
            </tr>
         </thead>
         <tbody>
            {rows}
         </tbody>
      </table>

      {!isUsed && (
         <Button
            className="my-4"
            style={{ marginLeft: "3.5rem" }}
            variant="primary"
            onClick={() =>
               addElement(
                  "items",
                  items,
                  setItems,
                  "item-" + (parentIndex + 1),
                  parentIndex
               )
            }
            disabled={!isThisRowFilled}
         >
            <IcoPlus className="light-color ico-small plus new-button" />
            <span className="d-md-inline">&nbsp;<OETrans code='common.addworkpackage' fallback={'Aggiungi work package'} /></span>
         </Button>
      )}
   </>);
};

  return (
    <>
      <Modal
        show={showModalProject}
        size="xl"
        className="modal-with-nav"
        dialogClassName="modal-75w"
        onHide={() => handleCloseModal(setShowModalProject)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {thisProject.id ? "Modifica Progetto" : "Nuovo Progetto"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs
            className="mb-4"
            activeKey={activeKey}
            onSelect={(k) => setActiveKey(k)}
            fill
            variant="pills"
          >
            {/* Anagrafica */}
            <Tab eventKey="registry" title={translate('common.registry', 'Anagrafica')}>
              {activeKey === "registry" && (
                <>
                  <Form onSubmit={handleSaveProject} id="form-new-project">
                    <Row style={{ marginBottom: "40px" }}>
                      <Form.Group as={Col}>
                        <Form.Label>
                          Nome<span className="required-field">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="name"
                          value={thisProject.name ?? ""}
                          onChange={(e) => onChange(e, 0, "name")}
                          onBlur={(e) =>
                            onBlur(e.target.value, setNameIsInvalid)
                          }
                          isInvalid={nameIsInvalid}
                        />
                        <Form.Control.Feedback
                          type="invalid"
                          className="ps-1 position-absolute"
                        >
                          Carattere vietato: il nome non può contenere il
                          simbolo "/"
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group as={Col}>
                        <Form.Label>
                          Società<span className="required-field">*</span>
                        </Form.Label>
                        <Select
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                          className="react-select-container"
                          classNamePrefix="react-select"
                          name="companyId"
                          placeholder={translate('common.placeholder.select', 'Seleziona...')}
                          value={selectCompany}
                          options={optionsCompanies}
                          onChange={(e) => onChange(e, 0, "companyId", setSelectCompany)}
                          isDisabled={
                            project?.editables
                              ? !project?.editables?.company
                              : false
                          }
                        />
                      </Form.Group>

                      <Form.Group as={Col}>
                        <Form.Label>
                          CUP<span className="required-field">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="cup"
                          value={thisProject.cup ?? ""}
                          onChange={(e) => onChange(e, 0, "cup")}
                          disabled={
                            project?.editables
                              ? !project?.editables?.cup
                              : false
                          }
                        />
                      </Form.Group>
                    </Row>

                    <Row style={{ marginBottom: "40px" }}>
                      <Form.Group as={Col}>
                        <Form.Label>
                          Gruppi<span className="required-field">*</span>
                        </Form.Label>
                        <Select
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                          className="react-select-container"
                          classNamePrefix="react-select"
                          closeMenuOnSelect={false}
                          name="groupids"
                          placeholder={translate(
                            "common.placeholder.select",
                            "Seleziona..."
                          )}
                          value={selectedGroups}
                          options={optionsGroups}
                          onChange={(e) =>
                            onChange(e, 0, "groupids", setSelectedGroups)
                          }
                          isDisabled={thisProject?.companyId ? false : true}
                          isMulti
                          isClearable
                        />
                      </Form.Group>

                      <Form.Group as={Col}>
                        <Form.Label>
                          Bando<span className="required-field">*</span>
                        </Form.Label>
                        <Select
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                          className="react-select-container"
                          classNamePrefix="react-select"
                          name="noticeId"
                          placeholder={translate('common.placeholder.select', 'Seleziona...')}
                          value={selectNotice}
                          options={optionsNotices}
                          onChange={(e) =>
                            onChange(e, 0, "noticeId", setSelectNotice)
                          }
                          isDisabled={
                            project?.editables
                              ? !project?.editables?.notice
                              : false
                          }
                        />
                      </Form.Group>

                      <Form.Group as={Col}>
                        <Form.Label>Opzioni di Costo Semplificate</Form.Label>
                        <Form.Check
                          className="pt-1"
                          type="checkbox"
                          name="sco"
                          defaultChecked={thisProject.sco}
                          onClick={(e) => onChange(e, 0, "sco")}
                        />
                      </Form.Group>
                  </Row>

                  <Row style={{ marginBottom: "40px" }}>
                      <Form.Group as={Col}>
                        <Form.Label>Responsabile</Form.Label>
                        <Form.Control
                          type="text"
                          name="manager"
                          value={thisProject.manager ?? ""}
                          onChange={(e) => onChange(e, 0, "manager")}
                        />
                      </Form.Group>

                      <Form.Group as={Col}>
                        <Form.Label>PM OE</Form.Label>
                        <Select
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                          className="react-select-container"
                          classNamePrefix="react-select"
                          name="oeManagerId"
                          placeholder={translate('common.placeholder.select', 'Seleziona...')}
                          value={selectManager}
                          options={optionsManagers}
                          onChange={(e) =>
                            onChange(e, 0, "oeManagerId", setSelectManager)
                          }
                          isClearable
                        />
                      </Form.Group>

                      <Form.Group as={Col}>
                        <Form.Label>
                           Configurazione progetto<span className="required-field">*</span>
                        </Form.Label>
                        <Col>
                           <Form.Check
                              inline
                              type='radio'
                              id='STREAM'
                              name='streamConfigurationType'
                              label='Stream'
                              onClick={e => onChange(e, 0, 'streamConfigurationType')}
                              defaultChecked={handleChecked('STREAM')}
                              disabled={project?.streams?.length > 0 ? true : (project?.editables ? !project?.editables?.company : false)}
                           />
                           <Form.Check
                              inline
                              type='radio'
                              id='WP'
                              name='streamConfigurationType'
                              label='Work Packages'
                              onClick={e => onChange(e, 0, 'streamConfigurationType')}
                              defaultChecked={handleChecked('WP')}
                              disabled={project?.streams?.length > 0 ? true : (project?.editables ? !project?.editables?.company : false)}
                           />
                        </Col>
                     </Form.Group>
                     </Row>

                     <Row> 
                      {isAiEnabledGlobal && ((selectCompany.length > 0 && selectCompany[0]?.aiEnabled) || selectCompany.aiEnabled) ? (
                        <Form.Group as={Col}>
                           <Form.Label>Intelligenza artificiale abilitata</Form.Label>
                           <Form.Check
                              className="pt-1"
                              type="checkbox"
                              name="aiEnabled"
                              defaultChecked={thisProject.aiEnabled}
                              onClick={(e) => onChange(e, 0, "aiEnabled")}
                           />
                        </Form.Group>
                      ) : <Form.Group as={Col}/>}
                    </Row>

                              <Row style={{ marginBottom: "40px" }}>
                                 {/* <Form.Group as={Col} className='px-4 col-3'>
                                 <Form.Label className='mb-3'>
                                    Durata <span className='text-muted'>(in mesi)</span>
                                    <span className='required-field'>*</span>
                                 </Form.Label>
                                 <Form.Control
                                    type='number'
                                    name='durationInMonths'
                                    value={thisProject.durationInMonths ?? ''}
                                    onChange={(e) => onChange(e, 0, 'durationInMonths')}
                                 />
                                 </Form.Group> */}

                      <Form.Group as={Col}>
                        <Form.Check
                          inline
                          type="checkbox"
                          name="network"
                          defaultChecked={thisProject.network}
                          onClick={(e) => onChange(e, 0, "network")}
                        />
                        <Form.Label>Progetto a rete</Form.Label>
                        <Form.Control
                           type="text"
                           name="networkNote"
                           placeholder={thisProject.network ? 'Localizzazione*' : ''}
                           value={thisProject.networkNote ?? ''}
                           onChange={(e) => onChange(e, 0, "networkNote")}
                           disabled={!thisProject.network}
                        />
                      </Form.Group>

                                 <Form.Group as={Col}>
                                    <Form.Label>
                                       Localizzazione (Regione)
                                       {!thisProject.network ? (
                                          <span className="required-field">&nbsp;*</span>
                                       ) : null}
                                    </Form.Label>
                                    <Select
                                       components={{
                                          IndicatorSeparator: () => null,
                                       }}
                                       className="react-select-container"
                                       classNamePrefix="react-select"
                                       placeholder="Seleziona Regione..."
                                       value={selectedRegion}
                                       options={optionsRegions}
                                       onChange={(e) =>
                                          onChange(e, 0, "regione", setSelectedRegion)
                                       }
                                       isClearable
                                       isDisabled={thisProject.network}
                                    />
                                 </Form.Group>

                      <Form.Group as={Col}>
                        <Form.Label>
                          Localizzazione (Comune)
                          {!thisProject.network ? (
                            <span className="required-field">&nbsp;*</span>
                          ) : null}
                        </Form.Label>
                        <Select
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                          className="react-select-container"
                          classNamePrefix="react-select"
                          name="locationCode"
                          placeholder="Seleziona Comune..."
                          value={selectLocation}
                          options={
                            selectedRegion.length > 0
                              ? optionsAllLocations.filter((allLoc) => {
                                  return selectedRegion.some((selectReg) => {
                                    return (
                                      selectReg.value === allLoc.codRegione
                                    );
                                  });
                                })
                              : []
                          }
                          onChange={(e) =>
                            onChange(e, 0, "locationCode", setSelectLocation)
                          }
                          isClearable
                          isDisabled={selectedRegion.length === 0 || thisProject.network}
                        />
                      </Form.Group>

                                 <Form.Group as={Col}>
                                    <Form.Label>Cluster geografici</Form.Label>
                                    <Select
                                       components={{
                                          IndicatorSeparator: () => null,
                                       }}
                                       className="react-select-container"
                                       classNamePrefix="react-select"
                                       name="businessAreaIds"
                                       placeholder="Seleziona Cluster..."
                                       value={clusters}
                                       options={optionsClusters}
                                       onChange={(e) =>
                                          onChange(
                                             e,
                                             0,
                                             "businessAreaIds",
                                             setClusters,
                                             clusters
                                          )
                                       }
                                       isMulti
                                       isClearable
                                    />
                                 </Form.Group>
                              </Row>

                              <Row style={{ marginBottom: "40px" }}>
                                 <Form.Group as={Col}>
                                    <Form.Label>
                                       Avvio previsto
                                       <span className="required-field">*</span>
                                    </Form.Label>
                                    <Form.Control
                                       type="date"
                                       name="forecastStartDate"
                                       //value={new Date(thisProject.forecastStartDate).toLocaleDateString('en-CA')}
                                       value={dateFormatterInput(thisProject.forecastStartDate) ?? ''}
                                       min="2000-01-01"
                                       onChange={(e) => onChange(e, 0, "forecastStartDate")}
                                    />
                                 </Form.Group>

                                 <Form.Group as={Col}>
                                    <Form.Label>
                                       Fine prevista<span className="required-field">*</span>
                                    </Form.Label>
                                    <Form.Control
                                       type="date"
                                       name="forecastEndDate"
                                       //value={new Date(thisProject.forecastEndDate).toLocaleDateString('en-CA')}
                                       value={dateFormatterInput(thisProject.forecastEndDate) ?? ''}
                                       max="2100-12-31"
                                       onChange={(e) => onChange(e, 0, "forecastEndDate")}
                                    />
                                 </Form.Group>

                                 <Form.Group as={Col}>
                                    <Form.Label>Avvio effettivo</Form.Label>
                                    <Form.Control
                                       type="date"
                                       name="startDate"
                                       value={dateFormatterInput(thisProject.startDate) ?? ''}
                                       min="2000-01-01"
                                       onChange={(e) => onChange(e, 0, "startDate")}
                                    />
                                 </Form.Group>

                                 <Form.Group as={Col}>
                                    <Form.Label>Fine effettiva</Form.Label>
                                    <Form.Control
                                       type="date"
                                       name="endDate"
                                       //value={new Date(thisProject.endDate).toLocaleDateString('en-CA')}
                                       value={dateFormatterInput(thisProject.endDate) ?? ''}
                                       max="2100-12-31"
                                       onChange={(e) => onChange(e, 0, "endDate")}
                                    />
                                 </Form.Group>
                              </Row>

                    <Row style={{ marginBottom: "40px" }}>
                      <Form.Group as={Col}>
                        <Form.Check
                          inline
                          type="checkbox"
                          name="dnshClimateMitigationCheck"
                          defaultChecked={thisProject.dnshClimateMitigation}
                          onClick={(e) =>
                            onChange(
                              e,
                              0,
                              "dnshClimateMitigation",
                              setSelectedDnshClimateMitigation
                            )
                          }
                        />
                        <Form.Label>
                          Dnsh Mitigation
                          {thisProject.dnshClimateMitigation && (
                            <>
                              <span className="required-field">*</span>
                            </>
                          )}
                        </Form.Label>
                        <Select
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                          className="react-select-container"
                          classNamePrefix="react-select"
                          isDisabled={!thisProject.dnshClimateMitigation}
                          name="Mitigation"
                          placeholder={translate('common.placeholder.select', 'Seleziona...')}
                          value={selectedDnshClimateMitigation}
                          options={optionsDnshClimateMitigation}
                          onChange={(e) =>
                            onChange(
                              e,
                              0,
                              "Mitigation",
                              setSelectedDnshClimateMitigation
                            )
                          }
                        />
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Check
                          inline
                          type="checkbox"
                          name="dnshClimateAdaptationCheck"
                          defaultChecked={thisProject.dnshClimateAdaptation}
                          onClick={(e) =>
                            onChange(
                              e,
                              0,
                              "dnshClimateAdaptation",
                              setSelectedDnshClimateAdaptation
                            )
                          }
                        />
                        <Form.Label>
                          Dnsh Adaptation
                          {thisProject.dnshClimateAdaptation && (
                            <>
                              <span className="required-field">*</span>
                            </>
                          )}
                        </Form.Label>
                        <Select
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                          className="react-select-container"
                          classNamePrefix="react-select"
                          isDisabled={!thisProject.dnshClimateAdaptation}
                          name="Adaptation"
                          placeholder={translate('common.placeholder.select', 'Seleziona...')}
                          value={selectedDnshClimateAdaptation}
                          options={optionsDnshClimateAdaptation}
                          onChange={(e) =>
                            onChange(
                              e,
                              0,
                              "Adaptation",
                              setSelectedDnshClimateAdaptation
                            )
                          }
                        />
                      </Form.Group>
                      <Form.Group as={Col}>
                        {/* <Form.Check
                      inline
                      style={{ marginTop: '0.2rem' }}
                      className='me-3'
                      type='checkbox'
                      name='dnshClimateMitigationCheck'
                      defaultChecked={thisProject.dnshClimateMitigation}
                      onClick={(e) =>
                        onChange(e, 0, 'dnshClimateMitigation', setSelectedDnshClimateMitigation)
                      }
                    /> */}
                        <Form.Label>Dnsh Element</Form.Label>
                        <Select
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                          className="react-select-container"
                          classNamePrefix="react-select"
                          isDisabled={
                            !isPnrr ||
                            (project?.editables && !project?.editables?.dnsh)
                          }
                          name="Element"
                          placeholder={translate(
                            "common.placeholder.select",
                            "Seleziona..."
                          )}
                          value={selectedDnshElement}
                          options={optionsDnshElements}
                          onChange={(e) =>
                            onChange(
                              e,
                              0,
                              "dnshElement",
                              setSelectedDnshElement
                            )
                          }
                          isClearable
                        />
                      </Form.Group>
                    </Row>
                        {/* <OETrans code='companies.aiEnabled' fallback={'Intelligenza artificiale abilitata'}/> */}
                    {/* <Row style={{ marginBottom: "40px" }}>
                      <Form.Label htmlFor="hasPartnership" className="mb-3">
                        Partenariato
                        <Form.Check
                          inline
                          style={{ marginTop: "0.2rem" }}
                          className="ms-3"
                          type="checkbox"
                          id="hasPartnership"
                          name="hasPartnership"
                          checked={thisProject.hasPartnership ?? false}
                          onChange={(e) => onChange(e, 0, "hasPartnership")}
                          // checked={checkboxChecked}
                          // onChange={checkboxOnChange}
                        />
                      </Form.Label>
                    </Row> */}

                              <Row style={{ marginBottom: "40px" }}>
                                 <Form.Group as={Col}>
                                    <Form.Label>Descrizione</Form.Label>
                                    <Form.Control
                                       as="textarea"
                                       rows={3}
                                       name="description"
                                       value={thisProject.description ?? ""}
                                       onChange={(e) => onChange(e, 0, "description")}
                                    />
                                 </Form.Group>
                              </Row>

                              <Row style={{ marginBottom: "40px" }}>
                                 <Form.Group as={Col}>
                                    <Form.Label className="mb-3">Note</Form.Label>
                                    <Form.Control
                                       as="textarea"
                                       rows={2}
                                       name="note"
                                       value={thisProject.note ?? ""}
                                       onChange={(e) => onChange(e, 0, "note")}
                                    />
                                 </Form.Group>
                              </Row>
                           </Form>

                  {sbShow}
                </>
              )}
            </Tab>
            <Tab
              eventKey="otherinfo"
              title={"Altre informazioni"}
              //disabled={!thisProject.id}
            >
              {activeKey === "otherinfo" && (
                <>
                  <Form onSubmit={handleSaveProject} id="form-new-project">
                    <Row style={{ marginBottom: "40px" }}>
                      <Form.Group as={Col}>
                        <Form.Label>Agevolabilità IVA</Form.Label>
                        <Select
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                          className="react-select-container"
                          classNamePrefix="react-select"
                          name="vatRate"
                          placeholder={translate(
                            "common.placeholder.select",
                            "Seleziona..."
                          )}
                          value={selectVatRate ?? ""}
                          options={optionsVatRates}
                          onChange={(e) =>
                            onChange(e, 0, "vatRate", setSelectVatRate)
                          }
                        />
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label>CLP</Form.Label>
                        <Form.Control
                          type="text"
                          name="localProjectCode"
                          value={thisProject.localProjectCode ?? ""}
                          onChange={(e) => onChange(e, 0, "localProjectCode")}
                        />
                      </Form.Group>

                      <Form.Group as={Col}>
                        <Form.Label>Codice Progetto (Timbratura)</Form.Label>
                        <Form.Control
                          type="text"
                          name="projectCodeStamp"
                          value={thisProject.projectCodeStamp ?? ""}
                          onChange={(e) => onChange(e, 0, "projectCodeStamp")}
                        />
                      </Form.Group>
                    </Row>
                    <Row style={{ marginBottom: "40px" }}>
                      <Form.Group as={Col}>
                        <Form.Label>Richiedente (Timbratura)</Form.Label>
                        <Form.Control
                          type="text"
                          name="applicant"
                          value={thisProject.applicant ?? ""}
                          onChange={(e) => onChange(e, 0, "applicant")}
                        />
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label>E-mail Richiedente (Timbratura)</Form.Label>
                        <Form.Control
                          type="text"
                          name="applicantEmail"
                          value={thisProject.applicantEmail ?? ""}
                          onChange={(e) => onChange(e, 0, "applicantEmail")}
                        />
                      </Form.Group>
                    </Row>
                  </Form>
                  {sbShow}
                </>
              )}
            </Tab>

                  {/* Setup stream */}
                  <Tab
                     eventKey="stream"
                     title={`Setup ${!thisProject.id ? 'WP/Stream' : (thisProject.streamConfigurationType === 'STREAM' ? 'Stream' : 'Work Packages')}`}
                     disabled={!thisProject.id}
                  >
                     {activeKey === "stream" && (
                        <>
                           <Form onSubmit={handleSaveProject} id="form-new-project">
                              {thisProject.streamConfigurationType === 'STREAM' && streamRows(streams, setStreams)}
                              {thisProject.streamConfigurationType === 'WP' && wpRows(streams, setStreams)}
                           </Form>
                           {sbShow}
                        </>
                     )}
                  </Tab>

            {thisProject.streamConfigurationType === 'WP' && (
               <Tab
                  eventKey="deliverables"
                  title="Deliverables"
                  disabled={!thisProject.id || streams.length === 0}
               >
               {thisProject.id && (
                  <div className="p-3">
                     <Deliverable
                     projectId={thisProject.id}
                     streams={streams}
                     isConfig={true}
                     />
                  </div>
               )}
               </Tab>
            )}
            
            {thisProject.streamConfigurationType === 'WP' && (
               <Tab
               eventKey="milestones"
               title="Milestones"
               disabled={!thisProject.id || streams.length === 0}
               >
               {thisProject.id && (
                  <div className="p-3">
                     <Milestones
                     projectId={thisProject.id}
                     canEdit={true}
                     streams={streams}
                     isConfig={true}
                     />
                  </div>
               )}
               </Tab>
            )}

            {/* Documenti */}
            <Tab
              eventKey="docs"
              title={<OETrans code="common.documents" fallback={"Documenti"} />}
              disabled={!thisProject.id}
            >
              {activeKey === "docs" && (
                <>
                  <UploadDocument
                    entity="projects"
                    entityId={thisProject.id}
                    filterBySection={["project"]}
                  />
                  {sbShow}
                </>
              )}
            </Tab>
            {/* Impostazioni Avanzate */}
            <Tab
                     eventKey='advancedSettings'
                     title={translate('common.advancedSettings', 'Impostazioni avanzate')}
                     className='px-5'
                     disabled={!thisProject.id || !thisProject?.advancedConfig || thisProject?.advancedConfig?.length === 0}>
                     <Form onSubmit={handleSaveProject} id='form-new-project'>
                        {activeKey === 'advancedSettings' && thisProject?.advancedConfig?.length > 0 &&
                           thisProject?.advancedConfig.sort((a, b) => a.id - b.id)
                              .map((el, i) => (
                                 <Row key={el.name} className="mb-3">
                                    <Form.Group as={Col}>
                                       <Form.Check
                                          inline
                                          type='checkbox'
                                          name={el.name}
                                          defaultChecked={thisProject?.advancedConfig?.some(advConfig => Number(advConfig.id) === Number(el.id) && advConfig.enabled)}
                                          onClick={(e) =>
                                             onAdvancedChange(
                                                e,
                                                'advancedSettings',
                                                setThisProject,
                                                el.id
                                             )
                                          }
                                       />
                                       <Form.Label>{el.label}</Form.Label>
                                       {el.description && (
                                          <OverlayTrigger
                                             placement='top'
                                             delay={{ show: 200, hide: 400 }}
                                             overlay={<Tooltip id='button-tooltip'>{el.description}</Tooltip>}>
                                             <TooltipInfoIcon className='tooltip-ico' style={{ width: '1rem' }} />
                                          </OverlayTrigger>
                                       )}
                                    </Form.Group>
                                 </Row>
                              ))}
                     </Form>
                     {sbShow}
                  </Tab>
            {/* TODO: add hasPartnership flag when API is ready */}
            {/* {!thisProject.id && thisProject.hasPartnership ? */}
            {(thisProject.id && thisProject.advancedConfig.some(
              (config) => config.name === "has_partnership" && config.enabled
            )) ? (
              <Tab
                eventKey="partnership"
                title="Parternariato"
                // disabled={!thisProject.id}
              >
                <PartnershipTab project={thisProject} />
              </Tab>
            ) : null}
          </Tabs>

          {activeKey !== "docs" && activeKey !== "otherinfo" && activeKey !== "milestones" && activeKey !== "partnership" && activeKey !== "deliverables" && (
            <Row>
              <Col className="col-12">
                <span className="label">
                  <span className="required-field">*</span>&nbsp;
                  <OETrans
                    code="common.requiredFields"
                    fallback={"Campi obbligatori"}
                  />
                </span>
              </Col>
            </Row>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => handleCloseModal(setShowModalProject)}
          >
            <OETrans code="common.cancel" fallback={"Annulla"} />
          </Button>
          <Button variant="primary" onClick={(e) => handleSaveProject(e)}>
            {thisProject.id ? (
              <OETrans code="common.edit" fallback={"Modifica"} />
            ) : (
              <OETrans code="common.create" fallback={"Crea"} />
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default ProjectModal;
