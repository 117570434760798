import React, { useState, useCallback, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Row,
  Col,
  OverlayTrigger,
  Button,
  Tooltip,
  Badge,
  ButtonToolbar,
  InputGroup,
} from 'react-bootstrap';
import Select from 'react-select';
import { createProjectStatement, getProjectTotalsReportedAmount } from '@lib/api';
import { dateFormatter, formatToDecimals } from '@lib/helpers/formatting';
import { columnCentered } from '@lib/helpers/agGridHelpers';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
import { LevelContext } from '@components/contexts/LevelContext';
import StatusBar from '@components/StatusBar';
import OETableAgGrid from '@components/oetable/OETableAgGrid';
import MakeReportingModal from '@components/projectStatements/MakeReportingModal';
import DataHeader from 'components/DataHeader';
import { translate } from 'components/translation/translation';

const MakeReporting = ({
  optionsStatementTypes,
  draftStatements,
  draftStatementsMeta,
  statements,
  localLang,
  handleSetAllStatements,
  isInCreate,
  setIsInCreate,
  isAdvancedSettings,
}) => {
  const { projectId } = useParams();
  const { isOe, userRole } = useContext(LevelContext);
  const initialState = { projectId: projectId };
  const [newStatement, setNewStatement] = useState(initialState);
  const [selectedStatementType, setSelectedStatementType] = useState([]);
  const [totals, setTotals] = useState({});
  const [sbShow, setSbShow] = useState(<></>);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    if (refresh) {
      handleSetAllStatements();

      setRefresh(false);
    }
  }, [handleSetAllStatements, refresh]);

  const refreshTotals = useCallback(() => {
    getProjectTotalsReportedAmount(projectId, '?state=DRAFT').then(
      (retrievedTotalsReportedAmount) => {
        if (retrievedTotalsReportedAmount.success) {
          //la proprietà total è l'importo rendicontato e totalRequestedAmount è il contributo richiesto
          //in statements ci sono gli importi rendicontati
          setTotals(retrievedTotalsReportedAmount.data);
        }
      }
    );
  }, [projectId]);

  useEffect(() => {
    refreshTotals();
  }, [refreshTotals]);

  const BadgeCellRenderer = useCallback((params) => {
    //console.log('params', params.data.state);

    return (
      <>
        {params.data.state === 'DRAFT' ? (
          <Row className='align-items-center justify-content-center'>
            <Col className='col-auto'>
              <Badge bg='warning' text='dark' className=''>
                Bozza
              </Badge>
            </Col>
          </Row>
        ) : null}
      </>
    );
  }, []);

  const draftColumns = [
    {
      headerName: 'id',
      field: 'id',
      hide: true,
    },
    {
      headerName: 'Tipo',
      field: 'statementTypeCode',
      //filter: 'agTextColumnFilter',
      valueGetter: (params) => {
        const type = optionsStatementTypes
          .filter((optType) => optType.value === params.data.statementTypeCode)
          .map((type) => type.label)
          .pop();
        return params.data.statementTypeCode === 'SAL'
          ? /* type + ' ' +  */ params.data.name
          : type;
      },
    },
    {
      headerName: 'Importo rendicontato €',
      field: 'reportedAmount', //non esiste, è calcolato
      type: 'rightAligned',
      filter: 'agTextColumnFilter',
      valueGetter: (params) => {
        const requestedAmount = totals.statements
          ?.filter((statement) => statement.statementId === params.data.id)
          .map((el) => el.total)
          .pop();
        return formatToDecimals(requestedAmount);
      },
    },
    {
      headerName: 'Contributo richiesto €',
      field: 'requestedAmount',
      type: 'rightAligned',
      filter: 'agTextColumnFilter',
      valueGetter: (params) => {
        return formatToDecimals(params.data.requestedAmount);
      },
      //minWidth: 100,
    },
    {
      headerName: 'Data aggiornamento',
      field: 'updatedAt',
      //filter: 'agDateColumnFilter',
      ...columnCentered(),
      valueGetter: (params) => {
        return params.data.updatedAt ? dateFormatter(params.data.updatedAt) : '';
      },
    },
    {
      headerName: '',
      field: 'state',
      filter: 'agTextColumnFilter',
      maxWidth: 90,
      ...columnCentered(),
      cellRenderer: BadgeCellRenderer,
    },
    {
      headerName: '',
      minWidth: 90,
      maxWidth: 90,
      cellStyle: { textAlign: 'center', padding: 0 },
      cellRenderer: MakeReportingModal,
      cellRendererParams: {
        isOe: isOe || userRole === 5,
        projectId: projectId,
        localLang: localLang,
        isShowNew: false,
        setIsShowNew: setIsInCreate,
        optionsStatementTypes: optionsStatementTypes,
        setRefresh: setRefresh,
        refreshTotals: refreshTotals,
        isAdvancedSettings: isAdvancedSettings,
      },
    },
  ];

  const handleStatusBar = (status, message, timeout) => {
    setSbShow(<StatusBar status={status} message={message} timeout={timeout} />);
  };

  const onChange = (e, fieldName, setSelect) => {
    //console.log('e', e);

    let tempNewStatement = { ...newStatement };
    let value;

    switch (fieldName) {
      case 'statementTypeCode':
        value = [e] ?? [];

        setSelect(value);

        tempNewStatement[fieldName] = e.value;

        break;
      default:
        console.log('blank');
    }

    setNewStatement(tempNewStatement);
  };

  const handleCreate = async (e) => {
    e?.preventDefault();

    setSbShow(<></>);

    if (
      (newStatement.statementTypeCode === 'ADV' &&
        statements?.some((el) => el.statementTypeCode === 'ADV')) ||
      (newStatement.statementTypeCode === 'BAL' &&
        statements?.some((el) => el.statementTypeCode === 'BAL'))
    ) {
      handleStatusBar('error', 'È già presente questa tipologia di rendiconto.');
      return;
    }

    const { createdAt, createdBy, updatedAt, updatedBy, ...restNewStatement } = newStatement;

    const result = await createProjectStatement(projectId, restNewStatement);

    if (result.success) {
      setNewStatement(result.data);
      //handleSetAllStatements();
      setRefresh(true);
      setIsInCreate(true);
    } else {
      handleStatusBar(
        'error',
        result.message ??
          translate(
            'common.error.save',
            'Si è verificato un errore durante il salvataggio delle informazioni'
          )
      );
    }
  };

  const tooltip = (props) => (
    <Tooltip {...props}>Inizia un rendiconto selezionando una tipologia</Tooltip>
  );

  return (
    <>
      {sbShow}

      <DataHeader
        data={[
          { name: 'Importo in lavorazione', value: formatToDecimals(totals.total) + ' €' },
          {
            name: 'Contributo richiesto',
            value: formatToDecimals(totals.totalRequestedAmount) + ' €',
          },
        ]}
        childrenButton={
          (isOe || userRole === 5) && (
            <ButtonToolbar className='justify-content-end'>
              <InputGroup className='align-items-start mt-3 mt-md-0 ms-2'>
                <Select
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  className='react-select-container'
                  classNamePrefix='react-select'
                  name='statementTypeCode'
                  placeholder='Seleziona tipologia...'
                  value={selectedStatementType}
                  options={optionsStatementTypes}
                  onChange={(e) => onChange(e, 'statementTypeCode', setSelectedStatementType)}
                />
                <OverlayTrigger
                  placement='top'
                  delay={{ show: 200, hide: 400 }}
                  overlay={tooltip}
                  {...(selectedStatementType.length !== 0 ? { show: false } : null)}>
                  <span className='d-inline-block'>
                    <Button
                      style={{
                        pointerEvents: selectedStatementType.length === 0 && 'none',
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                      }}
                      onClick={(e) => handleCreate(e)}
                      disabled={selectedStatementType.length === 0}>
                      Inizia un rendiconto
                    </Button>
                  </span>
                </OverlayTrigger>
              </InputGroup>
            </ButtonToolbar>
          )
        }
      />

      {/* <Row className='align-items-center mt-3'>
            <Col className='col-auto ms-auto text-right py-1'>
               <ButtonToolbar className='justify-content-end'>
                  <InputGroup className='align-items-center mt-3 mt-md-0 ms-2'>
                     <Select
                        classNamePrefix="select-statement"
                        name='statementTypeCode'
                        placeholder="Seleziona tipologia..."
                        value={selectedStatementType}
                        options={optionsStatementTypes}
                        onChange={e => onChange(e, 'statementTypeCode', setSelectedStatementType)}
                     />
                     <OverlayTrigger
                        placement='top'
                        delay={{ show: 200, hide: 400 }}
                        overlay={tooltip}
                        {...(selectedStatementType.length !== 0 ? { show: false } : null)}>
                        <span className='d-inline-block' style={{ margin: '1rem auto' }}>
                           <Button
                              style={{ pointerEvents: selectedStatementType.length === 0 && 'none', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                              onClick={e => handleCreate(e)}
                              disabled={selectedStatementType.length === 0}
                           >
                              Inizia un rendiconto
                           </Button>
                        </span>
                     </OverlayTrigger>
                  </InputGroup>
               </ButtonToolbar>
            </Col>
         </Row> */}

      <OETableAgGrid
        column={draftColumns}
        data={draftStatements}
        dataMeta={draftStatementsMeta}
        hideSearchBar={true}
        refTable='draftStatements'
      />

      {isInCreate && (
        <MakeReportingModal
          projectId={projectId}
          newStatement={newStatement}
          isOe={isOe || userRole === 5}
          setRefresh={setRefresh}
          isShowNew={true}
          setIsShowNew={setIsInCreate}
          localLang={localLang}
          optionsStatementTypes={optionsStatementTypes}
          refreshTotals={refreshTotals}
          isAdvancedSettings={isAdvancedSettings}
        />
      )}
    </>
  );
};
export default MakeReporting;
